import React from "react";
import { Card, CardBody, Col, Button, ButtonToolbar } from "reactstrap";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { load as loadAccount } from "./../../../../redux/reducers/commonReducer";
// import { Link } from 'react-router-dom';
// file upload
import RenderFileInputField from "../../../Form/components/FileUpload";
import CalendarBlankIcon from "mdi-react/CalendarBlankIcon";
import DatePicker from "../../../../shared/components/form/DatePicker";
import DefaultTextArea from "../../../../shared/components/form/DefaultTextArea";
import Select from "../../../../shared/components/form/Select";
// validate
import validate from "./validate";

// config
import fetch from "../../../../config/service";
import apiCalls from "../../../../config/apiCalls";
import config from "../../../../config/config";
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";

const renderField = ({
  input,
  placeholder,
  type,
  isDisable,
  meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap">
    <input
      {...input}
      disabled={isDisable}
      placeholder={placeholder}
      type={type}
    />
    {touched && error && (
      <span className="form__form-group-error">{error}</span>
    )}
  </div>
);

renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderField.defaultProps = {
  placeholder: "",
  meta: null,
  type: "text",
  isDisable: false,
};
// let phoneNoLength = 10;
const normalizePhone = (value) => {
  if (!value) {
    return value
  }
  const onlyNums = value.replace(/[^\d]/g, '')
  if (onlyNums.length <= 3) {
    return onlyNums
  }
  if (onlyNums.length <= 7) {
    return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`
  }
  return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10)}`
}
// const phoneLengthValidate = (value) => {
//   if (value && value.toString().trim().length > 0) {
//     let val = value.toString().trim();
//     val = val.replace(/-/g, '');
//     val = val.replace(/\(/g, '');
//     val = val.replace(/\)/g, '');
//     if (val && val.toString().trim().length >= phoneNoLength) {
//       return undefined;
//     } else {
//       return `Please enter valid ${phoneNoLength} digit number`;
//     }
//   } else {
//     return undefined;
//   }
// }
class Profile extends React.Component {
  static propTypes = {
    t: PropTypes.func,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      locationProps: "",
      profileFields: [
        {
          name: "email",
          label: "Email",
          id: "email",
          placeholder: "Enter Email",
          type: "text",
          required: true,
          isDisable: true,
        },
        {
          name: "phone",
          type: "text",
          placeholder: "Phone Number",
          label: "Phone Number",
          required: true,
        },
        {
          name: "fname",
          type: "text",
          placeholder: "FirstName",
          label: "First Name",
          required: true,
        },
        {
          name: "lname",
          type: "text",
          placeholder: "Last Name",
          label: "Last Name",
          required: true,
        },
        {
          name: "role",
          type: "text",
          placeholder: "Role",
          isDisable: true,
          required: true,
          label: "Role",
        },
      ],
    };
  }

  componentDidMount() {
    this.props.onRef(this);
    this.getUserData();
  }

  componentWillUnmount = () => {
    this.props.onRef(null);
  };

  // get data from server based on Id
  getUserData = async () => {
    let prevsPros = this.props.locationProps;
    this.setState({ locationProps: prevsPros });
    if (localStorage.getItem("loginCredentials")) {
      let user = await JSON.parse(localStorage.getItem("loginCredentials"));
      await this.setState({ userId: user._id });
      let Url = `${apiCalls.Employees}/${user._id}`;
      return fetch("GET", Url)
        .then(async (response) => {
          if (response && response.details) {
            if (response.details.photo) {
              this.setState({ pic: response.details.photo });
              user.photo = response.details.photo;
              localStorage.setItem("loginCredentials", JSON.stringify(user));
              this.updateTopbarData();
            }
            this.props.load(response.details);
          } else if (response && response.errorMessage) {
          }
        })
        .catch((err) => {
          return err;
        });
    } else {
      return;
    }
  };

  updateTopbarData = () => {
    let prevsPros = this.state.locationProps;
    if (
      prevsPros &&
      prevsPros.location &&
      prevsPros.location.state &&
      prevsPros.location.state.updateTopbarProfileData
    ) {
      this.props.locationProps.location.state.updateTopbarProfileData();
    }
  };

  getFileName = (file) => {
    this.setState({ pic: file });
  };

  submit = async (values) => {
    let formValues = Object.assign({}, values);

    if (formValues) {
      delete formValues.email;
      delete formValues.password;
      delete formValues.salt;
      let Url;
      if (localStorage.getItem("loginCredentials")) {
        let user = await JSON.parse(localStorage.getItem("loginCredentials"));
        Url = `${apiCalls.Employees}/${user._id}?type=profile`;
        user.photo = formValues.photo;
        user.fname = formValues.fname;
        user.lname = formValues.lname;
        return fetch("PUT", Url, formValues)
          .then(async (response) => {
            if (response && response.respCode && response.respCode === 205) {
              showToasterMessage(response.respMessage, "success");
            } else if (response && response.errorMessage) {
              showToasterMessage(response.errorMessage, "error");
            }
            localStorage.setItem("loginCredentials", JSON.stringify(user));
            this.updateTopbarData();
          })
          .catch((err) => {
            return err;
          });
      }
    } else {
      return;
    }
  };

  render() {
    const { handleSubmit, t } = this.props;
    const profileFields = this.state.profileFields;
    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text">Profile</h5>
            </div>
            <form className="form" onSubmit={handleSubmit(this.submit)}>
              <div>
                <div className="row">
                  {profileFields && profileFields.length > 0
                    ? profileFields.map((item, index) => (
                      <div
                        className="col-sm-12 col-md-6 pb-2"
                        key={item.name}
                      >
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {t(item.label)}
                          </span>
                          <div className="form__form-group-field">
                            {item.type === "text" ||
                              item.type === "textarea" ||
                              item.type === "password" ||
                              item.type === "email" ||
                              item.type === "url" ? (
                              <Field
                                id={item.id}
                                name={item.name}
                                component={
                                  item.type === "textarea"
                                    ? DefaultTextArea
                                    : renderField
                                }
                                type={item.text}
                                placeholder={t(item.placeholder)}
                                isDisable={
                                  item.isDisable ? item.isDisable : false
                                }
                                normalize={item.name === 'phone' ? normalizePhone : null}

                              />
                            ) : item.type === "dropDown" ? (
                              <Field
                                name={item.name}
                                component={Select}
                                type={item.text}
                                placeholder={t(item.placeholder)}
                                options={item.options}
                              />
                            ) : item.type === "date" ? (
                              <div className="w-100 d-flex">
                                <Field
                                  id={item.id}
                                  name={item.name}
                                  component={DatePicker}
                                  type={item.text}
                                  placeholder={t(item.placeholder)}
                                  isDisable={
                                    item.isDisable ? item.isDisable : false
                                  }
                                />
                                <div className="iconstyle form__form-group-icon">
                                  <CalendarBlankIcon />
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    ))
                    : null}
                  <div className="col-sm-12 col-md-6">
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {t("Profile Image")}
                      </span>
                      <div className="form__form-group-field">
                        <Field
                          name="photo"
                          component={RenderFileInputField}
                          onRef={(ref) => (this.profileUploadRef = ref)}
                          url={apiCalls.ProfileImagePath}
                          label="Upload photo"
                          type="profile"
                          acceptType={t("image/*")}
                          getFileName={this.getFileName}
                          imagePath={"employee"}
                        />
                      </div>
                      <div className="col-md-2" style={{ padding: "20px" }}>
                        <div style={{ justifyContent: "center" }}>
                          {this.state.pic ? (
                            <img
                              src={`${config.imgUrl}employee/${this.state.pic}`}
                              alt="user_photo"
                              className="detailsImgStyle"
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 ">
                    <div className="float-right">
                      <ButtonToolbar className="form__button-toolbar">
                        {/* <Link to={'/employee'}>
                          <Button style={{ marginLeft: '50px' }} color='primary'
                          >{t('Back')}</Button>
                        </Link> */}
                        <Button color="primary" type="submit">
                          {t("Submit")}
                        </Button>
                      </ButtonToolbar>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
Profile = reduxForm({
  form: "ProfileForm", // a unique identifier for this form
  enableReinitialize: true,
  validate,
})(Profile);

// You have to connect() to any reducers that you wish to connect to yourself
Profile = connect(
  (state) => ({
    initialValues: state.commonData.data, // pull initial values from account reducer
  }),
  { load: loadAccount } // bind account loading action creator
)(Profile);

export default withTranslation("common")(Profile);
