import React from "react";
import { FileUpload } from "primereact/fileupload";

import fetchMethodRequest from "../../../config/service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
// toaster message
import showToasterMessage from "../../../containers/UI/ToasterMessage/toasterMessage";
export default class ImageUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = { picture: [], fileName: "" };
  }

  componentWillUnmount() {
    this.props.onRef(null);
  }

  componentDidMount = async () => {
    await this.props.onRef(this);
  };

  onSelect = async (event) => {

    await this.setState({ picture: event.files[0], files: event.files });
    if (this.props.type && this.props.type === "bulkUpload") {
      if (this.props.setShowUpload) {
        this.props.setShowUpload(true);
      }
      return;
    } else {
      if (event) {
        this.UploadFileToServer(event.files);
      }
    }
  };

  onMultielect = async (event) => {

    await this.setState({
      picture: event.target.files[0],
      files: event.target.files,
    });
    if (this.props.type && this.props.type === "bulkUpload") {
      return;
    } else {
      if (event) {
        this.multipleUploadFileToServer(event.files);
      }
    }
  };

  UploadFileToServer = async () => {
    if (this.state.picture) {
      let imagePath = this.props.imagePath;
      let url = "";
      if (this.props.type === "bulkUpload") {
        url = this.props.url;
      } else if (this.props.type === "profile") {
        url = `uploads?uploadPath=${imagePath}`;
      }

      fetchMethodRequest("POST", url, this.state.picture, "upload").then(
        async (response) => {
          if (this.props.type === "bulkUpload") {
            if (this.props.setShowUpload) {
              this.props.setShowUpload(false);
            }
          }
          if (response && response.respCode) {
            if (response.fileName || response.fineName) {
              let fileName = response.fileName
                ? response.fileName
                : response.fineName;
              this.setState({ fileName: fileName });
              // call function in parent
              if (this.props.getFileName) {
                this.props.getFileName(fileName);
              }
              if (this.props.type === "profile") {
                if (fileName) {
                  this.props.input.onChange(fileName);
                }
              }
              if (response.respMessage === "204") {
                showToasterMessage(response.respMessage, "success");
              }
            }
            if (this.props.close) {
              this.props.close();
            }
          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, "error");
            if (this.props.resetClickCount) {
              await this.props.resetClickCount();
            }
          } else {
            if (response.sucess && response.sucess.respMessage) {
              showToasterMessage(response.sucess.respMessage, "success");
            } else if (response.failure && response.failure.errorMessage) {
              showToasterMessage(response.failure.errorMessage, "error");
            }
            if (this.props.close) {
              this.props.close();
            }
          }
        }
      );
    } else {
      return "";
    }
  };

  multipleUploadFileToServer() {
    if (this.state.picture) {

      fetchMethodRequest(
        "POST",
        "uploads?uploadPath=product",
        this.state.picture,
        "upload"
      )
        .then(async (response) => {
          if (response && response.respCode) {
            if (response.fileName || response.fineName) {
              let fileName = response.fileName
                ? response.fileName
                : response.fineName;
              this.setState({ fileName: fileName });
              // call function in parent
              if (this.props.getMulipleFileName) {
                this.props.getMulipleFileName(fileName);
              }
              if (this.props.type === "profile") {
                if (fileName) {
                  this.props.input.onChange(fileName);
                }
              }
              if (response.respMessage === "204") {
                showToasterMessage(response.respMessage, "success");
              }
            }
            if (this.props.close) {
              this.props.close();
            }
          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, "error");
          } else {
            if (response.sucess && response.sucess.respMessage) {
              showToasterMessage(response.sucess.respMessage, "success");
            } else if (response.failure && response.failure.errorMessage) {
              showToasterMessage(response.failure.errorMessage, "error");
            }
            if (this.props.close) {
              this.props.close();
            }
          }
        })
        .catch((err) => {
          return err;
        });
    } else {
      return "";
    }
  }

  docUploadHandler = ({ files }) => {

    if (files && files.length > 0) {
      this.multipleUploadFileToServer(files);
    }
    for (var x = 0; x < files.length; x++) {
      // var currFile = files[x];
      // var fileReader = new FileReader();
      // fileReader.onload = (e) => {
      //   console.log(currFile);
      // };
      // fileReader.readAsText(currFile);
    }
  };

  onRemove() {
    console.log("pp");
  }

  render() {
    return (
      <div style={{ wordBreak: "break-all" }}>
        {this.props.multiple ? (
          // <FileUpload
          //   onSelect={this.onMultielect}
          //   // onUpload={this.onUploadFile}
          //   // uploadHandler={this.docUploadHandler}
          //   customUpload={true}
          //   mode='advanced'
          //   name="file"
          //   uurl="./uploads?uploadPath=employee"
          //   maxFileSize={1000000}
          //   multiple
          //   auto={true}
          //   chooseLabel={this.props.label ? this.props.label : "Browse"}
          //   onClear={this.onRemove}
          //   accept={this.props.acceptType}
          // />
          <div className="box">
            <input
              type="file"
              name="file"
              className="inputfile"
              id="customFile"
              multiple
              accept="image/*"
              hidden
              onChange={this.onMultielect}
            />
            <label className="inputFilelabel" htmlFor="customFile">
              <div className="dotsCircle">
                <FontAwesomeIcon icon={faUpload} style={{ fontSize: 40 }} />
              </div>
            </label>
          </div>
        ) : (
          <FileUpload
            onSelect={this.onSelect}
            mode={"basic"}
            name="file"
            uurl="./upload.php"
            maxFileSize={1000000}
            auto={false}
            chooseLabel={this.props.label ? this.props.label : "Browse"}
            accept={this.props.acceptType}
          />
        )}
      </div>
    );
  }
}
