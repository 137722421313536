import React from 'react';
import {
    Button, Modal, ModalHeader, ModalBody, Card, CardBody
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { load as loadAccount } from './../../redux/reducers/commonReducer';
import { settingsPincode } from '../../redux/actions/settingsAction';

import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import AutoComplete from './components/AutoComplete'

import Select from '../../shared/components/form/FormSelect';
import DropdownSelect from '../../shared/components/form/Select';
import DefaultInput from '../../shared/components/form/DefaultInput';
import DatePicker from '../../shared/components/form/DatePicker';
import DefaultClearInput from '../../shared/components/form/DefaultClearInput';
import DefaultTextArea from '../../shared/components/form/DefaultTextArea';
import dateFormats from '../UI/FormatDate/formatDate';

import CheckBox from '../../shared/components/form/CheckBox';
import configMessages from '../../config/configMessages';
import fetchMethodRequest from '../../config/service';

// Toaster message
import showToasterMessage from '../UI/ToasterMessage/toasterMessage';

import validate from '../Validations/validate';

// Loader
import Loader from '../App/Loader';

//session expiry modal
import SessionExpiryModal from '../Cruds/CommonModals/SessionexpiryModal'
import config from '../../config/config';

let pincodeLength = 5;
let nameLength = 2;
let phoneNoLength = 10;
const required = (value, allValues, props, name) => {
    if ((typeof value === 'string' && value.length > 0) || value) {
        return undefined
    } else {
        return configMessages.fillField
    }
}
const requiredPincode = (value, allValues, props, name) => {
    if (value && value.toString().length === pincodeLength) {
        return undefined
    } else {
        return `Please enter ${pincodeLength} digits pincode`;
    }
};
const parentTypeRequired = (value, allValues, props, name) => {
    if(allValues.parent1 && allValues.parent2 && allValues.parent1 === allValues.parent2 ){
            return `Both parent1 type & parent2 type are same`
    }
};
const normalizePhone = (value) => {
    if (!value) {
        return value
    }
    const onlyNums = value.replace(/[^\d]/g, '')
    if (onlyNums.length <= 3) {
        return onlyNums
    }
    if (onlyNums.length <= 7) {
        return `(${onlyNums.slice(0, 3)})${onlyNums.slice(3)}`
    }
    return `(${onlyNums.slice(0, 3)})${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10)}`
}
const phoneLengthValidate = (value) => {
    if (value && value.toString().trim().length > 0) {
        let val = value.toString().trim();
        val = val.replace(/-/g, '');
        val = val.replace(/\(/g, '');
        val = val.replace(/\)/g, '');
        if (val && val.toString().trim().length >= phoneNoLength) {
            return undefined;
        } else {
            return `Please enter valid ${phoneNoLength} digit number`;
        }
    } else {
        return undefined;
    }
}

const nameLengthValidate = (value) => {
    if (value && value.toString().trim().length > 0) {
        if (value && value.toString().trim().length >= nameLength) {
            return undefined;
        } else {
            return `Require minimum ${nameLength} characters`;
        }
    } else {
        return undefined;
    }
}
const emailLengthValidate = (value) => {
    if (value && value.toString().trim().length > 0) {
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
            return `Invalid email address`;
        } else {
            return undefined;
        }
    }

}

class FamilyFormModal extends React.Component {
    static propTypes = {
        t: PropTypes.func,
        handleSubmit: PropTypes.func.isRequired,
        reset: PropTypes.func.isRequired,
        pristine: PropTypes.bool.isRequired,
        submitting: PropTypes.bool.isRequired,
    };
    constructor(props) {
        super(props);
        this.state = {
            deleteStudent: '',
            openDeleteModal: false,
            formFields: [],
            formValues: {},
            checked: false,
            sessionExpiryModal: false,
            studentArray: [],
            removedChilds: [],
            schoolOptions: [],
            classesOptions: [],
            stateOptions: config.stateOptions,
            schoolRecords: [],
            districts: [],
            isDisabledSchoolField: "",
            defaultFilterCriteria: {
                limit: 15,
                page: 1,
                criteria: [],
                sortfield: "created",
                direction: "desc"
            },
            showContactInfo: false,
            lastName1AsFamily: false,
            relatedlastName1AsFamily: false,
            // lastName2AsFamily: false,
            // relatedlastName2AsFamily: false,
            filteredParentOptions:[]
        };
    }

    handlechage = (value) => {
        this.setState({
            related_family: value
        })

    }

    componentDidMount = async () => {
        if (this.props.formType === 'add') {
            await this.props.reset();
            await this.props.load({})
            //Adding students Array default for first time
            this.addStudentArray('add')
        }
        if (this.props.onRef) {
            this.props.onRef(this);
        }

        await this.checkSessionExpire();
        let settingsData;
        let parentTypeOptions = [];
       await fetchMethodRequest('GET', `settings`).then(async (response) => {
            await this.checkSessionExpire();
            if (response && response.respCode) {
                settingsData = response.settings[0];
                parentTypeOptions = response.settings[0].parentTypes;
                pincodeLength = settingsData['pincodeLength'];
                await this.props.settingsPincodeLength(settingsData['pincodeLength'])
                await this.getParentOptions(parentTypeOptions);
            }
        })
        let getloginCredentials = JSON.parse(localStorage.getItem("loginCredentials"))
        await this.setState({
            roleName: getloginCredentials.role
        })
        this.getFormFields();
        // await this.getSchoolNames();
        await this.getDistrictOptions()
        
    }
    getDistrictOptions = () => {
        fetchMethodRequest('GET', `districts`)
            .then(async (response) => {
                if (response && response.districts) {
                    let districts = response.districts;
                    let modifieddistricts = [];
                    for (let district of districts) {
                        modifieddistricts.push({
                            label: district.name,
                            value: {
                                district_name: district.name,
                                district_id: district.district_id
                            }
                        })
                    }
                    await this.setState({
                        districts: modifieddistricts,
                        // isLoading: false
                    })
                    // this.setUnitName()
                }
            }).catch((err) => {
                return err;
            });
    }
    getParentOptions = async (options) => {
        let parentOptions = [];
        for (let i = 0; i < options.length; i++) {
            parentOptions.push(
                { label: options[i], value: options[i] }
            )
        }
        await this.setState({
            parentTypeOptions: parentOptions,
        })
    }

    checkSessionExpire = async () => {
        let sessionexpired = await localStorage.getItem('sessionexpired')
        if (sessionexpired === "true") {
            await this.setState({ sessionExpiryModal: true })
        }
    }

    //getSchoolNames
    getSchoolNames = async (value, key, studentIndex) => {
        let temp = this.state.studentArray
        let filter = this.state.defaultFilterCriteria;
        if (typeof value == "string") {
            filter['criteria'] = [{
                key: key,
                value: value,
                type: 'eq'
            }]
        } else {
            filter['criteria'] = [{
                key: key,
                value: value["district_name"],
                type: 'eq'
            }]
        }
        filter.limit = 10000;
        console.log("filter", url)
        let url = `schools?filter=${JSON.stringify(filter)}`
        return fetchMethodRequest('GET', url).then(async (response) => {
            await this.checkSessionExpire();
            if (response) {
                let studentData = this.state.studentArray;
                let formFields = studentData[studentIndex].stuFormFields
                let temp = [];
                let tempx = this.state.schoolOptions;
                let resdata = response['schools'];
                if (resdata && resdata.length > 0) {
                    for (let i = 0; i < resdata.length; i++) {
                        temp.push({
                            "label": resdata[i]['Name'],
                            "value": resdata[i]['Name']
                        })
                    }
                }
                tempx = temp
                studentData[studentIndex]['schoolOptions'] = tempx
                // await this.getDistrictIds(value, 'district_id', studentIndex, fieldKey, name);
                studentData[studentIndex]['totalSchoolData'] = resdata
                studentData[studentIndex]['district__name'] = value["name"]
                studentData[studentIndex]['district_id'] = value["district_id"]

                await this.setState({
                    schoolOptions: tempx,
                    studentArray: studentData,
                    schoolRecords: response['schools']
                })
            }

            this.setState({
                // isLoading: false,
                removedChilds: [],
            });
        }).catch((err) => {
            return err;
        });
    }

    getClassNames = async (value, key, studentIndex) => {
        let filter = this.state.defaultFilterCriteria;
        filter['criteria'] = [{
            key: key,
            value: value,
            type: 'eq'
        }]
        filter.limit = 10000;
        let url = `classes?filter=${JSON.stringify(filter)}`
        return fetchMethodRequest('GET', url).then(async (response) => {
            await this.checkSessionExpire();
            if (response) {
                let studentData = this.state.studentArray;
                let temp = [];
                let tempx = this.state.classesOptions;
                let resdata = response['classes'];
                if (resdata && resdata.length > 0) {
                    for (let i = 0; i < resdata.length; i++) {
                        temp.push({
                            "label": `${resdata[i]['class_display_name']}`,
                            "value": resdata[i]['_id'],
                        })
                    }
                }
                tempx = temp;
                studentData[studentIndex]['classesOptions'] = tempx;
                studentData[studentIndex]['totalClassData'] = resdata;
                await this.setState({
                    classesOptions: tempx,
                    studentArray: studentData
                })
            }

            this.setState({
                // isLoading: false,
                removedChilds: [],
            });
        }).catch((err) => {
            return err;
        });
    }

    getDistrictIds = async (e, searchName, studentIndex, fieldName, name) => {
        let temp = this.state.studentArray;
        let allRecords = this.state.schoolRecords;
        for (let i = 0; i < allRecords.length; i++) {
            if (e === allRecords[i]["Name"]) {
                // temp[studentIndex]['district_id'] = allRecords[i]['district_id'];
                // temp[studentIndex]['district_name'] = allRecords[i]['district_name'];
                temp[studentIndex]['school_id'] = allRecords[i]['school_id'];
            }
        }
        await this.setState({ studentArray: temp })
    }

    //addstudentArray
    addStudentArray = async (type, studentIndex, fieldName, e, name) => {
        let temp = this.state.studentArray;
        // console.log("hello", temp)
        if (type === 'add') {
            let tempLength = temp.length > 0 ? temp.length : 0;

            let formFields = [];
            formFields.push({
                "name": `district_nameARR${tempLength + 1}`,
                type: 'autoComplete',
                label: 'District Name',
                required: true
            });
            formFields.push({
                "name": `school_nameARR${tempLength + 1}`,
                type: 'dropdown',
                label: 'School Name',
                required: true
            });
            formFields.push({
                "name": `class_nameARR${tempLength + 1}`,
                type: 'dropdown',
                label: 'Class Name',
                required: true
            })
            formFields.push({
                "name": `birthdayARR${tempLength + 1}`,
                type: 'date',
                label: 'Birth Date',
                required: true
            })
            temp.push(
                {
                    stuFormFields: formFields
                }
            )
            await this.setState({
                studentArray: temp
            })

        } else if (type === 'delete') {
            if (this.props.formType === 'edit') {
                let deletedIds = this.state.removedChilds;
                deletedIds.push({
                    "child_id": temp[studentIndex]["child_id"]                })
                await this.setState({ removedChilds: deletedIds })
            }
            temp.splice(studentIndex, 1);
            await this.setState({
                studentArray: temp
            })
        } else if (type === 'change') {
            let fieldKey = fieldName;
            let sliceFrom = fieldName.search("ARR");
            if (sliceFrom >= 0) {
                fieldKey = fieldName.slice(0, sliceFrom);
            }
            // console.log("temp", fieldKey)
            if (e && e.target && e.target.value) {
                if (e.target.value) {
                    let captalizeValue = e.target.value.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
                    temp[studentIndex][fieldKey] = captalizeValue;
                    temp[studentIndex][name] = captalizeValue;
                    await this.setState({
                        studentArray: temp
                    })
                } else {
                    temp[studentIndex][fieldKey] = '';
                    temp[studentIndex][name] = '';
                    await this.setState({
                        studentArray: temp
                    })
                }
            } else if (e && name === "Student Birthday") {
                let formattedDate = dateFormats.formatDate(
                    e,
                    config.dbOnlyDateFormat
                )
                temp[studentIndex][fieldKey] = formattedDate
                temp[studentIndex][name] = formattedDate;

                await this.setState({
                    studentArray: temp
                })
            } else {
                temp[studentIndex][fieldKey] = '';
                temp[studentIndex][name] = '';
                await this.setState({
                    studentArray: temp
                })
            }
        } else if (type === 'dropdown') {
            let fieldKey = fieldName;
            let sliceFrom = fieldName.search("ARR");
            if (sliceFrom >= 0) {
                fieldKey = fieldName.slice(0, sliceFrom);
            }
            if (e) {
                if (fieldKey === 'class_name') {
                    temp[studentIndex]['totalClassData'].map(clas => {
                        if (clas._id === e) {
                            temp[studentIndex][fieldKey] = clas[fieldKey];
                            temp[studentIndex][name] = `${clas['class_display_name']}`;
                            temp[studentIndex]['grade_int'] = clas['grade']
                            temp[studentIndex]['class_val'] = clas['class_name']
                        }
                        return clas
                    })

                    await this.setState({
                        studentArray: temp
                    })
                    // console.log("hello", temp)
                } else if (fieldKey === 'school_name') {
                    temp[studentIndex][fieldKey] = e;
                    temp[studentIndex][name] = e;
                    // reset class on school change
                    temp[studentIndex]['class_name'] = '';
                    temp[studentIndex]['Class Name'] = '';
                    temp[studentIndex]['class_nameARR1'] = '';
                    temp[studentIndex]['grade_int'] = ''
                    temp[studentIndex]['class_val'] = ''
                    this.props.change('class_nameARR1', '')

                    await this.setState({
                        studentArray: temp
                    })


                    await this.getStudentFormFields(e, 'school_name', studentIndex);
                    await this.getClassNames(e, 'school_name', studentIndex);
                    // await this.getDistrictIds(e, 'district_id', studentIndex, fieldKey, name);
                } else if (fieldKey === 'district_name') {
                    // temp[studentIndex][fieldKey] = e.district_name;
                    // temp[studentIndex][name] = e.district_name;
                    // reset class on school change
                    console.log("Eee", e)
                    temp[studentIndex]['district_name'] = e.district_name;
                    temp[studentIndex]['District Name'] = e.district_name;
                    temp[studentIndex]['district_nameARR1'] = e.district_name;
                    this.props.change('district_nameARR1', e.district_name)

                    await this.setState({
                        studentArray: temp
                    })
                    await this.getSchoolNames(e, 'district_name', studentIndex);
                } else {
                    temp[studentIndex][fieldKey] = e;
                    temp[studentIndex][name] = e;
                    await this.setState({
                        studentArray: temp
                    })
                }
            } else {
                temp[studentIndex][fieldKey] = '';
                temp[studentIndex][name] = '';
                await this.setState({
                    studentArray: temp
                })
            }
        }
    }

    componentWillUnmount() {
        if (this.props.onRef) {
            this.props.onRef(null);
        }
    }

    getFormFields = async () => {
        if (this.props.formFields) {
            let formFields = await this.props.formFields();
            await this.setState({ formFields: formFields });
        }
    }

    getRowDataFromServer = async (item, apiUrl) => {
        let id = item._id;
        let path = apiUrl;
        let url = `${path}/${id}`;
        return fetchMethodRequest('GET', url).then(async res => {
            if (res && res.details) {
                return res.details;
            } else if (res && res.respMessage) {
                showToasterMessage(res.errorMessage, 'error')
                return null;
            } else {
                return item;
            }
        }).catch(err => {
            return err;
        })
    }

    //Get From Fields data on Edit
    getRowData = async (item, apiUrl) => {
        await this.setState({ isLoading: true })
        let selectedRowInfo = await this.getRowDataFromServer(item, apiUrl);
        let rowData = selectedRowInfo;
        if (this.props.type === 'Family' && this.props.formType === 'edit') {
            if (rowData['childrens'] && rowData['childrens'].length > 0) {
                let children = rowData['childrens'].length > 0 ? rowData['childrens'] : [];
                for (let i = 0; i < children.length; i++) {
                    let formFields = [];
                    let temp = this.state.studentArray;
                    formFields.push({
                        "name": `district_nameARR${i + 1}`,
                        type: 'autoComplete',
                        label: 'District Name',
                        required: true
                    });
                    formFields.push({
                        "name": `school_nameARR${i + 1}`,
                        type: 'dropdown',
                        label: 'School Name',
                        required: true
                    })
                    formFields.push({
                        "name": `class_nameARR${i + 1}`,
                        type: 'dropdown',
                        label: 'Class Name',
                        required: true
                    })

                    temp.push(
                        {
                            stuFormFields: formFields
                        }
                    )
                    await this.setState({
                        studentArray: temp
                    })
                    await this.getDistrictOptions()
                    await this.getSchoolNames(children[i]['district_name'], 'district_name', i);
                    await this.getClassNames(children[i]['school_name'], 'school_name', i);
                    await this.getStudentFormFields(children[i]['school_name'], 'school_name', i);
                    let studentArr = this.state.studentArray;
                    studentArr.map((stu, ind) => {
                        stu['child_id'] = children[ind]['child_id'];
                        stu['grade_int'] = children[ind]['grade_int'];
                        stu['class_val'] = children[ind]['class_name'];
                        stu['district_id'] = children[ind]['district_id'];
                        stu['district_name'] = children[ind]['district_name'];
                        stu['district__name'] = children[ind]['district_name'];
                        // this.props.change(stu[`district_nameArr${ind},)
                        let fields = stu.stuFormFields;

                        for (var field of fields) {
                            var fieldKey = field.name;
                            var sliceFrom = fieldKey.search("ARR");
                            if (sliceFrom >= 0) {
                                fieldKey = fieldKey.slice(0, sliceFrom);
                            }
                            if (fieldKey === 'class_name') {
                                stu[field.label] = `${children[ind]['grade_int']}${children[ind]['class_name'] ? ' - ' + children[ind]['class_name'] : ''}`
                                stu[field.name] = `${children[ind]['grade_int']}${children[ind]['class_name'] ? ' - ' + children[ind]['class_name'] : ''}`
                            } if (fieldKey === 'district_name') {
                                stu[field.label] = children[ind]['district_name']
                                stu[field.name] = children[ind]['district_name']
                            } else {
                                stu[field.label] = children[ind][fieldKey]
                                stu[field.name] = children[ind][fieldKey]
                            }
                            if (fieldKey === 'birthday') {
                                stu[field.label] =
                                    children[ind][fieldKey]
                                stu[field.name] =
                                    children[ind][fieldKey]

                            }
                        }
                        return stu
                    })
                }

                rowData['childrens'] = children;
            }

            let showContactInfo = false;
            if (rowData['showFullAddress'] &&
                rowData['showEmail1'] && rowData['showEmail2'
                ] && rowData['showphone1'] && rowData['showphone2'
                ] && rowData['showmobile1'] && rowData['showmobile2']
                && rowData['showhome1'] && rowData['showhome2']
            ) {
                showContactInfo = true
            } else {
                showContactInfo = false
            }
            await this.setState({
                studentArray: this.state.studentArray,
                showEmail1: rowData['showEmail1'],
                showEmail2: rowData['showEmail2'],
                showphone1: rowData['showphone1'],
                showphone2: rowData['showphone2'],
                showmobile1: rowData['showmobile1'],
                showmobile2: rowData['showmobile2'],
                showhome1: rowData['showhome1'],
                showhome2: rowData['showhome2'],
                showFullAddress: rowData['showFullAddress'],
                showContactInfo: showContactInfo
            })
        }

        await this.setState({
            isLoading: false,
            editRowDataID: rowData._id,
            family: rowData['family']
        });

        // for (var obj of rowData.childrens) {
        //     obj.birthday = "04/04/2022"
        // }
        //to load intail values of form
        if (this.props.load) {
            await this.props.load(rowData);
        }

    }

    getStudentFormFields = async (schoolVal, schoolField, stuIndex) => {
        let temp = this.state.studentArray;

        temp[stuIndex].stuFormFields.splice(3)
        let schoolFormFields = await this.getSchoolFields(schoolVal);
        schoolFormFields.map(field => {

            if (field && field.name) {
                if (field.name === 'student_lastname') {
                    temp[stuIndex][field.label] = this.state.family;
                }
                temp[stuIndex].stuFormFields.push(
                    {
                        name: `${field.name}ARR${stuIndex + 1}`,
                        type: field.type,
                        label: field.label,
                        options: field.options,
                        required: field.autoValidate,
                    }
                )
            }
            return field
        })
        await this.setState({
            studentArray: temp,
        })

    }

    getSchoolFields = async (val) => {
        let schools = this.state.schoolRecords;
        let sff = [];
        schools && schools.map(school => {
            // if (school.Name === val) {
            if (school.studentFormFields && school.studentFormFields.length && school.studentFormFields.length > 0) {
                sff = school.studentFormFields;

            }
            // }
            // }

            if (school.Name === val) {
                this.setState({
                    isDisabledSchoolField: val,

                })
            }

            return school
        })

        return sff;
    }

    closeFormModal = async () => {
        await this.clearFormFields();
        await this.props.reset();
        await this.props.closeFormModal(this.props.filterCriteria);
    }

    clearFormFields = async () => {
        if (this.props.load) {
            this.props.load({});
        }
        let formData = [...this.state.formFields];
        formData.forEach((item) => {
            item.value = '';
            item.invalid = false;
            item.message = ''
        });
        await this.setState({
            formFields: formData
        })
        this.getFormFields();
    }

    // form Submit
    submit = (values) => {
        if (values && Object.keys(values).length > 0) {
            this.saveDataToServer(values);
        } else {
            return;
        }
    }

    //send data to server
    saveDataToServer = async (formData) => {

        let formValues = Object.assign({}, formData);
        let validationExists;
        this.setState({
            isLoading: true
        });
        if (!validationExists) {
            if (formValues) {
                let method, apiUrl;
                if (this.props.formType === 'edit') {
                    delete formValues.email
                    delete formValues.password;
                    method = 'PUT';
                    apiUrl = `${this.props.apiUrl}/${this.state.editRowDataID}`;
                }
                else {
                    method = 'POST';
                    apiUrl = this.props.apiUrl;
                }
                if (this.props.type === 'Family') {
                    let studentArray2 = this.state.studentArray;
                    let studentArray = await JSON.parse(JSON.stringify(studentArray2));
                    if (studentArray && studentArray.length > 0) {
                        for (let i = 0; i < studentArray.length; i++) {
                            let schools = this.state.schoolRecords;
                            studentArray[i].stuFormFields.map(field => {
                                if (field.name) {
                                    let fieldKey = field.name;
                                    let sliceFrom = fieldKey.search("ARR");
                                    if (sliceFrom >= 0) {
                                        fieldKey = fieldKey.slice(0, sliceFrom);
                                    }
                                    if (fieldKey === 'class_name') {
                                        studentArray[i][fieldKey] = studentArray[i]['class_val'] ? studentArray[i]['class_val'] : ''
                                        if (studentArray[i][fieldKey] == "") {
                                            delete studentArray[i]['class_name']
                                        }

                                        delete studentArray[i]['class_val']
                                    } else {
                                        studentArray[i][fieldKey] = studentArray[i][field.label] ? studentArray[i][field.label] : ''
                                    }
                                    delete studentArray[i][field.label]
                                    if (!studentArray[i]['school_id']) {
                                        schools.map(school => {
                                            if (school.Name === studentArray[i].school_name) {
                                                studentArray[i]['school_id'] = school.school_id;
                                                studentArray[i]['district_id'] = school.district_id;
                                                studentArray[i]['district_name'] = school.district_name;

                                            }
                                            return school
                                        })
                                    }
                                }
                                return field
                            })

                            Object.keys(studentArray[i]).forEach((item, index) => {
                                if (studentArray[i].district__name != undefined && studentArray[i].district_name == "") {
                                    studentArray[i].district_name = studentArray[i].district__name;
                                }
                                if (item.includes('ARR') || item === 'stuFormFields' || item === 'classesOptions' || item === 'totalClassData' || item === 'schoolOptions' || item === 'totalSchoolData' || item === 'district__name') {
                                    delete studentArray[i][item];
                                    // delete

                                }
                            })

                        }
                        formValues['childrens'] = studentArray;

                        Object.keys(formValues).forEach((arrItem, arrIndex) => {
                            if (arrItem.includes("ARR")) {
                                delete formValues[arrItem];
                            }
                        })
                    }
                    if (this.props.formType === 'edit' && this.state.removedChilds && this.state.removedChilds.length > 0) {
                        formValues['removedChilds'] = this.state.removedChilds;
                    }
                }
                //  Object.assign(relatedfamily, { family: "formValues.related_family" });
                formValues['showEmail1'] = this.state['showEmail1'];
                formValues['showEmail2'] = this.state['showEmail2'];
                formValues['showphone1'] = this.state['showphone1'];
                formValues['showphone2'] = this.state['showphone2'];
                formValues['showmobile1'] = this.state['showmobile1'];
                formValues['showmobile2'] = this.state['showmobile2'];
                formValues['showhome1'] = this.state['showhome1'];
                formValues['showhome2'] = this.state['showhome2'];
                formValues['showFullAddress'] = this.state['showFullAddress'];
                // // Family 2 fields 
                // formValues['relatedFamily'] = {}
                // formValues['relatedFamily']['family'] = formValues.related_family;
                // formValues['relatedFamily']['parent1'] = formValues.related_parent1;
                // formValues['relatedFamily']['p1_suffix'] = formValues.related_p1_suffix;
                // formValues['relatedFamily']['p1_firstname'] = formValues.related_p1_firstname;
                // formValues['relatedFamily']['p1_middlename'] = formValues.related_p1_middlename;
                // formValues['relatedFamily']['lastName1AsFamily'] = formValues.related_lastName1AsFamily;
                // formValues['relatedFamily']['p1_lastname'] = formValues.related_p1_lastname;
                // formValues['relatedFamily']['parent2'] = formValues.related_parent2;
                // formValues['relatedFamily']['p2_suffix'] = formValues.related_p2_suffix;
                // formValues['relatedFamily']['p2_firstname'] = formValues.related_p2_firstname;
                // formValues['relatedFamily']['p2_middlename'] = formValues.related_p2_middlename;
                // formValues['relatedFamily']['lastName2AsFamily'] = formValues.related_lastName2AsFamily;
                // formValues['relatedFamily']['p2_lastname'] = formValues.related_p2_lastname;
                // formValues['relatedFamily']['showContactInfo'] = formValues.related_showContactInfo;
                // formValues['relatedFamily']['showFullAddress'] = formValues.related_showFullAddress;
                // formValues['relatedFamily']['address1'] = formValues.related_address1;
                // formValues['relatedFamily']['address2'] = formValues.related_address2;
                // formValues['relatedFamily']['zipCode'] = formValues.related_zipCode;
                // formValues['relatedFamily']['city'] = formValues.related_city;
                // formValues['relatedFamily']['state'] = formValues.related_state;
                // formValues['relatedFamily']['email1'] = formValues.related_email1;
                // formValues['relatedFamily']['showEmail1'] = formValues.related_showEmail1;
                // formValues['relatedFamily']['email2'] = formValues.related_email2;
                // formValues['relatedFamily']['showEmail2'] = formValues.related_showEmail2;
                // formValues['relatedFamily']['phone1_work'] = formValues.related_phone1_work;
                // formValues['relatedFamily']['showphone1'] = formValues.related_showphone1;
                // formValues['relatedFamily']['phone1_mobile'] = formValues.related_phone1_mobile;
                // formValues['relatedFamily']['showmobile1'] = formValues.related_showmobile1;
                // formValues['relatedFamily']['phone2_work'] = formValues.related_phone2_work;
                // formValues['relatedFamily']['showphone2'] = formValues.related_showphone2;
                // formValues['relatedFamily']['phone2_mobile'] = formValues.related_phone2_mobile;
                // formValues['relatedFamily']['showmobile2'] = formValues.related_showmobile2;
                // delete formValues.related_family; delete formValues.related_family;
                // delete formValues.related_phone2_mobile; delete formValues.related_phone2_work; delete formValues.related_phone1_mobile; delete formValues.related_phone1_work;
                // delete formValues.related_email1; delete formValues.related_email2; delete formValues.related_state; delete formValues.related_city; delete formValues.related_zipCode; delete formValues.related_address2; delete formValues.related_address1;
                // delete formValues.related_showFullAddress; delete formValues.related_showContactInfo; delete formValues.related_p2_lastname; delete formValues.related_lastName2AsFamily; delete formValues.related_p2_middlename; delete formValues.related_p2_firstname; delete formValues.related_p2_suffix;
                // delete formValues.related_parent2; delete formValues.related_p1_lastname; delete formValues.related_lastName1AsFamily; delete formValues.related_family; delete formValues.related_parent1; delete formValues.related_p1_suffix; delete formValues.related_p1_firstname;
                delete formValues['Sno'];
                delete formValues['createdString'];
                delete formValues['createdDate'];
                delete formValues['showContactInfo'];
                delete formValues['lastName1AsFamily'];
                delete formValues['lastName2AsFamily'];

                if (formValues && formValues['childrens'] && formValues['childrens'].length > 0) {
                } else {
                    showToasterMessage('At least one student required', 'error')
                    this.setState({
                        isLoading: false
                    })
                    return null
                }
                return fetchMethodRequest(method, apiUrl, formValues).then(async (response) => {
                    await this.checkSessionExpire();
                    if (response && response.respCode) {
                        await this.props.getDataFromServer(this.props.filterCriteria);
                        showToasterMessage(response.respMessage, 'success');
                        if (response) {
                            // this.props.closeFormModal('save', response.quantityId);
                            this.props.closeFormModal();
                        }
                        this.props.reset();
                        this.clearFormFields();
                    } else if (response && response.errorMessage) {
                        showToasterMessage(response.errorMessage, 'error');
                    }
                    this.setState({
                        isLoading: false,
                        removedChilds: [],
                    });
                }).catch((err) => {
                    return err;
                });
            } else {
                return;
            }
        }
    }

    autoPopulateCityStateFromZipCode = async (value) => {
        let filter = {}
        if (value && value.length === 5) {
            filter['criteria'] = [{
                key: 'zip_code',
                value: value,
                type: 'eq'
            }]
            let url = `zipcodes?filter=${JSON.stringify(filter)}`;
            fetchMethodRequest('GET', url).then(async (resp) => {
                if (resp && resp.zipcodes && resp.zipcodes.length > 0) {
                    let data = resp.zipcodes[0];
                    this.props.change('zipCode', data.zip_code)
                    this.props.change('state', data.state)
                    this.props.change('city', data.city)
                    return data;
                } else {
                    this.props.change('state', '')
                    this.props.change('city', '')
                    return null
                }
            }).catch(err => {
                return err
            })
        } else {
            return null
        }
    }

    toggleContacInfoCheckbox = async () => {
        if (this.state.showFullAddress &&
            this.state.showEmail1 && this.state.showEmail2
            && this.state.showphone1 && this.state.showphone2
            && this.state.showmobile1 && this.state.showmobile2
            && this.state.showhome1 && this.state.showhome2
        ) {
            await this.setState({
                showContactInfo: true
            })
        } else {
            await this.setState({
                showContactInfo: false
            })
        }
    }

    toggleShowContactInfo = async (bool, name) => {
        if (name) {
            await this.setState(
                {
                    [name]: !bool,
                },
                async () => {
                    await this.toggleContacInfoCheckbox()
                }
            )
        } else {
            await this.setState({
                showContactInfo: !this.state.showContactInfo,
            },
                async () => {
                    await this.setState({
                        showFullAddress: this.state.showContactInfo,
                        showEmail1: this.state.showContactInfo,
                        showEmail2: this.state.showContactInfo,
                        showphone1: this.state.showContactInfo,
                        showphone2: this.state.showContactInfo,
                        showmobile1: this.state.showContactInfo,
                        showmobile2: this.state.showContactInfo,
                        showhome1: this.state.showContactInfo,
                        showhome2: this.state.showContactInfo,
                    })
                }
            )
        }
    }

    toggleLastNameAsFamilyName = async (bool, name, lastname, type) => {
        let famName = '';
        if (bool) {
            famName = this.state.family;
        }

        await this.setState({
            [name]: bool,
        })

        await this.props.change([lastname], famName)


    }

    onchangeLastName = (val, name) => {
        let bool = false
        if (val === this.state.family) {
            bool = true
        }
        this.setState({
            [name]: bool
        })
    }

    getModalHeader(t) {
        return (
            <ModalHeader className="modal__header" >
                <button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.closeFormModal} />
                <p className="bold-text  modal__title"><b>
                    {this.props.formType && this.props.formType === 'edit' ? t('Edit ') : t('Add ')}
                    {this.props.type ? t(this.props.type) : null}
                </b></p>
            </ModalHeader>
        )
    }


    getModalBody(handleSubmit, t) {

        // Clering the input fields of Student school Name && class Name while changing the district Name s
        if (this.state.studentArray.length > 0) {
            this.state.studentArray = this.state.studentArray.map(
                (data, i, selfarr) => {
                    let districtName = selfarr[0].district_name;
                    let district_id = selfarr[0].district_id;
                    let totalSchoolData = selfarr[0].totalSchoolData && selfarr[0].totalSchoolData;
                    let schoolNameOptions = totalSchoolData && totalSchoolData.map(data => data.Name);
                    console.log('schoolopts', schoolNameOptions);
                    data = {
                        ...data,
                        district_nameARR2: districtName,
                        district_name: districtName,
                        district_nameARR1: districtName,
                        "District Name": districtName,
                        district_id: district_id,
                        schoolOptions: selfarr[i].schoolOptions && selfarr[i].schoolOptions,
                        totalSchoolData: selfarr[i].totalSchoolData && selfarr[i].totalSchoolData
                    }
                    if (schoolNameOptions && !schoolNameOptions.includes(data["School Name"])) {
                        return {
                            ...data,
                            "classesOptions": null,
                            "School Name": null,
                            "Class Name": null,
                            "school_name": null,
                            "class_name": null,
                            "grade_int": null,
                            'class_val' : null
                        }
                    }
                    else {
                        return data;
                    }
                }
            );
        }
        return (
            <ModalBody className="modal__body mb-0">
                <Card className='m-0 p-0 cradBodres' >
                    <CardBody className='m-0 p-2'>
                        <Loader loader={this.state.isLoading} />
                        <form key={this.props.type} onSubmit={handleSubmit(this.submit)} autoComplete={'off'}>
                            <div className='row form'>
                                <div className='col-sm-6'>
                                    <div className="form__form-group" >
                                        <label className="form__form-group-label">{t('Family Name')}</label>
                                        <div className="form__form-group-field mb-2">
                                            <Field
                                                type='text'
                                                name='family'
                                                component={DefaultInput}
                                                id='family'
                                                validate={[required, nameLengthValidate]}
                                                placeholder={t('Family Name')}
                                                onChange={(e) => {
                                                    this.setState({ family: e });
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-sm-6 mb-0'>
                                    <div className="form__form-group">
                                        <label className="form__form-group-label">{'Parent1 Type'}</label>
                                        <Field
                                            name='parent1'
                                            component={DropdownSelect}
                                            type='dropDown'
                                            options={this.state.parentTypeOptions}
                                            placeholder={'Parent1 Type'}
                                            // onChange={(e)=> this.parentOptionSelect(e, 'parent1')}
                                        validate={[parentTypeRequired]}
                                        />
                                    </div>
                                </div>
                                <div className='col-sm-6'>
                                    <div className="form__form-group" >
                                        <label className="form__form-group-label">{t('Parent1 Suffix')}</label>
                                        <div className="form__form-group-field mb-2">
                                            <Field
                                                type='text'
                                                name='p1_suffix'
                                                component={DefaultInput}
                                                id='p1_suffix'
                                                // validate={[required]}
                                                placeholder={t('Parent1 Suffix')}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-sm-6'>
                                    <div className="form__form-group" >
                                        <label className="form__form-group-label">{t("Parent1 First Name")}</label>
                                        <div className="form__form-group-field mb-2">
                                            <Field
                                                type='text'
                                                name='p1_firstname'
                                                component={DefaultInput}
                                                id='p1_firstname'
                                                validate={[required, nameLengthValidate]}
                                                placeholder={t('Parent1 First Name')}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-sm-6'>
                                    <div className="form__form-group" >
                                        <label className="form__form-group-label">{t('Parent1 Middle Name')}</label>
                                        <div className="form__form-group-field mb-2">
                                            <Field
                                                type='text'
                                                name='p1_middlename'
                                                component={DefaultInput}
                                                id='p1_middlename'
                                                placeholder={t('Parent1 Middle Name')}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-sm-6'>
                                    <div className="form__form-group" >
                                        <label className="form__form-group-label sameFamNameCheckBox">
                                            {t('Parent1 Last Name')}
                                            <span className='pl-2'>
                                                <Field
                                                    type='checkbox'
                                                    className=' my-0 align-items-center'
                                                    name='lastName1AsFamily'
                                                    component={CheckBox}
                                                    id='lastName1AsFamily'
                                                    label={t('Same as Family')}
                                                    checked={this.state.lastName1AsFamily}
                                                    onChange={() => this.toggleLastNameAsFamilyName(!this.state.lastName1AsFamily, 'lastName1AsFamily', 'p1_lastname')}
                                                />
                                            </span>
                                        </label>
                                        <div className="form__form-group-field mb-2">
                                            <Field
                                                type='text'
                                                name='p1_lastname'
                                                component={DefaultInput}
                                                id='p1_lastname'
                                                validate={[required, nameLengthValidate]}
                                                placeholder={t('Parent1 Last Name')}
                                                onChange={(e) => this.onchangeLastName(e, 'lastName1AsFamily')}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className='col-sm-6 mb-0'>
                                    <div className="form__form-group">
                                        <label className="form__form-group-label">{'Parent2 Type'}</label>
                                        <Field
                                            name='parent2'
                                            component={DropdownSelect}
                                            type='dropDown'
                                            options={this.state.parentTypeOptions}
                                           
                                            placeholder={'Parent2 Type'}
                                            // onChange={(e)=> this.parentOptionSelect(e, 'parent2')}
                                        validate={[parentTypeRequired]}
                                        />
                                    </div>
                                </div>
                                <div className='col-sm-6'>
                                    <div className="form__form-group" >
                                        <label className="form__form-group-label">{t('Parent2 Suffix')}</label>
                                        <div className="form__form-group-field mb-2">
                                            <Field
                                                type='text'
                                                name='p2_suffix'
                                                component={DefaultInput}
                                                id='p2_suffix'
                                                // validate={[required]}
                                                placeholder={t('Parent2 Suffix')}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-sm-6'>
                                    <div className="form__form-group" >
                                        <label className="form__form-group-label">{t('Parent2 First Name')}</label>
                                        <div className="form__form-group-field mb-2">
                                            <Field
                                                type='text'
                                                name='p2_firstname'
                                                component={DefaultInput}
                                                id='p2_firstname'
                                                validate={[nameLengthValidate]}
                                                placeholder={t('Parent2 First Name')}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-sm-6'>
                                    <div className="form__form-group" >
                                        <label className="form__form-group-label">{t('Parent2 Middle Name')}</label>
                                        <div className="form__form-group-field mb-2">
                                            <Field
                                                type='text'
                                                name='p2_middlename'
                                                component={DefaultInput}
                                                id='p2_middlename'
                                                placeholder={t('Parent2 Middle Name')}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-sm-6'>
                                    <div className="form__form-group" >
                                        <label className="form__form-group-label sameFamNameCheckBox">
                                            {t('Parent2 Last Name')}
                                            <span className='pl-2'>
                                                <Field
                                                    type='checkbox'
                                                    className=' my-0 align-items-center'
                                                    name='lastName2AsFamily'
                                                    component={CheckBox}
                                                    id='lastName2AsFamily'
                                                    label={t('Same as Family')}
                                                    checked={this.state.lastName2AsFamily}
                                                    onChange={() => this.toggleLastNameAsFamilyName(!this.state.lastName2AsFamily, 'lastName2AsFamily', 'p2_lastname')}
                                                />
                                            </span>
                                        </label>
                                        <div className="form__form-group-field mb-2">
                                            <Field
                                                type='text'
                                                name='p2_lastname'
                                                component={DefaultInput}
                                                id='p2_lastname'
                                                validate={[nameLengthValidate]}
                                                placeholder={t('Parent2 Last Name')}
                                                onChange={(e) => this.onchangeLastName(e, 'lastName2AsFamily')}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-12 pl-0 d-flex pb-2'>
                                <h5 className='text-primary'><b>{t('Contact Information')}</b></h5>
                                <span className='pl-2'>
                                    <Field
                                        type='checkbox'
                                        name='showContactInfo'
                                        component={CheckBox}
                                        id='showContactInfo'
                                        label={t('Show All')}
                                        className=' my-auto'
                                        checked={this.state.showContactInfo}
                                        onChange={() => this.toggleShowContactInfo(this.state.showContactInfo)}
                                    />
                                </span>
                            </div>
                            <div className='cradBodres card pb-0 addressDetailsBg'>
                                <div className='card-body p-2 '>
                                    <div className='row form pt-4'>
                                        <div className="col-12">
                                            <div className="form__form-group-field my-2">
                                                <h5 className='text-dark'><b>
                                                    {t('Address')}
                                                </b></h5>
                                                <span className='pl-2'>
                                                    <Field
                                                        type='checkbox'
                                                        name='showFullAddress'
                                                        component={CheckBox}
                                                        id='showFullAddress'
                                                        label={t('Show')}
                                                        className=' my-auto'
                                                        checked={this.state.showFullAddress}
                                                        onChange={() => this.toggleShowContactInfo(this.state.showFullAddress, 'showFullAddress')}
                                                    />
                                                </span>
                                            </div>
                                            <div className='card'>
                                                <div className='cradBodres p-2 addressDetailsBg borderRadius5'>
                                                    <div className="row pt-4">
                                                        <div className='col-sm-6'>
                                                            <div className="form__form-group">
                                                                <label className="form__form-group-label">{t('Address Line 1')}
                                                                </label>
                                                                <div className="form__form-group-field mb-2">
                                                                    <Field
                                                                        type='textarea'
                                                                        name='address1'
                                                                        component={DefaultTextArea}
                                                                        id='address1'
                                                                        validate={[required]}
                                                                        placeholder={t('Address Line 1')}
                                                                    />
                                                                    {/* <span className='pl-2'>
                                                        <Field
                                                            type='checkbox'
                                                            name='showaddress1'
                                                            component={CheckBox}
                                                            id='showaddress1'
                                                            placeholder={t('Address1')}
                                                            label={t('Show')}
                                                        />
                                                    </span> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-sm-6'>
                                                            <div className="form__form-group">
                                                                <label className="form__form-group-label">{t('Address Line 2')}</label>
                                                                <div className="form__form-group-field mb-2">
                                                                    <Field
                                                                        type='textarea'
                                                                        name='address2'
                                                                        component={DefaultTextArea}
                                                                        id='address2'
                                                                        placeholder={t('Address Line 2')}
                                                                    />
                                                                    {/* <span className='pl-2'>
                                                        <Field
                                                            type='checkbox'
                                                            name='showaddress2'
                                                            component={CheckBox}
                                                            id='showaddress2'
                                                            placeholder='Address2'
                                                            label={t('Show')}
                                                        />
                                                    </span> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-sm-6'>
                                                            <div className="form__form-group">
                                                                <label className="form__form-group-label">{t('Zip Code')}</label>
                                                                <div className="form__form-group-field mb-2">
                                                                    <Field
                                                                        type='text'
                                                                        name='zipCode'
                                                                        component={DefaultInput}
                                                                        id='zipCode'
                                                                        validate={[required, requiredPincode]}
                                                                        placeholder={t('Zip Code')}
                                                                        onChange={e => this.autoPopulateCityStateFromZipCode(e)}
                                                                    />
                                                                    {/* <span className='pl-2'>
                                                        <Field
                                                            type='checkbox'
                                                            name='showzipCode'
                                                            component={CheckBox}
                                                            id='showstate'
                                                            label={t('Show')}
                                                        />
                                                    </span> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-sm-6'>
                                                            <div className="form__form-group">
                                                                <label className="form__form-group-label">{t('City')}</label>
                                                                <div className="form__form-group-field mb-2">
                                                                    <Field
                                                                        type='text'
                                                                        name='city'
                                                                        defaultValue={this.state.city}
                                                                        component={DefaultInput}
                                                                        id='city'
                                                                        validate={[required]}
                                                                        placeholder={t('City')}
                                                                    // isDisable={true}
                                                                    />
                                                                    {/* <span className='pl-2'>
                                                        <Field
                                                            type='checkbox'
                                                            name='showcity'
                                                            component={CheckBox}
                                                            id='showcity'
                                                            label={t('Show')}
                                                        />
                                                    </span> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-sm-6'>
                                                            <div className="form__form-group">
                                                                <label className="form__form-group-label">{t('State')}</label>
                                                                <div className="form__form-group-field mb-2">
                                                                    <Field
                                                                        type='text'
                                                                        name='state'
                                                                        defaultValue={this.state.state}
                                                                        component={DefaultInput}
                                                                        id='state'
                                                                        validate={[required]}
                                                                        placeholder={t('State')}
                                                                    // options={this.state.stateOptions}
                                                                    // isDisable={true}
                                                                    />
                                                                    {/* <span className='pl-2'>
                                                        <Field
                                                            type='checkbox'
                                                            name='showstate'
                                                            component={CheckBox}
                                                            id='showstate'
                                                            label={t('Show')}
                                                        />
                                                    </span> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div className="form__form-group">
                                                <label className="form__form-group-label">{t('Parent1 Email')}</label>
                                                <div className="form__form-group-field mb-2">
                                                    <Field
                                                        type='text'
                                                        name='email1'
                                                        component={DefaultInput}
                                                        id='email1'
                                                        validate={[required, emailLengthValidate]}
                                                        placeholder={t('Parent1 Email')}
                                                    /><span className='pl-2'>
                                                        <Field
                                                            type='checkbox'
                                                            name='showEmail1'
                                                            component={CheckBox}
                                                            id='showEmail1'
                                                            label={t('Show')}
                                                            checked={this.state.showEmail1}
                                                            onChange={() => this.toggleShowContactInfo(this.state.showEmail1, 'showEmail1')}
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div className="form__form-group">
                                                <label className="form__form-group-label">{t('Parent2 Email')}</label>
                                                <div className="form__form-group-field mb-2">
                                                    <Field
                                                        type='text'
                                                        name='email2'
                                                        component={DefaultInput}
                                                        id='email2'
                                                        placeholder={t('Parent2 Email')}
                                                        validate={[emailLengthValidate]}
                                                    /><span className='pl-2'>
                                                        <Field
                                                            type='checkbox'
                                                            name='showEmail2'
                                                            component={CheckBox}
                                                            id='showEmail2'
                                                            label={t('Show')}
                                                            checked={this.state.showEmail2}
                                                            onChange={() => this.toggleShowContactInfo(this.state.showEmail2, 'showEmail2')}
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div className="form__form-group">
                                                <label className="form__form-group-label">{t('Parent1 Home Phone')}</label>
                                                <div className="form__form-group-field mb-2">
                                                    <Field
                                                        type='text'
                                                        name='phone1_home'
                                                        component={DefaultInput}
                                                        id='phone1_home'
                                                        placeholder={t('Parent1 Home Phone')}
                                                        normalize={normalizePhone}
                                                        validate={[phoneLengthValidate]}
                                                    /><span className='pl-2'>
                                                        <Field
                                                            type='checkbox'
                                                            name='showhome1'
                                                            component={CheckBox}
                                                            id='showhome1'
                                                            label={t('Show')}
                                                            checked={this.state.showhome1}
                                                            onChange={() => this.toggleShowContactInfo(this.state.showhome1, 'showhome1')}
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div className="form__form-group">
                                                <label className="form__form-group-label">{t('Parent2 Home Phone')}</label>
                                                <div className="form__form-group-field mb-2">
                                                    <Field
                                                        type='text'
                                                        name='phone2_home'
                                                        component={DefaultInput}
                                                        id='phone2_home'
                                                        placeholder={t('Parent2 Home Phone')}
                                                        normalize={normalizePhone}
                                                        validate={[phoneLengthValidate]}
                                                    /><span className='pl-2'>
                                                        <Field
                                                            type='checkbox'
                                                            name='showhome2'
                                                            component={CheckBox}
                                                            id='showhome2'
                                                            label={t('Show')}
                                                            checked={this.state.showhome2}
                                                            onChange={() => this.toggleShowContactInfo(this.state.showhome2, 'showhome2')}
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div className="form__form-group">
                                                <label className="form__form-group-label">{t('Parent1 Mobile Phone')}</label>
                                                <div className="form__form-group-field mb-2">
                                                    <Field
                                                        type='text'
                                                        name='phone1_mobile'
                                                        component={DefaultInput}
                                                        id='phone1_mobile'
                                                        placeholder={t('Parent1 Mobile Phone')}
                                                        normalize={normalizePhone}
                                                        validate={[phoneLengthValidate]}
                                                    /><span className='pl-2'>
                                                        <Field
                                                            type='checkbox'
                                                            name='showmobile1'
                                                            component={CheckBox}
                                                            id='showmobile1'
                                                            label={t('Show')}
                                                            checked={this.state.showmobile1}
                                                            onChange={() => this.toggleShowContactInfo(this.state.showmobile1, 'showmobile1')}
                                                        />
                                                    </span>
                                                </div>
                                            </div>

                                        </div>
                                        <div className='col-sm-6'>
                                            <div className="form__form-group">
                                                <label className="form__form-group-label">{t('Parent2 Mobile Phone')}</label>
                                                <div className="form__form-group-field mb-2">
                                                    <Field
                                                        type='text'
                                                        name='phone2_mobile'
                                                        component={DefaultInput}
                                                        id='phone2_mobile'
                                                        placeholder={t('Parent2 Mobile Phone')}
                                                        normalize={normalizePhone}
                                                        validate={[phoneLengthValidate]}
                                                    /><span className='pl-2'>
                                                        <Field
                                                            type='checkbox'
                                                            name='showmobile2'
                                                            component={CheckBox}
                                                            id='showmobile2'
                                                            label={t('Show')}
                                                            checked={this.state.showmobile2}
                                                            onChange={() => this.toggleShowContactInfo(this.state.showmobile2, 'showmobile2')}
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div className="form__form-group">
                                                <label className="form__form-group-label">{t('Parent1 Work Phone')}</label>
                                                <div className="form__form-group-field mb-2">
                                                    <Field
                                                        type='text'
                                                        name='phone1_work'
                                                        component={DefaultInput}
                                                        id='phone1_work'
                                                        placeholder={t('Parent1 Work Phone')}
                                                        normalize={normalizePhone}
                                                        validate={[phoneLengthValidate]}
                                                    /><span className='pl-2'>
                                                        <Field
                                                            type='checkbox'
                                                            name='showphone1'
                                                            component={CheckBox}
                                                            id='showphone1'
                                                            label={t('Show')}
                                                            checked={this.state.showphone1}
                                                            onChange={() => this.toggleShowContactInfo(this.state.showphone1, 'showphone1')}
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div className="form__form-group">
                                                <label className="form__form-group-label">{t('Parent2 Work Phone')}</label>
                                                <div className="form__form-group-field mb-2">
                                                    <Field
                                                        type='text'
                                                        name='phone2_work'
                                                        component={DefaultInput}
                                                        id='phone2_work'
                                                        placeholder={t('Parent2 Work Phone')}
                                                        normalize={normalizePhone}
                                                        validate={[phoneLengthValidate]}
                                                    /><span className='pl-2'>
                                                        <Field
                                                            type='checkbox'
                                                            name='showphone2'
                                                            component={CheckBox}
                                                            id='showphone2'
                                                            label={t('Show')}
                                                            checked={this.state.showphone2}
                                                            onChange={() => this.toggleShowContactInfo(this.state.showphone2, 'showphone2')}
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className='col-sm-4'>
                                            <div className="form__form-group">
                                                <label className="form__form-group-label">Birth Date</label>
                                                <div className="form__form-group-field mb-2">
                                                    <Field
                                                        type='date'
                                                        name='birthday'
                                                        component={DatePicker}
                                                        id='birthday'
                                                        placeholder={t('Birth day')}
                                                    // validate={[emailLengthValidate]}
                                                    />
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>

                            <div className='form'>
                                {this.props.type === 'Family' && this.state.studentArray && this.state.studentArray.length > 0 ?
                                    this.state.studentArray.map((student, studentIndex) => (
                                        // console.log("student", studentIndex, this.state.studentArray[studentIndex]["School Name"]),
                                        <React.Fragment key={studentIndex}>
                                            <div className='col-sm-12 pl-0 pt-3' key={studentIndex + 'studentFormHeader'}>
                                                <h5 className='text-primary'><b>{t(`Student ${studentIndex + 1} Information`)}</b></h5>
                                            </div>
                                            <div className='cradBodres card pb-0 addressDetailsBg mb-3' key={studentIndex + 'studentFormBody'}>
                                                <div className='card-body p-2 '>
                                                    <div key={studentIndex} className='row pt-4'>
                                                        {student.stuFormFields && student.stuFormFields.length && student.stuFormFields.length > 0 ?
                                                            <>  <div className='col-sm-6 mb-0'>
                                                                <div className="form__form-group">
                                                                    <label className="form__form-group-label">{`${student.stuFormFields[0].label}`}</label>
                                                                    <Field
                                                                        name={student.stuFormFields[0].name}
                                                                        component={Select}
                                                                        type='dropDown'
                                                                        options={this.state.districts}
                                                                        onChange={(e, i) => this.addStudentArray('dropdown', studentIndex, 'district_name', e, student.stuFormFields[1].label, i)}
                                                                        makeValue={student[student.stuFormFields[0].label] ? student[student.stuFormFields[0].label] : ''}
                                                                        isDisable={studentIndex == 0 ? false : true}
                                                                        placeholder={`${student.stuFormFields[0].label}`}
                                                                        validate={
                                                                            student.stuFormFields[0].required ?
                                                                                student[student.stuFormFields[0].label] ?
                                                                                    null
                                                                                    : [required]
                                                                                : null
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                                <div className='col-sm-6 mb-0'>
                                                                    <div className="form__form-group">
                                                                        <label className="form__form-group-label">{`${student.stuFormFields[1].label}`}</label>
                                                                        <Field
                                                                            name={student.stuFormFields[1].name}
                                                                            component={Select}
                                                                            type='dropDown'
                                                                            options={this.state.schoolOptions}
                                                                            onChange={(e) => this.addStudentArray('dropdown', studentIndex, 'school_name', e, student.stuFormFields[1].label)}
                                                                            makeValue={student[student.stuFormFields[1].label] ? student[student.stuFormFields[1].label] : ''}
                                                                            placeholder={`${student.stuFormFields[1].label}`}
                                                                            validate={
                                                                                student.stuFormFields[1].required ?
                                                                                    student[student.stuFormFields[1].label] ?
                                                                                        null
                                                                                        : [required]
                                                                                    : null
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className='col-sm-6 mb-0'>
                                                                    <div className="form__form-group">
                                                                        <label className="form__form-group-label">{`${student.stuFormFields[2].label}`}</label>
                                                                        <Field
                                                                            name={student.stuFormFields[2].name}
                                                                            component={Select}
                                                                            type='dropDown'
                                                                            options={student.classesOptions ? student.classesOptions : this.state.classesOptions}
                                                                            onChange={(e, i) => this.addStudentArray('dropdown', studentIndex, 'class_name', e, student.stuFormFields[2].label, i)}
                                                                            // makeValue={student[student.stuFormFields[2].label] ? `${student['grade_int']?student['grade_int']+ " - ":null} ${student[student.stuFormFields[2].label]}` : ''}
                                                                            makeValue={
                                                                                student[student.stuFormFields[2].label] ? 
                                                                                `${student[student.stuFormFields[2].label].startsWith(student['grade_int']) || student[student.stuFormFields[2].label].startsWith(`0${student['grade_int']}`)? student[student.stuFormFields[2].label]: student['grade_int'] + " - " + student[student.stuFormFields[2].label]}`
                                                                                :"" }

                                                                            placeholder={`${student.stuFormFields[2].label}`}
                                                                            validate={
                                                                                student.stuFormFields[2].required ?
                                                                                    student[student.stuFormFields[2].label] ?
                                                                                        null
                                                                                        : [required]
                                                                                    : null
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </>
                                                            : null
                                                        }
                                                        {student.stuFormFields && student.stuFormFields.length && student.stuFormFields.length > 0 ?
                                                            student.stuFormFields.map((field, index) => {
                                                                return (
                                                                    field.label === 'School Name' ?
                                                                        null
                                                                        : field.type === 'TextField' ?
                                                                            <div className='col-sm-6 mb-0' key={studentIndex + index}>
                                                                                <div className="form__form-group">
                                                                                    <label className="form__form-group-label">
                                                                                        {`${field.label}`}
                                                                                    </label>
                                                                                    <div className="form__form-group-field mb-2">
                                                                                        <Field
                                                                                            key={index}
                                                                                            name={field.name}
                                                                                            component={DefaultClearInput}
                                                                                            isDisable={(this.state.studentArray[studentIndex]["School Name"] !== this.state.isDisabledSchoolField) && (this.state.roleName === "schoolAdmin") ? true : false}
                                                                                            makeValue={student[field.label] ? student[field.label] : ''}
                                                                                            onChange={(e) => this.addStudentArray('change', studentIndex, field.name, e, field.label)}
                                                                                            type="text"
                                                                                            id={field.name}
                                                                                            placeholder={`${field.label}`}
                                                                                            validate={
                                                                                                field.required ?
                                                                                                    student[field.label] ?
                                                                                                        null
                                                                                                        : [required, nameLengthValidate]
                                                                                                    : null
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            : field.type === 'Dropdown' ?
                                                                                <div className='col-sm-6 mb-0' key={studentIndex + index}>
                                                                                    <div className="form__form-group">
                                                                                        <label className="form__form-group-label">
                                                                                            {`${field.label}`}
                                                                                        </label>
                                                                                        <div className="form__form-group-field ">
                                                                                            <Field
                                                                                                key={index} onChange={(e) => this.addStudentArray('dropdown', studentIndex, field.name, e, field.label)}

                                                                                                name={field.name}
                                                                                                type="dropDown"
                                                                                                component={Select}
                                                                                                isDisable={(this.state.studentArray[studentIndex]["School Name"] !== this.state.isDisabledSchoolField) && (this.state.roleName === "schoolAdmin") ? true : false}
                                                                                                options={field.options}
                                                                                                makeValue={student[field.label] ? student[field.label] : ''}
                                                                                                id={field.name}
                                                                                                placeholder={`${field.label}`}
                                                                                                validate={null
                                                                                                    // field.required ?
                                                                                                    //     student[field.label] ?
                                                                                                    //         null
                                                                                                    //         : [required]
                                                                                                    //     : null
                                                                                                }
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                : field.type === 'date' ?
                                                                                    <div className='col-sm-6 mb-0' key={studentIndex + index}>
                                                                                        <div className="form__form-group">
                                                                                            <label className="form__form-group-label">
                                                                                                {`${field.label}`}
                                                                                            </label>
                                                                                            <div className="form__form-group-field ">
                                                                                                <Field
                                                                                                    key={index}
                                                                                                    name={field.name}
                                                                                                    type="Date"
                                                                                                    component={DatePicker}
                                                                                                    // selected={props.input.value
                                                                                                    //     ? moment(props.input.value, 'DD-MM-YYYY')
                                                                                                    //     : null}
                                                                                                    // options={field.options}
                                                                                                    makeValue={student[field.label] ? student[field.label] : ''}
                                                                                                    onChange={(e) => this.addStudentArray('change', studentIndex, field.name, e, field.label)}
                                                                                                    id={field.name}
                                                                                                    placeholder={`${field.label}`}
                                                                                                    maxDate={new Date()}
                                                                                                    validate={null
                                                                                                        // field.required ?
                                                                                                        //     student[field.label] ?
                                                                                                        //         null
                                                                                                        //         : [required]
                                                                                                        //     : null
                                                                                                    }
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    : null
                                                                )
                                                            })
                                                            : null
                                                        }
                                                        {this.state.studentArray.length > 1 && 
                                                        <div className='col-sm-12'>
                                                            <span className='float-right'>
                                                                <FontAwesomeIcon
                                                                    className='deleteIcon'
                                                                    color='white'
                                                                    icon='trash-alt'
                                                                    data-toggle="tool-tip" title="Delete"
                                                                    // onClick={() => this.addStudentArray('delete', studentIndex)}
                                                                    onClick= { () => this.setState({ openDeleteModal: true, deleteStudent: studentIndex }) }
                                                                />
                                                            </span>
                                                        </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    ))
                                    : null
                                }
                                <div className='col-sm-12 pl-0'>
                                    {this.props.type === 'Family' && <h2 className='float-right my-2'>
                                        <span>
                                            <FontAwesomeIcon
                                                className='addIcon'
                                                color='white'
                                                icon='plus-circle'
                                                data-toggle="tool-tip" title="Add"
                                                onClick={() => this.addStudentArray('add')}
                                            />
                                        </span>
                                        <span className='pl-2'>
                                            {t('Add Students')}
                                        </span>
                                    </h2>}
                                </div>


                            </div>

                            <div className='col-sm-12 text-center my-2'>
                                <Button color='primary' type="button" className='mb-0' onClick={this.closeFormModal}>
                                    {t('Cancel')}
                                </Button>
                                <Button color='primary' className='mb-0' outline type="submit">
                                    {this.props.formType && this.props.formType === 'add' ?
                                        t('Save')
                                        : t('Update')
                                    }
                                </Button>
                            </div>
                        </form>
                        {this.state.sessionExpiryModal ?
                            <SessionExpiryModal
                                SOpen={this.state.sessionExpiryModal}
                            />
                            : null
                        }
                    </CardBody>
                </Card>
            </ModalBody >
        )
    }

    render() {
        const { handleSubmit, t, } = this.props;

        return (
            <div style={{ display: 'flex' }}>
                <Modal
                    isOpen={this.props.openFormModal}
                    className='modal-dialog-centered modal-dialog--primary modal-dialog--header'
                >
                    {this.getModalHeader(t)}
                    {this.getModalBody(handleSubmit, t)}
                </Modal>
                {/* Student Delete Conformation  */}
                <Modal isOpen={this.state.openDeleteModal}
                    className='modal-dialog-centered modal-dialog--primary m-auto logout_modal_width'
                >
                    <ModalBody className='deleteModalBody'>
                        <div style={{ marginBottom: 10 }}> Are you sure, you want to Delete Student {this.state.deleteStudent + 1} Information </div>
                        <Button color="primary" outline onClick={() => this.setState({ openDeleteModal: false })} className='deleteModalBtn marginRight'>NO</Button>
                        <Button color="primary" outline onClick={() => { this.addStudentArray('delete', this.state.deleteStudent); this.setState({ openDeleteModal: false }) }}
                            className='deleteModalBtn'>Yes</Button>
                    </ModalBody>
                </Modal>
            </div >

        );
    }
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
FamilyFormModal = reduxForm({
    form: "Admin2 Family Form Modal Form", // a unique identifier for this form
    validate,
    enableReinitialize: true,
})(FamilyFormModal);

// You have to connect() to any reducers that you wish to connect to yourself
FamilyFormModal = connect(
    state => ({
        initialValues: state.commonData.data // pull initial values from account reducer
    }),
    {
        load: loadAccount,
        settingsPincodeLength: settingsPincode
    } // bind account loading action creator
)(FamilyFormModal);

export default withTranslation('common')(FamilyFormModal);