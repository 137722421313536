import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { Collapse } from 'reactstrap';
import DownIcon from 'mdi-react/ChevronDownIcon';
import PropTypes from 'prop-types';
import config from '../../../config/config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// const gb = `${process.env.PUBLIC_URL}/img/language/gb.png`;
// const fr = `${process.env.PUBLIC_URL}/img/language/fr.png`;
// const de = `${process.env.PUBLIC_URL}/img/language/de.png`;

// const GbLng = () => (
//   <span className="topbar__language-btn-title">
//     <img src={gb} alt="gb" />
//     <span>EN</span>
//   </span>
// );

// const FrLng = () => (
//   <span className="topbar__language-btn-title">
//     <img src={fr} alt="fr" />
//     <span>FR</span>
//   </span>
// );

// const DeLng = () => (
//   <span className="topbar__language-btn-title">
//     <img src={de} alt="de" />
//     <span>DE</span>
//   </span>
// );
const LanBtn = ({ lang }) => {
  return (
    <span className="topbar__language-btn-title">
      <span style={{ textTransform: 'uppercase' }}>{lang ? lang : "en"}</span>
    </span>
  )
}
class TopbarLanguage extends PureComponent {
  static propTypes = {
    i18n: PropTypes.shape({ changeLanguage: PropTypes.func }).isRequired,
  };

  constructor() {
    super();
    this.state = {
      collapse: false,
      mainButtonContent: <LanBtn />,
      langKeys: ['en'],
      currentLang: 'en',
    };
  }
  componentDidMount = async () => {
    let lang = localStorage.getItem('currentLang');
    let langKeys = JSON.parse(localStorage.getItem('langKeys'));

    let button = this.state.mainButtonContent;
    if (lang) {
      button = <LanBtn lang={lang} />
    }
    this.setState({
      mainButtonContent: button,
      langKeys: langKeys ? langKeys : [],
      currentLang: lang ? lang : 'en',
    })
  }
  toggle = () => {
    this.setState(prevState => ({ collapse: !prevState.collapse }));
  };

  // changeLanguage = (lng) => {
  //   const { i18n } = this.props;
  //   i18n.changeLanguage(lng);
  //   switch (lng) {
  //     case 'en':
  //       this.setState({ mainButtonContent: <GbLng />, collapse: false, });
  //       break;
  //     case 'fr':
  //       this.setState({ mainButtonContent: <FrLng />, collapse: false, });
  //       break;
  //     case 'de':
  //       this.setState({ mainButtonContent: <DeLng />, collapse: false, });
  //       break;
  //     default:
  //       this.setState({ mainButtonContent: <GbLng />, collapse: false, });
  //       break;
  //   }
  // };
  // Change language based upon selection
  changeLanguage = async (lang) => {
    const { i18n } = this.props;
    i18n.changeLanguage(lang);

    this.setState({ mainButtonContent: <LanBtn lang={lang} />, currentLang: lang });
    localStorage.setItem('currentLang', lang ? lang : 'en')
    this.toggle();
  };
  render() {
    const { mainButtonContent, collapse, langKeys, currentLang } = this.state;

    return (
      <div className="topbar__collapse topbar__collapse--language">
        <button className="topbar__btn" type="button" onClick={this.toggle}>
          {mainButtonContent}
          <DownIcon className="topbar__icon" />
        </button>
        <Collapse
          isOpen={collapse}
          className="topbar__collapse-content topbar__collapse-content--language"
        >
          {langKeys.map(lang => {
            return (
              <button
                key={lang}
                className="topbar__language-btn"
                type="button"
                onClick={() => this.changeLanguage(lang)}
                style={
                  currentLang === lang ? {
                    textTransform: 'uppercase',
                    backgroundColor: config.templateColor,
                    color: 'white',
                    fontWeight: 600,
                  } : {
                    textTransform: 'uppercase',
                    fontWeight: 600,
                  }}
              >
                <span>
                  {
                    currentLang === lang ? <FontAwesomeIcon
                      className='genderIconAlignment'
                      color='white'
                      icon='check'
                      style={{ color: 'white', marginRight: 8, cursor: 'pointer' }}
                    /> : null}
                  <span style={
                    currentLang !== lang ? {
                      marginLeft: 21,
                    } : null}>
                    {lang}
                  </span>
                </span>
              </button>
            )
          })}
          {/* <button
            className="topbar__language-btn"
            type="button"
            onClick={() => this.changeLanguage('en')}
          >
            <GbLng />
          </button>
          <button
            className="topbar__language-btn"
            type="button"
            onClick={() => this.changeLanguage('fr')}
          >
            <FrLng />
          </button> */}
          {/* <button
            className="topbar__language-btn"
            type="button"
            onClick={() => this.changeLanguage('de')}
          >
            <DeLng />
          </button> */}
        </Collapse>
      </div>
    );
  }
}

export default withTranslation('common')(TopbarLanguage);
