import React from "react";
import StudentsDT from "./StudentsDT";
import store from "../../../App/store";
import DataTables from "../../CommonDataTable/DataTable";
import config from "../../../../config/config";

// config file
export default class Student extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      languageData: "",
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState();
    let languageData =
      storeData &&
        storeData.settingsData &&
        storeData.settingsData.settings &&
        storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  }

  getTableFields = () => {
    let data = [
      // {
      //   show: true,
      //   textAlign: "center",
      //   width: 47,
      //   field: "Sno",
      //   header: "Sno",
      //   filter: false,
      //   sortable: false,
      //   placeholder: "Search",
      // },
      {
        textAlign: "left",
        show: true,
        mobile: true,
        width: 200,
        field: "school_name",
        fieldType: "Link",
        header: "School Name",
        filter: false,
        sortable: true,
        style: {
          color: config.templateColor,
          cursor: "pointer",
          textTransform: "capitalize",
        },
        displayInSettings: true,
      },
      {
        textAlign: "center",
        show: true,
        mobile: true,
        width: 120,
        field: "grade_int",
        header: "Grade",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        textAlign: "left",
        show: true,
        mobile: true,
        width: 160,
        field: "class_name",
        header: "Class",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        textAlign: "left",
        show: true,
        width: 160,
        field: "firstname",
        header: "First Name",
        textCapitalize: true,
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        textAlign: "left",
        show: true,
        width: 160,
        field: "lastname",
        header: "Last Name",
        textCapitalize: true,
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        textAlign: "left",
        show: true,
        width: 150,
        field: "display_name",
        header: "Student Name",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        textAlign: "left",
        show: true,
        width: 160,
        field: "child_id",
        header: "Student Id",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        textAlign: "left",
        show: true,
        mobile: false,
        width: 160,
        field: "nick_name",
        header: "Nick Name",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        textAlign: "left",
        show: true,
        mobile: false,
        width: 160,
        field: "living_with",
        header: "Living With",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        textAlign: "left",
        show: true,
        mobile: false,
        width: 160,
        field: "home_room",
        header: "Home Room",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        textAlign: "left",
        show: true,
        mobile: false,
        width: 160,
        field: "student_health_details",
        header: "Health Details",
        filter: false,
        sortable: true,
        textCapitalize: true,
        displayInSettings: true,
      },
      {
        textAlign: "left",
        show: true,
        mobile: false,
        width: 160,
        field: "student_vaccination",
        header: "Vaccination",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        filter: false,
        sortable: true,
        width: 80,
        field: "parent1",
        mobile: false,
        header: "Parent1",
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        filter: false,
        sortable: true,
        width: 115,
        mobile: false,
        field: "p1_suffix",
        header: "Parent1 Suffix",
        displayInSettings: true,
      },
      {
        show: true,
        mobile: false,
        textAlign: "left",
        filter: false,
        sortable: true,
        width: 160,
        field: "p1_display_name",
        header: "Parent1 Name",
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        filter: false,
        mobile: true,
        sortable: true,
        width: 160,
        field: "p1_firstname",
        header: "Parent1 First Name",
        textCapitalize: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        filter: false,
        mobile: true,
        sortable: true,
        width: 160,
        field: "p1_lastname",
        header: "Parent1 Last Name",
        textCapitalize: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        filter: false,
        mobile: true,
        sortable: true,
        width: 160,
        field: "p1_middlename",
        header: "Parent1 Middle Name",
        textCapitalize: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        filter: false,
        mobile: true,
        sortable: true,
        width: 220,
        field: "email1",
        header: "Email1",
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        filter: false,
        sortable: true,
        width: 80,
        field: "parent2",
        mobile: false,
        header: "Parent2",
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        filter: false,
        mobile: false,
        sortable: true,
        width: 115,
        field: "p2_suffix",
        header: "Parent2 Suffix",
        displayInSettings: true,
      },
      {
        mobile: false,
        show: true,
        textAlign: "left",
        filter: false,
        sortable: true,
        width: 160,
        field: "p2_display_name",
        header: "Parent2 Name",
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        filter: false,
        mobile: true,
        sortable: true,
        width: 160,
        field: "p2_firstname",
        header: "Parent2 First Name",
        textCapitalize: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        filter: false,
        mobile: true,
        sortable: true,
        width: 160,
        field: "p2_lastname",
        header: "Parent2 Last Name",
        textCapitalize: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        filter: false,
        mobile: true,
        sortable: true,
        width: 160,
        field: "p2_middlename",
        header: "Parent2 Middle Name",
        textCapitalize: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        filter: false,
        sortable: true,
        width: 220,
        field: "address1",
        mobile: true,
        header: " Address Line 1",
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        mobile: true,
        filter: false,
        sortable: true,
        width: 220,
        field: "address2",
        header: " Address Line 2",
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        filter: false,
        mobile: false,
        sortable: true,
        width: 100,
        field: "city",
        header: "City",
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        filter: false,
        sortable: true,
        mobile: false,
        width: 100,
        field: "state",
        header: "State",
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "center",
        filter: false,
        sortable: true,
        mobile: false,
        width: 100,
        field: "zipCode",
        header: "Zip Code",
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "center",
        filter: false,
        mobile: true,
        sortable: true,
        width: 200,
        field: "phone1_work",
        header: "Parent1 PhoneNo Work",
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "center",
        filter: false,
        mobile: true,
        sortable: true,
        width: 200,
        field: "phone1_mobile",
        header: "Parent1 PhoneNo Mobile",
        displayInSettings: true,
      }, {
        show: true,
        textAlign: "center",
        filter: false,
        mobile: true,
        sortable: true,
        width: 200,
        field: "phone1_home",
        header: "Parent1 Mobile Home",
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "center",
        filter: false,
        mobile: true,
        sortable: true,
        width: 200,
        field: "phone2_home",
        header: "Parent2 Mobile Home",
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "center",
        filter: false,
        mobile: true,
        sortable: true,
        width: 200,
        field: "phone2_work",
        header: "Parent2 PhoneNo Work",
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "center",
        mobile: true,
        filter: false,
        sortable: true,
        width: 200,
        field: "phone2_mobile",
        header: "Parent2 PhoneNo Mobile",
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        filter: false,
        mobile: true,
        sortable: true,
        width: 220,
        field: "email2",
        header: "Email2",
        displayInSettings: true,
      }, {
        show: true,
        textAlign: "center",
        width: 70,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
      },
    ];
    return data;
  };

  getFormFields = () => {
    return [
      {
        required: true,
        value: "",
        searchField: "Name",
        searchApi: "schools",
        type: "autoComplete",
        name: "school_name",
        label: "School Name",
        id: "Name",
        placeholder: "School Name",
      },
      {
        value: "",
        type: "text",
        name: "firstname",
        label: "First Name",
        id: "firstName",
        placeholder: "First Name",
        required: true,
      },
      {
        value: "",
        type: "text",
        name: "lastname",
        label: "Last Name",
        id: "lastName",
        placeholder: "Last Name",
        required: true,
      },
      {
        value: "",
        type: "text",
        name: "middlename",
        label: "Middle Name",
        id: "middlename",
        placeholder: "Middle Name",
        required: true,
      },
      {
        value: "",
        type: "text",
        name: "nick_name",
        label: "Nick Name",
        id: "nickname",
        placeholder: "Nick Name",
        required: true,
      },
      {
        value: "",
        type: "text",
        name: "living_with",
        label: "Living With",
        id: "website",
        placeholder: "Living With",
        required: true,
      },
    ];
  };

  render() {
    return (
      <DataTables
        getTableFields={this.getTableFields}
        formFields={this.getFormFields}
        globalSearch={"School Name/Student Name/Email"}
        type="Students"
        deleteRequired={true}
        exportToCsv={true}
        apiUrl={"childs"}
        routeTo="students"
        displayViewOfForm="screen"
      />
    );
  }
}
