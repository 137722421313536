import React from "react";
import NoTRegisteredDT from "./NoTRegisteredDT";
import apiCalls from "../../../../config/apiCalls";
import store from "../../../App/store";
import { withTranslation } from "react-i18next";
import config from "../../../../config/config";
import DataTables from "../../CommonDataTable/DataTable";

// config file
class NoTRegistered extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      languageData: "",
      committeNames: [
        { label: "one", value: "one" },
        { label: "two", value: "two" },
        { label: "three", value: "three" },
      ],
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState();
    let languageData =
      storeData &&
        storeData.settingsData &&
        storeData.settingsData.settings &&
        storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  }

  getTableFields = () => {
    let data = [
      // {
      //   show: true,
      //   textAlign: "center",
      //   width: 47,
      //   field: "Sno",
      //   header: "Sno",
      //   filter: false,
      //   sortable: false,
      //   placeholder: "Search",
      // },
      {
        show: true,
        textAlign: "left",
        width: 150,
        field: "email",
        header: "Email",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 150,
        field: "desc",
        header: "Description",
        filter: false,
        sortable: true,
        textCapitalize: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 150,
        field: "created",
        header: "Created",
        filter: false,
        sortable: true,
        fieldType: "Date",
        dateFormat: config.dateDayMonthFormat,
        displayInSettings: true,
      },
    ];
    return data;
  };

  getFormFields = () => {
    return [
      {
        value: "",
        type: "text",
        name: "fname",
        label: "First Name",
        id: "firstName",
        placeholder: "First Name",
        required: true,
      },
      {
        value: "",
        type: "text",
        name: "lname",
        label: "Last Name",
        id: "lName",
        placeholder: "Last Name",
        required: true,
      },
      {
        value: "",
        type: "text",
        name: "email",
        label: "Email",
        id: "email",
        placeholder: "Email",
        required: true,
      },
      {
        value: "",
        type: "text",
        name: "phone",
        label: "Phone",
        id: "phone",
        placeholder: "Phone",
        required: true,
      },
    ];
  };

  render() {
    return (
      <DataTables
        getTableFields={this.getTableFields}
        formFields={this.getFormFields}
        addRequried={false}
        editRequried={false}
        deleteRequried={false}
        viewRequried={false}
        // globalSearch={'Email'}
        type="Non-Registered Families"
        apiUrl={apiCalls.Activities}
        criteria={criteria}
      />
    );
  }
}

const criteria = [
  { key: "context", value: "User" },
  { key: "contextType", value: "CREATE" },
];

export default withTranslation("common")(NoTRegistered);
