import React from "react";
import { Container } from "reactstrap";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import GroupMembersView from "./components/GroupMembersView";

const groupMemberView = ({ t }) => (
  <Container>
    <GroupMembersView />
  </Container>
);

groupMemberView.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation("common")(groupMemberView);
