import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import NoTRegistered from './components/NoTRegistered';

const notregistered = ({ t }) => (
  <Container>
    <NoTRegistered />
  </Container >
);

notregistered.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(notregistered);