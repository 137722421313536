import React from "react";
import { withTranslation } from "react-i18next";

class DefaultInput extends React.Component {
  constructor(props) {
    super(props);
  }

  onChange = (event, type) => {
    console.log("evvvvvvv", type)
    if (event && event.target) {
      const { onChange, name } = this.props.input;
      // if (this.props.type === 'number') {
      //     if (event.target.value) {
      //         let num = event.target.value;
      //         if (name === 'noofDrawers' && num > 10) {
      //             num = 10;
      //         } else if (num <= 0) {
      //             num = 0;
      //         }
      //         onChange(num);
      //     } else {
      //         onChange('');
      //     }
      // } else {
      //     if (this.props.handleComments) {
      //         this.props.handleComments(event.target.value)
      //     }
      //     onChange(event.target.value);
      // }
      if (type === "nocaptalize") {
        onChange(event.target.value);
      } else if (type === "firstcaptalize" && name !== "msrp" && name !== "cost" && name !== "price") {
        let value =
          event.target.value.charAt(0).toUpperCase() +
          event.target.value.slice(1);
        onChange(value);
      } else if (type === "captalize") {
        let value = event.target.value.replace(/\w\S*/g, function (txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
        onChange(value);
      } else if (name === "msrp" || name === "cost" || name === "price") {
        var t = event.target.value;
        let value = (t.indexOf(".") >= 0) ? (t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3)) : t;
        onChange(value);
      }
    }
  };

  render() {
    const {
      input,
      placeholder,
      type, id,
      isDisable,
      meta: { touched, error },
      className,
      t,
    } = this.props;

    return (
      <div className="form__form-group-input-wrap">
        <div className="form__form-group-field ">
          <input
            style={
              isDisable
                ? { background: "lightgray", border: "none" }
                : { background: "white" }
            }
            {...input}
            className="mb-0"
            disabled={isDisable}
            placeholder={placeholder}
            type={type}
            onChange={
              input &&
                ((input.name === "title" &&
                  id == "nameOfGroup") || (input.name === "class_name" &&
                    id == "class_name"))
                ? (e) => {
                  this.onChange(e, "firstcaptalize");
                } :
                input &&
                  (input.name === "title" ||
                    input.name == "role" ||
                    input.name === "desc" ||
                    input.name === "description")
                  ? (e) => {
                    this.onChange(e, "firstcaptalize");
                  }
                  : input &&
                    (input.name === "email" || input.name === "foundation_Email" ||
                      input.name === "image_url" ||
                      input.name === "from_email" ||
                      input.name === "role" ||
                      input.name === "website" ||
                      input.name === "teacher_email" ||
                      input.name === "icon" ||
                      input.name === "route" ||
                      input.name === "displayTitle" ||
                      input.name === "teacher_website" ||
                      input.name === "email2" ||
                      input.name === "email1" ||
                      input.name === "pta_president_email" ||
                      input.name === "principal_email" ||
                      input.name === "Email" ||
                      input.name === "password" ||
                      input.name == "paypal_client_id" ||
                      input.name == "paypal_client_secret") ||
                    input.name == "newPassword" ||
                    input.name == "confirmPassword" ||
                    input.name == "currentPassword" ||
                    input.name == "newPassword" || input.name == "cheque"
                    ? (e) => {
                      this.onChange(e, "nocaptalize");
                    }
                    : (e) => {
                      this.onChange(e, "firstcaptalize");
                    }
            }
            maxLength={this.props.maxLength ? this.props.maxLength : null}
            className={className}
            id={this.props.id}
          />
        </div>
        {/* {touched && error && <span className="form__form-group-error">{error}</span>} */}
        <div style={{ height: "auto" }}>
          {touched && error && (
            <span className="form__form-group-error">{t(error)}</span>
          )}
        </div>
      </div>
    );
  }
}
export default withTranslation("common")(DefaultInput);
