import React from "react";
import apiCalls from "../../../../config/apiCalls";
import config from "../../../../config/config";
import DataTables from "../../CommonDataTable/DataTable";

export default class EmailTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: false,
      },
      // {
      //   show: true,
      //   textAlign: "center",
      //   width: 47,
      //   field: "Sno",
      //   header: "Sno",
      //   filter: false,
      //   sortable: false,
      //   placeholder: "Search",
      // },
      // {
      //   show: true,
      //   textAlign: "left",
      //   width: 80,
      //   mobile: true,
      //   field: "school_name",
      //   header: "School Name",
      //   fieldType: "Link",
      //   filter: false,
      //   sortable: true,
      //   style: {
      //     color: config.templateColor,
      //     cursor: "pointer",
      //     textTransform: "capitalize",
      //   },
      //   displayInSettings: true,
      // },
      {
        textAlign: "left",
        width: 100,
        field: "title",
        mobile: true,
        header: "Title",
        filter: false,
        sortable: false,
        show: true,
        textCapitalize: true,
        displayInSettings: true,
      },
      {
        textAlign: "left",
        width: 100,
        mobile: true,
        field: "from_email",
        header: "Email",
        filter: false,
        sortable: false,
        show: true,
        displayInSettings: true,
      },
      // {
      //   show: true,
      //   textAlign: "left",
      //   width: 100,
      //   mobile: true,
      //   field: "from_name",
      //   header: "Name",
      //   filter: false,
      //   sortable: false,
      //   textCapitalize: true,
      //   displayInSettings: true,
      // },

      {
        show: true,
        textAlign: "left",
        mobile: true,
        width: 100,
        field: "subject",
        header: "Subject",
        filter: false,
        sortable: false,
        placeholder: "search",
        textCapitalize: true,
        displayInSettings: true,
      },
      // {
      //   textAlign: 'center',
      //   width: 28,
      //   field: 'created',
      //   header: 'Created',
      //   filter: false,
      //   sortable: false,
      //   placeholder: config.dateDayMonthFormat
      // },
      {
        show: true,
        textAlign: "center",
        width: 65,
        field: "Actions",
        header: "Actions",
        fieldType: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
      },
    ];
    return data;
  };

  getFormFields = () => {
    return [
      {
        required: true,
        value: "",
        type: "text",
        name: "title",
        label: "Title",
        id: "title",
        placeholder: "Title",
      },
      // {
      //   required: true,
      //   value: "",
      //   searchField: "Name",
      //   searchApi: "schools",
      //   options: [
      //     { school_name: "Name" },
      //     { district_id: "district_id" },
      //     { school_id: "school_id" },
      //   ],
      //   type: "autoComplete",
      //   name: "school_name",
      //   label: "School Name",
      //   id: "Name",
      //   placeholder: "School Name",
      // },
      {
        required: true,
        value: "",
        type: "text",
        name: "subject",
        label: "Subject",
        id: "subject",
        placeholder: "Subject",
      },
      // {
      //   required: true,
      //   value: "",
      //   type: "text",
      //   name: "from_email",
      //   label: "From Email",
      //   id: "from_email",
      //   placeholder: "From Email",
      // },
      // {
      //   required: true,
      //   value: "",
      //   type: "text",
      //   name: "from_name",
      //   label: "Name",
      //   id: "from_name",
      //   placeholder: "Name",
      // },
      // {
      //   required: true,
      //   value: "",
      //   type: "textarea",
      //   name: "content",
      //   label: "Content",
      //   id: "content",
      //   placeholder: "Content",
      // },
      {
        required: true,
        value: "",
        type: "ckeditor",
        name: "templateText",
        label: "Email Template",
        id: "emailTemplate",
        placeholder: "name",
      },
    ];
  };

  render() {
    return (
      <div>
        <DataTables
          type="Email Templates"
          getTableFields={this.getTableFields}
          formFields={this.getFormFields}
          addRequried={true}
          editRequired={true}
          exportToCsv={true}
          deleteRequired={true}
          viewRequired={true}
          settingsRequired={true}
          globalSearch={"Subject/Title/Email"}
          criteria={{ key: "isGroupEmail", value: true, type: "ne" }}
          groupEmailTemplateRequired={true}
          apiUrl={apiCalls.Templates}
          actionsTypes={actionsTypes}
        />
      </div>
    );
  }
}

const actionsTypes = [
  {
    name: "Delete",
    options: [{ label: "Delete", value: "Delete", show: true, multiple: true }],
  },
];
