import React from "react";
import apiCalls from "../../../../config/apiCalls";
import config from "../../../../config/config";
import DataTables from "../../CommonDataTable/DataTable";

export default class EmailTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: false,
      },
      // {
      //   show: true,
      //   textAlign: "center",
      //   width: 40,
      //   field: "Sno",
      //   header: "Sno",
      //   filter: false,
      //   sortable: false,
      //   placeholder: "Search",
      // },
      {
        show: true,
        textAlign: "left",
        width: 100,
        mobile: true,
        field: "school_name",
        header: "School Name",
        fieldType: "Link",
        filter: false,
        sortable: true,
        style: {
          color: config.templateColor,
          cursor: "pointer",
          textTransform: "capitalize",
        },
        displayInSettings: true,
      },
      {
        textAlign: "left",
        width: 100,
        field: "title",
        mobile: true,
        header: "Title",
        filter: false,
        sortable: false,
        show: true,
        textCapitalize: true,
        displayInSettings: true,
      },
      {
        textAlign: "left",
        width: 100,
        mobile: true,
        field: "from_email",
        header: "Email",
        filter: false,
        sortable: false,
        show: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 100,
        mobile: true,
        field: "from_name",
        header: "Name",
        filter: false,
        sortable: false,
        textCapitalize: true,
        displayInSettings: true,
      },

      {
        show: true,
        textAlign: "left",
        mobile: true,
        width: 100,
        field: "subject",
        header: "Subject",
        filter: false,
        sortable: false,
        placeholder: "search",
        textCapitalize: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 60,
        field: "last_sent",
        mobile: true,
        fieldType: "Date",
        header: "Last sent",
        filter: false,
        sortable: true,
        placeholder: config.dateDBFormat,
        dateFormat: config.dateDBFormat,
        displayInSettings: true,
      },

      {
        show: true,
        textAlign: "center",
        width: 65,
        field: "Actions",
        header: "Actions",
        fieldType: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
      },
    ];
    return data;
  };

  getFormFields = () => {
    return [
      {
        required: true,
        value: "",
        type: "text",
        name: "title",
        label: "Title",
        id: "title",
        placeholder: "Title",
      },
      {
        required: true,
        value: "",
        searchField: "Name",
        searchApi: "schools",
        options: [
          { school_name: "Name" },
          { district_id: "district_id" },
          { school_id: "school_id" },
        ],
        type: "autoComplete",
        name: "school_name",
        label: "School Name",
        id: "Name",
        placeholder: "School Name",
      },
      {
        required: true,
        value: "",
        type: "text",
        name: "subject",
        label: "Subject",
        id: "subject",
        placeholder: "Subject",
      },
      {
        required: true,
        value: "",
        type: "text",
        name: "from_email",
        label: "From Email",
        id: "from_email",
        placeholder: "From Email",
      },
      {
        required: true,
        value: "",
        type: "text",
        name: "from_name",
        label: "From Name",
        id: "from_name",
        placeholder: "From Name",
      },
      {
        required: true,
        value: "",
        type: "textarea",
        name: "content",
        label: "Content",
        id: "content",
        placeholder: "Content",
      },
      {
        required: true,
        value: "",
        type: "ckeditor",
        name: "templateText",
        label: "Email Template",
        id: "emailTemplate",
        placeholder: "name",
      },
    ];
  };

  render() {
    return (
      <div>
        <DataTables
          type="Group Email"
          getTableFields={this.getTableFields}
          formFields={this.getFormFields}
          addRequried={true}
          editRequired={true}
          exportToCsv={true}
          otherActionsRequired={true}
          copyRequired={true}
          deleteRequired={true}
          viewRequired={true}
          sendRequired={true}
          settingsRequired={true}
          criteria={{ key: "isGroupEmail", value: true, type: "eq" }}
          groupEmailRequired={true}
          globalSearch={"Name/Title/Email"}
          apiUrl={apiCalls.Templates}
          actionsTypes={actionsTypes}
        />
      </div>
    );
  }
}

const actionsTypes = [
  {
    name: "Delete",
    options: [{ label: "Delete", value: "Delete", show: true, multiple: true }],
  },
];
