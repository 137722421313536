
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Button, CardBody, Modal } from 'reactstrap';
import RadioButton from '../../../shared/components/form/RadioButton';
import DefaultInput from '../../../shared/components/form/DefaultInput';
import configMessages from '../../../config/configMessages';
// fecth method from service.js file
import fetch from '../../../config/service';

// show message 
import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';
import validate from '../../Form/validate';
import Loader from '../../App/Loader';
const required = value => (value || typeof value === 'string' ? undefined : configMessages.fillField);

class UserPasswordResetModal extends React.Component {
    constructor(props) {
        super(props);
        this.buttonActionType = null;
        this.state = {
            isLoginSuccess: false,
            isLoading: true
        };
    }

    componentDidMount() {
        this.setState({ isLoading: false });
    }

    submit = async (values) => {
        await this.setState({
            isLoading: true
        });
        let method = '';
        let body = {};
        let url = '';
        method = 'POST';
        body = {
            "selectedIds": this.props.selectedRowsId,
            "payment_method": values.payment_method,
            "cheque": values.cheque
        }
        url = 'payments/adminMultiPaymentUpdate';

        fetch(method, url, body).then(async response => {
            if (response && response.respCode && response.respMessage) {
                showToasterMessage(response.respMessage, 'success');
            } else if (response && response.errorMessage) {
                showToasterMessage(response.errorMessage, 'error');
            }
            await this.setState({
                isLoading: false
            });
            await this.props.cancelPayment();
        })
    }

    cancelPayment = async () => {
        await this.props.reset();
        await this.props.cancelPayment();
    }

    handlePaymentMode = async (type) => {
        await this.setState({ PaymentType: type })
    }

    render() {
        const { handleSubmit, openUserPasswordResetModal } = this.props;
        return (
            <Modal isOpen={openUserPasswordResetModal} centered className='userPwdResetModal'>
                <CardBody className='p-2'>
                    <h4 className='pb-3'>Select Payment Method</h4>
                    <form className="form" onSubmit={handleSubmit(this.submit)}>
                        <Loader loader={this.state.isLoading} />
                        <div className="form__form-group-field mb-2 col-4 justifyFlexEnd pb-2">
                            <Field
                                name={'payment_method'}
                                component={RadioButton}
                                label={'Cash'}
                                radioValue={'Cash'}
                                onChange={() => this.handlePaymentMode('Cash')}
                                disabled={false}
                                defaultChecked={true}
                            />
                        </div>
                        <div className="form__form-group-field mb-2 col-4 justifyFlexStart pb-2">
                            <Field
                                name={'payment_method'}
                                component={RadioButton}
                                label={'Cheque'}
                                radioValue={'Cheque'}
                                onChange={() => this.handlePaymentMode('Cheque')}
                                disabled={false}
                            />
                        </div>
                        {this.state.PaymentType === "Cheque" ?
                            <div className="form__form-group-field mb-2 col-8 justifyFlexStart pb-2">
                                <Field
                                    name={'cheque'}
                                    component={DefaultInput}
                                    label={'Cheque Number'}
                                    type="text"
                                    validate={[required]}
                                />
                            </div> : null}
                        <div className="col-12" style={{ margin: 'auto' }}>
                            <Button color="primary" type='submit'>Submit</Button>
                            <Button color="primary" type='buttom' onClick={this.cancelPayment}>Cancel</Button>
                        </div>
                    </form>
                </CardBody>
            </Modal>
        );
    }
}

UserPasswordResetModal = reduxForm({
    form: 'Payment Approval Form', // a unique identifier for this form
    validate,
})(UserPasswordResetModal);

export default UserPasswordResetModal;