import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Roles from './components/Roles';

const roles = ({ t }) => (
  <Container>
    <Roles />
  </Container>
);

roles.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(roles);