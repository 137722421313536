import React from "react";
import apiCalls from "../../../../config/apiCalls";
import DataTables from "../../CommonDataTable/DataTable";
import filePath from "../../../../config/configSampleFiles";

// config file
export default class Teachers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      languageData: "",
    };
  }

  getTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: false,
      },
      // {
      //   show: true,
      //   textAlign: "center",
      //   width: 47,
      //   field: "Sno",
      //   header: "Sno",
      //   filter: false,
      //   sortable: false,
      //   placeholder: "Search",
      // },
      {
        textAlign: "left",
        width: 140,
        show: true,
        fieldType: "Link",
        field: "school_name",
        mobile: true,
        header: "School Name",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 140,
        field: "department_name",
        mobile: true,
        header: "Department Name",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 80,
        mobile: true,
        field: "title",
        header: "title",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 180,
        mobile: true,
        field: "display_name",
        header: "Faculy/Staff Name",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 80,
        mobile: false,
        field: "phone",
        header: "Phone",
        fieldType: "phoneFormat",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 120,
        field: "email",
        header: "Email",
        mobile: false,
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 70,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
      },
    ];
    return data;
  };

  getFormFields = () => {
    return [
      {
        required: true,
        value: "",
        searchField: "name",
        searchApi: "districts",
        type: "autoComplete",
        name: "district_name",
        label: "School District",
        options: [
          { district_name: "name" },
          { district_id: "district_id" },
        ],
        id: "Name",
        placeholder: "School District",
      }, {
        required: true,
        value: "",
        searchField: "Name",
        searchApi: "schools",
        type: "autoComplete",
        options: [
          { school_name: "Name" },
          { district_id: "district_id" },
          { school_id: "school_id" },
        ],
        name: "school_name",
        label: "School Name",
        id: "Name",
        placeholder: "School Name",
      },
      {
        required: true,
        value: "",
        searchField: "category_name",
        searchApi: "departments",
        options: [
          { department_id: "_id" },
          { department_name: "category_name" },
        ],
        type: "autoComplete",
        name: "department_name",
        label: "Department",
        id: "department_id",
        placeholder: "Department",
      },
      {
        value: "",
        type: "text",
        name: "title",
        label: "Title",
        id: "title",
        placeholder: "Title",
        required: false,
      },
      {
        value: "",
        type: "text",
        name: "lname",
        label: "Last Name",
        id: "lastName",
        placeholder: "Last Name",
        required: true,
      },
      {
        value: "",
        type: "text",
        name: "fname",
        label: "First Name",
        id: "firstName",
        placeholder: "First Name",
        required: true,
      },
      {
        value: "",
        type: "text",
        name: "prefix",
        label: "Prefix",
        id: "prefix",
        placeholder: "Prefix",
        required: false,
      }, {
        value: "",
        type: "text",
        name: "phone",
        label: "Phone No",
        id: "Phone",
        placeholder: "Phone No",
        required: false,
      },
      {
        value: "",
        type: "text",
        name: "email",
        label: "Email",
        id: "email",
        placeholder: "Email",
        required: true,
      },

      {
        value: "",
        type: "text",
        name: "website",
        label: "website",
        id: "website",
        placeholder: "Website",
        required: false,
      },
    ];
  };

  render() {
    return (
      <DataTables
        getTableFields={this.getTableFields}
        formFields={this.getFormFields}
        addRequried={true}
        editRequired={true}
        deleteRequired={true}
        viewRequired={true}
        exportToCsv={true}
        settingsRequired={true}
        sample={true}
        sampleFilePath={filePath.faculty}
        bulkApi={"faculty"}
        globalSearch={"Department/Faculty Name/Email"}
        type="Faculty/Staff"
        apiUrl={apiCalls.Faculty}
        actionsTypes={actionsTypes}
      />
    );
  }
}

const actionsTypes = [
  {
    name: "Delete",
    options: [{ label: "Delete", value: "Delete", show: true, multiple: true }],
  },
  {
    name: "ResetFaculty",
    options: [
      {
        label: "Reset",
        value: "ResetFaculty",
        show: true,
        multiple: true,
      },
    ],
  },
];
