import React from 'react';
import PropTypes from 'prop-types';
import { Badge, Row } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

//config
import config from '../../../config/config';
const SidebarLink = ({
  title, icon, newLink, route, onClick, t, submenus, sidebar
}) => (
  <NavLink
    to={route}
    onClick={onClick}
    activeClassName="sidebar__link-active"
    activeStyle={{ backgroundColor: config.darkTemplateColor, color: config.whiteColor }}
  >
    <Row className="sidebar__link mx-0">
      {icon && !submenus ? <span
        activeclassname={`active_sidebar__link-icon lnr `}
        className={`sidebar__link-icon lnr `} >{icon.substring(0, 2)}</span> : ''}
      <p className="sidebar__link-title" activeclassname='sidebar__link-active'
      >
        {t(title) === "Orders Management Report" ? <span>Orders Management <span className='mr-2'>Report</span></span> : t(title)}
        {newLink ? <Badge className="sidebar__link-badge"><span>New</span></Badge> : ''}
      </p>
    </Row>
  </NavLink>
);

SidebarLink.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  newLink: PropTypes.bool,
  route: PropTypes.string,
  onClick: PropTypes.func,
};

SidebarLink.defaultProps = {
  icon: '',
  newLink: false,
  route: '/',
  onClick: () => { },
};

export default withTranslation('common')(SidebarLink);