import React from 'react';
import ChangeRecoverPassword from './components/ChangeRecoverPassword';
import LogoUI from '../../LogoUI/logoUI'

const ChangeRecoverPasswordPage = (props) => (
    <div className="account">
        <div className="account justify-content-center ">
            <div className="account__wrapper  cardLoginPadding">
                <LogoUI />
                <h3 className='text-center'>Reset Password</h3>
                <ChangeRecoverPassword {...props} />
            </div>
        </div>
    </div>
);

export default ChangeRecoverPasswordPage;