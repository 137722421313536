import React from "react";
import RolesDT from "./RolesDT";
import apiCalls from "../../../../config/apiCalls";
import config from "../../../../config/config";
import store from "../../../App/store";
import fetchMethodRequest from "../../../../config/service";
import DataTables from "../../CommonDataTable/DataTable";

export default class Roles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      languageData: "",
      menuList: [],
    };
  }
  componentDidMount = async () => {
    this.getMenuListFromServer();
  };

  getMenuListFromServer = async () => {
    let filterCriteria = {},
      url;
    filterCriteria.sortfield = "sequenceNo";
    filterCriteria.direction = "asc";
    let neWmenuList = [];
    url = `menulists?filter=${JSON.stringify(filterCriteria)}`;
    fetchMethodRequest("GET", url)
      .then(async (res) => {
        if (res && res.menulists && res.menulists.length > 0) {
          if (res.menulists) {
            res.menulists.forEach((item) => {
              if (item.submenus && item.submenus.length > 0) {
                item.submenus.forEach(sitem => {
                  neWmenuList.push(sitem);
                })
              } else {
                neWmenuList.push(item);
              }
            });
          }
          this.setState({
            menuList: neWmenuList
          })
        } else {
          this.setState({
            menuList: []
          })
        }
      })
      .catch((err) => {
        return err;
      });
  };
  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState();
    let languageData =
      storeData &&
        storeData.settingsData &&
        storeData.settingsData.settings &&
        storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  }

  getTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: false,
      },
      // {
      //   show: true,
      //   textAlign: "center",
      //   width: 37,
      //   field: "Sno",
      //   header: "Sno",
      //   filter: false,
      //   sortable: false,
      //   placeholder: "Search",
      // },
      {
        show: true,
        textAlign: "center",
        mobile: true,
        width: 300,
        field: "role",
        fieldType: "Role",
        header: "Role",
        filter: false,
        sortable: true,
        placeholder: "Role",
        options: config.employeeTableRolesOptions,
        style: {
          padding: "4px 6px",
          fontSize: 12,
          color: "white",
          fontWeight: "bold",
          textTransform: "capitalize",
        },
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 70,
        field: "Actions",
        fieldType: "Actions",
        header: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
      },
    ];
    return data;
  };

  getFormFields = () => {
    return [
      {
        value: "",
        type: "text",
        name: "role",
        label: "Role",
        id: "role",
        placeholder: "Role",
        required: true,
      },
      {
        value: "",
        type: "permission",
        name: "permission",
        label: "Permission",
        id: "permission",
        placeholder: "Permission",
        required: true,
      },
    ];
  };

  render() {
    return (
      <DataTables
        getTableFields={this.getTableFields}
        formFields={this.getFormFields}
        addRequried={true}
        editRequired={true}
        deleteRequired={true}
        viewRequired={true}
        settingsRequired={true}
        rolesRequired={true}
        globalSearch={"Role"}
        criteria={{ key: "type", value: "Admin", type: "eq" }}
        type="Roles"
        menuList={this.state.menuList}
        apiUrl={apiCalls.Roles}
        actionsTypes={actionTypes}
      />
    );
  }
}

const actionTypes = [
  {
    name: "Delete",
    options: [{ label: "Delete", value: "Delete", show: true, multiple: true }],
  },
];
