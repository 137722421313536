import React from "react";
import ParentDT from "./ParentDT";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import store from "../../../App/store";
import DataTables from "../../CommonDataTable/DataTable";

// config file
export default class Parent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      languageData: "",
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState();
    let languageData =
      storeData &&
        storeData.settingsData &&
        storeData.settingsData.settings &&
        storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  }

  getTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: false,
      },
      // {
      //   show: true,
      //   textAlign: "center",
      //   width: 40,
      //   field: "Sno",
      //   header: "Sno",
      //   filter: false,
      //   sortable: false,
      //   placeholder: "Search",
      // },
      {
        show: true,
        textAlign: "left",
        width: 220,
        mobile: false,
        field: "email",
        header: "Email",
        filter: false,
        sortable: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
        },
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        mobile: true,
        width: 160,
        field: "fname",
        header: "Parent First Name",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        mobile: true,
        width: 160,
        field: "lname",
        header: "Parent Last Name",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        mobile: true,
        width: 160,
        field: "display_name",
        header: "Parent Name",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        mobile: true,
        width: 120,
        field: "city",
        header: "City",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        mobile: true,
        width: 120,
        field: "district",
        header: "District",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        mobile: true,
        width: 120,
        field: "state",
        header: "State",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "center",
        mobile: true,
        width: 120,
        field: "zip",
        header: "Zip",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "center",
        mobile: true,
        width: 120,
        field: "authentication_type",
        header: "Login Via",
        textCapitalize: true,
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "center",
        mobile: true,
        width: 180,
        field: "last_login",
        header: "Last Login Date",
        dateFormat: "MM-DD-YYYY hh:mm a",
        fieldType: "Date",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "center",
        mobile: true,
        width: 160,
        field: "pwd_created",
        header: "Password Created",
        dateFormat: config.dateDBFormat,
        fieldType: "Date",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        mobile: true,
        width: 160,
        field: "failures",
        header: "Failed login #",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },

      {
        show: true,
        textAlign: "center",
        mobile: true,
        width: 160,
        field: "pwd_updated",
        header: "Password Updated",
        dateFormat: config.dateDBFormat,
        fieldType: "Date",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 160,
        mobile: false,
        field: "payment_status",
        fieldType: "BoldText",
        header: "Payment Status",
        filter: false,
        sortable: true,
        textCapitalize: true,
        displayInSettings: true,
      }, {
        show: true,
        textAlign: "center",
        mobile: true,
        width: 160,
        field: "workphone",
        header: "Mobile Work",

        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 160,
        mobile: false,
        field: "homephone",
        // fieldType: "BoldText",
        header: "Mobile Home",
        filter: false,
        sortable: true,
        textCapitalize: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 100,
        field: "status",
        mobile: true,
        header: "Status",
        fieldType: "Badge",
        options: config.tabfieldsStatusTypes,
        filter: false,
        sortable: true,
        style: {
          padding: "4px 6px",
          fontSize: 12,
          color: "white",
          textTransform: "capitalize",
          fontWeight: "bold",
        },
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 100,
        mobile: false,
        field: "role",
        fieldType: "Role",
        header: "Role",
        filter: false,
        sortable: true,
        style: {
          padding: "4px 6px",
          fontSize: 12,
          color: "white",
          textTransform: "capitalize",
          fontWeight: "bold",
        },
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 150,
        mobile: false,
        field: "isOtpVerified",
        header: "OTP Verified",
        filter: false,
        sortable: true,
        fieldType: "BooleanBadge",
        // "iconName": 'check-circle',
        options: config.userOtpVerifiedOptions,
        style: {
          padding: "4px 6px",
          fontSize: 12,
          color: "white",
          textTransform: "capitalize",
          fontWeight: "bold",
        },
        displayInSettings: true,
      },
      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 100,
      //   "field": "Actions",
      //   "fieldType": "Actions",
      //   "header": "Actions",
      //   "filter": false,
      //   "sortable": false
      // }
    ];
    return data;
  };

  getFormFields = () => {
    return [
      {
        required: true,
        value: "",
        searchField: "Name",
        searchApi: "schools",
        options: [
          { school_name: "Name" },
          { district_id: "district_id" },
          { school_id: "school_id" },
        ],
        type: "autoComplete",
        name: "school_name",
        label: "School Name",
        id: "Name",
        placeholder: "School Name",
      },

      {
        value: "",
        type: "text",
        name: "fname",
        label: "First Name",
        id: "fname",
        placeholder: "First Name",
        required: true,
      },
      {
        value: "",
        type: "text",
        name: "lname",
        label: "Last Name",
        id: "lname",
        placeholder: "Last Name",
        required: true,
      },
      {
        value: "",
        type: "text",
        name: "email",
        label: "Email",
        id: "email",
        placeholder: "Email",
        required: true,
      },
      {
        value: "",
        type: "text",
        name: "homephone",
        label: "Phone Number",
        id: "homephone",
        placeholder: "Phone Number",
        required: true,
      },
      {
        required: true,
        value: "",
        type: "dropDown",
        name: "role",
        label: "Role",
        id: "role",
        options: [{ label: "Parent", value: "Parent" }],
        placeholder: "Role",
      },
    ];
  };

  render() {
    return (
      <DataTables
        getTableFields={this.getTableFields}
        formFields={this.getFormFields}
        addRequried={false}
        editRequired={false}
        deleteRequired={false}
        viewRequired={true}
        settingsRequired={true}
        globalSearch={"Name/Email/Zip"}
        exportToCsv={true}
        type="Users"
        sendFailures={0}
        apiUrl={apiCalls.User}
        displayViewOfForm={"screen"}
        entityType="user"
        otherActionsRequired={true}
        actionsTypes={actionTypes}
        routeTo="users"
        displayViewOfForm="screen"
      />
    );
  }
}

const actionTypes = [
  // {
  //   name: "Divorce",
  //   options: [
  //     { label: "Divorce", value: "Divorce", show: true, multiple: false },
  //   ],
  // },
  // {
  //   name: "Block",
  //   options: [{ label: "Block", value: "Block", show: true, multiple: true }],
  // },
  // {
  //   name: "UnBlock",
  //   options: [
  //     { label: "UnBlock", value: "UnBlock", show: true, multiple: true },
  //   ],
  // },
  {
    name: "Active",
    options: [{ label: "Active", value: "Active", show: true, multiple: true }],
  },
  {
    name: "InActive",
    options: [
      { label: "InActive", value: "Inactive", show: true, multiple: true },
    ],
  },
  {
    name: "ResetPassword",
    options: [
      {
        label: "ResetPassword",
        value: "ResetPassword",
        show: true,
        multiple: false,
      },
    ],
  },
  {
    name: "Invite",
    options: [
      {
        label: "Invite",
        value: "Invite",
        show: true,
        multiple: true,
      },
    ],
  },
];
