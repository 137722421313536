import React from 'react';
import SignUpForm from './components/SignUpForm';
import config from '../../../config/config';
import configImages from '../../../config/configImages';
import LogoUI from '../../LogoUI/logoUI'
const LogIn = () => (
  <div className="account justify-content-center">
    <div>
      {/* <h2 className='text-center my-2'>
        <img src={configImages.defaultImg} className='hide-mobile img_htwt' ></img>
      </h2> */}
      <LogoUI />
      <div className="account__wrapper  cardLoginPadding">
        <div className="account__card cardWidthLogin">

          <div className='row justifyContentLogin'>
            {/* <div className='col-sm-5 textcenter mt-4 pl-0 pr-0 mb-4'>
            <LogoUI />
            <img src={configImages.defaultImg} className='hide-mobile img_htwt'></img>
          </div> */}
            <div className='col-sm-12 vertical_middle width_90 my-2 pl-0 pr-0 '>
              <div className='width_90'>
                <SignUpForm onSubmit />
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
);

export default LogIn;
