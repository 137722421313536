import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Card,
  CardBody,
  ButtonToolbar,
  ButtonGroup,
  Row,
  Col,
  Table,
  Badge,
} from "reactstrap";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { Link } from "react-router-dom";

import classNames from "classnames";
// import Moment from "moment";
import moment from "moment";
import 'moment-timezone';
import { load as loadAccount } from "./../../redux/reducers/commonReducer";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
//components
import AutoComplete from "./components/AutoComplete";
import CalendarBlankIcon from "mdi-react/CalendarBlankIcon";

import Select from "../../shared/components/form/Select";
import DefaultInput from "../../shared/components/form/DefaultInput";
import DefaultClearInput from "../../shared/components/form/DefaultClearInput";
import DefaultTextArea from "../../shared/components/form/DefaultTextArea";
import DatePicker from "../../shared/components/form/DatePicker";
import TimePicker from "../../shared/components/form/TimePicker";
import renderRadioButtonField from "../../shared/components/form/RadioButton";

import RenderFileInputField from "../Form/components/FileUpload";
// import CKEditor from "ckeditor4-react";
import CKEditor from "ckeditor4-react-advanced";

// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import config from "../../config/config";
import configMessages from "../../config/configMessages";
import configImages from "../../config/configImages";

import apiCalls from "../../config/apiCalls";
import fetchMethodRequest from "../../config/service";
import DeleteRowModal from "../Cruds/CommonModals/DeleteRowModal";
import UserPasswordResetModal from "../Cruds/CommonModals/UserPasswordResetModal";
// Toaster message
import showToasterMessage from "../UI/ToasterMessage/toasterMessage";

import dateFormats from "../UI/FormatDate/formatDate";
import validate from "../Validations/validate";

// Multi select Dropdown
import MultiSelectDropDown from "./components/MultiSelect";

// Loader
import Loader from "../App/Loader";

// Calendar
//session expiry modal
import SessionExpiryModal from "../Cruds/CommonModals/SessionexpiryModal";
//import TicketCommentsInfo from '../Tables/PrimeReactTable/Tickets/components/TicketCommentsInfo';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Scrollbar from "react-smooth-scrollbar";
import PaginatorComponent from "../Cruds/CommonDataTable/PaginatorComponent";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
const required = (value) =>
  value || typeof value === "string"  ? undefined : configMessages.fillField;

  const multiRequired = (value) => 
    value && value.length !== 0 ? undefined :configMessages.fillField;

const radioRequired = (value) => {
  console.log("value", value)
  let error = undefined;
  if (value || typeof value === "string") {
    error = undefined;
  } else {
    error = configMessages.fillRadio;
  }
  // console.log("rad_err", error);
  // console.log("rad_val", value);

  return error;
};
const normalizePhone = (value) => {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/[^\d]/g, "");
  if (onlyNums.length <= 3) {
    return onlyNums;
  }
  if (onlyNums.length <= 7) {
    return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`;
  }
  return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 6)}-${onlyNums.slice(
    6,
    10
  )}`;
};
class FormModal extends React.Component {
  static propTypes = {
    t: PropTypes.func,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      isDisabled: false,
      inputValues: {},
      formFields: [],
      actionsText: [],
      formValues: {},
      colored: false,
      header: true,
      isLoading: false,
      sessionExpiryModal: false,
      menuList: this.props.menuList ? this.props.menuList : [],
      defaultValue: true,
      roleOptions: [],
      filterCriteria: {
        limit: 15,
        page: 1,
        criteria: [],
        sortfield: "created",
        direction: "desc",
      },
      paypalClientId: "",
      activitiesData: [],
      totalRecordsLength: 0,
      first: 0,
      rows: 10,
      formType: this.props.formType,
    };
  }

  componentDidMount = async () => {
    if (this.props.formType === "add") {
      await this.props.reset();
      await this.props.load({});
    }
    if (this.props.onRef) {
      await this.props.onRef(this);
    }
    await this.checkSessionExpiration();
    await this.getFormFields();
    this.getSettingsData()
    this.setState({
      formType: this.props.formType,
      displayBreadCrumbValue: this.props.displayBreadCrumbValue,
      displayBreadCrumbField: this.props.displayBreadCrumbField,
      userStatus: this.props.userStatus,
      inputValues: this.props.formType == "edit" ? this.props.selectedInfo : '',
    });
    if (this.props.type === "Orders") {
    }

    // this.getActivities();
    if (this.props.type === "Roles") {
      let menu = this.props.menuList
      var result = menu.map(function (el) {
        var o = Object.assign({}, el);
        // o.Edit = false;
        // o.View = true;
        o.NoView = true
        return o;
      })
      this.setState({
        menuList: result
      })
    }

    // Districts page payment type changes when edit

    if (this.props.formType === "edit" && this.props.type == "District") {
      let formFields = this.state.formFields
      let selectedInformation = this.props.selectedInfo
      if (formFields.length && selectedInformation) {
        this.handleTexfields({ value: selectedInformation.payment_types }, formFields[4]);
      }
    }

  };


  getSettingsData = async () => {
    fetchMethodRequest("GET", `settings`).then(async (response) => {
      if (response && response.respCode) {
        let settingsData = response.settings[0];
        // Pincode length as per settings
        let pincodeLength = response.settings[0].pincodeLength
        if (this.props.type == 'District' || 'School') {
          config.pincodeRegex = new RegExp(`^([0-9]){${pincodeLength}}$`)
          configMessages.pincode = `Please enter a Valid ${pincodeLength} digit ZipCode`;
        }
        if (settingsData && settingsData['i18n']) {
          await this.setState({
            actionsText: settingsData['i18n'],
          });
        }
      }

    });
  }

  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(null);
    }
  }

  checkSessionExpiration = async () => {
    let sessionexpired = localStorage.getItem("sessionexpired");
    if (sessionexpired === "true") {
      await this.setState({ sessionExpiryModal: true });
    }
  };

  getFormFields = async () => {
    if (this.props.formFields) {
      let formFields = await this.props.formFields();
      if (this.props.formType == "edit" || this.state.formType == "view") {
        let keys = Object.keys(this.props.selectedInfo);
        for (let i = 0; i < formFields.length; i++) {
          for (let j = 0; j < keys.length; j++) {
            if (keys[j] == formFields[i]["name"]) {
              formFields[i]["show"] = true;
            }
            if (this.props.selectedInfo && this.props.type === "Administrators") {
              if (this.props.selectedInfo.role == "GroupAdmin") {
                if (formFields[i]["name"] == "isSelectRole") {
                  formFields[i]["show"] = true
                }
              }
            }
          }
        }
      }
      if (this.props.type === "Products") {
        for (let i = 0; i < formFields.length; i++) {
          if (formFields[i].name === "store_name" && this.props.formType == "edit") {
            // formFields[i].type = "text"
            formFields[i].type = "dropDown"
          } else if (formFields[i].name === "store_name" && this.props.formType == "add") {
            formFields[i].type = "dropDown"
          }
        }
      }
      await this.setState({ formFields: formFields });
    }
  };

  getFormFieldItem = async (key) => {
    let formFields = await this.props.formFields();
    for (let i = 0; i < formFields.length; i++) {
      if (formFields[i].name === key) {
        return formFields[i];
      }
    }
    return null;
  };
  setSlno = async (actionType) => {
    const { totalRecords, first, rows, onPageChange, isWeb } = this.props;
    let slno = this.state.slno;
    if (actionType === "back") {
      if (slno !== 0) {
        await this.setState({ slno: slno - 1 });
        this.handleNextAndBackActions(actionType);
      } else {
        await this.setState({ slno: slno + 1 });
      }
    } else if (actionType === "next") {
      let total = "";
      total = this.props.allUsersData.length;
      if (slno !== total) {
        await this.setState({ slno: slno + 1 });
        this.handleNextAndBackActions(actionType);
      } else {
        await this.setState({ slno: slno });
      }
    }
  };

  removeStateFieldNames = async () => {
    let fields = this.props.formFields();
    for (let i = 0; i < fields.length; i++) {
      let fieldItem = await this.getFormFieldItem(fields[i]["name"]);
      if (fieldItem) {
        if (
          (fieldItem.type === "multipleprofile" ||
            fieldItem.type === "ckeditor" ||
            fieldItem.type === "profile" ||
            fieldItem.type === "autoComplete") &&
          fieldItem.name
        ) {
          this.setState({
            [fieldItem.name]: "",
          });
        }
      }
    }
  };
  //Get From Fields data on Edit
  getRowData = async (item, type, apiUrl) => {
    this.removeStateFieldNames();
    await this.setState({ isLoading: true, rowData: item });
    if (this.props.type == "Groups" && this.props.formType == "edit") {
      await this.setState({
        schoolName: this.props.selectedInfo["school_id"],
      });
    }
    let selectedRowInfo = await this.getRowDataFromServer(item, apiUrl);
    let keys = Object.keys(selectedRowInfo);
    for (let i = 0; i < keys.length; i++) {
      let fieldItem = await this.getFormFieldItem(keys[i]);
      if (fieldItem) {
        if (this.props.type === "Groups" && fieldItem.name === "class_name") {
          selectedRowInfo[fieldItem.name] = selectedRowInfo.classOrGrade
        }
        if (
          this.props.type === "Departments" || this.props.type === "Class" || this.props.type === "Faculty/Staff"
        ) {
          this.setState({
            schoolName: selectedRowInfo['district_name'],
          });
        }
        //console.log(fieldItem, selectedRowInfo[fieldItem.name])
        if (
          (fieldItem.type === "multipleprofile" ||
            fieldItem.type === "ckeditor" ||
            fieldItem.type === "profile") &&
          selectedRowInfo[fieldItem.name]
        ) {
          this.setState({
            [fieldItem.name]: selectedRowInfo[fieldItem.name],
          });
        } else if (
          fieldItem.type === "autoComplete" &&
          selectedRowInfo[fieldItem.name]
        ) {
          if (this.props.type === "Class" && fieldItem.name === "school_name") {
            this.getSchoolGrades(
              selectedRowInfo[fieldItem.name],
              "Name",
              "schools",
              "schoolGrades"
            );
          }

          if (this.props.type === "Products") {
            await this.getRecords(
              selectedRowInfo[fieldItem.name],
              fieldItem.name,
              "stores",
              "store_name"
            );
          }
          if (this.props.type === "Orders" && fieldItem.name === "store_name") {
            this.getRecords(
              selectedRowInfo[fieldItem.name],
              fieldItem.name,
              "products",
              "product_name"
            );
          }

        } else if (fieldItem.type === "dropDown" && fieldItem.isMultiSelect) {
          this.handleTexfields(selectedRowInfo[fieldItem.name], selectedRowInfo)
        }
      }
    }
    if (this.props.type === "School") {
      selectedRowInfo["hasFoundation"] = selectedRowInfo.hasFoundation === true ? "true" : "false"
    }

    if (this.props.type === "Store") {
      this.showFoundationField()
      selectedRowInfo["isFoundationStore"] = selectedRowInfo.isFoundationStore === true ? "true" : "false"
    }

    if (this.props.type === "Groups") {
      selectedRowInfo["title"] = selectedRowInfo.name
    }
    if (this.props.type === "Groups") {
      selectedRowInfo["title"] = selectedRowInfo.name
    }

    if (selectedRowInfo["permissions"]) {

      let permissionsArray = [];
      let permissions = selectedRowInfo["permissions"];
      if (permissions) {
        let keys = Object.keys(permissions);
        keys.forEach((element) => {
          if (element) {
            selectedRowInfo[element] = permissions[element];
            let permissonObj = {
              title: element,
            };
            if (type === "edit") {
              if (selectedRowInfo[element] === "Edit") {
                permissonObj.Edit = false;
              } else if (selectedRowInfo[element] === "View") {
                permissonObj.View = false;
              } else if (selectedRowInfo[element] === "NoView") {
                permissonObj.NoView = false;
              }
              // permissionsArray.push(permissonObj);
            } else {
              if (
                selectedRowInfo[element] === "Edit" ||
                selectedRowInfo[element] === "View"
              ) {
                selectedRowInfo[element] = "NoView";
                permissonObj.NoView = false;
              }
            }
            permissionsArray.push(permissonObj);
          }
        });
      }
      for (var item of this.state.menuList) {
        let index = permissionsArray.findIndex(object => { return object.title === item.title; });
        let permissonObj = {
          title: item.title,
        };
        if (index === -1) {
          permissonObj.NoView = false;

          permissionsArray.push(permissonObj);
        }
      }
      this.setState({
        menuList: permissionsArray,
      });
    }
    if (this.props.type === "School") {
      selectedRowInfo.Closing_Delays = this.formatefortimePicker(selectedRowInfo.Closing_Delays)
      selectedRowInfo.Early_Dismissal_time = this.formatefortimePicker(selectedRowInfo.Early_Dismissal_time)
    }
    if (type === "copy") {
      selectedRowInfo["old_store_details"] = JSON.stringify(selectedRowInfo)
    }

    if (this.props.load) {
      this.props.load(selectedRowInfo);
    }

    // this.state.sc
    await this.setState({
      isLoading: false,
      editRowDataID: selectedRowInfo._id,
      displayBreadCrumbValue:
        selectedRowInfo[this.state.displayBreadCrumbField],
      formType: type,
      userData: selectedRowInfo,
    });
  };

  formatefortimePicker(strTime) {
    if (!(strTime.indexOf("am") >= 0 || strTime.indexOf("pm") >= 0)) {
      strTime = this.formatAMPM(strTime)
    }

    let totalHr;
    const getHour = strTime.split(":");
    const getMinutes = getHour[1].split(" ");
    if (getMinutes[1] === "am") {
      if (getHour[0] === "12") {
        totalHr = "00"
      }
      else if (getHour[0].length === 1) {
        totalHr = 0 + getHour[0]
      } else {
        totalHr = getHour[0]
      }
    } else {
      if (getHour[0] == 12 && (getMinutes[0] >= 0 || getMinutes[0] <= 59)) {
        totalHr = parseInt(getHour[0])
      }
      else {
        totalHr = parseInt(getHour[0]) + 12
      }
      // totalHr = parseInt(getHour[0]) + 12
    }
    // hours >= 12 ? 'pm' : 'am';
    let datefromDB = "2022-08-22T" + `${totalHr + ":" + getMinutes[0]}` + ":00.000Z"
    // }
    return  moment(datefromDB).utc();

  }
  getTableFields = () => {
    let data
    if (this.props.type === "Orders") {
      data = [
        {
          show: true,
          mobile: true,
          textAlign: "center",
          width: 400,
          field: "productImage",
          fieldType: "profile",
          header: "Product",
          filter: false,
          sortable: false,
        },
        {
          show: true,
          mobile: true,
          textAlign: "center",
          width: 40,
          field: "qty",
          header: "Qty",
          filter: false,
          sortable: false,
        },
        {
          show: true,
          mobile: true,
          textAlign: "center",
          width: 80,
          field: "price",
          header: "Price ($)",
          filter: false,
          sortable: false,
        },
        {
          show: true,
          mobile: true,
          textAlign: "center",
          width: 50,
          fieldType: "calculatedTotal",
          header: "Total ($)",
          filter: false,
          sortable: false,
        }, {
          show: true,
          mobile: true,
          textAlign: "center",
          width: 50,
          field: "Actions",
          fieldType: "Actions",
          header: "Actions",
          filter: false,
          sortable: false,
        },
      ];
    }

    else if (this.props.type === "Pending Carts") {
      data = [
        {
          show: true,
          mobile: true,
          textAlign: "center",
          width: 400,
          field: "productImage",
          fieldType: "profile",
          header: "Product",
          filter: false,
          sortable: false,
        },
        {
          show: true,
          mobile: true,
          textAlign: "center",
          width: 40,
          field: "product_name",
          header: "product Name",
          filter: false,
          sortable: false,
        },
        {
          show: true,
          mobile: true,
          textAlign: "center",
          width: 80,
          field: "comments",
          header: "Comments",
          filter: false,
          sortable: false,
        },

        {
          show: true,
          mobile: true,
          textAlign: "center",
          width: 20,
          field: "qty",
          header: "Qty",
          filter: false,
          sortable: false,
        },
        {
          show: true,
          mobile: true,
          textAlign: "center",
          width: 80,
          field: "price",
          header: "Price ($)",
          filter: false,
          sortable: false,
        },
      ]
    }

    else if (this.props.type === "Refund Details") {
      data = [
        {
          show: true,
          mobile: true,
          textAlign: "center",
          width: 400,
          field: "productImage",
          fieldType: "profile",
          header: "Product",
          filter: false,
          sortable: false,
        },
        {
          show: true,
          mobile: true,
          textAlign: "center",
          width: 80,
          field: "product_name",
          header: "product Name",
          filter: false,
          sortable: false,
        },
        {
          show: true,
          mobile: true,
          textAlign: "center",
          width: 80,
          field: "qty",
          header: "Qty",
          filter: false,
          sortable: false,
        },
        {
          show: true,
          mobile: true,
          textAlign: "center",
          width: 40,
          field: "totalPrice",
          header: "Total Price",
          filter: false,
          sortable: false,
        },
      ]
    }
    else if (this.props.type === "Store") {
      data = [
        {
          show: true,
          mobile: true,
          textAlign: "center",
          width: 300,
          field: "productImage",
          fieldType: "profile",
          header: "Product",
          filter: false,
          sortable: false,
        },
        {
          show: true,
          mobile: true,
          textAlign: "center",
          width: 80,
          field: "stock",
          header: "Stock",
          filter: false,
          sortable: false,
        },
        {
          show: true,
          mobile: true,
          textAlign: "center",
          width: 60,
          field: "purchase_limit",
          header: "Purchase Limit",
          filter: false,
          sortable: false,
        },
        {
          show: true,
          mobile: true,
          textAlign: "center",
          width: 80,
          field: "availableFrom",
          fieldType:"displayDate",
          header: "Start Date",
          filter: false,
          sortable: false,
        },
        {
          show: true,
          mobile: true,
          textAlign: "center",
          width: 80,
          field: "availableTo",
          fieldType:"displayDate",
          header: "End Date",
          filter: false,
          sortable: false,
        },
        {
          show: true,
          mobile: true,
          textAlign: "center",
          width: 80,
          field: "price",
          header: "Price",
          filter: false,
          sortable: false,
        },
        {
          show: true,
          mobile: true,
          textAlign: "center",
          width: 80,
          field: "status",
          header: "Status",
          fieldType:"Badge",
          filter: false,
          sortable: false,
        },
        
      ]
    }


    else {
      data = [
        {
          show: true,
          mobile: true,
          textAlign: "center",
          width: 100,
          field: "created",
          fieldType: "Date",
          type: "date",
          header: "Created",
          filter: true,
          sortable: true,
          dateFormat: config.dateDayMonthFormat,
        },
        {
          show: true,
          mobile: true,
          textAlign: "center",
          width: 100,
          field: "contextType",
          header: "Context Type",
          filter: true,
          sortable: true,
        },
        {
          show: true,
          mobile: true,
          textAlign: "left",
          width: 250,
          fieldType: "Array",
          field: "description",
          header: "Description",
          filter: true,
          sortable: true,
        },
        // {
        //     textAlign: 'center',
        //     width: 100,
        //     field: 'ipAddress',
        //     header: 'Ip Address',
        //     filter: true,
        //     sortable: true
        // },
        // {
        //     textAlign: 'center',
        //     width: 100,
        //     field: 'deviceType',
        //     header: 'Device Type',
        //     filter: true,
        //     sortable: true
        // },
        // {
        //     textAlign: 'center',
        //     width: 100,
        //     field: 'browserName',
        //     header: 'Browser',
        //     filter: true,
        //     sortable: true
        // },
        // {
        //     textAlign: 'center',
        //     width: 100,
        //     field: 'osName',
        //     header: 'Os Name',
        //     filter: true,
        //     sortable: true
        // },
        // {
        //     textAlign: 'center',
        //     width: 100,
        //     field: 'osVersion',
        //     header: 'Os Version',
        //     filter: true,
        //     sortable: true
        // },
      ];
    }
    return data;
  };

  getViewData = async (rowData, type, rowDataIndex, userData, _id) => {
    await this.setState({
      rowData: rowData,
      editRowDataID: _id,
      formType: type,
      slno: rowDataIndex,
      userData: userData,
      // activitiesData: userData.products

    });
    if (this.props.type === "Orders") {
      await this.setState({
        activitiesData: userData.products,
        cancelledProducts: userData.cancelled_products,
        GrandTotal: userData.ordered_price,
        cancelledProductsLength: userData.cancelled_products.length
      })
    }
    // Pending cart view details page fetch data
    else if (this.props.type === "Pending Carts") {
      await fetchMethodRequest('GET', `carts/viewCart/${_id}`).then(async (response) => {
        if (response.details) {
          await this.setState({ activitiesData: response.details[0].products })
        }
      })
    }

    //Refund Details page fetch data
    else if (this.props.type === "Refund Details") {
      await fetchMethodRequest('GET', `orders/viewCancelledProducts/${_id}`).then(async (response) => {
        if (response) {
          await this.setState({ activitiesData: response.cancelled_products })
        }
      })
    }

    //Store Details Page Api Data 
    else if (this.props.type === "Store") {
      await fetchMethodRequest('GET', `stores/${_id}`).then(async (response) => {
        if (response) {
          await this.setState({ activitiesData: response.productdetails })
        }
      })
    }

    if (type === "edit") {
      this.getRowData(userData, "edit", this.props.apiUrl);
    }
  };

  getRowDataFromServer = async (item, apiUrl) => {
    let id = item._id;
    let path = apiUrl;
    let url = `${path}/${id}`;
    return fetchMethodRequest("GET", url)
      .then(async (res) => {
        if (res && res.details) {
          console.log('details' , res.details);
          return res.details;
        } else if (res && res.respMessage) {
          showToasterMessage(res.errorMessage, "error");
          return null;
        } else {
          return item;
        }
      })
      .catch((err) => {
        return err;
      });
  };

  closeFormModal = async () => {
    await this.clearFormFields();
    await this.props.reset();
    await this.props.closeFormModal(this.props.filterCriteria);
  };

  // form Submit
  submit = (values) => {
    console.log('values' , values)
    if (values && Object.keys(values).length > 0) {
      this.setState({ isDisabled: true })
      this.saveDataToServer(values);
    } else {
      return;
    }
  };

  clearFormFields = async () => {
    if (this.props.load) {
      this.props.load({});
    }
    let formData = [...this.state.formFields];
    formData.forEach((item) => {
      item.value = "";
      item.invalid = false;
      item.message = "";
    });
    await this.setState({
      formFields: formData,
    });
    this.getFormFields();
  };

  // onEditorChange = async (evt, editor, name) => {
  //   console.log(editor, 'editor')
  //   if (evt) {
  //     this.setState({
  //       [name]: editor.getData(),
  //     });
  //   }

  // };
  onEditorChange = async (evt, name) => {
    console.log(evt)
    this.setState({
      [name]: evt.editor.getData(),
    });
  };

  // formatAMPM(date, type) {
  //   let utcTime;
  //   let dated = new Date(date)
  //   // if (type === "save") {
  //     // utcTime = moment(dated)
  //   utcTime = moment(dated).utcOffset('-05:30');// utc time
  //   if (utcTime && utcTime._d) {
  //     utcTime = utcTime._d
  //   }
  //   // }
  //   var hours = utcTime.getHours();
  //   var minutes = utcTime.getMinutes();
  //   var ampm = hours >= 12 ? 'pm' : 'am';
  //   hours = hours % 12;
  //   hours = hours ? hours : 12; // the hour '0' should be '12'
  //   minutes = minutes < 10 ? '0' + minutes : minutes;
  //   var strTime = hours + ':' + minutes + ' ' + ampm;
  //   return strTime;
  // }

  formatAMPM(date, type) {
    let dated = new Date(date)
    var data = moment(date).format('hh:mm a')
    return data;
  }

  //send data to server
  saveDataToServer = async (formValues) => {
    if (this.props.type === "School") {
      let indexismissal = formValues.Early_Dismissal_time && formValues.Early_Dismissal_time._d ? formValues.Early_Dismissal_time._d : formValues.Early_Dismissal_time
      // if (indexismissal.length > 0) {
      if ((formValues.Early_Dismissal_time && formValues.Early_Dismissal_time._d) || !(indexismissal.indexOf("am") >= 0 || indexismissal.indexOf("pm") >= 0)) {
        formValues.Early_Dismissal_time = this.formatAMPM(formValues.Early_Dismissal_time, "save")
      }

      let indexclosedelay = formValues.Closing_Delays && formValues.Closing_Delays._d ? formValues.Closing_Delays._d : formValues.Closing_Delays
      // if (indexismissal.length > 0) {
      if ((formValues.Closing_Delays && formValues.Closing_Delays._d) || !(indexclosedelay.indexOf("am") >= 0 || indexclosedelay.indexOf("pm") >= 0)) {
        formValues.Closing_Delays = this.formatAMPM(formValues.Closing_Delays, "save")
      }
      if (formValues.hasFoundation === "false") {
        formValues.hasFoundation = false
        delete formValues.foundation_ID
        delete formValues.foundation_Name
        delete formValues.foundation_Email
        delete formValues.paypal_client_id
        delete formValues.paypal_client_secret
      } else if (formValues.hasFoundation === "true") {
        formValues.hasFoundation = true
      }
    }
    if (this.props.type === "Products") {
      let a = formValues.cost
      // a.toString().split(".")[0]
      if (a.toString().split(".")[0].length === 0) {
        formValues.cost = "0" + "." + a.toString().split(".")[1]
      }
      let b = formValues.price
      if (b.toString().split(".")[0].length === 0) {
        formValues.price = "0" + "." + b.toString().split(".")[1]
      }
      let c = formValues.msrp
      if (c.toString().split(".")[0].length === 0) {
        formValues.msrp = "0" + "." + c.toString().split(".")[1]
      }

      //for  Add of stores in products page
      // if (formValues.store_name && this.props.type === "Products" && this.props.formType === "add") {
      //   let stores = formValues.store_name
      // let storeName = []
      //   console.log("formValues>>", formValues);
      //   for (var obj of formValues.store_name) {
      //     storeName.push(obj.store_name)
      //   }
      //   formValues.store_name = storeName
      //   formValues.stores = stores
      // }


      //for  Add of stores in products page s
      if (formValues.store_name && this.props.type === "Products" && this.props.formType === "add") {
        let stores = formValues.store_name
        formValues.store_name = [stores.store_name]
        formValues.stores = [stores]
      }

      //for edit  of stores in products page s
      if (formValues.store_name && this.props.type === "Products" && this.props.formType === "edit") {
        let stores = formValues.store_name
        formValues.stores = stores
        if (stores.store_name) {
          formValues.store_id = stores._id;
          formValues.store_name = [stores.store_name]
          formValues.stores = [stores]
        }
      }
    }

    if (this.props.type === "Store") {
      if (this.state.formType === "edit" && this.state.userData["school_id"] && this.props.type === "Store") {
        let selectedScools = this.state.selectedScools
        let kindex = selectedScools.findIndex(obj => obj.school_id === this.state.userData["school_id"])
        if (kindex !== -1) {
          formValues.school_name = selectedScools[kindex]
        }
      }
      if (formValues.isFoundationStore === "true") {
        formValues.isFoundationStore = true
        formValues.foundation_ID = formValues.school_name.foundation_ID ? formValues.school_name.foundation_ID : formValues.foundation_ID ? formValues.foundation_ID : ""
        formValues.foundation_Name = formValues.school_name.foundation_Name ? formValues.school_name.foundation_Name : formValues.foundation_Name ? formValues.foundation_Name : ""
        formValues.foundation_Email = formValues.school_name.foundation_Email ? formValues.school_name.foundation_Email : formValues.foundation_Email ? formValues.foundation_Email : ""
        formValues.paypal_client_id = formValues.school_name.paypal_client_id ? formValues.school_name.paypal_client_id : formValues.paypal_client_id ? formValues.paypal_client_id : ""
        formValues.paypal_client_secret = formValues.school_name.paypal_client_secret ? formValues.school_name.paypal_client_secret : formValues.paypal_client_secret ? formValues.paypal_client_secret : ""
      } else {
        formValues.isFoundationStore = false
        formValues.foundation_ID = ""
        formValues.foundation_Name = ""
        formValues.foundation_Email = ""
        formValues.paypal_client_id = ""
        formValues.paypal_client_secret = ""
      }
    }
    let userBody = Object.assign({}, formValues);
    let validationExists;
    this.setState({
      isLoading: true,
    });
    if (!validationExists) {
      if (formValues) {
        let method, apiUrl;
        if (this.state.formType === "edit") {
          //console.log("formValues", userBody.class_name)
          // delete userBody.email;
          delete userBody.password;
          method = "PUT";
          apiUrl = `${this.props.apiUrl}/${this.state.editRowDataID}`;
        } else {
          method = "POST";
          apiUrl = this.props.apiUrl;
        }


        // console.log(req);
        for (let i = 0; i < this.state.formFields.length > 0; i++) {
          if (this.state.formFields[i].show === false) continue;
          if (this.state.formFields[i].type === "autoComplete") {
            if (
              this.state.formFields[i]["options"] &&
              this.state.formFields[i]["options"].length > 0
            ) {
              for (
                let j = 0;
                j < this.state.formFields[i]["options"].length;
                j++
              ) {
                let keys = Object.keys(this.state.formFields[i]["options"][j]);
                let values = Object.values(
                  this.state.formFields[i]["options"][j]
                );
                if (
                  keys &&
                  keys[0] &&
                  values &&
                  values[0] &&
                  formValues[this.state.formFields[i]["name"]][values[0]]
                ) {
                  userBody[keys[0]] =
                    formValues[this.state.formFields[i]["name"]][values[0]];
                }
              }
            }
          } else if (
            this.state.formFields[i].type === "multipleprofile" ||
            this.state.formFields[i].type === "ckeditor" ||
            this.state.formFields[i].type === "profile"
          ) {
            userBody[this.state.formFields[i].name] = this.state[
              this.state.formFields[i].name
            ];
          } else if (this.state.formFields[i].type === "permission") {
            let permissions = {};
            let keys = Object.keys(formValues);
            this.state.menuList.forEach((item) => {
              keys.forEach((key) => {
                if (item.submenus && item.submenus.length > 0) {
                  item.submenus.map((sitem) => {
                    if (sitem.title === key) {
                      permissions[sitem.title] = formValues[key];
                    }
                  });
                } else {
                  if (item.title === key) {
                    permissions[item.title] = formValues[key];
                  }
                }
              });

            });
            for (var item of this.state.menuList) {
              let keysArr = Object.keys(permissions)
              let index = keysArr.findIndex(object => { return object === item.title; });
              if (index === -1) {
                permissions[item.title] = "NoView";
              }
            }
            userBody.permissions = { ...permissions };
          }
        }

        if (this.props.type == "Class" && userBody.class_name == "") {
          delete userBody.class_name
        }
        if (this.props.type == "Groups") {
          userBody.name = userBody.title
          delete userBody.title
        }
        if (this.props.groupEmailRequired) {
          userBody["isGroupEmail"] = true;
        }
        if (this.props.type == "Groups" && typeof userBody.class_name != 'string') {
          userBody.class_name = ""
        }
        if (this.props.rolesRequired) {
          userBody["type"] = "Admin";
        }
        if (this.props.groupEmailRequired && this.state.formType === "copy") {
          delete userBody["_id"];
        }
        if (this.state.formType === "copy") {
          delete userBody["_id"];
        }
        return fetchMethodRequest(method, apiUrl, userBody)
          .then(async (response) => {
            await this.checkSessionExpiration();
            if (response && response.respCode) {
              if (response.respMessage) {
                showToasterMessage(response.respMessage, "success");
                if (this.props.displayViewOfForm === "modal") {
                  await this.closeFormModal();
                } else {
                  if (
                    this.state.formType !== "add" &&
                    this.props.displayViewOfForm === "screen"
                  ) {
                    if(this.props.type == "Store" && this.props.formType == "copy"){
                      await this.closeFormModal();
                    }
                    await this.setState({
                      formType: "view",
                    });
                    await this.props.getDataFromServer(
                      this.props.filterCriteria
                    );
                    await this.handleNextAndBackActions();
                  } else {
                    // this.props.closeFormModal("save", response.quantityId);
                    this.props.closeFormModal(this.props.filterCriteria, response.quantityId);

                  }
                }
              }
            } else if (response && response.errorMessage) {
              showToasterMessage(response.errorMessage, "error");
            }
            this.setState({
              isLoading: false,
              isDisabled: false
            });
          })
          .catch((err) => {
            return err;
          });
      } else {
        return;
      }
    }
  };

  //getStores
  getRecords = async (valve, key, apiUrl, name) => {
    let filterCriteria = {};
    filterCriteria["criteria"] = [{ key: key, value: valve, type: "eq" }];
    let url = `${apiUrl}?filter=${JSON.stringify(filterCriteria)}`;
    fetchMethodRequest("GET", url)
      .then(async (response) => {
        if (response) {
          let states = response[apiUrl];
          let roleOptions = [];
          if (states && states.length > 0) {
            for (let i = 0; i < states.length; i++) {
              if (this.props.type === "Products") {
                roleOptions.push({
                  label: states[i][name],
                  value: states[i],
                });
              } else {
                roleOptions.push({
                  label: states[i][name],
                  value: states[i][name],
                });
              }
            }
            await this.setState({
              [apiUrl]: roleOptions,
            });


          } else {
            await this.setState({
              [apiUrl]: [],
            });
          }
          return roleOptions
        }
      })
      .catch((err) => {
        return err;
      });
  };

  // //Handle auto complete data
  handleAutoCompleteData = async (value, name) => {
    console.log(value, name)
    if (this.props.type === "School") {
      this.props.change('district_id', value['district_id'])
    }
    if (this.props.type === "Products") {
      this.getRecords(value["Name"], name, "stores", "store_name");
    }
    if (this.props.type === "Orders" && name === "store_name") {
      this.getRecords(value["store_name"], name, "products", "product_name");
    }
    if (this.props.type === "Faculty/Staff" || this.props.type === "Orders") {
      if (name === "school_name") {
        this.setState({
          schoolName: value["Name"],
        });
      }
    }
    if (this.props.type == "Groups" && name == "school_name") {
      this.setState({
        schoolName: value["school_id"],
      });
    }
    if (this.props.type == "Departments" || this.props.type === "Faculty/Staff" || this.props.type == "Class") {
      if (name === "school_name") {
        this.setState({
          schoolName: value["Name"],
        });
      } else if (name === "district_name") {
        this.setState({
          districtName: value["name"],
        });
      }
      if (name === "district_name") {
        this.props.change('school_name', "");
        this.props.change('department_name', "");
      } else if (name === "school_name") {
        this.props.change('department_name', "");
      }

    }
    if (this.props.type === "Class" && name === "school_name") {
      this.getSchoolGrades(value["Name"], "Name", "schools", "schoolGrades");
    }
    this.showFoundationField(value, name)
  };

  showFoundationField = async (value, name) => {
    console.log("value", value)
    if (this.state.formType === "edit" && value && value["school_id"] && this.props.type === "Store") {
      let selectedScools = this.state.selectedScools
      console.log("value", selectedScools)

      let kindex = selectedScools.findIndex(obj => obj.school_id === value["school_id"])
      if (kindex !== -1) {
        value = selectedScools[kindex]
        name = "school_name"
      }
    }
    if (this.props.type === "Store" && name === "school_name" && value.hasFoundation === true) {
      let formFields = this.state.formFields;
      formFields = await this.showField(
        formFields,
        "isFoundationStore", true
      );
      await this.setState({
        formFields: formFields
      })
    } else if (this.props.type === "Store" && name === "school_name" && value.hasFoundation === false) {
      {
        let formFields = this.state.formFields;
        formFields = await this.showField(
          formFields,
          "isFoundationStore", false
        );
        await this.setState({
          formFields: formFields
        })
      }
    }
  }

  getSchoolGrades = async (value, key, apiUrl, name) => {
    let filterCriteria = {};
    filterCriteria["criteria"] = [{ key: key, value: value, type: "eq" }];
    let url = `${apiUrl}?filter=${JSON.stringify(filterCriteria)}`;
    fetchMethodRequest("GET", url)
      .then(async (response) => {
        if (response) {
          let states = response[apiUrl];
          let roleOptions = [];
          if (states && states.length > 0 && states[0][name]) {
            for (let i = 0; i < states[0][name].length; i++) {
              roleOptions.push({
                label: states[0][name][i],
                value: states[0][name][i],
              });
            }
            await this.setState({
              schoolGrades: roleOptions,
            });
          } else {
            await this.setState({
              schoolGrades: [],
            });
          }
        }
      })
      .catch((err) => {
        return err;
      });
  };
  getTableFieldItem = async (field) => {
    for (let i = 0; i < this.props.tablefieldsToShow.length; i++) {
      if (this.props.tablefieldsToShow[i].field == field) {
        return this.props.tablefieldsToShow[i];
      }
    }
    return null;
  };
  getIconValue(rowData, labelKey) {
    if (labelKey && labelKey.options && labelKey.options.length > 0) {
      for (let i = 0; i < labelKey.options.length; i++) {
        if (labelKey.options[i].value === rowData[labelKey.field]) {
          return labelKey.options[i].displayText;
        }
      }
    }
    return "";
  }

  getUserDataById = async (item, type) => {
    fetchMethodRequest("GET", `${this.props.apiUrl}/${item["_id"]}`, "")
      .then(async (res) => {
        if (res && res["details"]) {
          // console.log("Helloooo", res["details"])
          if (res["children"] && res["children"].length > 0) {
            res["details"]["children"] = res["children"];
          }
          let rowData = res["details"];
          let userData = res["details"];
          await this.props.getDataFromServer(this.props.filterCriteria);
          let _id = rowData["_id"];
          let rowDataIndex = this.getUserData(rowData["_id"]);
          let keys = Object.keys(rowData);
          let formFields = [];
          if (formFields) {
            if (this.props.type) {
              if (rowData) {
                let values,
                  self = this,
                  icon = false,
                  objectFlag = false,
                  booleanBadge = false;
                // hari get all the labels from
                keys.forEach(async function (key) {
                  if (
                    key !== "_id" &&
                    key !== "__v" &&
                    key !== "active" &&
                    key !== "created" &&
                    key !== "updated" &&
                    key !== "color" &&
                    key !== "createdBy" &&
                    key !== "multipleImages" &&
                    key !== "updatedBy" &&
                    key !== "childIds" &&
                    key !== "classObjId" &&
                    key !== "lang" &&
                    key !== "password" &&
                    key !== "salt" &&
                    key !== "pwd_created" &&
                    key !== "pwd_updated" &&
                    key !== "last_login" &&
                    key !== "studentFormFields" &&
                    key !== "class_id" &&
                    // key !== "childrens" &&
                    // !key.includes("ObjId") &&
                    key !== "paidEmails" &&
                    key !== "step1" &&
                    key !== "step2" &&
                    key !== "step3" &&
                    key !== "step2Data" &&
                    key !== "step3Data" &&
                    key !== "forgotPasswordExpireTimeStamp" &&
                    key !== "language" &&
                    key !== "image" &&
                    key !== "templateText"

                  ) {
                    if (key == "children" && self.props.type == "Users") {
                      let label1, val1, label2, val2;
                      for (let i = 0; i < rowData[key].length; i++) {
                        if (
                          rowData[key][i] &&
                          rowData[key][i]["district_name"]
                        ) {
                          label1 =
                            "Student" + (i + 1).toString() + " District Name";
                          val1 = rowData[key][i]["district_name"];
                          if (val1 && label1) {
                            values = {
                              label: label1,
                              value: val1,
                            };
                            formFields.push(values);
                          }
                        }
                        if (rowData[key][i] && rowData[key][i]["school_name"]) {
                          label2 =
                            "Student" + (i + 1).toString() + " School Name";
                          val2 = rowData[key][i]["school_name"];
                          if (val2 && label2) {
                            values = {
                              label: label2,
                              value: val2,
                            };
                            formFields.push(values);
                          }
                        }
                        if (
                          (rowData[key][i] && rowData[key][i]["firstname"]) ||
                          (rowData[key][i] && rowData[key][i]["lastname"])
                        ) {
                          label2 = "Student" + (i + 1).toString() + " Name";
                          if (rowData[key][i]["lastname"] != null) {
                            val2 = rowData[key][i]["lastname"];
                          }
                          if (rowData[key][i]["firstname"] != null) {
                            val2 = val2 + " " + rowData[key][i]["firstname"];
                          }
                          if (val2 && label2) {
                            values = {
                              label: label2,
                              value: val2,
                            };
                            formFields.push(values);
                          }
                        }
                        // if (data[key][i] && data[key][i]["student_lastname"]) {
                        //   label2 = "Student" + (i + 1).toString() + " Last Name";
                        //   val2 = data[key][i]["student_lastname"];
                        //   if (val2 && label2) {
                        //     values = {
                        //       label: label2,
                        //       value: val2,
                        //     };
                        //     rowData.push(values);
                        //   }
                        // }
                      }
                    } else {
                      let labelKey = key.charAt(0).toUpperCase() + key.slice(1);
                      labelKey = labelKey.replace(/_/g, " ");
                      if (labelKey == null) {
                        labelKey = key;
                      } else {
                        let val = rowData[key];
                        if (labelKey.fieldType === "icon") {
                          val = self.getIconValue(rowData, labelKey);
                          icon = true;
                        }
                        if (labelKey.fieldType === "BooleanBadge") {
                          val = self.getIconValue(rowData, labelKey);
                          booleanBadge = true;
                        }

                        if (typeof val === "object") {
                          val = self.flattenArray(val);
                          objectFlag = true;
                        }
                        if (typeof val === "boolean") {
                          val = `${val}`;
                          objectFlag = true;
                        }
                        labelKey = labelKey;
                        // if (labelKey.includes("name")) {
                        //   labelKey = labelKey.replace(/name/g, " Name");
                        // }
                        // // if (labelKey.includes("1")) {
                        // //   labelKey = labelKey.replace(/1/g, " 1");
                        // // } if (labelKey.includes("2")) {
                        // //   labelKey = labelKey.replace(/2/g, " 2");
                        // // } 
                        // if (labelKey === "ZipCode") {
                        //   labelKey = labelKey.replace(/ZipCode/g, "Zip Code");
                        // }
                        if (val) {
                          values = {
                            label: labelKey,
                            value: objectFlag
                              ? val
                              : booleanBadge
                                ? val
                                : icon
                                  ? val
                                  : rowData[key],
                          };
                          formFields.push(values);
                          icon = false;
                          booleanBadge = false;
                          objectFlag = false;
                        }
                      }
                    }
                  }

                  // if (key == 'fromTime' || key == 'toTime') {
                  //   let date = dateFormats.addDaysToDate(rowData[key], 1);
                  //   date = dateFormats.formatDate(date, config.timeFormat);
                  //   values = {
                  //     label: key,
                  //     value: date
                  //   }
                  //   formFields.push(values);
                  // }
                });
              }
            }

            await this.setState({
              formType: "view",
              userData: rowData,
              userData: userData,
              displayBreadCrumbValue:
                rowData[this.state.displayBreadCrumbField],
              userStatus: rowData["status"],
            });
            await this.getViewData(
              formFields,
              "view",
              rowDataIndex,
              rowData,
              _id
            );
          }
        }
      })
      .catch((err) => {
        return err;
      });
  };

  handleViewDisplay = async (rowData, type) => {
    if(this.props.type == "Store"){
      this.closeFormModal();
    }
    this.getUserDataById(rowData, type);
  };

  async handleTexfields(e, item) {
    let formFields = this.state.formFields;
    let count = 0;
    if (item.dependentTextField && item.dependentTextField.length > 0) {
      for (let i = 0; i < item.dependentTextField.length; i++) {
        count = 0;
        for (let j = 0; j < e.value.length; j++) {
          if (e.value[j]["type"] == item.dependentTextField[i] || this.props.type == "District" && e.value[j]['name'] == item.name1) {
            for (let k = 0; k < formFields.length; k++) {
              if (formFields[k]["id"] == e.value[j]["type"] || this.props.type == "District" && formFields[k]["name1"] == e.value[j]["name"]) {

                // this if condition is for geeting data back when onchage payment type on edit
                if ( this.props.type == "District" && this.props.formType == "edit" && this.props.change) {
                  this.props.change(formFields[k]["name"], this.state.inputValues[formFields[k]["name"]])
                }
                formFields[k]["show"] = true;
              }
            }
          } else {
            count++;
          }
        }
        if (count == e.value.length) {
          for (let l = 0; l < formFields.length; l++) {
            if (formFields[l]["id"] == item.dependentTextField[i]) {
              formFields[l]["show"] = false;
              if (this.props.change) {
                this.props.change(formFields[l]["name"], "");
              }
            }
          }
        }
        if (count == e.length) {
          for (let l = 0; l < formFields.length; l++) {
            if (formFields[l]["id"] == item.dependentTextField[i]) {
              formFields[l]["show"] = false;
              if (this.props.change) {
                this.props.change(formFields[l]["name"], "");
              }
            }
          }
        }
      }
    } else {
      if (count == e.length) {
        for (let l = 0; l < formFields.length; l++) {
          if (formFields[l]['dependentEditTextField']) {
            formFields[l]["show"] = false;
            if (this.props.change) {
              this.props.change(formFields[l]["name"], "");
            }
          }
        }
      }
    }
    await this.setState({ formFields: formFields });
  }

  getEditButton() {
    const { t } = this.props;
    return (
      <div className="d-flex">
        <div className="col-12 px-0">
          <span className="float-right">
            {/* //Back button for school edit selectedRowEditIcon */}
            <div className="d-flex m-5">
              <div className="p-1 mr-2">
                {this.props.type === "School" &&
                  <ButtonToolbar>
                    <Button
                      color="primary"
                      outline
                      type="button"
                      onClick={() => this.closeFormModal()}
                    >
                      {t("Back") ? t("Back") : "Back"}
                    </Button>
                  </ButtonToolbar>
                }
              </div>
              <div className="p-1">
                <ButtonToolbar>
                  <Button
                    color="primary"
                    outline
                    type="submit"
                    onClick={() =>
                      this.getRowData(
                        this.state.userData,
                        "edit",
                        this.props.apiUrl
                      )
                    }
                  >
                    {t("Edit") ? t("Edit") : "Edit"}
                  </Button>
                </ButtonToolbar>
              </div>
            </div>
          </span>
        </div>
      </div>
    );
  }
  onChageStatus = async (item) => {
    let url = 'orders/updateOrderStatus'
    let body = {}
    let data = []
    data.push(item)
    body.cancelled_products = data
    body.status = "Cancelled"
    body.order_id = this.state.editRowDataID
    return fetchMethodRequest('POST', url, body)
      .then(async (response) => {
        let sessionexpired = localStorage.getItem("sessionexpired");
        if (sessionexpired == "true") {
          this.setState({ sessionExpiryModal: true });
        }
        await this.setState({
          isLoading: false,
          openDeleteModal: false,
          actions: "",
        });
        if (response && response.respCode) {
          showToasterMessage(response.respMessage, "success");
          await this.props.getDataFromServer(this.props.filterCriteria);
          // await this.handleNextAndBackActions();
          this.props.closeFormModal('');
        } else if (response && response.errorMessage) {
          showToasterMessage(response.errorMessage, "error");
        }
      })
      .catch((err) => {
        return err;
      });
  }
  getUserData(_id) {
    let data = this.props.allUsersData;
    for (let i = 0; i < data.length; i++) {
      if (data[i]["_id"] === _id) {
        return i;
      }
    }
  }
  getActivtiesTableFieldItem = (field) => {
    let tablefieldsToShow = this.getTableFields();
    for (let i = 0; i < tablefieldsToShow.length; i++) {
      if (tablefieldsToShow[i].field == field) {
        return tablefieldsToShow[i];
      }
    }
    return null;
  };

// Function to generate Badge based on item status
 generateBadge (item) {
  let mcolors;
  let val;

  if (item.isOutOfStock) {
    mcolors = 'danger';
    val = 'Out Of Stock';
  } else if (item.isExpiredProduct) {
    mcolors = 'danger';
    val = 'Expired';
  } else {
    mcolors = 'success';
    val = 'Available';
  }

  return (
    <Badge color={mcolors} pill style={{ margin: "4px" }}>
      <span>{val}</span>
    </Badge>
  );
}




  // hari need to move to derived class or controller
  changeFieldValues = (item, column) => {
    let self = this,
      tableItem;
    tableItem = self.getActivtiesTableFieldItem(column.field);
    if (tableItem.fieldType === "Array") {
      let val = this.flattenArray(item[column.field]);
      return (
        <span style={tableItem.style} title={val}>
          {val}
        </span>
      );
    } else if (tableItem.fieldType === "Actions") {
      console.log("shaghdsv", tableItem.fieldType)
      return <span style={{ cursor: "pointer" }}>
        <Badge color={"info"} pill onClick={() => this.confirmActionType("Cancelled", item)}>
          <span>Cancel</span>
        </Badge>        </span >

    } else if (tableItem.fieldType === "Badge") {
      return this.generateBadge(item); 
    }
  
    else if (tableItem.fieldType === "profile") { 
      let displayFields  = this.props.type === "Store" ? true : false
      return <div className="row col-sm-12">
        <span className="col-sm-2">
          <img
            src={item[column.field] ? `${config.imgUrl}product/${item[column.field]} ` : configImages.defaultImg}
            alt=""
            className="appLogo" />
        </span >
    {displayFields ?
       <span className="col-sm-10 text-left">
        <p> <span>{"ProductName: "}</span> : <span style = {{fontWeight:700}}>{item.product_name}</span> </p>
        <p>{"description: " + item.description ? item.description : 'No description'}</p>
      </span >
      :
      <span className="col-sm-10 text-left">
        <p style={{ fontSize: 14, fontWeight: 700 }}>{item.product_name}</p>
        <p className="mt-0">{"Store: " + item.store_name}</p>
        <p>{item.comments ? item.comments : "No Comments"}</p>
    </span >
      }
       
      </div>
    } else if (tableItem.fieldType === "calculatedTotal") {
      console.log("shaghdsv")
      let val = item.qty * item.price
      return <span>
        {val}        </span >
    } else if (tableItem.fieldType === "displayDate") {
        return(<span>{item[column.field] ? item[column.field].slice(0,10) : null}</span>)
    } else {
      // if (item[column.field] === 0) {
      //   return item[column.field];
      // }
      if (item[column.field] && typeof item[column.field] !== "object") {
        return item[column.field];
      }
    }
  };
  getDropdownMultiselect(i, item) {
    return (
      <div className="form__form-group-field mb-2">
        <Field
          key={i}
          name={item.name}
          onChange={
            item.dependentTextField
              ? (e) => {
                this.handleTexfields(e, item);
              }
              : null
          }
          component={MultiSelectDropDown}
          id={item.id}
          validate={item.required ? [multiRequired] : null}
          filterElement={
            item.options.length > 0
              ? item.options
              : this.state[item.optionsName] &&
                this.state[item.optionsName].length > 0
                ? this.state[item.optionsName]
                : []
          }
          disabled={item.disabled ? true : this.props.formType === "edit" && item.isEdit ? true : false}
          // this.props.formType === "edit" && item.isEdit ? true : false
          maxSelectedLabels={
            this.state.maxSelectedLabels ? this.state.maxSelectedLabels : null
          }
        />
      </div>
    );
  }

  async handleFnEnableControlsBasedOnValue(e, dependent, item) {
    console.log("e, dependent, item")
    let formFields = this.state.formFields;
    for (let i = 0; i < dependent.length; i++) {

      if (e && e === Object.keys(dependent[i])[0] || (this.props.type === "School" && e === "true")) {
        formFields = await this.showField(
          formFields,
          dependent[i][Object.keys(dependent[i])[0]],
          true
        );
      } else {
        if (item.multipleDependentSameValue == null) {

          formFields = await this.showField(
            formFields,
            dependent[i][Object.keys(dependent[i])[0]],
            false
          );
        }
      }
      // if (item.multipleDependentSameValue) {
      //   if (e && e === Object.keys(dependent[i])[1]) {
      //     formFields = await this.showField(
      //       formFields,
      //       dependent[i][Object.keys(dependent[i])[1]],
      //       true
      //     );
      //   } else {
      //     formFields = await this.showField(
      //       formFields,
      //       dependent[i][Object.keys(dependent[i])[1]],
      //       false
      //     );
      //   }
      // }
    }
    await this.setState({ formFields: formFields });
  }

  getDropdown(i, item) {
    // console.log("roeeee", this.state.rowData)
    return (
      <div className="form__form-group-field mb-2">
        <Field
          key={i}
          name={item.name}
          component={Select}
          onChange={
            item.dependent
              ? (e) =>
                this.handleFnEnableControlsBasedOnValue(
                  e,
                  item.dependent,
                  item
                )
              : null
          }
          selectedValue={this.props.formType && this.props.formType !== "add" ? this.state.rowData[item.name] : null}
          defaultValue={
            this.props.formType && this.props.formType === "add"
              ? item.defaultValue
              : null
          }
          placeholder={item.placeholder}
          isDisable={item.disable ? item.disable : false}
          ategoryProductType={this.getCategoryProductType}
          updateClinicName={this.updateClinicName}
          updateDistributorName={this.updateDistributorName}
          getProjectDropDownValues={this.getProjectDropDownValues}
          options={
            item.options.length > 0
              ? item.options
              : this.state[item.optionsName] &&
                this.state[item.optionsName].length > 0
                ? this.state[item.optionsName] :
                []
          }
        />
      </div>
    );
  }
  paypalvalidate = async (e) => {
    this.setState({
      paypalClientId: e
    })
  }

  showField(formFields, itemname, show = true) {
    console.log("false", formFields, itemname, show)
    let value = true;
    if (show === false) value = show;
    console.log("false", value, itemname)

    for (let i = 0; i < formFields.length; i++) {
      if (formFields[i].name === itemname) {
        formFields[i].show = value;
        //await this.setState({ formFields: formFields });
        break;
      }
    }
    return formFields;
  }

  // getRolePermissions() {
  //   return (
  //     <div className="form form--horizontal">
  //       <div className="form__form-group row">
  //         <div className="col-sm-6" style={{ paddingLeft: "119px" }}>
  //           <span className="pr-4 pl-5">{"Edit"}</span>
  //           <span className="pr-3 pl-2">{"View"}</span>
  //           <span className="pr-2 ">{"No View"}</span>
  //         </div>
  //         <div className="col-sm-6" style={{ paddingLeft: "119px" }}>
  //           <span className="pr-4 pl-5">{"Edit"}</span>
  //           <span className="pr-3 pl-2">{"View"}</span>
  //           <span className="pr-2 ">{"No View"}</span>
  //         </div>
  //       </div>

  //       {this.state.menuList && this.state.menuList.length > 0
  //         ? this.state.menuList.map((item, index) => {
  //           return item.submenus && item.submenus.length > 0 ? (
  //             item.submenus.map((sitem, sindex) => {
  //               return (
  //                 <div key={sindex} className="form__form-group col-sm-6">
  //                   <span className="form__form-group-label ">
  //                     {sitem.title}
  //                   </span>
  //                   <div className="form__form-group-field ">
  //                     <Field
  //                       name={sitem.title}
  //                       component={renderRadioButtonField}
  //                       radioValue={!sitem.Edit ? "Edit" : ""}
  //                     />
  //                     <Field
  //                       name={sitem.title}
  //                       component={renderRadioButtonField}
  //                       radioValue={!sitem.View ? "View" : ""}
  //                     // defaultChecked={this.state.defaultValue && index === 0 ? true : false}
  //                     />
  //                     <Field
  //                       name={sitem.title}
  //                       component={renderRadioButtonField}
  //                       radioValue={!sitem.NoView ? "NoView" : ""}
  //                     // defaultChecked={this.state.defaultValue && index > 0 ? true : false}
  //                     />
  //                   </div>
  //                 </div>
  //               );
  //             })
  //           ) : (
  //               <div key={index} className="form__form-group col-sm-6">
  //                 <span className="form__form-group-label ">{item.title}</span>
  //                 <div className="form__form-group-field ">
  //                   <Field
  //                     name={item.title}
  //                     component={renderRadioButtonField}
  //                     radioValue={!item.Edit ? "Edit" : ""}
  //                   />
  //                   <Field
  //                     name={item.title}
  //                     component={renderRadioButtonField}
  //                     radioValue={!item.View ? "View" : ""}
  //                   // defaultChecked={this.state.defaultValue && index === 0 ? true : false}
  //                   />
  //                   <Field
  //                     name={item.title}
  //                     component={renderRadioButtonField}
  //                     radioValue={!item.NoView ? "NoView" : ""}
  //                   // defaultChecked={this.state.defaultValue && index > 0 ? true : false}
  //                   />
  //                 </div>
  //               </div>
  //             );
  //         })
  //         : null}
  //     </div>
  //   );
  // }

  getRolePermissions() {
    return (
      <div className="form form--horizontal">
        <div className="form__form-group row">
          <div className="col-sm-6" style={{ paddingLeft: "119px" }}>
            <span className="pr-4 pl-5">{"Edit"}</span>
            <span className="pr-3 pl-2">{"View"}</span>
            <span className="pr-2 ">{"No View"}</span>
          </div>
          <div className="col-sm-6" style={{ paddingLeft: "119px" }}>
            <span className="pr-4 pl-5">{"Edit"}</span>
            <span className="pr-3 pl-2">{"View"}</span>
            <span className="pr-2 ">{"No View"}</span>
          </div>
        </div>

        {this.state.menuList && this.state.menuList.length > 0
          ? this.state.menuList.map((item, index) => {
            return (
              <div key={index} className="form__form-group col-sm-6">
                <span className="form__form-group-label ">{item.title}</span>
                <div className="form__form-group-field ">
                  <Field
                    name={item.title}
                    component={renderRadioButtonField}
                    radioValue={!item.Edit ? "Edit" : ""}
                  />
                  <Field
                    name={item.title}
                    component={renderRadioButtonField}
                    radioValue={!item.View ? "View" : ""}
                  // defaultChecked={this.state.defaultValue && index === 0 ? true : false}
                  />
                  <Field
                    name={item.title}
                    component={renderRadioButtonField}
                    radioValue={!item.NoView ? "NoView" : ""}
                    defaultChecked={true}
                  />
                </div>
              </div>
            );
          })
          : null}
      </div>
    );
  }

  getProfile(i, item) {
    return (
      <div key={i}>
        <Field
          key={i}
          onRef={(ref) => (this.profileUploadRef = ref)}
          name={item.name ? item.name : null}
          component={RenderFileInputField}
          label={item.label}
          type="profile"
          id={item.id}
          acceptType="image/*"
          url={apiCalls.LocationImagePath}
          getFileName={(file) => this.getFileName(file, item)}
          imagePath={item.imagePath}
        />

        <div className="col-md-2 d-flex" style={{ padding: "20px" }}>
          <div style={{ justifyContent: "center" }}>
            {this.state[item.name] && this.state[item.name].length > 0 ? (
              <img
                src={`${config.imgUrl}${item.imagePath}/${this.state[item.name]
                  }`}
                className="detailsImgStyle"
              />

            ) : null}
          </div>
          {this.state[item.name] && this.state[item.name].length > 0 ?

            <FontAwesomeIcon
              icon="times-circle"
              className="ml-3"
              data-toggle="tool-tip"
              title="click to remove image"
              style={{ color: "red" }}
              onClick={() =>
                this.getFileName("", item)}
            />
            : null}
        </div>
      </div >
    );
  }

  getFileName = async (file, item) => {
    let image = file;
    if (image && image.length > 0) {
      await this.setState({
        [item.name]: image,
      });
    } else {
      await this.setState({
        [item.name]: "",
      });
    }
  };

  getDate(i, item) {
    return (
      <div key={i} className="form__form-group">
        <div className="form__form-group-field mb-2">
          <Field
            key={i}
            className="mb-2"
            name={item.name ? item.name : null}
            placeholder={item.placeholder ? item.placeholder : null}
            id={item.id ? item.id : null}
            component={DatePicker}
            minDate={this.state.minDate ? this.state.minDate : null}
            maxDate={this.state.maxDate ? this.state.maxDate : null}
            item = {item}
            screen={this.props.type}
          />
          <div className="iconstyle form__form-group-icon">
            <CalendarBlankIcon />
          </div>
        </div>
        <div style={{ color: "#dc3545" }}>
          {item.message ? item.message : null}
        </div>
      </div>
    );
  }

  getTime(i, item) {
    return (
      <div key={i} className="form__form-group">
        <div className="form__form-group-field">
          <Field
            key={i}
            name={item.name ? item.name : null}
            placeholder={item.placeholder ? item.placeholder : null}
            id={item.id ? item.id : null}
            component={TimePicker}
            screen={this.props.type}
            type = {this.state.formType}
          />
        </div>
        <div style={{ color: "#dc3545" }}>
          {item.message ? item.message : null}
        </div>
      </div>
    );
  }

  getCkEditor(i, item) {
    let self = this;
    return (
      <CKEditor
        // editor={ClassicEditor}
        key={i}
        name={item.name}
        id={item.id}
        data={self.state[item.name] ? self.state[item.name] : null}
        onChange={(evt) => this.onEditorChange(evt, item.name)}
      // onChange={(event, editor) => this.onEditorChange(event, editor, self.state[item.name])}
      />
    );
  }

  getRadio(i, item) {
    return (
      <div className="mb-0">
        <div className="row">
          {item.options.map((option, ind) => {
            return (
              <Field
                key={this.props.type + i + ind}
                name={item.name ? item.name : null}
                component={renderRadioButtonField}
                label={option.label}
                radioValue={option.value}
                disabled={false}
                defaultChecked={
                  option.defaultChecked ? option.defaultChecked :
                    null
                }
                validate={item.required ? radioRequired : null}
                showError={option.showError ? true : false}
                onChange={
                  item.dependent
                    ? (e) =>
                      this.handleFnEnableControlsBasedOnValue(
                        e,
                        item.dependent,
                        item
                      )
                    : null
                }
              />
            );
          })}
        </div>
      </div>
    );
  }
  getFilteredSchool = async (dropdownData) => {
    await this.setState({
      selectedScools: dropdownData
    })
    await this.showFoundationField(this.state.userData)
  }
  getAutoComplete(i, item) {
    return (
      <Field
        key={i}
        name={item.name ? item.name : null}
        component={AutoComplete}
        type={item.type}
        filterField={item.filterField}
        filterValue={item.filterValue}
        filterType={item.isNotEq}
        multiple={item.isMultiple}
        placeholder={item.placeholder ? item.placeholder : ""}
        searchApi={item.searchApi}
        searchField={item.searchField}
        schoolName={this.state.schoolName}
        districtName={this.state.districtName}
        handleAutoCompleteData={(event) =>
          this.handleAutoCompleteData(event, item.name)
        }
        getFilteredSchool={this.getFilteredSchool}
        screen={this.props.type}
        allow={this.state.allowDuplicates}
        filterFieldType={item.filterFieldType ? item.filterFieldType : null}
        disabled={item.disable ? item.disable : this.props.formType === "edit" && item.isEdit ? true : false}
        formType={this.props.formType}
      />
    );
  }

  getTextArea(i, item) {
    return (
      <Field
        key={i}
        name={item.name ? item.name : null}
        component={DefaultTextArea}
        type={item.type ? item.type : null}
        placeholder={item.placeholder ? item.placeholder : null}
        validate={item.required ? [required] : null}
      />
    );
  }

  getButtonGroup(i, item) {
    return (
      <ButtonToolbar className="mt-0">
        <ButtonGroup size="sm">
          {item.options.map((button) => {
            return (
              <Button
                style={{ paddingTop: 3, paddingBottom: 3 }}
                color="primary"
                outline
                size="sm"
                active={this.state[`is${button.label}ReOrder`]}
                onClick={() => this.handleReOrdering(button.value)}
              >
                {button.label}
              </Button>
            );
          })}
        </ButtonGroup>
      </ButtonToolbar>
    );
  }

  getDefault(i, item) {
    //console.log("item", this.state.paypalClientId)
    const { t } = this.props;
    return (
      <div>
        <Field
          key={i}
          name={item.name ? item.name : null}
          component={DefaultInput}
          isDisable={
            this.props.formType &&
              this.props.formType === "edit" &&
              (item.type === "email" || item.type === "password")
              ? true : this.props.type === "Products" && this.props.formType &&
                this.props.formType === "edit" && item.name === "store_name" || this.props.formType === "edit" && item.name === "sku" ? true
                : item.disable
          }
          type={item.type ? item.type : "text"}
          id={item.id ? item.id : null}
          placeholder={item.placeholder ? item.placeholder : null}
          validate={item.required || (item.name == "paypal_client_secret" && this.state.paypalClientId != "") ? [required] : null}
          onChange={item.name == "paypal_client_id" ? ((e) => this.paypalvalidate(e)) : item.name == "teacher_email" || item.name == "teacher_website" ? ((e) => this.props.getValidations(e, item.name)) : null}
          // onChange={item.name == "paypal_client_id" ? ((e) => this.paypalvalidate(e)) : ""}
          normalize={item.name === "phone" || item.name === "Transportation" || item.name === "Attendance_Line" || item.name === "Main_Number" ? normalizePhone : null}
        />
      </div>
    );
  }
  getclearInput(i, item) {
    const { t } = this.props;
    return (
      <div>
        <Field
          key={i}
          name={item.name ? item.name : null}
          component={DefaultClearInput}
          makeValue={item.value ? item.value : ""}
          onChange={(e) => this.setValues(e, item)}
          type={item.type ? item.type : "text"}
          id={item.id ? item.id : null}
          placeholder={item.placeholder ? item.placeholder : null}
          validate={item.required ? [required] : null}
          normalize={item.name === "phone" ? normalizePhone : null}
        />
      </div>
    );
  }
  setValues = async (e, item) => { };

  getModalHeader() {
    const { t } = this.props;
    return (
      <ModalHeader className="modal__header">
        <button
          className="lnr lnr-cross modal__close-btn"
          type="button"
          onClick={this.closeFormModal}
        />
        <p className="bold-text  modal__title">
          {this.props.formType && this.props.formType === "edit"
            ? t("Edit")
            : t("Add")}{" "}
          {this.props.type ? this.props.type : null}
        </p>
      </ModalHeader>
    );
  }

  getButtonToolbar() {
    const { t } = this.props;
    const isDisabled = this.state.isDisabled;
    return (
      <div className="d-flex">
        <div className="col-12 px-0">
          <span className="float-right">
            <ButtonToolbar>
              {this.state.formType !== "add" &&
                this.props.displayViewOfForm === "screen" ? (
                <Button
                  color="primary"
                  type="button"
                  onClick={() =>
                    this.handleViewDisplay(this.state.userData, "view")
                  }
                >
                  {t("Back") ? t("Back") : "Back"}
                </Button>
              ) : (
                <Button
                  color="primary"
                  type="button"
                  onClick={this.closeFormModal}
                >
                  {t("Cancel") ? t("Cancel") : "Cancel"}
                </Button>
              )}
              <Button color="primary" outline type="submit" disabled={isDisabled}>
                {this.state.formType && this.state.formType === "add"
                  ? t("Save")
                    ? t("Save")
                    : "Save"
                  : this.state.formType && this.state.formType === "copy"
                    ? t("Save")
                      ? t("Save")
                      : "Save"
                    : t("Update")
                      ? t("Update")
                      : "Update"}
              </Button>
            </ButtonToolbar>
          </span>
        </div>
      </div>
    );
  }

  getDependentFields(type, formType) {
    return null;
  }

  getMultiplePhotoUpload(i, item) {
    return (
      <div>
        <Field
          key={i}
          onRef={(ref) => (this.profileUploadRef = ref)}
          name={item.name ? item.name : null}
          component={RenderFileInputField}
          label={item.label}
          type="profile"
          id={"photo"}
          acceptType={"image/*"}
          url={apiCalls.LocationImagePath}
          getMulipleFileName={(file) => this.getMulipleFileName(file, item)}
          multiple={true}
        />
        <Scrollbar>
          <div className="mindivForMultipleupload">
            {this.state[item.name] && this.state[item.name].length > 0
              ? this.state[item.name].map(
                (imagLocationItem, imagLocationIndex) => (
                  <div
                    className="col-md-2"
                    style={{ padding: "20px" }}
                    key={imagLocationIndex}
                  >
                    <div
                      key={imagLocationIndex}
                      style={{ justifyContent: "center" }}
                    >
                      <img
                        key={imagLocationIndex}
                        src={`${config.imgUrl}${item.imagePath}/${imagLocationItem["file"]}`}
                        className="detailsImgStyle"
                      />
                      <FontAwesomeIcon
                        icon="times-circle"
                        className="timesCircleIcon"
                        onClick={() =>
                          this.removeMultipleUploadedImages(
                            imagLocationIndex,
                            item
                          )
                        }
                      />
                    </div>
                  </div>
                )
              )
              : null}
          </div>
        </Scrollbar>
      </div>
    );
  }

  // Store selected Images in state
  getMulipleFileName = async (file, item) => {
    let multipleLocationImage = this.state[item.name]
      ? this.state[item.name]
      : [];
    multipleLocationImage.push({ file: file });
    await this.setState({
      [item.name]: multipleLocationImage,
    });
  };

  // Remove selected image from state
  removeMultipleUploadedImages = async (imagLocationIndex, item) => {
    let multipleLocationImage = this.state[item.name]
      ? this.state[item.name]
      : [];
    if (
      multipleLocationImage &&
      multipleLocationImage.length &&
      multipleLocationImage.length > 0
    ) {
      multipleLocationImage.splice(imagLocationIndex, 1);
    }
    await this.setState({
      [item.name]: multipleLocationImage,
    });
  };

  getItemField(item, i) {
    const { t } = this.props;
    return (
      <div
        id={item.name}
        key={this.props.type + i}
        className={
          item.type === "ckeditor" ||
            item.type === "permission" ||
            item.type === "multipleprofile"
            ? "col-sm-12"
            : (item.type === "autoComplete" && item.name === "assignedTo") ||
              item.name === "closebutton"
              ? "col-sm-4"
              : "col-sm-6"
        }
      >
        <div className="form__form-group">
          <label className="form__form-group-label">{t(item.label)}{item.showHelpIcon ? <FontAwesomeIcon
            icon={faQuestionCircle}
            className="pl-1"
            size="lg"
            data-toggle="tool-tip"
            title={this.state.actionsText[item.helpText]}
          /> : null} </label>
          {item.type === "dropDown" && item.isMultiSelect ? (
            this.getDropdownMultiselect(i, item)
          ) : item.type === "clearInput" ? (
            this.getclearInput(i, item)
          ) : item.type === "dropDown" ? (
            this.getDropdown(i, item)
          ) : item.name === "closebutton" ? (
            this.getCloseButton(i, item)
          ) : item.type === "profile" ? (
            this.getProfile(i, item)
          ) : item.type === "date" ? (
            this.getDate(i, item)
          ) : item.type === "time" ? (
            this.getTime(i, item)
          ) : item.type === "ckeditor" ? (
            this.getCkEditor(i, item)
          ) : item.type === "empty" ? (
            <div> </div>
          ) : item.type === "radio" ? (
            this.getRadio(i, item)
          ) : item.type === "permission" ? (
            this.getRolePermissions()
          ) : item.type === "autoComplete" ? (
            this.getAutoComplete(i, item)
          ) : item.type === "textarea" ? (
            this.getTextArea(i, item)
          ) : item.type === "buttonGroup" ? (
            item.options && item.options.length > 0 ? (
              this.getButtonGroup(i, item)
            ) : null
          ) : item.type === "multipleprofile" ? (
            this.getMultiplePhotoUpload(i, item)
          ) : (
            this.getDefault(i, item)
          )}
        </div>
      </div>
    );
  }

  getFields() {
    let allFields = <div></div>;
    let item;
    for (let i = 0; i < this.state.formFields.length; i++) {
      item = this.state.formFields[i];
      if (item.show === false) {
      } else {
        allFields = (
          <>
            {allFields}
            {this.getItemField(item, i)}
          </>
        );
      }
    }
    return allFields;
  }
  getHeader() {
    const { t } = this.props;
    return (
      <div className="d-flex">
        <div className="col-12 px-0 pb-1">
          <span className="float-left pt-2">
            <h4 style={{ textTransform: "capitalize" }}>
              <b>
                <Link to={this.props.routeTo} onClick={this.closeFormModal}>
                  <i className="pi pi-arrow-left" style={{ fontSize: '1rem' , marginRight:'8px' }}></i> 
                  {t(this.props.type)}
                </Link>
                {this.state.formType !== "add" &&
                  this.state.displayBreadCrumbValue
                  ? ` / ${this.state.displayBreadCrumbValue} `
                  : null}
              </b>{" "}
            </h4>
          </span>
        </div>
      </div>
    );
  }
  submitActionsData = async (method, url) => {
    return fetchMethodRequest(method, url)
      .then(async (response) => {
        let sessionexpired = localStorage.getItem("sessionexpired");
        if (sessionexpired == "true") {
          this.setState({ sessionExpiryModal: true });
        }
        await this.setState({
          openDeleteModal: false,
          actions: "",
        });
        if (response && response.respCode) {
          showToasterMessage(response.respMessage, "success");
          await this.props.getDataFromServer(this.props.filterCriteria);
          await this.handleNextAndBackActions();
          // this.props.closeFormModal('save');
        } else if (response && response.errorMessage) {
          showToasterMessage(response.errorMessage, "error");
        }
      })
      .catch((err) => {
        return err;
      });
  };
  submitDivorceData = async (method, url, body) => {
    return fetchMethodRequest(method, url, body)
      .then(async (response) => {
        let sessionexpired = localStorage.getItem("sessionexpired");
        if (sessionexpired == "true") {
          this.setState({ sessionExpiryModal: true });
        }
        await this.setState({
          openDeleteModal: false,
          actions: "",
        });
        if (response && response.respCode) {
          showToasterMessage(response.respMessage, "success");
          await this.props.getDataFromServer(this.props.filterCriteria);
          await this.handleNextAndBackActions();
          // this.props.closeFormModal('save');
        } else if (response && response.errorMessage) {
          showToasterMessage(response.errorMessage, "error");
        }
      })
      .catch((err) => {
        return err;
      });
  };
  flattenArray = (arrayVal) => {
    let val = "";
    if (arrayVal) {
      val = JSON.stringify(arrayVal);
      val = val
        .replace(/"/g, "")
        .replace(/\[/g, "")
        .replace(/]/g, "")
        .replace(/{/g, "")
        .replace(/}/g, "")
        .replace(/,/g, " , ")
        .replace(/:/g, " : ");
    }
    return val;
  };
  onPageChange = async (event) => {
    let filterCriteria = this.state.filterCriteria;
    if (event && event.rows) {
      let currentPage = event.page + 1;
      filterCriteria["limit"] = event.rows;
      filterCriteria["page"] = currentPage;
      this.setState({
        rows: event.rows,
        page: event.page,
        first: event.first,
      });
    }
    this.getActivities();
  };
  updateDateFormat(itemdata, dateFormat) {
    let modifiedData = [];
    let tablefieldsToShow = this.getTableFields();
    for (let i = 0; i < itemdata.length; i++) {
      for (let k = 0; k < tablefieldsToShow.length; k++) {
        if ("Date" == tablefieldsToShow[k]["fieldType"]) {
          itemdata[i][tablefieldsToShow[k]["field"]] = dateFormats.formatDate(
            itemdata[i][tablefieldsToShow[k]["field"]],
            tablefieldsToShow[k]["dateFormat"]
          );
        }
      }
      modifiedData.push(itemdata[i]);
    }
    return modifiedData;
  }

  getActivities = async () => {
    let filterCriteria = this.state.filterCriteria;
    filterCriteria["criteria"] = [
      { key: "contextId", value: this.state.editRowDataID, type: "eq" },
    ];
    let url = `activities?filter=${JSON.stringify(filterCriteria)}`;
    return fetchMethodRequest("GET", url)
      .then(async (response) => {
        if (response) {
          let responseData = "",
            totalRecordsLength = this.state.totalRecordsLength;
          if (
            response &&
            response["activities"] &&
            response["activities"].length &&
            response["activities"].length >= 0
          ) {
            if (response.pagination && response.pagination.totalCount) {
              totalRecordsLength = response.pagination.totalCount;
            }
            responseData = this.updateDateFormat(
              response["activities"],
              this.state.dateFormat
            );
          } else {
            if (
              response.pagination &&
              (response.pagination.totalCount ||
                response.pagination.totalCount == 0)
            ) {
              totalRecordsLength = response.pagination.totalCount;
            }
          }
          await this.setState({
            activitiesData: responseData,
            totalRecordsLength: totalRecordsLength,
            filterCriteria: filterCriteria,
          });
        }
      })
      .catch((err) => {
        return err;
      });
  };

  handleNextAndBackActions = async () => {
    let { allUsersData } = this.props;
    let { slno } = this.state;
    let data = "";
    data = allUsersData[slno];
    if (this.state.formType === "view") {
      await this.setState({
        editRowDataID: data["_id"],
      });
      await this.handleViewDisplay(data, "view");
    } else {
      await this.getRowData(data, "edit");
    }
    await this.setState({
      filterCriteria: {
        limit: 15,
        page: 1,
        criteria: [],
        sortfield: "created",
        direction: "desc",
      },
      first: 0,
      rows: 10,
      totalRecordsLength: 0,
    });
    await this.getActivities();
  };

  handleActions = async () => {
    let apiUrl = this.props.apiUrl,
      url = "",
      method = "";
    if (this.state.confirmType === "Delete") {
      method = "DELETE";
      url = `${apiUrl}/${this.state.editRowDataID}`;
      this.submitActionsData(method, url);
    }
    if (this.state.confirmType === "Block") {
      method = "PUT";
      url = `${apiUrl}/block/${this.state.editRowDataID}?block=true`;
      this.submitActionsData(method, url);
    }
    if (this.state.confirmType === "UnBlock") {
      method = "PUT";
      url = `${apiUrl}/block/${this.state.editRowDataID}?unblock=true`;
      this.submitActionsData(method, url);
    }
    if (this.state.confirmType === "Cancelled") {
      this.onChageStatus(this.state.cancelledItem)
    }
    if (this.state.confirmType === "Divorce") {
      let data =
        this.props.allUsersData && this.props.allUsersData.length > 0
          ? this.props.allUsersData
          : [];
      let userEmail = data[this.state.slno]["email"];
      let body = "";
      method = "POST";
      url = `${apiUrl}/divorce`;
      body = { email: userEmail };
      this.submitDivorceData(method, url, body);
    }
    if (this.state.confirmType === "ResetPassword") {
      await this.setState({
        openUserPasswordResetModal: true,
        openDeleteModal: false,
      });
    }

  };
  //close delete modal
  closeDeleteModal = async () => {
    this.setState({
      openDeleteModal: false,
      actions: "",
    });
  };
  getDeleteRowModal() {
    return (
      <DeleteRowModal
        openDeleteModal={this.state.openDeleteModal}
        closeDeleteModal={this.closeDeleteModal}
        selectActions={this.state.selectActions}
        deleteSelectedRow={this.handleActions}
        confirmModalText={this.state.confirmModalText}
      />
    );
  }
  // conformation for delete item
  deleteConfirmAction = async (rowData, selectActions) => {
    this.setState({
      openDeleteModal: true,
      selectActions: selectActions,
    });
  };
  confirmActionType = async (type, item) => {
    if (type === "Delete") {
      await this.setState({
        confirmType: type,
        confirmModalText: "Are you sure want to Delete",
      });
      this.deleteConfirmAction();
    } else if (type === "Block") {
      await this.setState({
        confirmType: type,
        confirmModalText: "Are you sure want to Block",
      });
      this.deleteConfirmAction();
    } else if (type === "UnBlock") {
      await this.setState({
        confirmType: type,
        confirmModalText: "Are you sure want to UnBlock",
      });
      this.deleteConfirmAction();
    } else if (type === "ResetPassword") {
      await this.setState({
        confirmType: type,
        confirmModalText: "Are you sure want to Reset Password",
        openUserPasswordResetModal: true,
        openDeleteModal: false,
      });
      // this.deleteConfirmAction()
    } else if (type === "Divorce") {
      await this.setState({
        confirmType: "Divorce",
        confirmModalText:
          "Are you sure, you want user to be divorced with the Family",
      });
      this.deleteConfirmAction();
    } else if (type === "Cancelled") {
      await this.setState({
        confirmType: "Cancelled",
        cancelledItem: item,
        isLoading: true,
        confirmModalText:
          "Are you sure, you want to Cancel the Product",
      });
      this.deleteConfirmAction();
    }
  };

  //onActionsChange
  onActionsChange = async (event, type) => {
    if (type == "dropdownFilter") {
      await this.setState({
        [event.target.name]: event.target.value,
      });
      this.confirmActionType(event.target.value);
    }
  };
  getPaginationWithIcons() {
    const { totalRecords, first, rows, onPageChange, isWeb } = this.props;
    const { slno } = this.state;
    return (
      <Row>
        <Col sm={12} className="pb-2 text-right">
          <span className="showingNumber">
            {slno === 0 ? 1 : slno + 1} / {this.props.allUsersData.length}
            &nbsp;&nbsp;
          </span>
          <span>
            <ButtonGroup className="mb-0">
              <Button
                color="primary"
                outline
                disabled={slno === 0 ? true : false}
                size="sm"
                className="p-1 ml-auto mt-1 mb-0"
                onClick={() => this.setSlno("back")}
              >
                <FontAwesomeIcon
                  icon="chevron-left"
                  className="pl-1"
                  size="lg"
                  data-toggle="tool-tip"
                  title="List"
                />
              </Button>
              <Button
                color="primary"
                outline
                disabled={
                  slno === this.props.allUsersData.length - 1 ? true : false
                }
                size="sm"
                className="p-1 ml-auto mt-1 mb-0"
                onClick={() => this.setSlno("next")}
              >
                <FontAwesomeIcon
                  icon="chevron-right"
                  className="pl-1"
                  size="lg"
                  data-toggle="tool-tip"
                  title="List"
                />
              </Button>
            </ButtonGroup>
          </span>
        </Col>
      </Row>
    );
  }
  getViewBody() {
    const { t } = this.props;
    return (
      <div>
        <div className="row">
          <div className="col-sm-4 pb-2">
            {this.props.actionsTypes && this.props.actionsTypes.length > 0 && this.props.type !== "Orders" && this.props.type !== "Store" ? (
              <Dropdown
                style={{ minWidth: "10%", lineHeight: 1.3 }}
                className="mr-3"
                // appendTo={document.body}
                name="actions"
                value={this.state.actions}
                options={this.props.actionsTypes}
                placeholder={t("Actions")}
                onChange={(e) => this.onActionsChange(e, "dropdownFilter")}
              />
            ) : null}
          </div>
          <div className="col-sm-8 text-lg-right">
            {this.state.originalTableFields &&
              this.state.originalTableFields.length > 0
              ? this.state.originalTableFields.map((item, index) => {
                return item.fieldType === "Badge" &&
                  item.options &&
                  item.options.length > 0
                  ? item.options.map((optionsItem, optionsIndex) => {
                    return (
                      <Button
                        key={optionsIndex}
                        color="primary"
                        onClick={() =>
                          this.saveDataToServer({
                            status: optionsItem.value,
                          })
                        }
                        disabled={
                          this.state.userStatus === optionsItem.value
                            ? true
                            : false
                        }
                      >
                        {optionsItem.label}
                      </Button>
                    );
                  })
                  : null;
              })
              : null}
          </div>
        </div>
        {this.state.rowData && this.state.rowData.length > 0 ? (
          <div className="row form">
            {this.props.type != "School" && this.props.type != "Orders" ?
              this.state.rowData.map((item, i) => {
                let val = this.flattenArray(item.value);
                return val !== "" && val !== null && item.label !== "Sno" && item.label !== "Products" && item.label !== "Cancelled products" ? (
                  <div className="col-12 col-sm-6" key={i}>
                    <div className="row" style={{ margin: "auto" }}>
                      <div className="col-5 paddingRowDataCol">
                        {item.label !== "Pincode" ? <span className="fontWeight">{t(item.label)}</span> :
                          <span className="fontWeight">{"Zip Code"}</span>}
                      </div>
                      <div className="col-7 paddingOfRowData">
                        <span>{item.label === "Birthday" ? dateFormats.formatDate(
                          item.value,
                          "MM-DD-YYYY") : item.value}</span>
                      </div>
                    </div>
                  </div>
                )
                  : null;
              })
              :
              this.props.type == "Orders" ?
                <div className="col-sm-12 row">
                  <div className="col-sm-12 row">
                    <div className="col-sm-6">
                      <div className="col-12" >
                        <div className="row" style={{ margin: "auto" }}>
                          <div className="col-5 paddingRowDataCol">
                            <span className="fontWeight">Order ID</span>
                          </div>
                          <div className="col-7 paddingOfRowData">
                            <span> {this.state.userData.order_id}</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-12" >
                        <div className="row" style={{ margin: "auto" }}>
                          <div className="col-5 paddingRowDataCol">
                            <span className="fontWeight">Approval Code</span>
                          </div>
                          <div className="col-7 paddingOfRowData">
                            <span> {this.state.userData && this.state.userData["ecommercePaymentId"] && this.state.userData["ecommercePaymentId"]["payment_method"] ? this.state.userData["ecommercePaymentId"]["payment_method"] : ""}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-12" >
                        <div className="row" style={{ margin: "auto" }}>
                          <div className="col-5 paddingRowDataCol">
                            <span className="fontWeight">School</span>
                          </div>
                          <div className="col-7 paddingOfRowData">
                            <span> {this.state.userData.school_name}</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-12" >
                        <div className="row" style={{ margin: "auto" }}>
                          <div className="col-5 paddingRowDataCol">
                            <span className="fontWeight">Store</span>
                          </div>
                          <div className="col-7 paddingOfRowData">
                            <span> {this.flattenArray(this.state.userData.store_name)}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="col-12" >
                        <div className="row" style={{ margin: "auto" }}>
                          <div className="col-5 paddingRowDataCol">
                            <span className="fontWeight">Order Code</span>
                          </div>
                          <div className="col-7 paddingOfRowData">
                            <span> {this.flattenArray(this.state.userData.order_code)}</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-12" >
                        <div className="row" style={{ margin: "auto" }}>
                          <div className="col-5 paddingRowDataCol">
                            <span className="fontWeight">Payment Gateway</span>
                          </div>
                          <div className="col-7 paddingOfRowData">
                            <span> {this.state.userData && this.state.userData["ecommercePaymentId"] && this.state.userData["ecommercePaymentId"]["payment_method"] ? this.state.userData["ecommercePaymentId"]["payment_method"] : ""}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-12" >
                        <div className="row" style={{ margin: "auto" }}>
                          <div className="col-5 paddingRowDataCol">
                            <span className="fontWeight">IP Iddress</span>
                          </div>
                          <div className="col-7 paddingOfRowData">
                            <span> {this.state.userData.ip_address}</span>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>

                </div>
                :
                <div className="col-sm-12 row">
                  <div className="col-sm-12 row">
                    <div className="col-sm-6">
                      <div className="col-12" >
                        <div className="row" style={{ margin: "auto" }}>
                          <div className="col-5 paddingRowDataCol">
                            <span className="fontWeight">District Name</span>
                          </div>
                          <div className="col-7 paddingOfRowData">
                            <span> {this.flattenArray(this.state.userData.district_name)}</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-12" >
                        <div className="row" style={{ margin: "auto" }}>
                          <div className="col-5 paddingRowDataCol">
                            <span className="fontWeight">School Name</span>
                          </div>
                          <div className="col-7 paddingOfRowData">
                            <span> {this.flattenArray(this.state.userData.Name)}</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-12" >
                        <div className="row" style={{ margin: "auto" }}>
                          <div className="col-5 paddingRowDataCol">
                            <span className="fontWeight">Address</span>
                          </div>
                          <div className="col-7 paddingOfRowData">
                            <span> {this.flattenArray(this.state.userData.Address)}</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-12" >
                        <div className="row" style={{ margin: "auto" }}>
                          <div className="col-5 paddingRowDataCol">
                            <span className="fontWeight">State</span>
                          </div>
                          <div className="col-7 paddingOfRowData">
                            <span> {this.flattenArray(this.state.userData.state)}</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-12" >
                        <div className="row" style={{ margin: "auto" }}>
                          <div className="col-5 paddingRowDataCol">
                            <span className="fontWeight">City</span>
                          </div>
                          <div className="col-7 paddingOfRowData">
                            <span> {this.flattenArray(this.state.userData.city)}</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-12" >
                        <div className="row" style={{ margin: "auto" }}>
                          <div className="col-5 paddingRowDataCol">
                            <span className="fontWeight">Zip Code</span>
                          </div>
                          <div className="col-7 paddingOfRowData">
                            <span> {this.flattenArray(this.state.userData.pincode)}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="col-12" >
                        <div className="row" style={{ margin: "auto" }}>
                          <div className="col-5 paddingRowDataCol">
                            <span className="fontWeight">Principal Name</span>
                          </div>
                          <div className="col-7 paddingOfRowData">
                            <span> {this.flattenArray(this.state.userData.principal_name)}</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-12" >
                        <div className="row" style={{ margin: "auto" }}>
                          <div className="col-5 paddingRowDataCol">
                            <span className="fontWeight">Principal Email</span>
                          </div>
                          <div className="col-7 paddingOfRowData">
                            <span> {this.flattenArray(this.state.userData.principal_email)}</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-12" >
                        <div className="row" style={{ margin: "auto" }}>
                          <div className="col-5 paddingRowDataCol">
                            <span className="fontWeight">PTA President Name(s)</span>
                          </div>
                          <div className="col-7 paddingOfRowData">
                            <span> {this.flattenArray(this.state.userData.pta_president_name)}</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-12" >
                        <div className="row" style={{ margin: "auto" }}>
                          <div className="col-5 paddingRowDataCol">
                            <span className="fontWeight">PTA President Email(s)</span>
                          </div>
                          <div className="col-7 paddingOfRowData">
                            <span> {this.flattenArray(this.state.userData.pta_president_email)}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 row mt-4">
                    <div className="col-sm-6">
                      <div className="col-12" >
                        <div className="row" style={{ margin: "auto" }}>
                          <div className="col-5 paddingRowDataCol">
                            <span className="fontWeight">Website</span>
                          </div>
                          <div className="col-7 paddingOfRowData">
                            <span> {this.flattenArray(this.state.userData.website)}</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-12" >
                        <div className="row" style={{ margin: "auto" }}>
                          <div className="col-5 paddingRowDataCol">
                            <span className="fontWeight">School Hours</span>
                          </div>
                          <div className="col-7 paddingOfRowData">
                            <span> {this.flattenArray(this.state.userData.School_Hours)}</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-12" >
                        <div className="row" style={{ margin: "auto" }}>
                          <div className="col-5 paddingRowDataCol">
                            <span className="fontWeight">Main Number</span>
                          </div>
                          <div className="col-7 paddingOfRowData">
                            <span> {this.flattenArray(this.state.userData.Main_Number)}</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-12" >
                        <div className="row" style={{ margin: "auto" }}>
                          <div className="col-5 paddingRowDataCol">
                            <span className="fontWeight">Attendance Line</span>
                          </div>
                          <div className="col-7 paddingOfRowData">
                            <span> {this.flattenArray(this.state.userData.Attendance_Line)}</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-12" >
                        <div className="row" style={{ margin: "auto" }}>
                          <div className="col-5 paddingRowDataCol">
                            <span className="fontWeight">Nurse</span>
                          </div>
                          <div className="col-7 paddingOfRowData">
                            <span> {this.flattenArray(this.state.userData.Nurse)}</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-12" >
                        <div className="row" style={{ margin: "auto" }}>
                          <div className="col-5 paddingRowDataCol">
                            <span className="fontWeight">Transportation</span>
                          </div>
                          <div className="col-7 paddingOfRowData">
                            <span> {this.flattenArray(this.state.userData.Transportation)}</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-12" >
                        <div className="row" style={{ margin: "auto" }}>
                          <div className="col-5 paddingRowDataCol">
                            <span className="fontWeight">Delayed Opening</span>
                          </div>
                          <div className="col-7 paddingOfRowData">
                            <span> {this.flattenArray(this.state.userData.Closing_Delays)}</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-12" >
                        <div className="row" style={{ margin: "auto" }}>
                          <div className="col-5 paddingRowDataCol">
                            <span className="fontWeight">Early Dismissal Time</span>
                          </div>
                          <div className="col-7 paddingOfRowData">
                            <span> {this.flattenArray(this.state.userData.Early_Dismissal_time)}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">

                      <div className="col-12" >
                        <div className="row" style={{ margin: "auto" }}>
                          <div className="col-5 paddingRowDataCol">
                            <span className="fontWeight">PTA dues types</span>
                          </div>
                          <div className="col-7 paddingOfRowData">
                            <span> {this.flattenArray(this.state.userData.pta_dues.map((u) => { return u.type; }))}</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-12" >
                        <div className="row" style={{ margin: "auto" }}>
                          <div className="col-5 paddingRowDataCol">
                            <span className="fontWeight">Supporting Donations</span>
                          </div>
                          <div className="col-7 paddingOfRowData">
                            <span> {this.flattenArray(this.state.userData.supporting_donations)}</span>
                          </div>
                        </div>
                      </div>
                      {this.state.userData.pta_dues.map((u) => { return u.type }).includes("Individual") ? <div className="col-12" >
                        <div className="row" style={{ margin: "auto" }}>
                          <div className="col-5 paddingRowDataCol">
                            <span className="fontWeight">Individual Dues</span>
                          </div>
                          <div className="col-7 paddingOfRowData">
                            <span>{this.flattenArray(this.state.userData.Individual_dues)}</span>
                          </div>
                        </div>
                      </div> : null}
                      {this.state.userData.pta_dues.map((u) => { return u.type }).includes("Family") ? <div className="col-12" >
                        <div className="row" style={{ margin: "auto" }}>
                          <div className="col-5 paddingRowDataCol">
                            <span className="fontWeight">Family Dues</span>
                          </div>
                          <div className="col-7 paddingOfRowData">
                            <span> {this.flattenArray(this.state.userData.Family_dues)}</span>
                          </div>
                        </div>
                      </div> : null}
                      {this.state.userData.pta_dues.map((u) => { return u.type }).includes("Teacher") ? <div className="col-12" >
                        <div className="row" style={{ margin: "auto" }}>
                          <div className="col-5 paddingRowDataCol">
                            <span className="fontWeight">Teacher Dues</span>
                          </div>
                          <div className="col-7 paddingOfRowData">
                            <span> {this.flattenArray(this.state.userData.Teacher_dues)}</span>
                          </div>
                        </div>
                      </div> : null}
                      {this.state.userData.septaFee ? <div className="col-12" >
                        <div className="row" style={{ margin: "auto" }}>
                          <div className="col-5 paddingRowDataCol">
                            <span className="fontWeight">Septa Fee</span>
                          </div>
                          <div className="col-7 paddingOfRowData">
                            <span>{this.flattenArray(this.state.userData.septaFee)}</span>
                          </div>
                        </div>
                      </div> : null}
                      <div className="col-12" >
                        <div className="row" style={{ margin: "auto" }}>
                          <div className="col-5 paddingRowDataCol">
                            <span className="fontWeight">PreLoad</span>
                          </div>
                          <div className="col-7 paddingOfRowData">
                            <span>{this.flattenArray(this.state.userData.preload)}</span>
                          </div>
                        </div>
                      </div>



                    </div>
                  </div>
                </div>

            }
          </div>
        ) : null}
      </div>
    );
  }
  //sorting fields
  sortChange = (event) => {
    this.setState({ selectedRows: "" });
    let sortCount = this.state.sortCount;
    if (event && event["sortField"]) {
      sortCount = sortCount == 0 ? sortCount + 1 : 0;
      let sortField = event["sortField"];
      let filterCriteria = this.state.filterCriteria;
      filterCriteria["direction"] = sortCount == 0 ? "desc" : "asc";
      filterCriteria["sortfield"] = sortField;
      this.setState({
        sortCount: sortCount,
        filterCriteria: filterCriteria,
      });
      this.getActivities();
    }
  };
  getColumns(e, d) {
    const { t } = this.props;
    const self = this;
    self.e = e;
    self.d = d;
    let tablefieldsToShow = this.getTableFields();
    if (tablefieldsToShow && tablefieldsToShow.length > 0) {
      return tablefieldsToShow.map((item, i) => {
        let column = item.show && (
          <Column
            key={item.field + i}
            style={{
              maxwidth: item.width,
              padding: 2,
            }}
            bodyStyle={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textAlign:
                item.field == "status" || item.field == "role"
                  ? "center"
                  : item.textAlign,
            }}
            field={item.field}
            header={t(item.header)}
            body={self.changeFieldValues}
            headerStyle={{
              padding: 4,
              fontWeight: 500,
              width: item.width,
              fontSize: 13,
              color: config.whiteColor,
              backgroundColor: config.templateColor,
            }}
            filter={false}
            sortable={item.sortable ? true : false}
            filterPlaceholder={item.placeholder ? item.placeholder : "search"}
            selectionMode={item.selectionMode}
          />
        );
        return column;
      });
    }
  }
  getDataTable(type) {
    let self = this;
    return (
      <DataTable
        ref={(el) => (this.dt = el)}
        value={
          type ? this.state.cancelledProducts : 
          this.state.activitiesData}
        totalRecords={type ? this.state.cancelledProductsLength : this.state.totalRecordsLength}
        paginator={false}
        lazy={true}
        resizableColumns={true}
        columnResizeMode="expand"
        onSort={this.sortChange}
        globalFilter={this.state.globalFilter}
        onFilter={this.onFilterChange}
        scrollable={true}
        selection={false}
        scrollHeight="1000px"
        style={{ marginTop: 0 }}
        emptyMessage={configMessages.noRecords}
        sortMode="single"
        // sortField="fname"
        // sortOrder={-1}
        // selectionMode={'multiple'}
        metaKeySelection={false}
        loading={this.state.isLoading}
        style={
          this.state.activitiesData && this.state.activitiesData.length == 0
            ? { textAlign: "center" }
            : null
        }
      >
        {self.getColumns()}
      </DataTable>
    );
  }
  getActivitiesHeader() {
    const { t } = this.props;
    return (
      <div className="col-12  pb-1">
        <span className="float-left pt-3">
          <h4 style={{ textTransform: "capitalize" }}>
            <b>
              <Link to={this.props.routeTo} onClick={this.closeFormModal}>
                {t("Activities")}
              </Link>
              {this.state.formType !== "add" &&
                this.state.displayBreadCrumbValue
                ? ` / ${this.state.displayBreadCrumbValue} `
                : null}
            </b>{" "}
          </h4>
        </span>
        <span className="float-right ">{this.getPaginator()}</span>
      </div>
    );
  }
  getPaginator() {
    return (
      <PaginatorComponent
        totalRecords={this.state.totalRecordsLength}
        first={this.state.first}
        rows={this.state.rows}
        onPageChange={this.onPageChange}
        isWeb={true}
      />
    );
  }
  //getScreenBody
  getScreenBody(handleSubmit) {
    return (
      <form
        key={this.props.type}
        onSubmit={handleSubmit(this.submit)}
        autoComplete={"off"}
      >
        <Loader loader={this.state.isLoading} />
        <div
          className={
            this.props.formType === "add" ? "row form pt-3" : "row form "
          }
        >
          {this.getFields()}
          {this.state.sessionExpiryModal ? (
            <SessionExpiryModal SOpen={this.state.sessionExpiryModal} />
          ) : null}
          {this.props.getDependentFields &&
            this.props.getDependentFields(this.props.type, this.props.formType)}
        </div>
        {this.getButtonToolbar()}
      </form>
    );
  }

  getDataTableforCanceled() {
    const { cancelledProducts } = this.state
    return (
      <div className='row mx-1 mt-3' style={{ width: "100%" }}>
        <div className="col-12 modalTbody pl-0 pr-0" >
          <Table>
            <thead style={{ backgroundColor: "rgb(14, 71, 104)", color: "white" }}>
              <tr>
                <th style={{ width: "75%", color: "white" }}>Product Name</th>
                <th style={{ color: "white" }}>Qty</th>
                <th style={{ color: "white" }}>Price</th>
                <th style={{ color: "white" }}>Total</th>
                <th style={{ color: "white" }}>Cancelled Date</th>

              </tr>
            </thead>
            <tbody style={{ maxHeight: 80 }}>
              {cancelledProducts && cancelledProducts && cancelledProducts.length > 0 ?
                cancelledProducts.map((item, i) => {
                  return <tr>
                    <td style={{ width: "75%" }}>
                      <div className="row col-sm-12">
                        <span className="col-sm-2">
                          <img
                            src={item["productImage"] ? `${config.imgUrl}product/${item["productImage"]} ` : configImages.defaultImg}
                            alt=""
                            className="appLogo" />
                        </span >
                        <span className="col-sm-10 text-left">
                          <p style={{ fontSize: 14, fontWeight: 700 }}>{item.product_name}</p>
                          <p className="mt-0">{"Store: " + item.store_name}</p>
                          <p>{item.comments ? item.comments : "No Comments"}</p>

                        </span >
                      </div>
                    </td>

                    <td>{item.qty}</td>
                    <td>{item.price}</td>
                    <td>{item.qty * item.price}</td>
                    <td>{dateFormats.formatDate(item.cancelled_date, config.dateDayMonthFormat)}</td>


                  </tr>
                }) : <tr><td>No Records Found</td></tr>}
            </tbody>

          </Table>
        </div>
      </div>)
  }
  //getScreenView
  getScreenView() {
    const { handleSubmit } = this.props;
    return this.props.openFormModal ? (
      <Card style={{ margin: "7px" }}>
        <CardBody className="tableCardBody">
          <div>
            <div>
              <div className="row">
                <div className="col-sm-12 pb-2">
                  <span className="float-left">{this.getHeader()}</span>
                  <span className="float-right">
                    {this.state.formType !== "add" && this.props.type !== "Orders" && this.props.type !== "Pending Carts" && this.props.type !== 'Refund Details' && this.props.type !== 'Store' && 
                      this.getPaginationWithIcons()}
                  </span>
                </div>
              </div>
              {this.state.formType !== "view"
                ? this.getScreenBody(handleSubmit)
                : this.getViewBody()}
              {this.state.formType === "view" && this.props.editRequired && this.props.type !== "Store"
                ? this.getEditButton()
                : null}
              {this.state.formType !== "add" ? (
                <>
                  <div>
                    {/* {this.props.type !== "Orders" && this.props.type !== "Pending Carts" && this.props.type !== "Refund Details" ? 
                      <div className="row pt-3">{this.getActivitiesHeader()}</div>
                      : <div className="col-sm-12 pt-3" style={{ fontSize: 16 }}><b>Order Items(Cart)</b></div>} */}

                    <div className="row pt-3">
                      {this.props.type !== "Orders" && this.props.type !== "Pending Carts" && this.props.type !== "Refund Details" && this.props.type !== 'Store' 
                        ? this.getActivitiesHeader()
                        : this.props.type === "Pending Carts"
                          ? <div className="col-sm-12 pt-3" style={{ fontSize: 16 }}><b>Cart Details</b></div>
                          : this.props.type === "Refund Details"
                            ? <div className="col-sm-12 pt-3" style={{ fontSize: 16 }}><b>Cancelled Products</b></div>
                            :this.props.type == 'Store' 
                             ?  ((this.props.formType == 'edit' || this.props.formType == "copy") && this.props.type == "Store")? null :<div className="col-sm-12 pt-3" style={{ fontSize: 16 }}><b>Store Products</b></div>
                            : <div className="col-sm-12 pt-3" style={{ fontSize: 16 }}><b>Order Items(Cart)</b></div>
                      }
                    </div>
                    <div className="row">
                    <div className="col-sm-12">{((this.props.formType === 'edit' || this.props.formType === 'copy') && this.props.type ==='Store') ? null : this.getDataTable()}</div>
                    </div>
                    {this.props.type == "Orders" ?
                      <div className="row col-sm-12">
                        <div className="col-sm-11 text-right"><b>Grand Total</b></div>
                        <div className="col-sm-1"><b>{"$" + this.state.GrandTotal}</b></div>

                      </div> : null}
                  </div>
                  <div>
                    {this.props.type === "Orders" ?
                      <div>
                        <div className="col-sm-12 pt-3" style={{ fontSize: 16 }}><b>Cancelled Items(Cart)</b></div>
                        <div className="row">
                          <div className="col-sm-12">{this.getDataTableforCanceled('canceled')}</div>
                        </div></div>
                      : null}
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </CardBody>
      </Card>
    ) : null;
  }
  //getModalView
  getModalView() {
    const { handleSubmit } = this.props;
    const modalClass = classNames({
      "modal-dialog--colored": this.state.colored,
      "modal-dialog--header": this.state.header,
    });
    return (
      <Modal
        isOpen={this.props.openFormModal}
        className={`modal-dialog-centered modal-dialog--primary  ${modalClass}`}
      >
        {this.getModalHeader()}
        {this.state.formType === "view" ? (
          <ModalBody className="modal__body mb-0 pt-1">
            <Card className="pb-0 cardForListMargin">
              <CardBody className="tableCardBody">
                {this.getViewBody()}
                {this.getEditButton()}
              </CardBody>
            </Card>
          </ModalBody>
        ) : (
          this.getModalBody(handleSubmit)
        )}
      </Modal>
    );
  }
  getUserPasswordResetModal = () => {
    return (
      <UserPasswordResetModal
        openUserPasswordResetModal={this.state.openUserPasswordResetModal}
        user={this.state.userData}
        cancelReset={this.cancelUserPwdResetModal}
        entityType={this.props.entityType}
      />
    );
  };
  cancelUserPwdResetModal = async () => {
    await this.setState({
      openUserPasswordResetModal: false,
      actions: "",
    });
  };
  getModalBody(handleSubmit) {
    return (
      <ModalBody className="modal__body mb-0">
        <Card className="m-0 p-0">
          <CardBody className="m-0 p-0">
            <Loader loader={this.state.isLoading} />
            <form
              key={this.props.type}
              onSubmit={handleSubmit(this.submit)}
              autoComplete={"off"}
            >
              {this.getButtonToolbar()}

              <div className="row form">
                {this.getFields()}
                {this.state.sessionExpiryModal ? (
                  <SessionExpiryModal SOpen={this.state.sessionExpiryModal} />
                ) : null}
                {this.props.getDependentFields &&
                  this.props.getDependentFields(
                    this.props.type,
                    this.props.formType
                  )}
              </div>
            </form>
          </CardBody>
        </Card>
      </ModalBody>
    );
  }

  render() {
    const { handleSubmit, displayViewOfForm } = this.props;
    const modalClass = classNames({
      "modal-dialog--colored": this.state.colored,
      "modal-dialog--header": this.state.header,
    });
    return (
      <div>
        {displayViewOfForm === "screen"
          ? this.getScreenView()
          : this.getModalView()}
        {this.state.openDeleteModal ? this.getDeleteRowModal() : null}
        {this.state.openUserPasswordResetModal
          ? this.getUserPasswordResetModal()
          : null}
      </div>
    );
  }
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
FormModal = reduxForm({
  form: "Common Form Modal", // a unique identifier for this form
  validate,
  enableReinitialize: true,
})(FormModal);

// You have to connect() to any reducers that you wish to connect to yourself
FormModal = connect(
  (state) => ({
    initialValues: state.commonData.data, // pull initial values from account reducer
  }),
  { load: loadAccount } // bind account loading action creator
)(FormModal);

export default withTranslation("common")(FormModal);
