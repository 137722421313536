import React from "react";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";

// File Upload
import FileUpload from "../../Form/components/FileUpload";
import Loader from "../../App/Loader";

// config file
export default class BulkUploadModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clickCount: 0,
    };
  }

  componentDidMount = () => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  };

  componentWillUnmount = () => {
    if (this.props.onRef) {
      this.props.onRef(null);
    }
  };

  uploadToServer = async () => {
    await this.setState({
      isLoading: true,
    });
    if (this.state.clickCount === 0) {
      await this.setState({
        clickCount: this.state.clickCount++,
      });
      await this.fileUploadRef.UploadFileToServer();
    } else {
      await this.handleClickCount();
    }
  };

  handleClickCount = async () => {
    setTimeout(
      () =>
        this.setState({
          isLoading: false,
          clickCount: 0,
        }),
      1000
    );
  };

  render() {
    const { isLoading } = this.state;
    return (
      <Modal
        isOpen={this.props.openBulkUploadModal}
        className={`modal-dialog-centered modal-dialog--primary modal-dialog--header`}
      >
        <Loader loader={isLoading} />
        <ModalHeader className="modal__header px-2 bold-text">
          <button
            className="lnr lnr-cross modal__close-btn bold-text"
            type="button"
            onClick={this.props.closeBulkModal}
          />
          <span>Bulk Upload</span>
        </ModalHeader>
        <ModalBody className="deleteModalBody">
          {this.props.sampleFilePath && (
            <div className="row">
              <div className="col-12">
                <a
                  className="btn btn-primary outline whiteColor"
                  target="_blank"
                  download
                  href={this.props.sampleFilePath}
                >
                  Download Sample Document
                </a>
              </div>
            </div>
          )}

          <div className="row">
            <div className="col-12">
              <FileUpload
                type="bulkUpload"
                onRef={(ref) => (this.fileUploadRef = ref)}
                url={"employees/csvupload?type=" + this.props.bulkApi}
                close={this.props.closeBulkModal}
                resetClickCount={() => this.handleClickCount()}
                acceptType={".xlsx"}
              />
            </div>
            <div className="col-sm-12 text-center">
              <Button
                color="primary"
                outline
                onClick={this.props.closeBulkModal}
              >
                Close
              </Button>
              <Button
                color="primary"
                onClick={isLoading ? null : this.uploadToServer}
              >
                Upload - {this.props.type}
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}
