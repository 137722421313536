import React from "react";
import apiCalls from "../../../../config/apiCalls";
import store from "../../../App/store";
import filePath from "../../../../config/configSampleFiles";
import DataTables from "../../CommonDataTable/DataTable";
import config from "../../../../config/config";

// config file
export default class Class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      languageData: "",
      teacherEmailRequired: false,
      teachersWebsiteRequired: false
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState();
    let languageData =
      storeData &&
        storeData.settingsData &&
        storeData.settingsData.settings &&
        storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  }

  getTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: false,
      },
      // {
      //   textAlign: "center",
      //   width: 47,
      //   field: "Sno",
      //   header: "Sno",
      //   filter: false,
      //   sortable: false,
      //   placeholder: "Search",
      //   show: true,
      // },
      {
        show: true,
        textAlign: "left",
        width: 140,
        fieldType: "Link",
        mobile: true,
        field: "school_name",
        header: "School Name",
        filter: false,
        sortable: true,
        style: {
          color: config.templateColor,
          cursor: "pointer",
          textTransform: "capitalize",
        },
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 80,
        mobile: true,
        field: "class_name",
        header: "Class",
        filter: false,
        sortable: true,
        textCapitalize: true,
        displayInSettings: true,
      }, {
        show: true,
        textAlign: "center",
        width: 80,
        mobile: true,
        field: "class_display_order",
        header: "Class Order",
        filter: false,
        sortable: true,
        textCapitalize: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 80,
        field: "grade",
        mobile: true,
        header: "Grade",
        filter: false,
        sortable: true,
        textCapitalize: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 180,
        field: "teacher_display_name",
        mobile: true,
        header: "Teacher Name",
        filter: false,
        sortable: true,
        textCapitalize: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 140,
        field: "teacher_email",
        mobile: true,
        header: "Teacher Email",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 160,
        field: "teacher_website",
        header: "Teacher Website",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 70,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
      },
    ];
    return data;
  };

  getFormFields = () => {
    return [
      {
        required: true,
        value: "",
        searchField: "name",
        searchApi: "districts",
        type: "autoComplete",
        name: "district_name",
        options: [
          { district_name: "name" },
          { district_id: "district_id" },
        ],
        label: "School District",
        id: "Name",
        placeholder: "School District",
      },
      {
        required: true,
        value: "",
        searchField: "Name",
        searchApi: "schools",
        type: "autoComplete",
        options: [
          { school_name: "Name" },
          { district_id: "district_id" },
          { school_id: "school_id" },
        ],
        name: "school_name",
        label: "School Name",
        id: "Name",
        placeholder: "School Name",
      },
      {
        value: "",
        type: "dropDown",
        id: "grade",
        required: true,
        placeholder: "Grade",
        name: "grade",
        label: "Grade",
        options: [],
        optionsName: "schoolGrades",
      }, {
        value: "",
        type: "number",
        id: "class_display_order",
        required: true,
        placeholder: "Class Order",
        name: "class_display_order",
        label: "Class Display Order",

      },
      {
        value: "",
        type: "text",
        name: "class_name",
        label: "Class Name",
        id: "class_name",
        placeholder: "Class Name",
        //required: true,
      },
      {
        value: "",
        type: "text",
        id: "teacher_first_name",
        required: false,
        name: "teacher_first_name",
        label: "Teacher First Name",
        placeholder: "First Name",
        required:true
      },
      {
        value: "",
        type: "text",
        id: "teacher_last_name",
        required: false,
        name: "teacher_last_name",
        label: "Teacher Last Name",
        placeholder: "Last Name",
        required:true
      },
      
      {
        value: "",
        type: "text",
        id: "teacher_email",
        // required: false,
        required: this.state.teacherEmailRequired,
        placeholder: "Teacher Email",
        name: "teacher_email",
        label: "Teacher Email",
      },
      {
        value: "",
        type: "text",
        id: "teacher_website",
        // required: false,
        required: this.state.teachersWebsiteRequired,
        placeholder: "Teacher Website",
        name: "teacher_website",
        label: "Teacher Website",
      },
    ];
  };

  // email and website format validations
  getValidations = (e, name) => {
    this.setState({
      teacherEmailRequired: (e !== "" && name === "teacher_email") ? true : false,
      teachersWebsiteRequired: (e !== "" && name === "teacher_website") ? true : false
    });
  }

  render() {
    return (
      <DataTables
        getTableFields={this.getTableFields}
        formFields={this.getFormFields}
        addRequried={true}
        editRequired={true}
        deleteRequired={true}
        exportToCsv={true}
        viewRequired={true}
        settingsRequired={true}
        sample={true}
        sampleFilePath={filePath.class}
        bulkApi={"class"}
        globalSearch={"School Name/Teacher Name/Email"}
        type="Class"
        apiUrl={apiCalls.Classes}
        actionsTypes={actionsTypes}
        getValidations={this.getValidations}
      />
    );
  }
}

const actionsTypes = [
  {
    name: "Delete",
    options: [{ label: "Delete", value: "Delete", show: true, multiple: true }],
  },
];
