import React from "react";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";

// config file
export default class AlertModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  deleteSelectedRow = () => {
    // this.props.deleteSelectedRow();
  };

  render() {
    return (
      <div>
        <Modal isOpen={this.props.open} className="deleteModalHeight">
          <ModalBody className="deleteModalBody">
            <div style={{ marginBottom: 10 }}>{this.props.alertText}</div>
            <Button
              color="primary"
              outline
              onClick={this.props.close}
              className="deleteModalBtn marginRight"
            >
              Ok
            </Button>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
