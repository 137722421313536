import React from "react";
import { Modal, ModalBody, ModalHeader, Badge, Table, tbody } from "reactstrap";
import classNames from "classnames";
import Scrollbar from "react-smooth-scrollbar";
import config from "../../../config/config";
import fetchMethodRequest from "../../../config/service";
import Loader from "../../App/Loader";

export default class ViewModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rowData: {},
      label: "",
      status: "",
      type: null,
      apiData: {},
      isLoading: false,
    };
  }

  componentDidMount = () => {
    this.props.onRef(this);
  };

  componentWillUnmount() {
    this.props.onRef(null);
  }

  getRowData = async (rowItem, url, viewQuery) => {
    await this.setState({
      isLoading: true,
    });
    let apiData = {};
    let apiUrl = `${url}/${rowItem._id}`;
    if (viewQuery) {
      apiUrl = `${apiUrl}?${viewQuery}`;
    }
    let method = "GET";

    let data = await fetchMethodRequest(method, apiUrl).then(async (resp) => {

      if (resp && resp.details) {

        if (
          resp &&
          resp.details
        ) {
          apiData = resp.details;
        }

      } else {
        apiData = rowItem;
      }
      return apiData;
    });

    await this.setState({
      data: data,
      apiData: apiData,
      isLoading: false,
    });
  };





  render() {
    const modalClass = classNames({
      "modal-dialog--colored": this.state.colored,
      "modal-dialog--header": this.state.header,
    });
    console.log(this.state.apiData)
    return (
      <div style={{ display: "flex" }}>
        <Loader loader={this.state.isLoading} />
        <Modal
          isOpen={this.props.openViewModal}
          className={` modal-dialog--primary modal-dialog--header ${modalClass}`}
        >
          <ModalHeader className="modal__header viewModalHeader">
            {this.props.type} Details
            <button
              className="lnr lnr-cross modal__close-btn"
              type="button"
              onClick={this.props.closeViewModal}
            />
          </ModalHeader>
          <ModalBody className="mr-4" style={{ overflowX: "auto" }}>
            <div>
              <Table style={{ padding: "5px", width: "auto" }} className="table_static">
                <tbody>
                  <tr>
                    <th>Parent/Guardian</th>
                    <th>Parent 1</th>
                    {this.state.apiData.email2 ? <th>Parent 2</th> : ""}
                  </tr>
                  <tr>
                    <td>Type</td>
                    <td>{this.state.apiData.parent1 ? this.state.apiData.parent1 : ""}</td>
                    {this.state.apiData.email2 ? <td>{this.state.apiData.parent2 ? this.state.apiData.parent2 : ""}</td> : ""}
                  </tr>
                  <tr>
                    <td>Last Name </td>
                    <td>{this.state.apiData.p1_lastname ? this.state.apiData.p1_lastname : ""}</td>
                    {this.state.apiData.email2 ? <td>{this.state.apiData.p2_lastname ? this.state.apiData.p2_lastname : ""}</td> : ""}
                  </tr>
                  <tr>
                    <td>First Name</td>
                    <td>{this.state.apiData.p1_firstname ? this.state.apiData.p1_firstname : ""}</td>
                    {this.state.apiData.email2 ? <td>{this.state.apiData.p2_firstname ? this.state.apiData.p2_firstname : ""}</td> : ""}
                  </tr>
                  <tr>
                    <td>Middle Name</td>
                    <td>{this.state.apiData.p1_middlename ? this.state.apiData.p1_middlename : ""}</td>
                    {this.state.apiData.email2 ? <td>{this.state.apiData.p2_middlename ? this.state.apiData.p2_middlename : ""}</td> : ""}
                  </tr>
                  <tr>
                    <td>Suffix</td>
                    <td>{this.state.apiData.p1_suffix ? this.state.apiData.p1_suffix : ""}</td>
                    {this.state.apiData.email2 ? <td>{this.state.apiData.p2_suffix ? this.state.apiData.p2_suffix : ""}</td> : ""}
                  </tr>
                  <tr>
                    <td>Email</td>
                    <td>{this.state.apiData.email1 ? this.state.apiData.email1 : ""}</td>
                    {this.state.apiData.email2 ? <td>{this.state.apiData.email2 ? this.state.apiData.email2 : ""}</td> : ""}
                  </tr>
                  <tr>
                    <td>Cell phone#</td>
                    <td>{this.state.apiData.phone1_mobile ? this.state.apiData.phone1_mobile : ""}</td>
                    {this.state.apiData.email2 ? <td>{this.state.apiData.phone2_mobile ? this.state.apiData.phone2_mobile : ""}</td> : ""}
                  </tr>
                  <tr>
                    <td>Work </td>
                    <td>{this.state.apiData.phone1_work ? this.state.apiData.phone1_work : ""}</td>
                    {this.state.apiData.email2 ? <td>{this.state.apiData.phone2_work ? this.state.apiData.phone2_work : ""}</td> : ""}
                  </tr>
                  <tr>
                    <td>Home </td>
                    <td>{this.state.apiData.phone1_home ? this.state.apiData.phone1_home : ""}</td>
                    {this.state.apiData.phone2_home ? <td>{this.state.apiData.phone2_home ? this.state.apiData.phone2_home : ""}</td> : ""}
                  </tr>
                  <tr>
                    <td>Address</td>
                    <td>{this.state.apiData.address1 ? this.state.apiData.address1 : ""}</td>
                    {this.state.apiData.email2 ? <td>{this.state.apiData.address2 ? this.state.apiData.address2 : ""}</td> : ""}
                  </tr>
                  <tr>
                    <td>City</td>
                    <td>{this.state.apiData.city ? this.state.apiData.city : ""}</td>
                    {this.state.apiData.email2 ? <td>{this.state.apiData.city ? this.state.apiData.city : ""}</td> : ""}
                  </tr>
                  <tr>
                    <td>State</td>
                    <td>{this.state.apiData.state ? this.state.apiData.state : ""}</td>
                    {this.state.apiData.email2 ? <td>{this.state.apiData.state ? this.state.apiData.state : ""}</td> : ""}
                  </tr>
                </tbody>
              </Table>
              <div className="m-2" style={{ width: 500 }}>
                <Table className="student custom-table" >
                  <tbody>
                    <tr>
                      <th>Student</th>
                      {this.state.apiData && this.state.apiData.childrens && this.state.apiData.childrens.map((arrayItem, arrayIndex) => {
                        return (
                          <th style={{ width: 20 }}>Student {arrayIndex + 1}</th>
                        )
                      })
                      }
                    </tr>

                    <tr>
                      <td style={{ width: 20 }}>Last Name</td>
                      {this.state.apiData && this.state.apiData.childrens && this.state.apiData.childrens.map((arrayItem, arrayIndex) => {
                        return (
                          <td style={{ width: 20 }}>{arrayItem.student_lastname}</td>
                        )
                      })
                      }
                    </tr>
                    <tr>
                      <td style={{ width: 20 }}>First Name</td>
                      {this.state.apiData && this.state.apiData.childrens && this.state.apiData.childrens.map((arrayItem, arrayIndex) => {
                        return (
                          <td style={{ width: 20 }}>{arrayItem.student_firstname}</td>
                        )
                      })
                      }
                    </tr>
                    <tr>
                      <td style={{ width: 20 }}>Middle Name</td>
                      {this.state.apiData && this.state.apiData.childrens && this.state.apiData.childrens.map((arrayItem, arrayIndex) => {
                        return (
                          <td style={{ width: 20 }}> {arrayItem.student_middlename}</td>
                        )
                      })
                      }
                    </tr>
                    <tr>
                      <td style={{ width: 20 }}>District</td>
                      {this.state.apiData && this.state.apiData.childrens && this.state.apiData.childrens.map((arrayItem, arrayIndex) => {
                        return (
                          <td style={{ width: 20 }}>{arrayItem.district_name}</td>
                        )
                      })
                      }
                    </tr>
                    <tr>
                      <td style={{ width: 20 }}>School</td>
                      {this.state.apiData && this.state.apiData.childrens && this.state.apiData.childrens.map((arrayItem, arrayIndex) => {
                        return (
                          <td style={{ width: 20 }}>{arrayItem.school_name}</td>
                        )
                      })
                      }
                    </tr>
                    <tr>
                      <td>Grade</td>
                      {this.state.apiData && this.state.apiData.childrens && this.state.apiData.childrens.map((arrayItem, arrayIndex) => {
                        return (
                          <td>{arrayItem.grade_int}</td>
                        )
                      })
                      }
                    </tr>
                    <tr>
                      <td>Class</td>
                      {this.state.apiData && this.state.apiData.childrens && this.state.apiData.childrens.map((arrayItem, arrayIndex) => {
                        return (
                          <td> {arrayItem.class_name}</td>
                        )
                      })
                      }
                    </tr>
                    <tr>
                      <td>Student Id</td>
                      {this.state.apiData && this.state.apiData.childrens && this.state.apiData.childrens.map((arrayItem, arrayIndex) => {
                        return (
                          <td> {arrayItem.child_id}</td>
                        )
                      })
                      }
                    </tr>
                    {/* <tr>
                      <td>Living with</td>
                      {this.state.apiData && this.state.apiData.childrens && this.state.apiData.childrens.map((arrayItem, arrayIndex) => {
                        return (
                          <td>{arrayItem.living_with}</td>
                        )
                      })
                      }
                    </tr> */}
                  </tbody>
                </Table>
              </div>
            </div>

          </ModalBody>
        </Modal>
      </div>
    );
  }
}
