import React from 'react';
import Scrollbar from 'react-smooth-scrollbar';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import SidebarContent from './SidebarContent';
import { SidebarProps } from '../../../shared/prop-types/ReducerProps';

import fetch from "../../../config/service";

export default class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuList: [],
      loginCredentials: {}
    }
  }

  componentDidMount = async () => {
    let loginCredentials = localStorage.getItem("loginCredentials");
    if (loginCredentials) {
      loginCredentials = JSON.parse(loginCredentials);
      this.setState({
        loginCredentials: loginCredentials
      })
    }
    await this.getMenuListFromServer();
  }

  getMenuListFromServer = async () => {
    let loginCredentials = this.state.loginCredentials;
    if (loginCredentials) {
      let filterCriteria = {}, url;
      filterCriteria.sortfield = 'sequenceNo';
      filterCriteria.direction = 'asc';
      url = `menulists?filter=${JSON.stringify(filterCriteria)}`;
      fetch('GET', url).then(async (res) => {
        if (res && res.menulists && res.menulists.length > 0) {
          let menuList = res.menulists;
          let rolePermissions = localStorage.getItem("rolePermissions");
          rolePermissions = JSON.parse(rolePermissions);
          if (rolePermissions) {
            let neWmenuList = [];
            let keys = Object.keys(rolePermissions);
            if (menuList) {
              menuList.forEach((item) => {
                if (item.submenus && item.submenus.length > 0) {
                  let newSubmenus = [];
                  item.submenus.map(sitem => {
                    keys.forEach(element => {
                      if (sitem.title === element) {
                        if (rolePermissions[element] !== "NoView") {
                          newSubmenus.push(sitem);
                        }
                      }
                    });
                  })
                  if (newSubmenus && newSubmenus.length > 0) {
                    item.submenus = newSubmenus;
                    neWmenuList.push(item);
                  }
                } else {
                  keys.forEach(element => {
                    if (item.title === element) {
                      if (rolePermissions[element] !== "NoView") {
                        neWmenuList.push(item);
                      }
                    }
                  });
                }
              });
            }
            await this.setState({ menuList: neWmenuList });
          }
        }
      }).catch((err) => {
        return err
      })
    }
  }

  render() {
    const { changeToDark, changeToLight, changeMobileSidebarVisibility, sidebar } = this.props;
    const sidebarClass = classNames({
      sidebar: true,
      'sidebar--show': sidebar.show,
      'sidebar--collapse': sidebar.collapse,
    });

    return (
      <div className={sidebarClass}>
        <button className="sidebar__back" type="button" onClick={changeMobileSidebarVisibility} />
        <Scrollbar
          damping={1}
          thumbMinSize={20}
          className="sidebar__scroll scroll">
          <div className="sidebar__wrapper sidebar__wrapper--desktop">
            <SidebarContent
              onClick={() => { }}
              changeToDark={changeToDark}
              changeToLight={changeToLight}
              menuList={this.state.menuList}
              sidebar={this.props.sidebar}
            />
          </div>
          <div className="sidebar__wrapper sidebar__wrapper--mobile">
            <SidebarContent
              onClick={changeMobileSidebarVisibility}
              changeToDark={changeToDark}
              changeToLight={changeToLight}
              menuList={this.state.menuList}
            />
          </div>
        </Scrollbar>
      </div>
    );
  };
}

Sidebar.propTypes = {
  sidebar: SidebarProps.isRequired,
  changeToDark: PropTypes.func.isRequired,
  changeToLight: PropTypes.func.isRequired,
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
};