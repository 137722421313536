import config from "../../config/config";
import configMessages from "../../config/configMessages";
import dateFormats from "../UI/FormatDate/formatDate";
import { connect } from "react-redux";
const validate = (values, props) => {
  let fields = [];
  let tempFields = [];
  // get formFields from props and set validation  fields
  if (props.formFields) {
    let formFields = props.formFields();
    if (formFields && formFields.length > 0) {
      formFields.forEach((formField) => {
        if (formField.required) {
          tempFields.push(formField.name);
        }
      });
    }
    fields = tempFields;
  }
  const errors = {};
  let checkRegExpFieldsArray = [
    "aadharcardNumber",
    "from_email",
    "teacher_email",
    "email1",
    "email2",
    "pancardNumber",
    "principal_email",
    "pta_president_email",
    "email",
    "roomId",
    "companyEmail",
    "phoneNumber",
    "password",
    "phone",
    "displayName",
    "firstName",
    "lastName",
    "name",
    "companyName",
    "projectName",
    "teamName",
    "subject",
    "Main_Number",
    "website",
    "teacher_website",
    "pincode",
    "zipcode",
    "cheque",
    "Individual_dues",
    "Family_dues",
    "Teacher_dues"
  ];

  fields.forEach((field) => {

    if (!values[field]) {
      errors[field] = configMessages.fillField;
    } else if (checkRegExpFieldsArray.includes(field)) {
      let regex = config[field + "Regex"];
      if (field === "email") {
        regex = config["email" + "Regex"];
      }
      if (field === "principal_email") {
        regex = config["email" + "Regex"];
      }
      if (field === "from_email") {
        regex = config["email" + "Regex"];
      }
      if (field === "teacher_email") {
        regex = config["email" + "Regex"];
      }
      if (field === "email1") {
        regex = config["email" + "Regex"];
      }
      if (field === "email2") {
        regex = config["email" + "Regex"];
      }
      if (field === "pta_president_email") {
        regex = config["email" + "Regex"];
      }
      if (field === "phone" || field === "Main_Number") {
        regex = config["phoneNumber" + "Regex"];
      }
      if (field === "roomId") {
        regex = config["roomId" + "Regex"];
      }
      if (field === "subject") {
        regex = config["subject" + "Regex"];
      }
      if (field === "lastName") {
        regex = config["lastName" + "Regex"];
      }
      if (field === "website" || field === "teacher_website") {
        regex = config["website" + "Regex"];
      }
      if (field === "pincode" || field === "zipcode") {
        regex = config["pincode" + "Regex"];
      }
      if (field === "cheque") {
        regex = config["cheque" + "Regex"];
      } if (field === "Individual_dues" || field === "Family_dues" || field === "Teacher_dues") {
        regex = config["number" + "Regex"];
      }
      if (
        field === "displayName" ||
        field === "firstName" ||
        field === "name"
      ) {
        regex = config["userName" + "Regex"];
      }
      if (
        field === "companyName" ||
        field === "projectName" ||
        field === "teamName"
      ) {
        regex = config["company" + "Regex"];
      }
      if (
        values[field] &&
        typeof values[field] === "string" &&
        regex.test(values[field].trim()) === false
      ) {
        var ifFileds = [
          "companyName",
          "from_email",
          "teacher_email",
          "email1",
          "email2",
          "projectName",
          "principal_email",
          "roomId",
          "pta_president_email",
          "teamName",
          "companyEmail",
          "displayName",
          "firstName",
          "lastName",
          "name",
          "subject",
          "phone",
          "Main_Number",
          "pincode",
          "zipcode",
          "teacher_website",
          "cheque",
          "Individual_dues",
          "Family_dues",
          "Teacher_dues"
        ];
        if (ifFileds.indexOf(field) > -1) {
          if (
            field === "from_email" ||
            field === "companyEmail" ||
            field === "email2" ||
            field === "email1" ||
            field === "teacher_email" ||
            field === "principal_email" ||
            field === "pta_president_email"
          ) {
            errors[field] = configMessages["email"];
          }
          if (
            field === "displayName" ||
            field === "firstName" ||
            field === "lastName" ||
            field === "name"
            //|| field === "subject"
          ) {
            errors[field] = configMessages["userName"];
          }
          if (
            field === "companyName" ||
            field === "projectName" ||
            field === "teamName"
          ) {
            errors[field] = configMessages["companyName"];
          }
          if (field === "roomId") {
            errors[field] = configMessages["roomId"];
          }
          if (field === "phone" || field === "Main_Number") {
            errors[field] = configMessages["phoneNumber"];
          }
          if (field === "pincode" || field === "zipcode") {
            errors[field] = configMessages["pincode"];
          }
          if (field === "teacher_website") {
            errors[field] = configMessages["website"];
          }
          if (field === "cheque") {
            errors[field] = configMessages["cheque"];
          }
          if (field === "Individual_dues" || field === "Family_dues" || field === "Teacher_dues") {
            errors[field] = configMessages["number"];
          }
        } else {
          errors[field] = configMessages[field];
        }
      }
    }
  });
  if (values && values.toTime && values.fromTime) {
    let toTime = values.toTime._d;
    let fromTime = values.fromTime._d;
    if ((toTime && fromTime && toTime.getTime() - fromTime.getTime()) < 0) {
      errors.toTime = configMessages.toTimeFromTimeValidation;
    }
  }
  let todayDate = dateFormats.formatDate(new Date(), config.dateDayMonthFormat);
  let compare;
  if (values && values.dateOfBirth) {
    compare = dateFormats.datesComparisionBefore(todayDate, values.dateOfBirth);
    if (compare) {
      errors.dateOfBirth = configMessages.InvalidDate;
    }
  }

  if (values && values.endDate && values.startDate) {
    compare = dateFormats.datesComparisionBefore(
      values.endDate,
      values.startDate
    );
    if (compare) {
      errors["endDate"] = configMessages.InvalidDate;
    } else {
      errors["endDate"] = "";
    }
  }
  if (values && values.toDate && values.fromDate) {
    compare = dateFormats.datesComparisionBefore(
      values.toDate,
      values.fromDate
    );
    if (compare) {
      errors["toDate"] = configMessages.InvalidDate;
    } else {
      errors["toDate"] = "";
    }
  }
  if (values && values.joinDate) {
    if (values.dateOfBirth) {
      let years = dateFormats.differenceBetweenDatesInYears(
        values.joinDate,
        values.dateOfBirth
      );
      if (years < 15) {
        errors.joinDate = configMessages.InvalidDate;
      } else {
        errors.joinDate = "";
      }
    }
  }
  if (values && values.newPassword && values.confirmPassword) {
    if (values.newPassword !== values.confirmPassword) {
      errors.confirmPassword = configMessages.passwordMatchValidation;
    }
  }
  return errors;
};

export default validate;
