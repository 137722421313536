import React from "react";
import DataTables from "../../CommonDataTable/DataTable";
import store from "../../../App/store";
import config from "../../../../config/config";

export default class News extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      languageData: "",
      newletterCategoryOptions: "",
      newPublishOptions: "",
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState();
    let languageData,
      newletterCategoryOptions = [],
      newPublishOptions = [];
    if (
      storeData &&
      storeData.settingsData &&
      storeData.settingsData.settings
    ) {
      let tempStore = storeData.settingsData.settings;
      languageData = tempStore.languageTranslation
        ? tempStore.languageTranslation
        : "";
      newletterCategoryOptions =
        tempStore.news_category && tempStore.news_category.length > 0
          ? tempStore.news_category
          : [];

      newPublishOptions =
        tempStore.news_publish && tempStore.news_publish.length > 0
          ? tempStore.news_publish
          : [];
      state.newletterCategoryOptions = newletterCategoryOptions;
      state.newPublishOptions = newPublishOptions;
    }
    return {
      languageData: languageData,
    };
  }

  getTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: false,
      },
      // {
      //   textAlign: "center",
      //   width: 47,
      //   field: "Sno",
      //   header: "Sno",
      //   filter: false,
      //   sortable: false,
      //   placeholder: "Search",
      //   show: true,
      // },
      {
        show: true,
        textAlign: "left",
        width: 200,
        field: "school_name",
        mobile: true,
        header: "School Name",
        fieldType: "Link",
        filter: false,
        sortable: true,
        style: {
          color: config.templateColor,
          cursor: "pointer",
          textTransform: "capitalize",
        },
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        mobile: true,
        width: 150,
        field: "title",
        header: "Title",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 150,
        mobile: true,
        field: "news_category",
        header: "News Category",
        filter: false,
        sortable: true,
        textCapitalize: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 150,
        mobile: true,
        field: "news_publish",
        header: "Publish/Unpublish",
        filter: false,
        sortable: true,
        textCapitalize: true,
        displayInSettings: true,
      },
      // {
      //   show: true,
      //   textAlign: "center",
      //   width: 250,
      //   mobile: true,
      //   field: "description",
      //   header: "Description",
      //   filter: false,
      //   sortable: true,
      //   displayInSettings: true,
      // },
      {
        show: true,
        textAlign: "center",
        width: 70,
        field: "Actions",
        fieldType: "Actions",
        header: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
      },
    ];
    return data;
  };

  getFormFields = () => {
    var loginRole;
    let loginData = localStorage.getItem("loginCredentials");
    if (loginData) {
      loginData = JSON.parse(loginData);
      if (loginData && loginData.role) {
        loginRole = loginData.role;
      }
    }
    return [
      {
        show: loginRole == "schoolAdmin" ? false : true,
        required: true,
        value: "",
        searchField: "Name",
        searchApi: "schools",
        type: "autoComplete",
        options: [
          { school_name: "Name" },
          { district_id: "district_id" },
          { school_id: "school_id" },
        ],
        name: "school_name",
        label: "School Name",
        id: "Name",
        placeholder: "School Name",
      },
      {
        value: "",
        type: "text",
        name: "title",
        label: "Title",
        id: "title",
        placeholder: "Title",
        required: true,
      },
      // {
      //   value: "",
      //   type: "textarea",
      //   id: "description",
      //   required: true,
      //   placeholder: "Description",
      //   name: "description",
      //   label: "Description",
      // },
      {
        value: "",
        type: "dropDown",
        id: "newsCategory",
        required: true,
        placeholder: "Newsletter Category",
        name: "news_category",
        label: "Newsletter Category",
        options:
          this.state.newletterCategoryOptions &&
            this.state.newletterCategoryOptions.length > 0
            ? this.state.newletterCategoryOptions
            : [],
      },
      {
        value: "",
        type: "dropDown",
        id: "newsPublish",
        required: true,
        placeholder: "Publish/Unpublish",
        name: "news_publish",
        label: "Publish/Unpublish",
        options:
          this.state.newPublishOptions &&
            this.state.newPublishOptions.length > 0
            ? this.state.newPublishOptions
            : [],
      },
      {
        required: true,
        value: "",
        type: "date",
        name: "publicationDate",
        label: "Publication Date",
        id: "publicationDate",
        placeholder: "Publication Date",
      },
      {
        required: true,
        value: "",
        type: "ckeditor",
        name: "newsletterBody",
        label: "Newsletter Body",
        id: "newsletterBody",
        placeholder: "name",
      },
    ];
  };

  render() {
    return (
      <DataTables
        getTableFields={this.getTableFields}
        formFields={this.getFormFields}
        addRequried={true}
        editRequired={true}
        deleteRequired={true}
        viewRequired={true}
        settingsRequired={true}
        globalSearch={"School Name / Title"}
        exportToCsv={true}
        type="News"
        apiUrl={"news"}
        actionsTypes={actionsTypes}
      />
    );
  }
}

const actionsTypes = [
  {
    name: "Delete",
    options: [{ label: "Delete", value: "Delete", show: true, multiple: true }],
  },
];
