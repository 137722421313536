import React from "react";
import apiCalls from "../../../../config/apiCalls";
import DataTables from "../../CommonDataTable/DataTable";
import filePath from "../../../../config/configSampleFiles";

export default class Departments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      languageData: "",
    };
  }

  getTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: false,
      },
      // {
      //   show: true,
      //   textAlign: "center",
      //   width: 37,
      //   field: "Sno",
      //   header: "Sno",
      //   filter: false,
      //   sortable: false,
      //   placeholder: "Search",
      // },
      {
        show: true,
        textAlign: "left",
        fieldType: "Link",
        width: 160,
        field: "category_name",
        mobile: true,
        header: "Category Name",
        filter: false,
        sortable: true,
        textCapitalize: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 140,
        field: "role",
        mobile: true,
        header: "Role",
        filter: false,
        sortable: true,
        textCapitalize: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 160,
        field: "school_name",
        mobile: true,
        header: "School Name",
        filter: false,
        sortable: true,
        textCapitalize: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 80,
        field: "display_order",
        header: "Display Order",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 70,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
      },
    ];
    return data;
  };

  getFormFields = () => {
    return [
      {
        required: true,
        value: "",
        searchField: "name",
        searchApi: "districts",
        type: "autoComplete",
        name: "district_name",
        label: "School District",
        options: [
          { district_name: "name" },
          { district_id: "district_id" },
        ],
        id: "Name",
        placeholder: "School District",
      },
      {
        required: true,
        value: "",
        searchField: "Name",
        searchApi: "schools",
        type: "autoComplete",
        options: [
          { school_name: "Name" },
          { district_id: "district_id" },
          { school_id: "school_id" },
        ],
        name: "school_name",
        label: "School Name",
        id: "Name",
        placeholder: "School Name",
      }, {
        value: "",
        type: "text",
        name: "category_name",
        label: "Category Name",
        id: "category_name",
        placeholder: "Category Name",
        required: true,
      },
      // {
      //   value: "",
      //   type: "text",
      //   name: "role",
      //   label: "Role",
      //   id: "Role",
      //   placeholder: "Role",
      //   required: true,
      // },
      {
        value: "",
        type: "number",
        name: "display_order",
        label: "Display Order",
        id: "display_order",
        placeholder: "Display Order",
        required: true,
      },
    ];
  };

  render() {
    return (
      <DataTables
        getTableFields={this.getTableFields}
        formFields={this.getFormFields}
        addRequried={true}
        editRequired={true}
        deleteRequired={true}
        exportToCsv={true}
        viewRequired={true}
        sample={true}
        sampleFilePath={filePath.department}
        bulkApi={"department"}
        settingsRequired={true}
        globalSearch={"Category Name/Role/School Name"}
        type="Departments"
        apiUrl={apiCalls.Departments}
        actionsTypes={actionsTypes}
      />
    );
  }
}

const actionsTypes = [
  {
    name: "Delete",
    options: [{ label: "Delete", value: "Delete", show: true, multiple: true }],
  },
];
