import React from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import { withTranslation } from 'react-i18next';

class DeleteRowModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    deleteSelectedRow = () => {
        this.props.deleteSelectedRow(this.props.type);
    }

    render() {
        const { t, confirmModalText, type } = this.props
        return (
            <div>
                <Modal isOpen={this.props.openDeleteModal}
                    className='modal-dialog-centered modal-dialog--primary m-auto logout_modal_width'
                >
                    <ModalBody className='deleteModalBody'>
                        <div style={{ marginBottom: 10 }}>{t(confirmModalText)}?</div>
                        <Button color="primary" outline onClick={this.props.closeDeleteModal} className='deleteModalBtn marginRight'>{t('No')}</Button>
                        <Button color="primary" outline onClick={this.deleteSelectedRow}
                            className='deleteModalBtn'>{t('Yes')}</Button>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

export default withTranslation('common')(DeleteRowModal);