import React, { PureComponent } from "react";
import DatePicker from "react-datepicker";
import PropTypes from "prop-types";
import * as moment from "moment";

import config from "../../../config/config";
import { withTranslation } from "react-i18next";

class DatePickerField extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    meta: PropTypes.shape({
      touched: PropTypes.bool,
      error: PropTypes.string,
    }),
  };

  constructor(props) {
    super(props);
    this.state = {
      startDate: null,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount = () => {
    if (this.props.currentDate) {
      let date = this.props.currentDate ? this.props.currentDate : null;
      this.handleChange(date);
    }
  };

  handleChange(date) {
    const { onChange } = this.props.input;
    if (date) {
      this.setState({
        startDate: moment(date).utc().toDate(),
      });
      if(this.props.screen !== "Store" && this.props.screen !== "Products" ){
        if (this.props.input && (this.props.input.name === "availableTo" || this.props.input.name === "endDate")) {
          onChange(moment(date).format(config.datedbOnlyDateFormat));
        } else if (this.props.input && (this.props.input.name === "availableFrom" || this.props.input.name === "startDate")) {
          onChange(moment(date).format(config.atdbOnlyDateFormat));
        } else {
          onChange(moment(date).toDate());
        }
      }else{
        onChange(moment(date).toDate());
      }
    
    }
  }

  updateDatePickerValues = () => {
    this.setState({
      startDate: null,
    });
    const { onChange } = this.props.input;
    if (onChange) {
      onChange(null);
    }
  };

  render() {
    const { startDate } = this.state;
    const { meta, clear, t } = this.props;
    const item = this.props.item ?  this.props.item  : {};
    const { name, onChange, value } = this.props.input;
    if (
      (name === "startDate" || name === "endDate") &&
      this.props.minDate &&
      !(startDate && value)
    ) {
      onChange(moment(this.props.minDate).utc().format(config.dateDBFormat));
    }
    const date = startDate
      ? startDate
      : value
        ? moment(value).toDate()
        : this.props.makeValue
          ? moment(this.props.makeValue).toDate()
          : this.props.minDate
            ? this.props.minDate
            : null;

    return (
      <div className="date-picker mb-2">
        <DatePicker
          className="form__form-group-datepicker mb-2"
          selected={clear ? this.updateDatePickerValues() : date}
          onChange={this.handleChange}
          // dateFormat="MM-dd-yyyy"
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          scrollableYearDropdown
          yearDropdownItemNumber={20}
          dropDownMode="select"
          maxDate={this.props.maxDate}
          minDate={this.props.minDate}
          placeholderText="Select Date"
          dateFormat= {item.dateFormat ? item.dateFormat : "MM-dd-yyyy" } 
          showTimeInput={item.showTimeInput ? true : false}
          timeInputLabel="Time:"
        />
        <div style={{ height: "13px" }}>
          {meta.touched && meta.error && (
            <span className="form__form-group-error">{t(meta.error)}</span>
          )}
        </div>
        {/* {/ {meta.touched && meta.error && <span className="form__form-group-error">{meta.error}</span>} /} */}
      </div>
    );
  }
}

const renderDatePickerField = (props) => {
  const { input } = props;
  return <DatePickerField {...input} {...props} clear={props.clear} />;
};

renderDatePickerField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
};

export default withTranslation("common")(renderDatePickerField);
