import React from "react";
import GroupMembersDT from "./GroupMembersDT";
import store from "../../../App/store";
import { withTranslation } from "react-i18next";
import apiCalls from "../../../../config/apiCalls";
import config from "../../../../config/config";
import DataTables from "../../CommonDataTable/DataTable";

// config file
class GroupMembers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      languageData: "",
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState();
    let languageData =
      storeData &&
        storeData.settingsData &&
        storeData.settingsData.settings &&
        storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  }

  getTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: false,
      },
      // {
      //   show: true,
      //   textAlign: "center",
      //   width: 47,
      //   field: "Sno",
      //   header: "Sno",
      //   filter: false,
      //   sortable: false,
      //   placeholder: "Search",
      // },
      {
        show: true,
        textAlign: "center",
        width: 150,
        field: "lname",
        header: "Family",
        filter: false,
        sortable: false,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 150,
        field: "display_name",
        header: "Parent Name",
        filter: false,
        sortable: false,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 150,
        field: "email",
        header: "Email",
        filter: false,
        sortable: false,
        displayInSettings: true,
      },
    ];
    return data;
  };

  render() {
    return (
      <DataTables
        getTableFields={this.getTableFields}
        addRequried={false}
        editRequried={false}
        deleteRequried={false}
        viewRequried={false}
        globalSearch={"Family / Parent Name / Email"}
        showSchoolDropdown={true}
        showDependentDropdown={true}
        dependentUrl={apiCalls.Groups}
        responseDependentUrlName="members"
        type="Add Member"
        apiUrl={apiCalls.GroupMembers}
        criteria={criteria}
        actionsTypes={actionsTypes}
      />
    );
  }
}
const criteria = [{ key: "school_name", value: "", type: "eq" }];

const actionsTypes = [
  {
    name: "Add Member",
    options: [
      { label: "Add Member", value: "AddMember", show: true, multiple: true },
    ],
  },
];

export default withTranslation("common")(GroupMembers);
