import React from "react";
import { Modal, ModalBody, ModalHeader, Badge, Table, tbody } from "reactstrap";
import classNames from "classnames";
import Scrollbar from "react-smooth-scrollbar";
import config from "../../../config/config";
import fetchMethodRequest from "../../../config/service";
import Loader from "../../App/Loader";

export default class ViewModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rowData: {},
      label: "",
      status: "",
      type: null,
    };
  }

  componentDidMount = () => {
    this.props.onRef(this);
  };

  componentWillUnmount() {
    this.props.onRef(null);
  }

  getRowData = async (rowItem, url, viewQuery) => {
    await this.setState({
      isLoading: true,
    });
    let apiData = {};
    let apiUrl = `${url}/${rowItem._id}`;
    if (viewQuery) {
      apiUrl = `${apiUrl}?${viewQuery}`;
    }
    let method = "GET";

    let data = await fetchMethodRequest(method, apiUrl).then(async (resp) => {

      if (resp && resp.details) {
        let schoolsArray = [];
        if (
          resp &&
          resp.details
        ) {
          apiData = resp.details;
          if (resp.details.schools &&
            resp.details.schools.length > 0) {
            resp.details.schools.map((school) => {
              schoolsArray.push(school.Name);
            });
            apiData.schools = schoolsArray;
          }
          if (this.props.type == "District") {
            // let keys = ["name", "payment_types", "district_id", "preload", "schools", ""]
            // let labels = ["Name", "Payment Types", "District Code", "Preload", "Schools", "Paypal Client ID", "", "PayPal Client secret"]
            // let data = []
            // keys.forEach((key1, index) => {
            //   values = {
            //     label: labels[index],
            //     value: apiData[key1],
            //   };
            //   data.push(values);
            // });

            let types = resp.details;
            let paymetTypes = [];
            for (var obj of types.payment_types) {
              paymetTypes.push(obj.name)
            }
            resp.details.paymetTypes = paymetTypes;
            await this.setState({
              data: resp.details
            })
          }
        }

      } else {
        apiData = rowItem;
      }
      return apiData;
    });

    let keys = await Object.keys(data);
    let rowData = [];
    let values = {};
    keys.forEach(async (key) => {
      if (
        key !== "_id" &&
        key !== "__v" &&
        key !== "active" &&
        key !== "created" &&
        key !== "updated" &&
        key !== "color" &&
        key !== "createdBy" &&
        key !== "multipleImages" &&
        key !== "updatedBy" &&
        key !== "childIds" &&
        key !== "lang" &&
        key !== "password" && key !== "related_parent" &&
        key !== "salt" && key !== "status" &&
        key !== 'pwd_created' &&
        key !== 'pwd_updated' &&
        key !== 'last_login' &&
        key !== "studentFormFields" &&
        key !== "class_id" &&
        // key !== "childrens" &&
        // !key.includes("ObjId") &&
        key !== "paidEmails" &&
        key !== "step1" &&
        key !== "step2" &&
        key !== "step3" &&
        key !== "step2Data" &&
        key !== "step3Data" &&
        key !== "forgotPasswordExpireTimeStamp" &&
        key !== "language" &&
        key !== "image" &&
        key !== "templateText" &&
        key !== "listPreferences"

      ) {

        if (key == "separated_families" && this.props.type == "Family") {
          let label1, val1;
          // if (data[key]["family"]) {
          //   label1 = "separated families" + " family Name";
          //   val1 = data[key]["family"]["family"];
          //   if (val1 && label1) {
          //     values = {
          //       label: label1,
          //       value: val1,
          //     };
          //     rowData.push(values);
          //   }

          // }
          if (key == "separated_families") {
            let label2, value3;
            if (data[key] && data[key]["family"]) {

              const keys = Object.keys(data[key]["family"]);
              const Seperated_keys = keys.shift("_id")

              keys.forEach((key1, index) => {
                values = {
                  label: (key1 == "family" ? "Seperated Family Name" : "Seperated Family  " + `${key1}`),
                  value: data[key]["family"][key1],
                };
                rowData.push(values);

              });
            }

            label1 = "separated families" + " Email1";
            val1 = data[key]["family"]["email1"];

            // if (val1 && label1) {
            //   values = {
            //     label: label1,
            //     value: val1,
            //   };
            //   rowData.push(values);
            // }
            // }
          }
        }
        if (key == "childrens" && this.props.type == "Family") {
          let label1, val1, label2, val2;
          for (let i = 0; i < data[key].length; i++) {
            if (data[key][i] && data[key][i]["district_name"]) {
              label1 = "Student" + (i + 1).toString() + " District Name";
              val1 = data[key][i]["district_name"];
              if (val1 && label1) {
                values = {
                  label: label1,
                  value: val1,
                };
                rowData.push(values);
              }
            }
            if (data[key][i] && data[key][i]["school_name"]) {
              label2 = "Student" + (i + 1).toString() + " School Name";
              val2 = data[key][i]["school_name"];
              if (val2 && label2) {
                values = {
                  label: label2,
                  value: val2,
                };
                rowData.push(values);
              }
            }
            if (
              (data[key][i] && data[key][i]["student_firstname"]) ||
              (data[key][i] && data[key][i]["student_lastname"])
            ) {
              label2 = "Student" + (i + 1).toString() + " Name";
              if (data[key][i]["student_lastname"] != null) {
                val2 = data[key][i]["student_lastname"];
              }
              if (data[key][i]["student_firstname"] != null) {
                val2 = val2 + " " + data[key][i]["student_firstname"];
              }
              if (val2 && label2) {
                values = {
                  label: label2,
                  value: val2,
                };
                rowData.push(values);
              }
            }
            // if (data[key][i] && data[key][i]["student_lastname"]) {
            //   label2 = "Student" + (i + 1).toString() + " Last Name";
            //   val2 = data[key][i]["student_lastname"];
            //   if (val2 && label2) {
            //     values = {
            //       label: label2,
            //       value: val2,
            //     };
            //     rowData.push(values);
            //   }
            // }
          }
        }
        //}
        else {
          let label = key.charAt(0).toUpperCase() + key.slice(1);
          label = label.replace(/_/g, " ");
          let val = data[key];
          if (label != "Separated families") {
            if (val && label) {
              values = {
                label: label,
                value: val,
              };
              rowData.push(values);
            }
          }
        }
      }
    });
    await this.setState({
      rowData: this.props.type == "District" ? this.state.data : rowData,
      apiData: apiData,
      isLoading: false,
    });
  };

  flattenArray = (arrayVal) => {
    let val = "";
    if (arrayVal) {
      val = JSON.stringify(arrayVal);
      val = val
        .replace(/"/g, "")
        .replace(/\[/g, "")
        .replace(/]/g, "")
        .replace(/{/g, "")
        .replace(/}/g, "")
        .replace(/,/g, " , ")
        .replace(/:/g, " : ");
    }
    return val;
  };

  schoolsArray = (arrayVal) => {
    if (arrayVal) {
      return <ol style={{ listStyle: "none" }}>
        {arrayVal.map((item, i) => {
          return <li>{item}</li>
        })}
      </ol>
    }
  }

  render() {
    const modalClass = classNames({
      "modal-dialog--colored": this.state.colored,
      "modal-dialog--header": this.state.header,
    });
    return (
      <div style={{ display: "flex" }}>
        <Loader loader={this.state.isLoading} />
        <Modal
          isOpen={this.props.openViewModal}
          className={` modal-dialog--primary modal-dialog--header ${modalClass}`}
        >
          <ModalHeader className="modal__header viewModalHeader">
            {this.props.type} Details
            <button
              className="lnr lnr-cross modal__close-btn"
              type="button"
              onClick={this.props.closeViewModal}
            />
          </ModalHeader>
          <ModalBody>

            {this.props.type !== "District" && this.props.type !== "Family" && this.state.rowData && this.state.rowData.length > 0 ? (
              <div className="row form">
                {this.state.rowData.map((item, i) => {
                  let val = item.value;
                  if (item.label == 'Related parent') {
                    val = val;
                  } if (item.label == 'Payment Types') {
                    val = this.flattenArray(item.value[0]);
                  } else if (typeof val == 'object') {
                    val = val;
                  } else {
                    val = this.flattenArray(item.value);
                  }
                  return val !== "" && val !== null && item.label !== "Sno" ? (
                    <div className="col-sm-6 " key={i}>
                      {
                        item.label == 'Related parent' ? <div className="row" style={{ margin: "auto" }}>
                          <div
                            className="col-sm-12"
                            style={{ textAlign: "left", padding: "5px 5px" }}
                          >
                            <span
                              style={{
                                fontWeight: "bold",
                                textTransform: "capitalize",
                              }}
                            >
                              {item.label}
                            </span>
                          </div>
                          <div className='col-sm-12 pb-2' >
                            {
                              val && val.length > 0 ? val.map((arrayItem, arrayIndex) => {
                                return arrayIndex === 0 && (
                                  <>
                                    <p>
                                      <span className='pr-2'
                                        style={{
                                          fontWeight: "bold",
                                          textTransform: "capitalize",
                                        }}
                                      >{`Email`}</span>
                                      <span>{`${arrayItem['email']}`}</span>
                                    </p>
                                    <p>
                                      <span className='pr-2'
                                        style={{
                                          fontWeight: "bold",
                                          textTransform: "capitalize",
                                        }}
                                      >{`Display Name`}</span>
                                      <span>{`${arrayItem['display_name']}`}</span>
                                    </p>
                                  </>
                                )
                              }) : null
                            }
                          </div>
                        </div> :
                          typeof val == 'object' ? <div className="row" style={{ margin: "auto" }}>
                            <div
                              className="col-sm-12"
                              style={{
                                textAlign: "left", padding: "5px 5px",
                              }}
                            >
                              <span
                                style={{
                                  fontWeight: "bold",
                                  textTransform: "capitalize",
                                  fontSize: 14
                                }}
                              >
                                {item.label}
                              </span>
                            </div>
                            <div className='col-sm-12' >
                              {
                                Object.entries(val).map(([key, value]) => {
                                  let k = val && val[0] ? "" : this.flattenArray(key);
                                  console.log(k)
                                  k = k.replace(/_/g, ' ')
                                  return (
                                    <div className='row'>
                                      <div
                                        className="col-sm-5"
                                        style={{ textAlign: "left", padding: "5px 5px" }}
                                      >
                                        <span
                                          style={{
                                            fontWeight: "bold",
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          {`${k}`}
                                        </span>
                                      </div>

                                      <div
                                        className="col-sm-7"
                                        style={{ textAlign: "left", padding: "5px 0px", wordBreak: 'break-all' }}
                                      >
                                        <span
                                        >
                                          {`${value}`}
                                        </span>
                                      </div>
                                    </div>

                                    // <p>
                                    //   <span className='pr-2'
                                    //     style={{
                                    //       fontWeight: "bold",
                                    //       textTransform: "capitalize",
                                    //     }}
                                    //   >{`${k}`}</span>
                                    //   <span>{`${value}`}</span>
                                    // </p>
                                  )
                                }
                                )
                              }
                            </div>
                          </div> : typeof val != 'array' ? <div className="row" style={{ margin: "auto" }}>
                            <div
                              className="col-sm-5"
                              style={{ textAlign: "left", padding: "5px 5px" }}
                            >
                              <span
                                style={{
                                  fontWeight: "bold",
                                  textTransform: "capitalize",
                                }}
                              >
                                {item.label}
                              </span>
                            </div>

                            <div
                              className="col-sm-7"
                              style={{ textAlign: "left", padding: "5px 0px", wordBreak: 'break-all' }}
                            >
                              <span>
                                {item.label.toLowerCase() === "role" ? (
                                  <Badge
                                    color={
                                      val === "schoolAdmin"
                                        ? "info"
                                        : val === "parent"
                                          ? "secondary"
                                          : val === "superAdmin"
                                            ? "success"
                                            : "info"
                                    }
                                    style={{
                                      padding: 5,
                                      fontSize: 11,
                                      fontWeight: "500",
                                      textTransform: "capitalize",
                                      color: "white",
                                    }}
                                  >
                                    {val}
                                  </Badge>
                                ) : item.label.toLowerCase() === "status" ? (
                                  <Badge
                                    pill
                                    color={
                                      val === "Active"
                                        ? "success"
                                        : val === "Pending"
                                          ? "danger"
                                          : val === "Inactive"
                                            ? "warning"
                                            : "info"
                                    }
                                    style={{
                                      padding: 5,
                                      fontSize: 11,
                                      fontWeight: "500",
                                      textTransform: "capitalize",
                                      color: "white",
                                    }}
                                  >
                                    {val}
                                  </Badge>
                                ) : (
                                  <span
                                    style={{
                                      textTransform:
                                        item.label.includes("mail") ||
                                          item.label.includes("website")
                                          ? "lowercase"
                                          : "capitalize",
                                    }}
                                  >
                                    {val}
                                  </span>
                                )}
                              </span>
                            </div>
                          </div> : null
                      }
                    </div>
                  ) : null;
                })}
              </div>
            ) :
              this.props.type !== "Family" ?
                <div className="col-sm-12 row">
                  <div className="col-sm-6">
                    <div className="col-12" >
                      <div className="row" style={{ margin: "auto" }}>
                        <div className="col-5 paddingRowDataCol">
                          <span className="fontWeight">Name</span>
                        </div>
                        <div className="col-7 paddingOfRowData">
                          <span> {this.flattenArray(this.state.rowData.name)}</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-12" >
                      <div className="row" style={{ margin: "auto" }}>
                        <div className="col-5 paddingRowDataCol">
                          <span className="fontWeight">District Code</span>
                        </div>
                        <div className="col-7 paddingOfRowData">
                          <span> {this.flattenArray(this.state.rowData.district_id)}</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-12" >
                      <div className="row" style={{ margin: "auto" }}>
                        <div className="col-5 paddingRowDataCol">
                          <span className="fontWeight">ZipCode</span>
                        </div>
                        <div className="col-7 paddingOfRowData">
                          <span> {this.flattenArray(this.state.rowData.pincode)}</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-12" >
                      <div className="row" style={{ margin: "auto" }}>
                        <div className="col-5 paddingRowDataCol">
                          <span className="fontWeight">Schools</span>
                        </div>
                        <div className="col-7 paddingOfRowData">
                          <span> {this.schoolsArray(this.state.rowData.schools)}</span>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div className="col-sm-6">
                    <div className="col-12" >
                      <div className="row" style={{ margin: "auto" }}>
                        <div className="col-5 paddingRowDataCol">
                          <span className="fontWeight">Payment Types</span>
                        </div>
                        <div className="col-7 paddingOfRowData">
                          <span> {this.flattenArray(this.state.rowData.paymetTypes)}</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-12" >
                      <div className="row" style={{ margin: "auto" }}>
                        <div className="col-5 paddingRowDataCol">
                          <span className="fontWeight">Preload</span>
                        </div>
                        <div className="col-7 paddingOfRowData">
                          <span> {this.flattenArray(this.state.rowData.preload)}</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-12" >
                      <div className="row" style={{ margin: "auto" }}>
                        <div className="col-5 paddingRowDataCol">
                          <span className="fontWeight">Paypal Client ID</span>
                        </div>
                        <div className="col-7 paddingOfRowData">
                          <span> {this.flattenArray(this.state.rowData.paypal_client_id)}</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-12" >
                      <div className="row" style={{ margin: "auto" }}>
                        <div className="col-5 paddingRowDataCol">
                          <span className="fontWeight">PayPal Client secret</span>
                        </div>
                        <div className="col-7 paddingOfRowData">
                          <span> {this.flattenArray(this.state.rowData.paypal_client_secret)}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                : null
            }
            {
              this.props.type === "Products" &&
                this.props.multipleLocationImage &&
                this.props.multipleLocationImage.length > 0 ? (
                <Scrollbar>
                  <div className="mindivForMultipleupload">
                    {this.props.multipleLocationImage &&
                      this.props.multipleLocationImage.length > 0
                      ? this.props.multipleLocationImage.map(
                        (imagLocationItem, imagLocationIndex) => (
                          <div className="col-md-2" style={{ padding: "20px" }}>
                            <div style={{ justifyContent: "center" }}>
                              <img
                                key={imagLocationIndex}
                                src={`${config.imgUrl}/product/${imagLocationItem["file"]}`}
                                className="detailsImgStyle"
                              />
                            </div>
                          </div>
                        )
                      )
                      : null}
                  </div>
                </Scrollbar>
              ) : null
            }

          </ModalBody>
        </Modal>
      </div>
    );
  }
}
