// import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Badge,
  Button,
  Row,
  Card,
  CardBody,
  Modal,
  ModalBody,
} from "reactstrap";
import moment from "moment";

import "../../../scss/dashboardStyles.css";
import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { CSVLink } from "react-csv";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { withTranslation } from "react-i18next";
import ExcelSheet from "./Excelreact"
//Modals
import ShowHideColumnsModal from "../CommonModals/ShowHideColumnsModal";
import FormModal from "../../Form/FormModal";
import FamilyFormModal from "../../Form/FamilyFormModal";
import AlertModal from "../CommonModals/AlertModal";
import DeleteRowModal from "../CommonModals/DeleteRowModal";
import ViewModal from "../CommonModals/viewModal";
import BulkUploadModal from "../CommonModals/BulkUploadModal";
import ConfirmationModal from "../CommonModals/ConfirmationModal";
import SessionExpiryModal from "../CommonModals/SessionexpiryModal";
import DivorceConfModal from "../CommonModals/DivorceConfirmationModal";
import UserPasswordResetModal from "../CommonModals/UserPasswordResetModal";
import PaymentApproveModal from "../CommonModals/PaymentApproveModal";
import FamilyViewModal from "../CommonModals/FamilyviewModal";
import PaymentsFormModal from "../CommonModals/PaymentsFormModal";
// fetch method for server call
import fetchMethodRequest from "../../../config/service";
import config from "../../../config/config";
import configMessages from "../../../config/configMessages";

// Toaster message
import showToasterMessage from "../../UI/ToasterMessage/toasterMessage";

// Date Formate
import dateFormats from "../../UI/FormatDate/formatDate";
//Loader
import Loader from "../../App/Loader";
//store
import store from "../../App/store";
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import PaginatorComponent from "./PaginatorComponent";

import { Redirect } from "react-router-dom";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

// tooltip styles
const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: config.templateColor,
    color: "#fff",
    boxShadow: theme.shadows[10],
    fontSize: 14,
    fontWeight: "500",
    margin: 0,
    textTransform: "capitalize",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}))(Tooltip);

const AnotherLightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: config.templateColor,
    color: "#fff",
    boxShadow: theme.shadows[10],
    fontSize: 14,
    fontWeight: "500",
    margin: 0,
  },
}))(Tooltip);

const defaultFilterCriteria = {
  limit: 15,
  page: 1,
  criteria: [],
  sortfield: "created",
  direction: "desc",
};

class DataTables extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allUsersData: [],
      deleteRowDataId: {},
      viewType: "list",
      tableFields: this.props.getTableFields(),
      originalTableFields: this.props.getTableFields(),
      tablefieldsToShow: this.props.getTableFields(),
      isOpenShowHideColumnsModal: false,
      first: 0,
      rows: 15,
      sortified: false,
      direction: "asc",
      selectedSchool: "Both",
      filterCriteria: {
        limit: 15,
        page: 1,
        criteria: [],
        sortfield: "created",
        direction: "desc",
      },
      selectedRows: "",
      selectedRowsId: "",
      totalRecords: [],
      exportData: [],
      isOpenFormModal: false,
      openBulkUploadModal: false,
      openViewModal: false,
      isLoading: false,
      loginRole: "Admin",
      startDate: "",
      endDate: "",
      apiUrl: this.props.apiUrl,
      totalRecordsLength: 0,
      tabType: this.props.tabType,
      sessionExpiryModal: false,
      mobileListFields: [],
      dateFormat: "",
      selectschool: "",
      roleType: "",
      committeNames: [],
      isOpenFamilyFormModal: false,
      openId: 1,
      openDeleteModal: false,
      displayViewOfForm: this.props.displayViewOfForm,
      selectActions: "",
      displayBreadCrumbField: "",
      displayBreadCrumbValue: "",
      school_name: "",
      name: "",
      isDisplay: false,
      showInitialValue: true,
      noRecordsFound: false,
      familyRowData:null
    };
  }

  componentDidMount = async () => {
    await this.getUserPermissions();
    await this.checkSessionExpiration();
    await this.getTimeFormat();
    await this.getLoginRole();
    await this.getActions();
    await this.getSettingsData()
    await this.sendColumnDataToServer("", "GET");
    if (this.props.paymentMode) {
      await this.setState({
        payment: this.props.paymentMode[0]["options"][0]["value"],
      });
    }

    if (
      this.props.type === "Family Data" ||
      this.props.type === "Non-Registered Families" ||
      this.props.type === "Last Login in the last 30 Days Report" ||
      this.props.showSchoolDropdown
    ) {
      let roleOptions = this.state.committeNames;
      let filterCriteria = Object.assign({}, defaultFilterCriteria);
      let criteria, apiUrl;
      if (this.props.type === "Add Member" || this.props.type === "View and Delete Member" || this.props.type === "Groups") {
        apiUrl = "schools?isGroup=true"
      } else {
        apiUrl = "schools"
      }
      await fetchMethodRequest("GET", apiUrl).then(async (response) => {
        if (response) {
          let states = response["schools"];
          if (states && states.length > 0) {
            for (let i = 0; i < states.length; i++) {
              if (i == 0 && this.props.showAllDropdownValue) {
                roleOptions.push({
                  label: "All",
                  value: "All",
                });
              }
              roleOptions.push({
                label: states[i]["Name"],
                value: states[i]["Name"],
              });
            }
          }
          setTimeout(()=>{
            this.setState({noRecordsFound : true})
          },400)
        }
      });

      let defaultSelectedValue;
      defaultSelectedValue =
        roleOptions && roleOptions.length > 0 ? roleOptions[0]["label"] : "";

      if (this.props.criteria && this.props.criteria.length > 0) {
        this.props.criteria[0]["value"] = defaultSelectedValue;
        await this.setState({
          school_name: defaultSelectedValue,
        });
      }
      if (this.props.showAllDropdownValue) {
        criteria = [];
      } else {
        criteria = this.props.criteria;
      }

      filterCriteria.criteria = criteria;
      if (this.props.showDependentDropdown) {
        await this.getDependentData(filterCriteria, "school_name", "yes");
      }
      await this.setState({
        committeNames: roleOptions,
        filterCriteria: filterCriteria,
        defaultFilterCriteria: filterCriteria,
      });
    }
    if (this.props.showDependentDropdown != true)
      await this.getDataFromServer(this.state.filterCriteria);
  };

  getUserPermissions = async () => {
    let screenPermissions = localStorage.rolePermissions
      ? await JSON.parse(localStorage.rolePermissions)
      : null;
    await this.setState({
      screenPermissions: screenPermissions,
    });
  };

  componentWillUnmount() {
    // this.props.onRef(null);
  }

  checkSessionExpiration = async () => {
    let sessionexpired = await localStorage.getItem("sessionexpired");
    if (sessionexpired === "true") {
      await this.setState({ sessionExpiryModal: true });
    }
  };

  // Get global actions
  getActions = async () => { 
    let data = []
    if (this.props.type === "Orders" && this.state.selectedRows && typeof this.state.selectedRows !== "array") {
      data.push(this.state.selectedRows)
      await this.setState({
        selectedRows: data
      })
    }

    let selectedRows = this.state.selectedRows
      ? [...this.state.selectedRows]
      : [];
    let actionsTypes = this.props.actionsTypes
      ? [...this.props.actionsTypes]
      : [];
    let result = [];
    if (actionsTypes && actionsTypes.length) {
      for (let i = 0; i < actionsTypes.length; i++) {
        if (
          actionsTypes[i]["options"] &&
          actionsTypes[i]["options"].length > 0
        ) {
          let options = actionsTypes[i]["options"];
          for (let j = 0; j < options.length; j++) {
            if (options[j]["show"] === true) {
              if (
                selectedRows &&
                selectedRows.length >= 2 &&
                options[j]["multiple"] === true 
              ) {
                result.push({
                  label: options[j]["label"],
                  value: options[j]["value"],
                });
              } else if (selectedRows && selectedRows.length === 1) {
                result.push({
                  label: options[j]["label"],
                  value: options[j]["value"],
                });
              } else if (selectedRows && selectedRows.length === 0) {
                result.push({
                  label: options[j]["label"],
                  value: options[j]["value"],
                });
              }
            }
          }
        }
      }
    }
    await this.setState({
      actionsTypes: result,
    });
  };

  getSettingsData = async () => {
    fetchMethodRequest("GET", `settings`).then(async (response) => {
      if (response && response.respCode) {
        let settingsData = response.settings[0];
        if (settingsData && settingsData['i18n']) {
          await this.setState({
            actionsText: settingsData['i18n'],
          });
        }
      }

    });
  }
  getTimeFormat = () => {
    // let store = this.store.getState()
    // let dateFormat = store && store.commonData && store.commonData.data && store.commonData.data.timeFormat ? store.commonData.data.timeFormat : 'DD-MM-YYYY'
    // console.log('timeFormat', dateFormat)
  };

  // Handle Table fields to show in datatable
  getTableFieldsOrder() {
    this.setState({
      tablefieldsToShow: [],
      isLoading: true,
    });

    let colOrder = localStorage.getItem(`${this.props.type}_column_order`);
    let columns = JSON.parse(colOrder);
    let tempTableFields = [];
    let newTableFields = [];
    let staticTableFields = [...this.state.originalTableFields];

    if (columns) {
      for (let i = 0; i < columns.length; i++) {
        for (let j = 0; j < staticTableFields.length; j++) {
          if (columns[i].field === staticTableFields[j].field) {
            let pushItem = staticTableFields[j];
            pushItem.show = columns[i].show;
            pushItem.displayInSettings = columns[i].displayInSettings;
            tempTableFields.push(pushItem);
            break;
          }
        }
      }
      let colFields = columns.map((c) => c.field)
      for (let i = 0; i < staticTableFields.length; i++) {
        if (!colFields.includes(staticTableFields[i].field)) {
          let pushItem = staticTableFields[i];
          pushItem.show = true;
          pushItem.displayInSettings = true;// columns[i].displayInSettings;
          tempTableFields.push(pushItem);
          let colObj = {
            displayInSettings: true,
            field: staticTableFields[i].field,
            header: staticTableFields[i].header,
            show: true
          }
          columns.push(colObj);
          // break;
        }
      }
      localStorage.setItem(
        `${this.props.type}_column_order`,
        JSON.stringify(columns)
      );

    } else {
      tempTableFields = this.state.originalTableFields;
    }

    newTableFields = tempTableFields;

    let TableFieldsToShow = [];
    if (newTableFields && newTableFields.length > 0) {
      newTableFields.map((item) => {
        if (item.show) {
          TableFieldsToShow.push(item);
        }
      });
    }

    this.setState({
      tablefieldsToShow: TableFieldsToShow,
      isLoading: false,
    });
    // if (dateFormat) {
    //   self.setState({
    //     dateFormat: dateFormat
    //   })
    // }
  }

  // Handle Table fields order of display in DataTable
  updateColumnsOrder(currentOrder) {
    this.setState({
      isLoading: true,
    });
    let originalTableFields = [...this.state.originalTableFields];
    let order = currentOrder ? currentOrder : null;
    let updatedOrder = [];
    let unmatchedTableFields = [...this.state.originalTableFields];

    if (order && order.length > 0) {
      order.map(async (item) => {
        if (item && item.props && item.props.field) {
          originalTableFields.map((col) => {
            if (item.props.field === col.field) {
              updatedOrder.push({
                field: col.field,
                show: col.show,
                header: col.header,
                displayInSettings: col.displayInSettings,
              });
            }
          });
        }
      });

      order.map(async (item) => {
        if (item && item.props && item.props.field) {
          for (let i = 0; i < unmatchedTableFields.length; i++) {
            if (item.props.field === unmatchedTableFields[i].field) {
              unmatchedTableFields.splice(i, 1);
            }
          }
        }
      });

      if (unmatchedTableFields && unmatchedTableFields.length > 0) {
        unmatchedTableFields.map((col) => {
          updatedOrder.push({
            field: col.field,
            show: false,
            header: col.header,
            displayInSettings: col.displayInSettings,
          });
        });
      }
    } else {
      originalTableFields.map(async (col) => {
        updatedOrder.push({
          field: col.field,
          show: col.show,
          header: col.header,
          displayInSettings: col.displayInSettings,
        });
      });
    }

    localStorage.setItem(
      `${this.props.type}_column_order`,
      JSON.stringify(updatedOrder)
    );
    this.getTableFieldsOrder(this.state.originalTableFields);
  }

  static getDerivedStateFromProps(props, state) {
    let self = this;
    let storeData = store.getState();
    let dateFormat =
      storeData &&
        storeData.settingsData &&
        storeData.settingsData.settings &&
        storeData.settingsData.settings.dateFormat
        ? storeData.settingsData.settings.dateFormat
        : "MM-DD-YYYY";
    // console.log('timeFormat', dateFormat);

    if (state.dateFormat !== dateFormat) {
      return { dateFormat: dateFormat };
    }
    return { dateFormat: dateFormat };
  }

  getLoginRole() {
    let loginData = localStorage.getItem("loginCredentials");
    if (loginData) {
      loginData = JSON.parse(loginData);
      if (loginData && loginData.role) {
        this.setState({
          loginRole: loginData.role,
          loginData: loginData,
        });
      }
    }
  }

  updateSlNoToData(itemdata) {
    let modifiedData = [];

    itemdata.forEach((item, index) => {
      if (this.state.first) {
        item.Sno = index + 1 + this.state.first;
      } else {
        item.Sno = index + 1;
      }

      modifiedData.push(item);
    });
    return modifiedData;
  }

  updateDateFormat(itemdata, dateFormat) {
    let modifiedData = [];
    for (let i = 0; i < itemdata.length; i++) {
      for (let k = 0; k < this.state.tablefieldsToShow.length; k++) {
        if ("Date" === this.state.tablefieldsToShow[k]["fieldType"]) {
          itemdata[i][
            this.state.tablefieldsToShow[k]["field"]
          ] = dateFormats.formatDate(
            itemdata[i][this.state.tablefieldsToShow[k]["field"]],
            dateFormat,
            this.state.tablefieldsToShow[k]["withoutTimeoffset"] ? true : false
          );
        } else if ("Time" === this.state.tablefieldsToShow[k]["fieldType"]) {
          let val = itemdata[i][this.state.tablefieldsToShow[k]["field"]];
          let time = moment(val).utc();
          let dd = dateFormats.formatDate(time, "h:mm a");
          itemdata[i][this.state.tablefieldsToShow[k]["field"]] = dd;
        }
      }
      modifiedData.push(itemdata[i]);
    }
    return modifiedData;
  }

  getFilterPageNumber = async (pageNumber, details) => {
    if (details && details !== undefined) {
      let filterCriteria = Object.assign({}, this.state.filterCriteria);
      if (pageNumber != 0 && filterCriteria["page"] != 1 && details.length == 0) {
        let page = filterCriteria["page"];
        let limit = filterCriteria["limit"];
        let first = this.state.first;
        page = page - 1;
        first = limit - first;
        filterCriteria["page"] = page;
        await this.setState({
          filterCriteria: { ...filterCriteria },
          first: first,
        });
        await this.getDataFromServer(filterCriteria);
      }
    }
  };
  //get table list data from server with filters if any
  getDataFromServer = async (filter, type) => {
    console.log("TYPE",this.getAPIUrl());

    let url;
    if (
      type == "dependentDropdown" &&
      this.props.showAllDropdownValue == null
    ) {
      url = this.getAPIUrl() + `/${this.state.name}`;
    } else {
      url = this.getAPIUrl();
    }
    let filterCriteria = Object.assign({}, filter);
    if (url) {
      let apiUrl;
      await this.setState({
        isLoading: true,
        selectedRows: "",
      });
      if (type === "refresh") {
        // clearing of groups dropdown  in add members screen
        if(this.props.type === "Add Member"){
          this.onActionsChange({target:{name:"school_name",value: this.state.committeNames[0]["label"]}}, "school_name")

        }

        // clearing school and grade dropdown values
        if (this.props.type === "Add Member" || this.props.type === "View and Delete Member" || this.props.type === "Groups") {
          this.state.school_name = null;
          this.state.name = null;
        }

        filterCriteria = Object.assign({}, defaultFilterCriteria);
        let individualFilters = document.getElementsByClassName(
          "p-column-filter"
        );
        if (individualFilters && individualFilters.length > 0) {
          for (let i = 0; i < individualFilters.length; i++) {
            individualFilters[i].value = "";
          }
        }
        if (
          document.getElementById("globalSearch") &&
          document.getElementById("globalSearch").value
        ) {
          document.getElementById("globalSearch").value = "";
        }
        this.setState({
          allUsersData: [],
          status: "",
          selectschool: "",
          roleType: "",
          page: 1,
          first: 0,
          filterCriteria: filterCriteria,
          rows: 15,
          selectedSchool: "Both",
          selectedStore: ""
        });
      } else if (
        filterCriteria === undefined ||
        !filterCriteria ||
        filterCriteria === {}
      ) {
        filterCriteria = Object.assign({}, defaultFilterCriteria);
      } else if (!filterCriteria["criteria"]) {
        filterCriteria["criteria"] = [];
      } else {
        filterCriteria = filterCriteria;
      }
      if (
        this.props.groupEmailRequired ||
        this.props.groupEmailTemplateRequired ||
        this.props.rolesRequired
      ) {
        if (this.props.criteria) {
          filterCriteria["criteria"] = filterCriteria["criteria"]
            ? filterCriteria["criteria"]
            : [];
          filterCriteria["criteria"].push(this.props.criteria);
        }
      }
      if (url && filterCriteria) {
        apiUrl = `${url}?filter=${JSON.stringify(filterCriteria)}`;
      }
      if (this.state.selectedStore) {
        apiUrl = `${apiUrl}&storeId=${this.state.selectedStore}`
      }

      if (this.state.selectedSchool && this.state.selectedSchool !== "Both") {
        apiUrl = `${apiUrl}&foundation_filter=${this.state.selectedSchool}`
      }


      return fetchMethodRequest("GET", apiUrl)
        .then(async (response) => {
          await this.checkSessionExpiration();
          let responseData = [],
            totalRecordsLength = this.state.totalRecordsLength;

          if (response && response.details && response.details.length > 0) {
            responseData = response.details;
            totalRecordsLength = response.details.length;
          } else {
            if (
              this.props.showDependentDropdown &&
              type == "dependentDropdown"
            ) {
              url = "members";
            }
            if (url === "orders/orders_by_class" || url === "orders/orders_by_grade" || url === "orders/orders_by_store" || url === "orders/orders_summary_report" || url === "orders/orders_by_products" || url === "orders/orders_by_store_products") {
              url = "orders"
            }
            if (url === "carts/get_pending_cart") {
              url = "carts"
            }
            if (url === "orders/cancelled_products") {
              url = "refundDetails"
            }
            if(this.getAPIUrl() == 'stores' ||this.getAPIUrl() == 'products'){
              let storeKey = this.getAPIUrl() == 'stores' ? "isCopiedStore" : "isCopiedProduct";
              response[storeKey] = response[url].map(d=>{
                if(!d[storeKey]) {
                  d[storeKey] = "False";
                  return d;
                }
                else{
                  d[storeKey] = "True";
                  return d;
                };
              })
              console.log("RESPONSONE",response[url]);
            }
            if (
              response &&
              response[url] &&
              response[url].length &&
              response[url].length >= 0
            ) {
              if (response.pagination && response.pagination.totalCount) {
                totalRecordsLength = response.pagination.totalCount;
              }

              if (
                this.props.paymentMode &&
                response.totals &&
                response.totals.length > 0
              ) {
                let selectedPaymentsData;
                for (let i = 0; i < response.totals.length; i++) {
                  if (
                    response.totals[i]["_id"] &&
                    response.totals[i]["_id"] == this.state.payment
                  ) {
                    selectedPaymentsData = response.totals[i];
                  }
                }
                await this.setState({
                  selectedPaymentsData: selectedPaymentsData,
                  totalPaymentsData: response.totals,
                });
              }
              responseData = this.updateSlNoToData(response[url]);
              responseData = this.updateDateFormat(
                responseData,
                this.state.dateFormat
              );
            } else {
              if (
                response.pagination &&
                (response.pagination.totalCount ||
                  response.pagination.totalCount === 0)
              ) {
                totalRecordsLength = response.pagination.totalCount;
              } else if (response){
                totalRecordsLength = response.orders ? response.orders.length: response.carts ? response.carts.length : response.refundDetails ? response.refundDetails.length:null
                // if (response) {
                //   if (response.orders) {
                //     totalRecordsLength = response.orders.length;
                //   } else if (response.carts) {
                //     totalRecordsLength = response.carts.length;
                //   } else if (response.refundDetails) {
                //     totalRecordsLength = response.refundDetails.length;
                //   }
                // }
              }
              this.getFilterPageNumber(this.state.page, response[url]);
            }
          }
          await this.setState({
            allUsersData: responseData,
            isLoading: false,
            totalRecordsLength: totalRecordsLength,
            filterCriteria: filterCriteria,
          });
          return responseData;
        })
        .catch((err) => {
          return err;
        });
    }
  };

  getAPIUrl() {
    return this.props.apiUrl;
  }

  getFilterUrl(filterCriteria, type) {
    return this.props.apiUrl;
  }


  generateExcel(list = [], header = []) {
    let namesArrays = this.state.allUsersData.split("}").map(u => [u])
    // let namesArrays = this.state.allUsersData

    header = this.state.headerAddress

    //Create workbook and worksheet
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Names');
    let worksheet1 = workbook.addWorksheet('Names1')
    // add new rows and return them as array of row objects
    const secondRows = worksheet1.addRows(namesArrays)
    worksheet1.state = 'hidden'
    //Add Header Row
    let headerRow = worksheet.addRow(header);

    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFFF00' },
        bgColor: { argb: '#FFFFFF' }
      }
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    })
    for (let i = 1; i <= 50; i++) {
      worksheet.getColumn(i).width = 30;
    }

    //Generate Excel File with given name
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'Address.xlsx');
    })

  }

  //Get all data of current screen with filters applied from server to export to CSV
  getDataToExport = async () => {
    this.setState({ isLoading: true });
    //let filterCriteria = this.state.filterCriteria;
    this.state.filterCriteria['limit'] = 1000;
    let filterCriteria = this.state.filterCriteria;
     delete filterCriteria['page'];
     delete filterCriteria['limit'];
    let url = this.getAPIUrl();


    let apiUrl;
    apiUrl = `${this.state.apiUrl}?filter=${JSON.stringify(
      filterCriteria
    )}&type=exportToCsv`;

    if (this.props.type === "Email Templates") {
      apiUrl += `&screen=EmailTemplatesCsv`;
    } else if (this.props.type === "Group Email") {
      apiUrl += `&screen=GroupEmailCsv`;
    }

    if (this.state.selectedSchool && this.state.selectedSchool !== "Both") {
      apiUrl = `${apiUrl}&foundation_filter=${this.state.selectedSchool}`
    }

    if (url === "ecommercepayments") {
      apiUrl = `${apiUrl}&limit=No Limit`
    }
    return fetchMethodRequest("GET", apiUrl)
      .then(async (response) => {
        await this.checkSessionExpiration();
        if (url === "orders/orders_by_class" || url === "orders/orders_by_grade" || url === "orders/orders_by_store" || url === "orders/orders_summary_report" || url === "orders/orders_by_products" || url === "orders/orders_by_store_products") {
          url = "orders"
        }
        if(url == 'carts/get_pending_cart') url = 'carts';
        if(url == 'orders/cancelled_products') url = 'refundDetails';
        if (response && response[url]) {
          let exportData = response[url];
          exportData.forEach((item) => {
            for (let key in item) {
              if (apiUrl.startsWith("orders/orders_summary_report") && key == "products") {
                delete item[key];
              }
              if (item[key] && item[key].name) {
                item[key] = item[key].name;
              }
              if (typeof item[key] == "object") {
                item[key] = this.flattenArray(item[key]);
              }
            }
          });
          this.setState(
            {
              exportData: exportData,
              isLoading: false,
            },
            () => {
              // click the CSVLink component to trigger the CSV download
              this.csvLinkRef.link.click();
            }
          );
          this.state.filterCriteria['limit'] = 15;
        }
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
        return err;
      });
  };

  //open Form modal
  openFormModal = async (type, rowData) => {
    if (this.props.type !== "Family") {
      await this.setState({
        formType: type,
        selectedRows: "",
        isOpenFormModal: true,
      });
      if (type === "edit") {
        await this.setState({
          selectedInfo: rowData,
        });
        if (this.state.isOpenFormModal && this.formModalRef) {
          await this.formModalRef.getRowData(
            rowData,
            "edit",
            this.props.apiUrl
          );
        }
      } else if (type === "copy") {
        if (this.state.isOpenFormModal && this.formModalRef) {
          await this.formModalRef.getRowData(
            rowData,
            "copy",
            this.props.apiUrl
          );
        }
      }
    } else {
      await this.setState({
        isOpenFamilyFormModal: true,
        formType: type,
        familyRowData:rowData
      });
      if (
        this.state.isOpenFamilyFormModal &&
        this.familyFormModalRef &&
        type === "edit"
      ) {
        this.familyFormModalRef.getRowData(rowData, this.props.apiUrl);
      }
    }
  };

  //close form modal
  closeFormModal = async (filter) => {
    await this.setState({
      isOpenFormModal: false,
      isOpenFamilyFormModal: false,
    });
    if (filter && filter.globalSearch && filter.globalSearch.value) {
      if (
        document.getElementById("globalSearch") &&
        document.getElementById("globalSearch").value === ""
      ) {
        document.getElementById("globalSearch").value = filter.globalSearch.value
      }

    }
    await this.getDataFromServer(this.state.filterCriteria)
    // await this.getDataFromServer(
    //   this.props.showSchoolDropdown ? this.state.filterCriteria : filter
    // );
  };
  getExcelSheets() {
    return (
      <ExcelSheet
        order={this.state.orderforArchievedStore}
        products={this.state.productsforArchievedStore}
        archievedStore={this.state.archievedStoreDetails}
      />
    )
  }
  submitActionsData = async (method, url, body) => {
    return fetchMethodRequest(method, url, body)
      .then(async (response) => {
        await this.checkSessionExpiration();
        await this.setState({
          openDeleteModal: false,
          actions: "",
          selectedRows: "",
          selectedRowsId: "",
          deleteRowDataId: "",
        });
        var headers2 = [], headers1 = [], headers3 = []
        if (response) {
          if (response && response.respCode) {
            if (this.props.type === "Store" && url === "stores/archiveStore") {
              var dataSet2 = response.response.orders ? response.response.orders : []
              var keys2 = dataSet2 && dataSet2.length > 0 ? Object.keys(dataSet2[0]) : []
              var dataSet1 = response.response.products ? response.response.products : []
              var keys1 = dataSet2 && dataSet1.length > 0 ? Object.keys(dataSet1[0]) : []
              if (keys2.length > 0) {
                keys2.push("products_0_product")
              }
              for (var item2 of dataSet2) {
                if (item2["products"]) {
                  let data = []
                  for (var obj of item2["products"]) {
                    data.push(obj.product_name + "-" + obj.product_id)
                    item2["products_0_product"] = data
                  }
                }
                for (var key of keys2) {
                  if (typeof item2[key] === "object") {
                    if (typeof item2[key][0] === "string") {
                      item2[key] = item2[key][0].toString();
                    }
                  }
                }

              }
              for (var data of dataSet1) {
                for (var data2 of keys1) {
                  if (typeof data[data2] === "object") {
                    if (typeof data[data2][0] === "string") {
                      console.log("data", data[data2][0])
                      data[data2] = data[data2][0].toString();
                    }
                  }
                }
              }

              for (var item2 of keys2) {
                if (typeof dataSet2[0][item2] !== "object") {
                  headers2.push(item2)
                }
              }
              for (var item1 of keys1) {
                if (typeof dataSet1[0][item1] !== "object") {
                  headers1.push(item1)
                }
              }
              console.log("kjsadhhvc", headers3, dataset3)

              var keys3 = Object.keys(response.response.store_details)
              var dataset3 = []
              dataset3.push(response.response.store_details)
              for (var item3 of keys3) {
                if (typeof dataset3[0][item3] !== "object") {
                  headers3.push(item3)
                }
              }
              console.log("headers3", headers3, dataset3)

              await this.setState({
                productsforArchievedStore: response.response.products,
                orderforArchievedStore: response.response.orders,
                archievedStoreDetails: dataset3,
                headers2: headers2,
                headers1: headers1,
                headers3: headers3
              })
              document.getElementsByClassName("excel_download")[0].click()
              // }
            }
            showToasterMessage(response.respMessage, "success");

            this.getDataFromServer(
              this.state.filterCriteria,
              this.props.showDependentDropdown ? "dependentDropdown" : ""
            );
          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, "error");
          }
        }
      })
      .catch((err) => {
        return err;
      });
  };

  handleActions = async (apiType) => {
    let apiUrl = this.state.apiUrl;
    let method,
      url,
      body = {};
    if (this.state.confirmType === "Delete") {
      if (this.props.showDependentDropdown) {
        url = `${apiUrl}/multiDelete`;
        method = "POST";
        body = {
          selectedIds: this.state.selectedRowsId,
        };
      } else {
        method = "POST";
        url = `${apiUrl}/${apiType}`;
        body = {
          selectedIds: this.state.selectedRowsId,
        };
      }
    } else if (this.state.confirmType === "SingleDelete") {
      let id = this.state.deleteRowDataId;
      method = "DELETE";
      // url = `${apiUrl}/${id}`;
      url = `${apiUrl=='carts/get_pending_cart'? 'carts' : apiUrl}/${id}`;
    } else if (
      this.state.confirmType === "Block" ||
      this.state.confirmType === "UnBlock"
    ) {
      url = `${apiUrl}/${apiType}`;
      method = "POST";
      body = {
        selectedIds: this.state.selectedRowsId,
      };
    } else if (this.state.confirmType === "Invite") {
      url = `${apiType}`;
      method = "POST";
      body = {
        selectedIds: this.state.selectedRowsId,
        entityType: this.props.entityType,
      };
    } else if (this.state.confirmType === "Delivered" || this.state.confirmType === "Cancelled" || this.state.confirmType === "Returned" || this.state.confirmType === "Exchanged") {
      url = `${apiType}`;
      method = "POST";
      body = {
        "order_id": this.state.selectedRowsId[0],
        "status": this.state.confirmType

      };
    } else if (this.state.confirmType === "Archive") {
      url = `${apiUrl}/${apiType}`;
      method = "POST";
      body = {
        archiveStoreId: this.state.selectedRowsId,
        // entityType: this.props.entityType,
      };
    } else if (this.state.confirmType === "AddMember") {
      url = `${apiType}`;
      method = "POST";
      body = {
        members: this.state.selectedRows,
      };
    } else if (
      this.state.confirmType === "Active" ||
      this.state.confirmType === "Inactive"
    ) {
      url = `${apiUrl}/${apiType}`;
      method = "POST";
      body = {
        selectedIds: this.state.selectedRowsId,
        status: this.state.confirmType,
      };
      if (
        this.props.sendFailures != null &&
        this.state.confirmType === "Active"
      ) {
        body["failures"] = 0;
      }
    } else if (apiType != "Family" && this.state.confirmType === "Divorce") {
      let userEmail = this.state.selectedRows[0].email;
      method = "POST";
      url = `${apiUrl}/${apiType}`;
      body = { email: userEmail };
    } else if (this.state.confirmType === "ResetFamily") {
      method = "POST";
      url = `${apiUrl}/${apiType}`;
      body = {
        selectedIds: this.state.selectedRowsId,
      };
    }
    // else if (this.state.confirmType === "Divorse") {
    //   method = "POST";
    //   url = `${apiUrl}/${apiType}`;
    //   body = {
    //     selectedIds: this.state.selectedRowsId,
    //   };
    // }
    else if (this.state.confirmType === "ResetFaculty") {
      method = "POST";
      url = `${apiUrl}/${apiType}`;
      body = {
        selectedIds: this.state.selectedRowsId,
      };
    } else if (apiType == 'Family') {
      method = "POST";
      url = `${apiUrl}/divorce`;
      body = {
        selectedIds: this.state.selectedRowsId,
      };
    }
    else if (this.state.confirmType === "Separated") {
      method = "POST";
      url = `${apiUrl}/${apiType}`;
      body = {
        selectedIds: this.state.selectedRowsId,
      };
    }

    this.submitActionsData(method, url, body);
  };

  // Handle action on selected row
  deleteSelectedRow = async (type) => {
    if (this.state.confirmType === "Delete") {
      this.handleActions("multiDelete");
    } else if (this.state.confirmType === "SingleDelete") {
      this.handleActions();
    } else if (this.state.confirmType === "Block") {
      this.handleActions("multiBlock?block=true");
    } else if (this.state.confirmType === "Active") {
      this.handleActions("updateStatus");
    } else if (this.state.confirmType === "Inactive") {
      this.handleActions("updateStatus");
    } else if (this.state.confirmType === "UnBlock") {
      this.handleActions("multiBlock?unblock=true");
    } else if (this.state.confirmType === "Invite") {
      this.handleActions(`auth/invite`);
    } else if (this.state.confirmType === "AddMember") {
      this.handleActions(`groups/members/${this.state.name}`);
    } else if (type != 'Family' && this.state.confirmType === "Divorce") {
      this.handleActions("divorce");
    } else if (this.state.confirmType === "ResetFamily") {
      this.handleActions("multiResetUsers");
    } else if (type == 'Family' && this.state.confirmType === "Divorce") {
      this.handleActions(type);
    } else if (this.state.confirmType === "Separated") {
      this.handleActions("divorce");
    } else if (this.state.confirmType === "ResetFaculty") {
      this.handleActions("multiReset");
    } else if (this.state.confirmType === "Archive") {
      this.handleActions("archiveStore");
    } else if (this.state.confirmType === "Delivered" || this.state.confirmType === "Cancelled" || this.state.confirmType === "Returned" || this.state.confirmType === "Exchanged") {
      this.handleActions("orders/updateOrderStatus");
    } else if (this.state.confirmType === "ResetPassword") {
      await this.setState({
        openUserPasswordResetModal: true,
        openDeleteModal: false,
        selectedRows: this.state.selectedRows,
      });
    } else if (this.state.confirmType === "ApprovePayment") {
      await this.setState({
        openPaymentApproveModal: true,
        openDeleteModal: false,
        selectedRows: this.state.selectedRows,
      });
    }
  };

  //close delete modal
  closeDeleteModal = async () => {
    await this.setState({
      openDeleteModal: false,
      actions: "",
      deleteRowDataId: "",
    });
    await this.getDataFromServer(this.state.filterCriteria);
  };

  // conformation for delete item
  deleteConfirmAction = async (rowData, selectActions) => {
    let selectedRowsId = [];
    let selectedRows = [...this.state.selectedRows];
    if (rowData && selectActions === "Delete") {
      this.setState({
        deleteRowDataId: rowData,
        openDeleteModal: true,
        confirmType: "SingleDelete",
        confirmModalText: this.state.actionsText.ActiontoDelete,
      });
    } else if (selectedRows && selectedRows.length && selectedRows.length > 0) {
      selectedRows.forEach((item, index) => {
        selectedRowsId.push(item._id);
      });
      await this.setState({
        selectedRowsId: selectedRowsId,
        openDeleteModal: true,
        selectActions: selectActions,
      });
    }
  };

  //change dropdown elememt
  changeDropDownElement = (event) => {
    this.dt.filter(event.target.value, event.target.name, "equals");
    this.setState({
      [event.target.name]: event.value,
    });
  };

  getTableFieldItem(field) {
    for (let i = 0; i < this.state.tablefieldsToShow.length; i++) {
      if (this.state.tablefieldsToShow[i].field === field) {
        return this.state.tablefieldsToShow[i];
      }
      //condition to match fieldType
      if (this.state.tablefieldsToShow[i].fieldType == field) {
        return this.state.tablefieldsToShow[i]["field"];
      }
    }
    return null;
  }

  getColorFromOptions(options, name) {
    if (options) {
      for (let i = 0; i < options.length; i++) {
        // if (options[i].value === name) {
        if ((typeof (name) == "string" && options[i].value === name.toLowerCase()) || options[i].value === name) {
          return options[i].color;
        }
      }
    }
    return "success";
  }

  getTooltipFromOtptions(options, name) {
    if (options) {
      for (let i = 0; i < options.length; i++) {
        if (options[i].value === name) {
          return options[i].tooltip;
        }
      }
    }
    return "";
  }

  getDisplayTextFromOptions(options, name) {
    if (options) {
      for (let i = 0; i < options.length; i++) {
        if (options[i].value === name) {
          return options[i].displayText;
        }
      }
    }
    return "";
  }

  // openViewModal = async (rowData) => {
  //   let keys = Object.keys(rowData);
  //   let url = this.getAPIUrl();
  //   let formFields = [];
  //   if (formFields) {
  //     if (this.props.type) {
  //       if (rowData) {
  //         // let values, self = this;
  //         // hari get all the labels from
  //         // keys.forEach(async function (key) {
  //         //   let labelKey = await self.getTableFieldItem(key);
  //         //   if (labelKey === null) {
  //         //     labelKey = key;
  //         //   } else {
  //         //     labelKey = labelKey.header
  //         //     let val = rowData[key];
  //         //     if (val) {
  //         //       values = {
  //         //         label: labelKey,
  //         //         value: rowData[key]
  //         //       }
  //         //       formFields.push(values);
  //         //     }
  //         //   }
  //         // });
  //       }
  //     }
  //     await this.setState({
  //       openViewModal: true,
  //     });
  //     await this.viewModalRef.getRowData(rowData, url, this.props.viewQuery);
  //   }
  // };
  getUserDataById = async (item, type) => {
    let urlbyId;
    if (type == "Related view") {
      urlbyId = `${this.props.apiUrl}/${item["separated_families"]["family"]["_id"]}`
    } else {
      urlbyId = `${this.props.apiUrl}/${item["_id"]}`
    }

    fetchMethodRequest("GET", urlbyId, "")
      .then(async (res) => {
        if (res && res["details"]) {
          if (res["children"] && res["children"].length > 0) {
            res["details"]["children"] = res["children"];
          }
          this.openViewModal(res["details"], type);
        }
      })
      .catch((err) => {
        return err;
      });
  };
  getUserData = (_id, type) => {
    let data = this.state.allUsersData;
    for (let i = 0; i < data.length; i++) {
      if (data[i]["_id"] === _id) {
        return i;
      }
    }
  };
  getIconValue(rowData, labelKey) {
    if (labelKey && labelKey.options && labelKey.options.length > 0) {
      for (let i = 0; i < labelKey.options.length; i++) {
        if (labelKey.options[i].value === rowData[labelKey.field]) {
          return labelKey.options[i].displayText;
        }
      }
    }
    return "";
  }

  openViewModal = async (rowData, type) => {
    await this.setState({
      selectedInfo: rowData,
    });
    let url = this.getAPIUrl();
    let rowDataIndex = await this.getUserData(rowData["_id"], "_id");
    let _id = rowData["_id"];
    let status = rowData["status"] ? rowData["status"] : "";
    let keys = Object.keys(rowData);

    let formFields = [];
    if (formFields) {
      if (this.props.type) {
        if (rowData) {
          let values,
            self = this,
            objectFlag = false,
            icon = false,
            booleanBadge = false;

          // hari get all the labels from
          keys.forEach(async function (key) {
            if (
              key !== "_id" &&
              key !== "__v" &&
              key !== "active" &&
              key !== "created" &&
              key !== "updated" &&
              key !== "color" &&
              key !== "createdBy" &&
              key !== "multipleImages" &&
              key !== "updatedBy" &&
              key !== "pwd_created" &&
              key !== "pwd_updated" &&
              key !== "last_login" &&
              key !== "childIds" &&
              key !== "classObjId" &&
              key !== "lang" &&
              key !== "password" &&
              key !== "salt" &&
              key !== "studentFormFields" &&
              key !== "class_id" &&
              // key !== "childrens" &&
              // !key.includes("ObjId") &&
              key !== "paidEmails" &&
              key !== "ecommercePaymentId" &&
              key !== "step1" &&
              key !== "step2" &&
              key !== "step3" &&
              key !== "step2Data" &&
              key !== "step3Data" &&
              key !== "forgotPasswordExpireTimeStamp" &&
              key !== "language" &&
              key !== "image" &&
              key !== "templateText"
            ) {
              if (key == "childrens") {
                let label1, val1, label2, val2;
                for (let i = 0; i < rowData[key].length; i++) {
                  if (rowData[key][i] && rowData[key][i]["district_name"]) {
                    label1 = "Student" + (i + 1).toString() + " District Name";
                    val1 = rowData[key][i]["district_name"];
                    if (val1 && label1) {
                      values = {
                        label: label1,
                        value: val1,
                      };
                      formFields.push(values);
                    }
                  }
                  if (rowData[key][i] && rowData[key][i]["school_name"]) {
                    label2 = "Student" + (i + 1).toString() + " School Name";
                    val2 = rowData[key][i]["school_name"];
                    if (val2 && label2) {
                      values = {
                        label: label2,
                        value: val2,
                      };
                      formFields.push(values);
                    }
                  }
                  if (
                    (rowData[key][i] && rowData[key][i]["firstname"]) ||
                    (rowData[key][i] && rowData[key][i]["lastname"])
                  ) {
                    label2 = "Student" + (i + 1).toString() + " Name";
                    if (rowData[key][i]["lastname"] != null) {
                      val2 = rowData[key][i]["lastname"];
                    }
                    if (rowData[key][i]["firstname"] != null) {
                      val2 = val2 + " " + rowData[key][i]["firstname"];
                    }

                    if (val2 && label2) {
                      values = {
                        label: label2,
                        value: val2,
                      };
                      formFields.push(values);
                    }
                  }
                  // if (data[key][i] && data[key][i]["student_lastname"]) {
                  //   label2 = "Student" + (i + 1).toString() + " Last Name";
                  //   val2 = data[key][i]["student_lastname"];
                  //   if (val2 && label2) {
                  //     values = {
                  //       label: label2,
                  //       value: val2,
                  //     };
                  //     rowData.push(values);
                  //   }
                  // }
                }
              }
              if (key == "separated_families") {
                let label1, val1, label2, val2;
                if (rowData[key]["family"] && rowData[key]["family"]) {

                  label1 = "separated_families" + " family_Name";
                  val1 = rowData[key]["family"]["family"];

                  if (val1 && label1) {
                    values = {
                      label: label1,
                      value: val1,
                    };
                    formFields.push(values);
                  }

                }
              }
              else {
                let labelKey = key.charAt(0).toUpperCase() + key.slice(1);
                labelKey = labelKey.replace(/_/g, " ");
                if (labelKey == null) {
                  labelKey = key;
                } else {
                  let val = rowData[key];
                  if (labelKey.fieldType === "icon") {
                    val = self.getIconValue(rowData, labelKey);
                    icon = true;
                  }
                  if (labelKey.fieldType === "BooleanBadge") {
                    val = self.getIconValue(rowData, labelKey);
                    booleanBadge = true;
                  }
                  if (typeof val === "object") {
                    val = self.flattenArray(val);
                    objectFlag = true;
                  }
                  if (typeof val === "boolean") {
                    val = `${val}`;
                    objectFlag = true;
                  }
                  labelKey = labelKey;
                  if (val) {
                    values = {
                      label: labelKey,
                      value: objectFlag
                        ? val
                        : booleanBadge
                          ? val
                          : icon
                            ? val
                            : rowData[key],
                    };
                    formFields.push(values);
                    icon = false;
                    booleanBadge = false;
                    objectFlag = false;
                  }
                }
              }
            }
            // if (key == 'fromTime' || key == 'toTime') {
            //   let date = dateFormats.addDaysToDate(rowData[key], 1);
            //   date = dateFormats.formatDate(date, config.timeFormat);
            //   values = {
            //     label: key,
            //     value: date
            //   }
            //   formFields.push(values);
            // }
          });
        }
      }
      if (this.state.displayViewOfForm === "screen") {
        let displayBreadCrumbField = this.getTableFieldItem("Link");
        await this.setState({
          isOpenFormModal: true,
          formType: type,
          displayBreadCrumbValue: rowData[displayBreadCrumbField],
          displayBreadCrumbField: displayBreadCrumbField,
          userStatus: status,
        });
        if (this.state.isOpenFormModal && this.formModalRef) {
          await this.formModalRef.getViewData(
            formFields,
            type,
            rowDataIndex,
            rowData,
            _id
          );
        }

        // await this.viewModalRef.getRowData(formFields);
      } else {
        await this.setState({
          openViewModal: true,
        });
        if (this.props.type !== "Family") {
          await this.viewModalRef.getRowData(rowData, url, this.props.viewQuery);
        } else {
          await this.familyviewModalRef.getRowData(rowData, url, this.props.viewQuery);

        }
      }
    }
    await this.getDataFromServer(this.state.filterCriteria);
  };

  closeViewModal = async () => {
    this.setState({
      openViewModal: false,
    });
  };

  //on changing pagination
  onPageChange = async (event, filterCriteria) => {
    if (event && event.rows) {
      let currentPage = event.page + 1;
      filterCriteria["limit"] = event.rows;
      filterCriteria["page"] = currentPage;
      await this.setState({
        rows: event.rows,
        page: currentPage,
        first: event.first,
        pageCount: event.pageCount,
      });
    }
    this.getDataFromServer(filterCriteria, this.props.showDependentDropdown ? "dependentDropdown" : "");
  };

  onPageChangeAccordion = async (event) => {
    let filterCriteria = this.state.filterCriteria;
    let id = this.state.openId;
    if (event && event.rows) {
      let currentPage = event.page + 1;
      filterCriteria["limit"] = event.rows;
      filterCriteria["page"] = currentPage;
      this.setState({
        [`rows${id}`]: event.rows,
        page: event.page,
        [`first${id}`]: event.first,
      });
    }
    // this.getDataFromServer(filterCriteria)
  };

  //sorting fields
  sortChange = async (event) => {
    //Empty the search fields when sorted ||CodeByTej
    let individualFilters = document.getElementsByClassName("p-column-filter");
    if (individualFilters && individualFilters.length > 0) {
      for (let i = 0; i < individualFilters.length; i++) {
        individualFilters[i].value = "";
      }
    }
    if (
      document.getElementById("globalSearch") &&
      document.getElementById("globalSearch").value
    ) {
      document.getElementById("globalSearch").value = "";
    }

    let sortCount = this.state.sortCount;
    if (event && event["sortField"]) {
      sortCount = sortCount === 0 ? sortCount + 1 : 0;
      let sortField = event["sortField"];
      let filterCriteria = {
        direction: sortCount === 0 ? "desc" : "asc",
        sortfield: sortField,
        limit: this.state.rows,
        page: this.state.page ? this.state.page : 1,
      };
      await this.setState({
        sortCount: sortCount,
        filterCriteria: filterCriteria,
        selectedRows: "",
      });
      await this.getDataFromServer(filterCriteria);
    }
  };

  // on search get data from server
  onFilterChange = async (event, type) => {
    this.setState({
      isLoading: true,
      selectedRows: "",
      page: 1,
      first: 0,
      rows: 15,
    });
    if (type === "dropdownFilter") {
      await this.setState({
        [event.target.name]: event.target.value,
      });
    }
    let fieldName = "",
      filterCriteria = this.state.filterCriteria,
      selectedFilterValue,
      selectedFilter,
      selecterFilterType,
      formattedTime,
      formattedDate,
      isDateFilter = false;
    filterCriteria.page = 1
    if (event) {
      if (event.filters && !type) {
        if (Object.keys(event.filters) && Object.keys(event.filters)[0]) {
          fieldName = Object.keys(event.filters)[0];
        }
        let field = event.filters;
        selectedFilter = field[fieldName];
        if (
          fieldName === "date" ||
          fieldName === "created" ||
          fieldName === "updated"
        ) {
          isDateFilter = true;
          selectedFilterValue =
            selectedFilter &&
              selectedFilter.value &&
              selectedFilter.value.length === 10
              ? selectedFilter.value
              : null;
          let date = dateFormats.addDaysToDate(selectedFilter.value, 1);
          formattedDate = dateFormats.formatDate(
            date,
            config.dayYearDateFormat
          );
          selecterFilterType = "eq";
        } else {
          selecterFilterType = "regexOr";
          selectedFilterValue =
            selectedFilter && selectedFilter.value
              ? selectedFilter.value
              : null;
        }
      } else {
        fieldName = event.target.name;
        selectedFilterValue =
          event && event.target && event.target.value
            ? event.target.value
            : null;
        await this.setState({
          selectedFilterValue: selectedFilterValue
        })
      }
      if (type === "dropdownFilter") {
        selecterFilterType = "eq";
      }
      if (
        selectedFilterValue &&
        selectedFilterValue.length &&
        selectedFilterValue.length >= 3
      ) {
        if (fieldName === "createdBy") {
          fieldName = "createdBy.name";
        }
        if (fieldName === "phone") {
          fieldName = "phone";
        }
        if (fieldName === "globalSearch") {

          let dataType
          if (this.props.type === "Orders") {
            dataType = "order"
          } else if (this.props.type === "Order Summary Report") {
            dataType = "orderBySR"

          } else if (this.props.type === "Orders by Store") {
            dataType = "orderByStore"

          } else if (this.props.type === "Orders by Product") {
            dataType = "orderByProduct"

          } else if (this.props.type === "Orders by Store and Product") {
            dataType = "orderByStoreProduct"

          } else if (this.props.type === "Orders Summary By Grade") {
            dataType = "orderByGrade"

          } else if (this.props.type === "Pending Carts") {
            dataType = "pendingCart"

          } else if (this.props.type === "Orders Summary By Class") {
            dataType = "orderByClass"

          } else {
            dataType = "user"

          }
          filterCriteria.globalSearch = {
            value: selectedFilterValue,
            type: dataType,
          };
        } else {
          if (selecterFilterType === "gte") {
            let obj = filterCriteria.criteria.find((x) => x.key === fieldName);
            let index = filterCriteria.criteria.indexOf(obj);
            if (index !== -1) {
              filterCriteria["criteria"].splice(index, 1, {
                key: fieldName,
                value: formattedDate,
                type: "eq",
              });
            } else {
              filterCriteria["criteria"].push({
                key: fieldName,
                value: formattedDate,
                type: "eq",
              });
            }
          } else {
            if (fieldName === "updated") {
              fieldName = "updated.name";
            }
            if (
              (!filterCriteria["criteria"] ||
                filterCriteria["criteria"].length === 0) &&
              selecterFilterType !== "lte"
            ) {
              filterCriteria["criteria"] = [];
              filterCriteria["criteria"].push({
                key: fieldName,
                value: selectedFilterValue,
                type: selecterFilterType,
              });
            } else {
              let obj = filterCriteria.criteria.find(
                (x) => x.key === fieldName
              );
              let index = filterCriteria.criteria.indexOf(obj);
              if (selecterFilterType === "lte") {
                if (selectedFilterValue.length === 10) {
                  filterCriteria["criteria"].splice(0, 1, {
                    key: fieldName,
                    value:
                      selectedFilterValue.substring(6, 10) +
                      "-" +
                      selectedFilterValue.substring(3, 5) +
                      "-" +
                      selectedFilterValue.substring(0, 2) +
                      "T23:59:59Z",
                    type: selecterFilterType,
                  });
                }
              }
              if (index !== -1 && selecterFilterType !== "lte") {
                filterCriteria["criteria"].splice(index, 1, {
                  key: fieldName,
                  value: selectedFilterValue,
                  type: selecterFilterType,
                });
              } else if (selecterFilterType !== "lte") {
                filterCriteria["criteria"].push({
                  key: fieldName,
                  value: selectedFilterValue,
                  type: selecterFilterType,
                });
              }
            }
          }
          await this.setState({
            filterCriteria: filterCriteria,
          });
        }
        await this.getDataFromServer(
          this.state.filterCriteria,
          this.props.showDependentDropdown ? "dependentDropdown" : ""
        );
      } else {
        if (selectedFilterValue === null && !isDateFilter) {
          if (this.props.showSchoolDropdown != true) {
            let obj = filterCriteria.criteria.find((x) => x.key === fieldName);
            let index = filterCriteria.criteria.indexOf(obj);
            filterCriteria.criteria.splice(index, 1);
            if (fieldName === "globalSearch") {
              filterCriteria.globalSearch = {};
            }
            await this.setState({
              filterCriteria: filterCriteria,
            });
          } else {
            if (fieldName === "globalSearch") {
              filterCriteria.globalSearch = {};
            }
          }
          await this.getDataFromServer(
            filterCriteria,
            this.props.showDependentDropdown ? "dependentDropdown" : ""
          );
        }
      }
      this.setState({
        isLoading: false,
      });
    }
  };

  //open Bulk Modal
  bulkUploadMoadal() {
    this.setState({ openBulkUploadModal: true });
  }

  //close Bulk Modal
  closeBulkModal = async () => {
    await this.setState({
      openBulkUploadModal: false,
    });
    await this.getDataFromServer(this.state.filterCriteria);
  };

  //Set selected rows and get ActionTypes
  onSelectRowsUpdate = async (event) => {
    await this.setState({ selectedRows: event.value });
    await this.getActions();
  };

  //openConfirmationModal
  openConfirmationModal = async (rowData, status, type) => {
    this.setState(
      {
        isOpenFormModal: false,
      },
      async () => {
        this.setState({
          isOpenFormModal: false,
          openConfirmationModal: true,
          leaveStatus: status,
          item: rowData,
          confirmText: type,
        });
      }
    );
  };
  sendGroupEmail = async (item) => {
    localStorage.setItem("templateData", JSON.stringify(item));
    this.setState({
      isDisplay: true,
    });
  };
  closeConfirmationModal() {
    this.setState({
      openConfirmationModal: false,
    });
  }
  setFilterCriteriaForActivities = async (editRowDataID) => {
    let filterCriteria = this.state.filterCriteria;
    filterCriteria["criteria"].push({
      key: "contextId",
      value: editRowDataID,
      type: "eq",
    });
    await this.setState({
      filterCriteria: filterCriteria,
    });
  };
  //change table body values//
  // hari need to move to derived class or controller
  changeFieldValues = (item, column) => {
    let count = 0;
    let screenPermissions = this.state.screenPermissions;
    let self = this,
      tableItem;
    tableItem = self.getTableFieldItem(column.field);
    if (this.state.selectedRows && this.state.selectedRows.length > 0) {
      this.state.selectedRows.map(async (details) => {
        if (details == item) {
          count++;
        }
      });
    }


    // Payment_types in district Page
    if( this.props.type == "District" && tableItem.field == "payment_types"){
      let paymentDues = item[column.field].map((obj)=> obj.name).join(',')
      return (
        <span
          style={{ textTransform: "capitalize", cursor: "pointer" , fontWeight:'bold'}}
          title={paymentDues}
        >
          {paymentDues}
        </span>
      );
    }
    // Pending carts Products data
    if( this.props.type == "Pending Carts" && tableItem.field == "products"){
      let productDetails = item[column.field].map((obj)=> obj.product_name).join(',')
      return (<span>{productDetails}</span>)
    }


    if (tableItem.fieldType === "Link") {
      return (
        <div
          className={count != 0 ? "selectedRowColor" : "textElipses"}
          title={item[column.field]}
        >
          <span
            style={count != 0 ? null : tableItem.style}
            onClick={() => this.getUserDataById(item, "view")}
          >
            {item[column.field]}
          </span>
        </div>
      );
    } else if (tableItem.field === "related_family_family") {
      if (item["separated_families"] && item["separated_families"]["family"] && item["separated_families"]["family"]["family"]) {
        return (
          <div
            className={count != 0 ? "selectedRowColorfamily" : "textElipses"}
            title={item["separated_families"]["family"]["family"]}
            color="rgb(14, 71, 104)"
          >
            <span
              style={{ color: "rgb(14, 71, 104)" }}
              onClick={() => this.getUserDataById(item, "Related view")}
              title={item["separated_families"]["family"]["family"]}>
              {item["separated_families"]["family"]["family"]}
            </span>
          </div>
        );
      }
    } 
    
      //orders payment changes
      else if (tableItem.fieldType === "payment_Method" && this.props.type =="Orders") {
        let val = item && item["ecommercePaymentId"] && item["ecommercePaymentId"]["payment_method"] ? item["ecommercePaymentId"]["payment_method"] : ""
        return (
          <span
            style={{ textTransform: "capitalize", cursor: "pointer" , fontWeight:'bold'}}
            title={val}
          >
            {val}
          </span>
        );
      }
    
    else if (tableItem.field === "payment_method") {   
        return (
          <div><span style={{ textTransform: "capitalize", cursor: "pointer" , fontWeight:'bold' }}>{item[column.field]}</span><span>{item["cheque"] ? "-" + `${item["cheque"]}` : ""}</span></div>
        );
    }

    //Orders Summary By Class
    else if (this.props.type === "Orders Summary By Class" && tableItem.field === "comments") {
      let val = item && item.comments
      return <span> {val} </span>
    }
    
    else if (tableItem.fieldType === "Currency") {
      return (
        <div>${item[column.field]}</div>
      );
    } else if (tableItem.field === "divorced_parent_email") {
      if (item["divorced_parent"] && item["divorced_parent"]["email"]) {
        return (
          <span title={item["divorced_parent"]["email"]}>
            {item["divorced_parent"]["email"]}
          </span>
        );
      }
    } else if (tableItem.field === "divorced_parent_displayName") {
      if (item["divorced_parent"] && item["divorced_parent"]["display_name"]) {
        return (
          <span title={item["divorced_parent"]["display_name"]}>
            {item["divorced_parent"]["display_name"]}
          </span>
        );
      }
    } else if (tableItem.field === "related_family_family") {
      if (item["related_family"] && item["related_family"]["family"]) {
        return (
          <span title={item["related_family"]["family"]}>
            {item["related_family"]["family"]}
          </span>
        );
      }
    } else if (tableItem.field === "related_parent_email") {
      if (
        item["related_parent"] &&
        item["related_parent"].length > 0 &&
        item["related_parent"][0]["email"]
      ) {
        return (
          <span title={item["related_parent"][0]["email"]}>
            {item["related_parent"][0]["email"]}
          </span>
        );
      }
    } else if (tableItem.field === "related_parent_displayName") {
      if (
        item["related_parent"] &&
        item["related_parent"].length > 0 &&
        item["related_parent"]["0"]["display_name"]
      ) {
        return (
          <span title={item["related_parent"]["0"]["display_name"]}>
            {item["related_parent"]["0"]["display_name"]}
          </span>
        );
      }
    } else if (tableItem.fieldType === "Download") {
      if (item[column.field]) {
        return (
          <a
            href={`${config.imgUrl}/${item[column.field]}`}
            download
            style={{ textDecoration: "underline" }}
          >
            <FontAwesomeIcon
              icon='download'
              size='sm'
              style={{ marginRight: 5 }}
            />
            {item[column.field]}
          </a>
        );
      }
    } else if (tableItem.fieldType === "Number") {
      return <p>{`${item[column.field]} `}</p>;
    } else if (tableItem.fieldType === "Capitalize") {
      if (item[column.field]) {
        return (
          <p style={{ textTransform: "capitalize" }}>{`${item[column.field]
            } `}</p>
        );
      }
    } else if (tableItem.fieldType === "Badge") {
      console.log("DDDDCOL", item[column.field]);
      let mcolor = self.getColorFromOptions(
        tableItem.options,
        item[column.field]
      );
      return (
        <Badge color={mcolor} pill style={tableItem.style}>
          <span>{item[column.field]}</span>
        </Badge>
      );
    } else if (tableItem.fieldType === "Role") {
      let mcolor = self.getColorFromOptions(
        tableItem.options,
        item[column.field]
      );
      return (
        <Badge color={mcolor} style={tableItem.style}>
          <span>{item[column.field]}</span>
        </Badge>
      );
    } else if ((column.field == "phone1_mobile" || column.field == "homephone" || column.field == "phone1_home" || column.field == "workphone" || column.field == "phone2_home" || column.field == "Transportation" || column.field == "Attendance_Line" || column.field == "phone1_mobile" || column.field == "phone2_work" || column.field == "Main_Number" || column.field == "phone2_mobile" || column.field == "phone1_work") && item[column.field]) {
      let data = item[column.field].replace(/[^0-9]/g, "")
      // let index = item[column.field].includes("(");
      // if (index == true) {
      //   return (
      //     <span title={item[column.field]}>
      //       {item[column.field]}
      //     </span>
      //   )
      //   //console.log("Hi", item[column.field])
      // } else {
      //   if (data === true) {

      // } else {
      //   // console.log("Hi#######", item[column.field])
      //   return (`(${item[column.field].slice(0, 3)}) ${item[column.field].slice(3, 6)}-${item[column.field].slice(6, 10)}`)
      // }
      // }
      return (`(${data.slice(0, 3)}) ${data.slice(3, 6)}-${data.slice(6, 10)}`)

    }
    else if (tableItem.fieldType === "phoneFormat" && item[column.field]) {
      let index = (item[column.field].indexOf("-"))
      if (index == 3) {
        return (
          <span>
            {item[column.field]}
          </span>
        );
      } else {
        const onlyNums = item[column.field].replace(/[^\d]/g, "");
        if (onlyNums.length <= 3) {
          return onlyNums;
        }
        if (onlyNums.length <= 7) {
          return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`;
        }
        return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 6)}-${onlyNums.slice(
          6,
          10
        )}`;
      }

    }
    else if (tableItem.fieldType === "icon") {
      let mcolor = self.getColorFromOptions(
        tableItem.options,
        item[column.field]
      );
      let tooltip = self.getTooltipFromOtptions(
        tableItem.options,
        item[column.field]
      );
      return (
        <FontAwesomeIcon
          style={{ color: mcolor, cursor: tooltip ? "pointer" : "" }}
          color="white"
          icon={tableItem.iconName}
          data-toggle="tool-tip"
          title={tooltip}
        />
      );
    } else if (tableItem.fieldType === "Actions") {
      return (
        <div className="row" style={{ justifyContent: "center" }}>
          {screenPermissions &&
            screenPermissions[this.props.type] &&
            screenPermissions[this.props.type] === "Edit" ? (
            <>
              {this.props.editRequired && (
                <FontAwesomeIcon
                  className={count != 0 ? "selectedRowEditIcon" : "rowEditIcon"}
                  color="white"
                  icon="edit"
                  data-toggle="tool-tip"
                  title="Edit"
                  onClick={
                    this.state.displayViewOfForm === "screen"
                      ? () => this.getUserDataById(item, "edit")
                      : () => this.openFormModal("edit", item)
                  }
                />
              )}
              {this.props.deleteRequired &&
                <FontAwesomeIcon
                  className='rowDeleteIcon'
                  color='white'
                  icon='trash-alt'
                  data-toggle="tool-tip"
                  title="Delete"
                  onClick={() => this.deleteConfirmAction(item._id, "Delete")}
                />
              }
            </>
          ) : null}
            {this.props.type === "Orders" || this.props.type === "Refund Details" || this.props.type === "Pending Carts" ? (
            <>
              <Badge color={"info"} pill onClick={() => this.openViewModal(item, "view")}
                style={tableItem.style}>
                <span>View Details</span>
              </Badge>
            </>
          ) : null}
          {this.props.otherActionsRequired ? (
            <>
              {this.props.copyRequired && (
                <FontAwesomeIcon
                  className={count != 0 ? "selectedRowEditIcon" : "rowCopyIcon"}
                  color="white"
                  icon="copy"
                  data-toggle="tool-tip"
                  title="Copy"
                  onClick={() => this.openFormModal("copy", item)}
                />
              )}
              {this.props.sendRequired && (
                <FontAwesomeIcon
                  className={
                    count != 0 ? "selectedRowEditIcon" : "rowShareIcon"
                  }
                  color="white"
                  icon="share"
                  data-toggle="tool-tip"
                  title="Share"
                  onClick={() => this.sendGroupEmail(item)}
                />
              )}
              {this.props.type === "Payments" && (
                <FontAwesomeIcon
                  className={
                    item.payment_isCompleted === true
                      ? "checkCircleSuccess"
                      : item.payment_mode === "offline"
                        ? "checkCircleAction"
                        : "checkCirclePending"
                  }
                  color="white"
                  icon={"check-circle"}
                  data-toggle="tool-tip"
                  title={
                    item.payment_isCompleted === true
                      ? "Payment Completed"
                      : item.payment_mode === "offline"
                        ? "Approve Payment"
                        : "Payment Pending"
                  }
                  onClick={
                    item.payment_isCompleted === true
                      ? null
                      : item.payment_mode === "offline"
                        ? () => this.handleOfflinePaymentApproval(item)
                        : null
                  }
                />
              )}
            </>
          ) : null}
        </div>
      );
    } else if (tableItem.fieldType === "payment_method" && tableItem.shownField) {
      let val = item["pay_details"] && item["pay_details"][0] && item["pay_details"][0]["payment_method"] && item["pay_details"][0]["payment_method"] !== undefined ? item["pay_details"][0]["payment_method"] : ""
      return (
        <span
          style={{ textTransform: "capitalize", cursor: "pointer" , fontWeight:'bold'}}
          title={val}
        >
          {val}    {/* //order summry report // */}
        </span>
      );
    }

  
     else if (tableItem.fieldType === "productsTypeee") {
      let val = item["products"] && item["products"]["product_name"] && item["products"]["product_name"] !== undefined ? item["products"]["product_name"] : ""
      return (
        <span
          style={{ textTransform: "capitalize", cursor: "pointer" }}
          title={val}
        >
          {val}
        </span>
      );   
    } else if (tableItem.fieldType === "other_name" ) {
      let val = item["products_details"] && item["products_details"].map((value)=> value.other_name)
      return (
        <span
          style={{ textTransform: "capitalize", cursor: "pointer" }}
          title={val}
        >
          {val}
        </span>
      );
    } 
    else if (tableItem.field === "ordered_cost") {
      let val = item["products"] && item["products"]["price"] && item["products"]["price"] !== undefined ? item["products"]["price"] : ""
      return (
        <span
          style={{ textTransform: "capitalize", cursor: "pointer" }}
          title={val}
        >
          {"$" + val}
        </span>
      );
    } else if (tableItem.fieldType === "productsqty") {
      let val = item["products"] && item["products"]["qty"] && item["products"]["qty"] !== undefined ? item["products"]["qty"] : ""
      return (
        <span
          style={{ textTransform: "capitalize", cursor: "pointer" }}
          title={val}
        >
          {val}
        </span>
      );
    } else if (tableItem.fieldType === "transactionId") {
      let val = item["pay_details"] && item["pay_details"][0] && item["pay_details"][0]["transactionId"] && item["pay_details"][0]["transactionId"] !== undefined ? item["pay_details"][0]["transactionId"] : ""
      return (
        <span
          style={{ textTransform: "capitalize", cursor: "pointer" }}
          title={val}
        >
          {val}
        </span>
      );
    }
    else if (tableItem.fieldType === "user_name") {
      let val = item["pay_details"] && item["pay_details"][0] && item["pay_details"][0]["user_name"] && item["pay_details"][0]["user_name"] !== undefined ? item["pay_details"][0]["user_name"] : ""
      return (
        <span
          style={{ textTransform: "capitalize", cursor: "pointer" }}
          title={val}
        >
          {val}
        </span>
      );
    } else if (tableItem.fieldType === "p1_mobile") {
      let val = item["pay_details"] && item["pay_details"][0] && item["pay_details"][0]["p1_mobile"] && item["pay_details"][0]["p1_mobile"] !== undefined ? item["pay_details"][0]["p1_mobile"] : ""
      return (
        <span
          style={{ textTransform: "capitalize", cursor: "pointer" }}
          title={val}
        >
          {val}
        </span>
      );
    }
    else if (tableItem.fieldType === "child_detailslast") {
      let val = item["child_details"] && item["child_details"][0] && item["child_details"][0]["lastname"] && item["child_details"][0]["lastname"] !== undefined ? item["child_details"][0]["lastname"] : ""
      return (
        <span
          style={{ textTransform: "capitalize", cursor: "pointer" }}
          title={val}
        >
          {val}
        </span>
      );
    } else if (tableItem.field === "child_detailsphone") {
      let val = item["child_details"] && item["child_details"][0] && item["child_details"][0]["p1_mobile"] && item["child_details"][0]["p1_mobile"] !== undefined ? item["child_details"][0]["p1_mobile"] : ""
      return (
        <span
          style={{ textTransform: "capitalize", cursor: "pointer" }}
          title={val}
        >
          {val}
        </span>
      );

      }else if (tableItem.fieldType === "child_details") {
        let val = item["child_details"] && item["child_details"][0] && item["child_details"][0]["phone1_mobile"] && item["child_details"][0]["phone1_mobile"] !== undefined ? item["child_details"][0]["phone1_mobile"] : ""
        return (
          <span
            style={{ textTransform: "capitalize", cursor: "pointer" }}
            title={val}
          >
            {val}
          </span>
        );
      
        }else if (tableItem.field === "comments") {
        let val = item["products"] && item["products"] && item["products"]["comments"] && item["products"]["comments"] !== undefined ? item["products"]["comments"] : ""
        return (
          <span
            style={{ textTransform: "capitalize", cursor: "pointer" }}
            title={val}
          >
            {val}
          </span>
        );

    } else if (tableItem.field === "class_nameclass") {
      let val = item["child_details"] && item["child_details"][0] && item["child_details"][0]["class_name"] && item["child_details"][0]["class_name"] !== undefined ? item["child_details"][0]["class_name"] : ""
      return (
        <span
          style={{ textTransform: "capitalize", cursor: "pointer" }}
          title={val}
        >
          {val}
        </span>
      );
    }
    else if (tableItem.field === "child_detailsaddress1") {
      let val = item["child_details"] && item["child_details"][0] && item["child_details"][0]["address1"] && item["child_details"][0]["address1"] !== undefined ? item["child_details"][0]["address1"] : ""
      return (
        <span
          style={{ textTransform: "capitalize", cursor: "pointer" }}
          title={val}
        >
          {val}
        </span>
      );
    }
    else if (tableItem.fieldType === "calculateType") {
      let val = (item["products"]["price"] * item["products"]["qty"]).toFixed(2)
      return (
        <span
          style={{ textTransform: "capitalize", cursor: "pointer" }}
          title={val}
        >
          {"$" + val}
        </span>
      );
    } else if (tableItem.fieldType === "Array") {
      let val = this.flattenArray(item[column.field]);
      return (
        <span
          style={{ textTransform: column.field !== "userEmail" ? "capitalize" : "", cursor: "pointer" }}
          title={val}
        >
          {val}
        </span>
      );
    } else if (tableItem.fieldType === "Currency") {
      let val = item[column.field];
      return (
        <span>
          {"$" + val}
        </span>
      );
    } else if (tableItem.fieldType === "Boolean") {
      let val = item[column.field] === true ? "True" : "False";
      return (
        <span>
          {val}
        </span>
      );
    } else if (tableItem.fieldType === "Bool") {
      let val = item[column.field];
      let bool = val && val.length > 0 ? "True" : "False";
      let mcolor = self.getColorFromOptions(tableItem.options, bool);
      return (
        <span
          style={{
            textTransform: "capitalize",
            fontWeight: "bold",
            color: mcolor,
          }}
          title={bool}
        >
          {bool}
        </span>
      );
    } else if (tableItem.fieldType === "BoolText") {
      let val = item[column.field];
      console.log("val", val, typeof val)
      let mcolor = self.getColorFromOptions(tableItem.options, val);
      return (
        <span
          style={{
            textTransform: "capitalize",
            fontWeight: "bold",
            color: mcolor,
          }}
          title={val}
        >
          {val}
        </span>
      );
    } else if (tableItem.fieldType === "BoldText") {
      return (
        <span
          style={{
            textTransform: tableItem.textCapitalize ? "capitalize" : "none",
            fontWeight: "bold",
          }}
        >
          {item[column.field]}
        </span>
      );
    }
    else if (tableItem.field === "display_name" && this.props.type === "Orders") {
      let val = item["user_id"]
      return (
        <span
        >
          {val ? val.display_name : ""}
        </span>
      );
    } else if (tableItem.field === "productQty" && this.props.type === "Pending Carts") {
      let val = item && item["products"] && item["products"].qty ? item["products"].qty : 0
      return (
        <span
        >
          {val ? val : 0}
        </span>
      );
    } else if (tableItem.field === "productExpires" && this.props.type === "Pending Carts") {
      let val = item && item["products"] && item["products"].expires ? dateFormats.formatDate(
        item["products"].expires,
        tableItem.dateFormat
      ) : ""
      return (
        <span
        >
          {val ? val : ""}
        </span>
      );
    }
    else if (tableItem.field === "productsProductName" && this.props.type === "Pending Carts") {
      let val = item && item["product_details"] && item["product_details"][0] && item["product_details"][0].product_name ? item["product_details"][0].product_name : 0
      return (
        <span
        >
          {val ? val : ""}
        </span>
      );
    }
    else if (tableItem.field === "child_detailsfname" && this.props.type === "Pending Carts") {
      let val = item && item["child_details"] && item["child_details"][0] && item["child_details"][0].firstname ? item["child_details"][0].firstname : 0
      return (
        <span
        >
          {val ? val : ""}
        </span>
      );
    } else if (tableItem.field === "child_detailslName" && this.props.type === "Pending Carts") {
      let val = item && item["child_details"] && item["child_details"][0] && item["child_details"][0].lastname ? item["child_details"][0].lastname : 0
      return (
        <span
        >
          {val ? val : ""}
        </span>
      );
    }
    else if (tableItem.field === "paymentMethod") {
      let val = item && item["ecommercePaymentId"] && item["ecommercePaymentId"]["payment_method"] ? item["ecommercePaymentId"]["payment_method"] : ""
      return (
        <span >
          {val}
        </span>
      );
    }
    else if (tableItem.fieldType === "BooleanBadge") {
      let mcolor = self.getColorFromOptions(
        tableItem.options,
        item[column.field]
      );
      let displayText = self.getDisplayTextFromOptions(
        tableItem.options,
        item[column.field]
      );
      return (
        <Badge color={mcolor} pill style={tableItem.style}>
          <span>{displayText}</span>
        </Badge>
      );
    } else {
      if (item[column.field] && typeof item[column.field] !== "object") {
        return (
          <span
            style={{
              textTransform: tableItem.textCapitalize ? "capitalize" : "none",
            }}
            title={item[column.field]}
          >
            {item[column.field]}
          </span>
        );
      }
    }
  };

  clickConfirm() {
    this.closeConfirmationModal();
  }

  rowClassName(item) {
    if (item.status === "Blocked") {
      return { "p-highlightRow": item.status === "Blocked" };
    }
  }

  handleDateInterval(startDate, endDate) {
    if (startDate) {
      this.setState({
        startDate: startDate,
      });
    }
    if (endDate) {
      this.setState({
        endDate: endDate,
      });
    }
  }

  // ShowHideColumns Button Click Handler
  openShowHideColumnsModal = () => {
    this.setState({
      isOpenShowHideColumnsModal: true,
    });
  };

  getSettings = async () => {
    this.props.getSettings();
  };

  // Called When Clicked on Cancel or Confirm in ShowHideColumnsModal
  // closeShowHideColumnsModal = async (type, columnFields) => {
  //   if (type && type === "confirm" && columnFields) {
  //     let updatedOrder = [];
  //     let fields = [...this.state.originalTableFields];

  //     this.setState({
  //       isOpenShowHideColumnsModal: false,
  //     });

  //     fields.map(async (item) => {
  //       Object.entries(columnFields).forEach(async ([key, value]) => {
  //         if (item.field === key) {
  //           return (item.show = value);
  //         }
  //       });
  //     });

  //     fields.map(async (col) => {
  //       updatedOrder.push({
  //         field: col.field,
  //         show: col.show,
  //         header: col.header,
  //       });
  //     });

  //     localStorage.setItem(
  //       `${ this.props.type }_column_order`,
  //       JSON.stringify(updatedOrder)
  //     );

  //     await this.getTableFieldsOrder(this.state.originalTableFields);
  //   } else {
  //     this.setState({
  //       isOpenShowHideColumnsModal: false,
  //     });
  //   }
  // };
  // Called When Clicked on Cancel or Confirm in ShowHideColumnsModal

  closeShowHideColumnsModal = async (
    type,
    columnFields,
    changedTableFields
  ) => {
    if (type && type === "confirm" && columnFields) {
      let updatedOrder = [];
      let fields = [...changedTableFields];

      this.setState({
        isOpenShowHideColumnsModal: false,
      });

      fields.map(async (item) => {
        Object.entries(columnFields).forEach(async ([key, value]) => {
          if (item.field === key) {
            return (item.show = value);
          }
        });
      });

      fields.map(async (col) => {
        updatedOrder.push({
          field: col.field,
          show: col.show,
          header: col.header,
          displayInSettings: col.displayInSettings,
        });
      });
      await this.sendColumnDataToServer(updatedOrder, "PUT");
      localStorage.setItem(
        `${this.props.type}_column_order`,
        JSON.stringify(updatedOrder)
      );
      await this.getTableFieldsOrder(this.state.originalTableFields);
    } else if (type && type === "confirm") {
      let fields = [...changedTableFields];
      this.setState({
        isOpenShowHideColumnsModal: false,
      });
      await this.sendColumnDataToServer(fields, "PUT");
      // localStorage.setItem(
      //   `${ this.props.type }_column_order`,
      //   JSON.stringify(fields)
      // );

      await this.getTableFieldsOrder(this.state.originalTableFields);
    } else {
      this.setState({
        isOpenShowHideColumnsModal: false,
      });
    }
  };

  searchInDateRange() {
    if (this.state.startDate && this.state.endDate) {
      let filterCriteria = {};
      let startDate = moment(this.state.startDate).format(
        config.dateDayMonthFormat
      );
      let endDate = moment(this.state.endDate).format(
        config.dateDayMonthFormat
      );
      filterCriteria["limit"] = 20;
      filterCriteria["page"] = 1;
      filterCriteria["criteria"] = [
        {
          key: "date",
          value: startDate,
          type: "gte",
        },
        {
          key: "date",
          value: endDate,
          type: "eq",
        },
      ];
      this.setState({ filterCriteria: filterCriteria });
      this.getDataFromServer(filterCriteria);
    } else {
      alert("no dates selected");
    }
  }

  toggle = async (id) => {
    let openId = this.state.openId;
    this.setState({ openId: openId === id ? "" : id });
  };

  //onActionsChange
  onActionsChange = async (event, type) => {
    let value = "";
    if (type == "school_name") {
      await this.setState({
        name: value,
        showInitialValue: false,
        showDependentDropdownOptions: null
      });
    }
    await this.setState({
      [event.target.name]: event.target.value,
    });
    if (type == "payment") {
      let tempTotalPayments, selectedPaymentsData;
      tempTotalPayments = this.state.totalPaymentsData;
      for (let i = 0; i < tempTotalPayments.length; i++) {
        if (
          tempTotalPayments[i]["_id"] &&
          tempTotalPayments[i]["_id"] == this.state.payment
        ) {
          selectedPaymentsData = this.state.totalPaymentsData[i];
          await this.setState({selectedPaymentsData:selectedPaymentsData});
        }
      }

      let filterCriteria = Object.assign({}, defaultFilterCriteria);
      if (this.state.payment == "all") {
        filterCriteria.criteria = [];
      } else {
        filterCriteria.criteria = [
          { key: "payment_mode", value: this.state.payment, type: "eq" },
        ];
      }
      // await this.setState({
      //   selectedPaymentsData: selectedPaymentsData,
      // });
      this.getDataFromServer(filterCriteria, "");
    } else {
      if (type === "dropdownFilter") {
        this.confirmActionType(event.target.value);
      } else {
        if (this.props.showSchoolDropdown) {
          this.showFilteredData(event.target.value, type);
        }
      }
    }
  };

  showFilteredData = async (data, fromDropdownType) => {
    let criteria;
    if (
      this.props.criteria &&
      this.props.criteria.length > 0 &&
      fromDropdownType == "school_name"
    ) {
      this.props.criteria[0]["value"] = data;
    }
    if (this.props.showAllDropdownValue && this.state.school_name == "All") {
      criteria = [];
    } else if (
      this.props.showAllDropdownValue &&
      this.props.dependentCriteria &&
      this.props.dependentCriteria.length > 0 &&
      fromDropdownType != "school_name"
    ) {
      if (this.state.name == "All") {
        this.props.criteria[0]["value"] = this.state.school_name;
        criteria = this.props.criteria;
      } else {
        this.props.dependentCriteria[0]["value"] = this.state.name;
        criteria = this.props.dependentCriteria;
      }
    } else {
      criteria = this.props.criteria;
    }
    let filterCriteria = Object.assign({}, defaultFilterCriteria);
    filterCriteria.criteria = criteria;
    if (
      fromDropdownType == "dependentDropdown" &&
      this.props.showAllDropdownValue == null
    ) {
      filterCriteria.criteria = [];
      this.getDataFromServer(filterCriteria, fromDropdownType);
    } else if (
      fromDropdownType == "dependentDropdown" &&
      this.props.showAllDropdownValue
    ) {
      this.getDataFromServer(filterCriteria, fromDropdownType);
    } else {
      if (this.props.showDependentDropdown) {
        this.getDependentData(filterCriteria, fromDropdownType, "no");
      } else {
        this.getDataFromServer(filterCriteria);
      }
    }
  };

  getDependentData = async (data, type, fromInitial) => {
    let apiUrl =
      type == "school_name" ? this.props.dependentUrl : this.props.apiUrl;
    let url = `${apiUrl}?filter=${JSON.stringify(data)}`;
    await fetchMethodRequest("GET", `${url}`).then(async (response) => {
      if (response) {
        let options = [];
        let showDependentDropdownOptions = response[this.props.dependentUrl];
        if (
          showDependentDropdownOptions &&
          showDependentDropdownOptions.length > 0
        ) {
          for (let i = 0; i < showDependentDropdownOptions.length; i++) {
            if (i == 0 && this.props.showAllDropdownValue) {
              options.push({
                label: "All",
                value: "All",
              });
            }
            options.push({
              label: showDependentDropdownOptions[i]["name"],
              value: this.props.showAllDropdownValue
                ? showDependentDropdownOptions[i]["name"]
                : showDependentDropdownOptions[i]["_id"],
            });
          }

          await this.setState({
            showDependentDropdownOptions: options,
          });
          let defaultSelectedValue;
          if (this.state.name == "") {
            defaultSelectedValue =
              options && options.length > 0 ? options[0]["value"] : "";
          }
          await this.setState({
            name: defaultSelectedValue,
          });
          let filterCriteria;
          filterCriteria = this.state.filterCriteria;
          if (
            this.props.showAllDropdownValue &&
            this.props.dependentCriteria &&
            this.props.dependentCriteria.length > 0 &&
            fromInitial != "yes" &&
            fromInitial != "no"
          ) {
            this.props.dependentCriteria[0]["value"] = this.state.name;
            filterCriteria.criteria = this.props.dependentCriteria;
          } else if (
            this.props.showAllDropdownValue &&
            fromInitial == "no" &&
            this.state.school_name != "All"
          ) {
            filterCriteria.criteria = this.props.criteria;
          } else {
            filterCriteria.criteria = [];
          }
          await this.setState({
            name: defaultSelectedValue,
            filterCriteria: filterCriteria,
          });
          // if (fromInitial == "yes") {
          this.getDataFromServer(filterCriteria, "dependentDropdown");
          // }
        } else {
          await this.setState({
            allUsersData: [],
            showDependentDropdownOptions: [],
          });
        }
      }
    });
  };

  confirmActionType = async (type) => {

    if (type === "Delete") {
      await this.setState({
        confirmType: "Delete",
        confirmModalText: this.state.actionsText.ActiontoDelete,
      });
      this.deleteConfirmAction();
    } else if (type === "Block") {
      await this.setState({
        confirmType: "Block",
        confirmModalText: this.state.actionsText.ActiontoBlock,
      });
      this.deleteConfirmAction();
    } else if (type === "UnBlock") {
      await this.setState({
        confirmType: "UnBlock",
        confirmModalText: this.state.actionsText.ActiontoUnblock,
      });
      this.deleteConfirmAction();
    } else if (type === "Active") {
      await this.setState({
        confirmType: "Active",
        confirmModalText: this.state.actionsText.ActiontoActive,
      });
      this.deleteConfirmAction();
    } else if (type === "Inactive") {
      await this.setState({
        confirmType: "Inactive",
        confirmModalText: this.state.actionsText.ActiontoInactive,
      });
      this.deleteConfirmAction();
    } else if (type === "ResetPassword") {
      await this.setState({
        confirmType: "ResetPassword",
        confirmModalText: this.state.actionsText.ActiontoresetPassword,
      });
      this.deleteConfirmAction();
    } else if (type === "Divorce") {
      await this.setState({
        confirmType: "Divorce",
        confirmModalText:
          this.state.actionsText.ActiontoDivorce,
      });
      this.deleteConfirmAction();
    } else if (type === "ResetFamily") {
      await this.setState({
        confirmType: "ResetFamily",
        confirmModalText: this.state.actionsText.ActiontoresetFamily,
      });
      this.deleteConfirmAction();
    } if (type === "Separated") {
      await this.setState({
        confirmType: "Separated",
        confirmModalText: this.state.actionsText.ActiontoSeperated,
      });
      this.deleteConfirmAction();
    } else if (type === "ResetFaculty") {
      await this.setState({
        confirmType: "ResetFaculty",
        confirmModalText: this.state.actionsText.ActiontoresetFaculty,
      });
      this.deleteConfirmAction();
    } else if (type === "Archive") {
      await this.setState({
        confirmType: "Archive",
        confirmModalText: this.state.actionsText.ActiontoArchieveStore ? this.state.actionsText.ActiontoArchieveStore : "Are you sure do you want to Archieve the store",
      });
      this.deleteConfirmAction();
    } else if (type === "Delivered" || type === "Cancelled" || type === "Returned" || type === "Exchanged") {
      await this.setState({
        confirmType: type,
        confirmModalText: this.state.actionsText[type] ? this.state.actionsText[type] : `${`Are you sure do you want to ${type} the order`}`,
      });
      this.deleteConfirmAction();
    } else if (type === "Invite") {
      let isInActiveExists = false;
      for (let i = 0; i < this.state.selectedRows.length; i++) {
        if (this.state.selectedRows[i]["status"] == "Inactive") {
          isInActiveExists = true;
          break;
        }
      }
      if (isInActiveExists) {
        await this.setState({
          alertText:
            this.state.selectedRows.length > 1
              ? "Some of the selected users are inactive please make them active before inviting."
              : "Please make the user active before inviting.",
          isOpenAlertModal: true,
        });
      } else {
        await this.setState({
          confirmType: "Invite",
          confirmModalText: "Are you sure, you want to Invite",
        });
        this.deleteConfirmAction();
      }
    } else if (type === "ApprovePayment") {
      await this.setState({
        confirmType: "ApprovePayment",
        confirmModalText: "Are you sure, you want to approve Payment",
      });
      this.deleteConfirmAction();
    } else if (type == "AddMember") {
      await this.setState({
        confirmType: "AddMember",
        confirmModalText: "Are you sure, you want to Add Member",
      });
      this.deleteConfirmAction();
    }
  };

  onFilterGlobalChange = (event) => {
    if (event.key === "Enter") {
      if (event.target.value.length > 0) {
        this.onFilterChange(event)
      } else {
        let filterCriteria = this.state.filterCriteria
        filterCriteria.globalSearch = {}
        delete filterCriteria.globalSearch
        this.setState({
          filterCriteria: filterCriteria,
          page: "1",
          first: 0,
        });
        this.getDataFromServer(
          filterCriteria,
          this.props.showDependentDropdown ? "dependentDropdown" : ""
        );
      }
    }
  }
  onFilterStore = async (val, type) => {
    if (type === "School") {
      await this.setState({
        selectedSchool: val ? val : ""
      })
    } else {
      await this.setState({
        selectedStore: val ? val : "",
      })
    }
    this.getDataFromServer(this.state.filterCriteria)
  }


  getHeader() {
    let screenPermissions = this.state.screenPermissions;
    const { t } = this.props;
    return (
      <div>
        <div className="row">
          <div className="col-12 px-0">
            <span className="float-left pt-2">
              <h4>
                <b>
                  {t(this.props.type == 'Email Status' ? "Email History" : this.props.type == 'News' ? 'News Letter' : this.props.type == 'Non-Registered Families' ? 'Registered Families' : this.props.type)}
                  {this.props.type === "Activiti" ? "es" : ""}
                </b>
              </h4>
            </span>

            <span className="pt-2">
              {this.props.type === "Orders Summary By Class" || this.props.type === "Orders Summary By Grade" ?
                <span className="float-right d-flex pl-3">
                  <div>
                    <div className="p-inputgroup">

                      <Dropdown
                        options={this.props.storeOptions}
                        value={this.state.selectedStore}
                        onChange={(e) => this.onFilterStore(e.value, "Store")}
                        // onChange={(e) => this.onFilterChange(e)}
                        placeholder={"Select Store"
                        }
                        size="30"
                      />
                    </div>
                  </div>
                  <div>
                    {/* <FontAwesomeIcon
                      icon={faTimes}
                      color="red"
                      data-toggle="tool-tip"
                      title="Export To CSV"
                      className="pl-1"
                      size="lg"
                      onClick={() => this.onFilterStore("", "Store")}
                    /> */}
                  </div>
                </span>
                : null}
              {this.props.type === "School" || this.props.type === "Store" || this.props.type === "Ecommerce Payments" || this.props.type === "Pending Carts" || this.props.type === "Products" || this.props.apiUrl.includes("orders") ?
                <span className="float-right d-flex pl-3">
                  <div className="d-flex">
                    <div className="p-inputgroup">
                      <Dropdown
                        options={[{ label: "Foundation", value: "Foundation", showError: true },
                        { label: "PTA", value: "Non-Foundation" }, { label: "Both", value: "Both" },]}
                        value={this.state.selectedSchool}
                        onChange={(e) => this.onFilterStore(e.value, "School")}
                        // onChange={(e) => this.onFilterChange(e)}
                        // placeholder={"Select School"}
                        size="50"
                      />
                    </div>
                    <div>
                      {/* <FontAwesomeIcon
                        icon={faTimes}
                        color="red"
                        data-toggle="tool-tip"
                        title="Export To CSV"
                        className="pl-1"
                        size="lg"
                        onClick={() => this.onFilterStore("", "School")}
                      /> */}
                    </div>
                  </div>

                </span>
                : null}
              {this.props.globalSearch && (
                <span className="float-right pl-3">
                  <div>
                    <div className="p-inputgroup">
                      <span className="p-inputgroup-addon">
                        <i className="pi pi-search"></i>
                      </span>
                      <InputText
                        type="search"
                        name="globalSearch"
                        id="globalSearch"
                        // value={this.state.selectedFilterValue}
                        onKeyPress={(e) => this.onFilterGlobalChange(e)}
                        onChange={(e) => this.onFilterGlobalChange(e)}
                        // onChange={(e) => this.onFilterChange(e)}
                        placeholder={
                          this.props.globalSearch
                            ? this.props.globalSearch
                            : "search"
                        }
                        size="40"
                      />
                    </div>
                  </div>
                </span>
              )}

              <span className="float-right">
                {this.props.paymentMode && (
                  <Dropdown
                    style={{ minWidth: "120px", lineHeight: 1.3 }}
                    className="ml-auto mb-3 mr-3"
                    name="payment"
                    value={
                      this.state.payment
                        ? this.state.payment
                        : this.props.paymentMode[0]["options"][0]["value"]
                    }
                    options={this.props.paymentMode[0]["options"]}
                    placeholder={t("Payment")}
                    onChange={(e) => this.onActionsChange(e, "payment")}
                  />
                )}
                {this.props.actionsTypes && (
                  <Dropdown
                    style={{ minWidth: "120px", lineHeight: 1.3 }}
                    className="ml-auto mb-3 mr-3"
                    name="actions"
                    value={this.state.actions}
                    disabled={
                      this.state.selectedRows &&
                        this.state.selectedRows.length > 0
                        ? false
                        : true
                    }
                    options={this.state.actionsTypes}
                    placeholder={t("Actions")}
                    onChange={(e) => this.onActionsChange(e, "dropdownFilter")}
                  />
                )}
                {this.props.showSchoolDropdown &&
                  this.state.committeNames &&
                  this.state.committeNames.length > 0 && (
                    <Dropdown
                      style={{ minWidth: "10%", lineHeight: 1.3 }}
                      className="ml-auto mb-3 mr-3"
                      name="school_name"
                      value={
                        this.state.school_name
                          ? this.state.school_name
                          : this.state.committeNames[0]["label"]
                      }
                      options={this.state.committeNames}
                      placeholder={t("Schools")}
                      onChange={(e) => this.onActionsChange(e, "school_name")}
                    />
                  )}
                {this.props.showDependentDropdown &&
                  this.state.showDependentDropdownOptions &&
                  this.state.showDependentDropdownOptions.length > 0 && (
                    <Dropdown
                      style={{ minWidth: "10%", lineHeight: 1.3 }}
                      className="ml-auto mb-3 mr-3"
                      name="name"
                      value={
                        this.state.name
                          ? this.state.name
                          // : this.state.showInitialvalue
                          : this.state.showInitialValue
                            ? this.state.showDependentDropdownOptions[0]["value"]
                            : []
                      }
                      options={this.state.showDependentDropdownOptions}
                      placeholder={t("Groups")}
                      onChange={(e) =>
                        this.onActionsChange(e, "dependentDropdown")
                      }
                    />
                  )}
                {this.props.exportToCsv ? (
                  <>
                    <Button
                      color="primary"
                      className="p-1 ml-auto mt-1"
                      size={"sm"}
                      onClick={this.getDataToExport}
                    >
                      <FontAwesomeIcon
                        icon="file"
                        data-toggle="tool-tip"
                        title="Export To CSV"
                        className="pl-1"
                        size="lg"
                      />
                    </Button>
                    <CSVLink
                      data={this.state.exportData}
                      filename={`${this.props.type}.csv`}
                      className="hidden text-white"
                      ref={(r) => (this.csvLinkRef = r)}
                      target="_blank"
                    ></CSVLink>
                  </>
                ) : null}
                <ExcelSheet
                  order={this.state.orderforArchievedStore}
                  products={this.state.productsforArchievedStore}
                  archievedStore={this.state.archievedStoreDetails}
                  headers2={this.state.headers2}
                  headers1={this.state.headers1}
                  headers3={this.state.headers3}
                />
                {/* <Button color='primary' outline onClick={() => { this.generateExcel() }}>Download</Button> */}
                {this.props.sample ? (
                  <Button
                    color="primary"
                    size="sm"
                    className="p-1 ml-auto mt-1"
                    onClick={() => this.bulkUploadMoadal()}
                  >
                    <FontAwesomeIcon
                      icon="upload"
                      className="pl-1"
                      size="lg"
                      data-toggle="tool-tip"
                      title="Bulk Upload"
                      onClick={() => this.bulkUploadMoadal()}
                    />
                  </Button>
                ) : null}

                <Button
                  color="primary"
                  size="sm"
                  className="p-1 ml-auto mt-1"
                  onClick={() => this.openShowHideColumnsModal()}
                >
                  <FontAwesomeIcon
                    icon="cog"
                    className="pl-1"
                    size="lg"
                    data-toggle="tool-tip"
                    title="Settings"
                    onClick={() => this.openShowHideColumnsModal()}
                  />
                </Button>

                {/* Add button */}
                {screenPermissions &&
                  screenPermissions[this.props.type] &&
                  screenPermissions[this.props.type] === "Edit" &&
                  this.props.addRequried ? (
                  <Button
                    color="primary"
                    size="sm"
                    className="p-1 ml-auto mt-1"
                    onClick={() => this.openFormModal("add")}
                  >
                    <FontAwesomeIcon
                      icon="plus"
                      className="pl-1"
                      size="lg"
                      data-toggle="tool-tip"
                      title="Add"
                      onClick={() => this.openFormModal("add")}
                    />
                  </Button>
                ) : null}

                {/* priority  in Issues*/}
                <Button
                  color="secondary"
                  size="sm"
                  // className="p-1 ml-auto"
                  className={
                    this.props.addRequried ? "p-1 mt-1" : "p-1 ml-auto mt-1"
                  }
                  onClick={() =>
                    this.getDataFromServer(this.state.filterCriteria, "refresh")
                  }
                >
                  <FontAwesomeIcon
                    icon="sync-alt"
                    size="lg"
                    data-toggle="tool-tip"
                    title="Refresh"
                    color={config.templateColor}
                    className=" ml-auto refreshIcon pl-1"
                  />
                </Button>
              </span>
            </span>
          </div>
        </div>
        {/* <div className="pl-3"> */}
        {this.props.paymentMode && this.state.selectedPaymentsData && (
          <span>
            <div className="row">
              <h5 className="paymentColor">
                Total Amount :
                <span className="blackColor pl-2">
                  {this.state.selectedPaymentsData["total_amount"]}
                </span>
              </h5>
            </div>
            <div className="row">
              <h5 className="paymentColor">
                Total Pta Dues :
                <span className="blackColor pl-2">
                  {this.state.selectedPaymentsData["total_pta_dues"]}
                </span>
              </h5>
            </div>
            <div className="row">
              <h5 className="paymentColor">
                Total Supporting Donations :
                <span className="blackColor pl-2">
                  {
                    this.state.selectedPaymentsData[
                    "total_suppporting_donations"
                    ]
                  }
                </span>
              </h5>
            </div>
          </span>
        )}
        {/* </div> */}
      </div>
    );
  }

  getColumns(e, d) {
    const { t } = this.props;

    const self = this;
    self.e = e;
    self.d = d;
    if (
      this.state.tablefieldsToShow &&
      this.state.tablefieldsToShow.length > 0
    ) {
      return this.state.tablefieldsToShow.map((item, i) => {
        let column = (
          <Column
            key={item.field + i}
            style={{
              maxwidth: item.width,
              padding: 4,
            }}
            bodyStyle={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textAlign:
                item.field === "status" || item.field === "role"
                  ? "center"
                  : item.textAlign,
            }}
            field={item.field}
            editRequired={self.e}
            deleteRequired={self.d}
            header={t(item.header)}
            body={self.changeFieldValues}
            headerStyle={{
              padding: 4,
              fontWeight: 500,
              width: item.width,
              fontSize: 13,
              color: config.whiteColor,
              backgroundColor: config.templateColor,
            }}
            filter={item.filter ? true : false}
            sortable={item.sortable ? true : false}
            filterPlaceholder={item.placeholder ? item.placeholder : "Search"}
            filterElement={
              item.filterElement ? (
                <div style={{ height: 26 }}>
                  <Dropdown
                    style={{ minWidth: "10%", lineHeight: 1.1 }}
                    appendTo={document.body}
                    name={item.field}
                    value={this.state[item.field]}
                    options={item.filterElement}
                    onChange={(e) => this.onFilterChange(e, "dropdownFilter")}
                  />
                </div>
              ) : null
            }
            selectionMode={item.selectionMode}
          />
        );

        return column;
      });
    }
  }

  getDataTable() {
    let self = this;
    self.editRequired = this.props.editRequried;
    self.deleteRequired = this.props.deleteRequried;
    return (
      <DataTable
        rowClassName={this.rowClassName}
        ref={(el) => (this.dt = el)}
        value={this.state.allUsersData}
        header={this.getHeader()}
        totalRecords={this.state.totalRecordsLength}
        paginator={false}
        lazy={true}
        resizableColumns={true}
        showGridlines={true}
        columnResizeMode="fit"
        onSort={this.sortChange}
        globalFilter={this.state.globalFilter}
        onFilter={this.onFilterChange}
        scrollable={true}
        selection={false}
        onSelectionChange={(e) => this.onSelectRowsUpdate(e)}
        selection={this.state.selectedRows}
        scrollHeight="1000px"
        style={{ marginTop: 0 }}
        emptyMessage={this.state.noRecordsFound ? configMessages.noRecords : null}
        sortMode="single"
        // selectionMode={'single'}
        metaKeySelection={false}
        loading={this.state.isLoading}
        style={
          this.state.allUsersData && this.state.allUsersData.length === 0
            ? { textAlign: "center" }
            : null
        }
      >
        {self.getColumns(self.editRequired, self.deleteRequired)}
      </DataTable>
    );
  }

  getTabInfo() {
    //console.log('from datatable  getTabinfo');
    return null;
  }

  getPaginator() {
    return (
      <PaginatorComponent
        totalRecords={this.state.totalRecordsLength}
        first={this.state.first}
        rows={this.state.rows}
        onPageChange={(e) => this.onPageChange(e, this.state.filterCriteria)}
        isWeb={true}
      />
    );
  }

  getTotalCard() {
    return this.state.isOpenFormModal &&
      this.state.displayViewOfForm === "screen" ? null : (
      <Card style={{ margin: "7px" }} className={"table_data_Desktop"}>
        <CardBody className="tableCardBody">
          {this.getTabInfo()}
          {this.getDataTable()}
          {this.getPaginator()}
        </CardBody>
      </Card>
    );
  }

  getMobileCard() {
    return (
      <div className={"listData_mobile"}>
        <Loader loader={this.state.isLoading} />
        <div className="row p-2 mr-0 ml-0">
          <h4>
            <b>{this.props.type}</b>
          </h4>
        </div>

        <div className="px-3">
          {this.state.allUsersData &&
            this.state.allUsersData.length &&
            this.state.allUsersData.length > 0
            ? this.state.allUsersData.map((item, index) => {
              return (
                <div
                  className="cardBorder row mb-1 pb-2 bg-white"
                  key={index}
                  style={
                    item.status === "Completed"
                      ? {
                        borderLeftColor: "green",
                        borderLeftWidth: "5px",
                        borderLeftStyle: "solid",
                      }
                      : item.status === "Pending"
                        ? {
                          borderLeftColor: config.templateColor,
                          borderLeftWidth: "5px",
                          borderLeftStyle: "solid",
                        }
                        : {
                          borderLeftColor: "#007bff",
                          borderLeftWidth: "5px",
                          borderLeftStyle: "solid",
                        }
                  }
                >
                  {/* {console.log('render', this.state.mobileListFields)} */}
                  {this.state.mobileListFields &&
                    this.state.mobileListFields.length &&
                    this.state.mobileListFields.length > 0
                    ? this.state.mobileListFields.map((elememt, index) => {
                      return (
                        <div
                          className={
                            elememt.mobile
                              ? `col-6 mr-0 ml-0 mt-1 d-flex justify-content-start`
                              : "d-none"
                          }
                          key={index}
                        >
                          <div
                            className={elememt.className + "row m-0 p-0"}
                          >
                            {elememt.fieldType === "Badge" ? (
                              <Badge
                                color={
                                  item.status === "Active"
                                    ? "success"
                                    : item.status === "Inactive"
                                      ? "warning"
                                      : item.status === "Pending"
                                        ? "danger"
                                        : item.status === "Reject"
                                          ? "error"
                                          : item.status === "Completed"
                                            ? "primary"
                                            : ""
                                }
                              >
                                {item[elememt.field]}
                              </Badge>
                            ) : elememt.fieldType === "Date" ? (
                              <div>
                                {dateFormats.formatDate(
                                  item[elememt.field],
                                  config.dateDayMonthFormat
                                )}
                              </div>
                            ) : elememt.fieldType === "Time" ? (
                              <div>
                                {dateFormats.formatDate(
                                  item[elememt.field],
                                  config.timeFormat
                                )}{" "}
                                - $
                                {dateFormats.formatDate(
                                  item["toTime"],
                                  config.timeFormat
                                )}
                              </div>
                            ) : elememt.fieldType === "Array" ? (
                              <span style={{ textTransform: "capitalize" }}>
                                {this.flattenArray(item[elememt.field])}
                              </span>
                            ) : elememt.fieldType === "Bool" ? (
                              <span
                                style={{
                                  textTransform: "capitalize",
                                  fontWeight: "bold",
                                }}
                              >
                                {item[elememt.field] &&
                                  item[elememt.field].length > 0
                                  ? "True"
                                  : "False"}
                              </span>
                            ) : elememt.fieldType === "BoldText" ? (
                              <span
                                style={{
                                  textTransform: "capitalize",
                                  fontWeight: "bold",
                                }}
                              >
                                {item[elememt.field]}
                              </span>
                            ) : (
                              <div>{item[elememt.field]}</div>
                            )}
                          </div>
                        </div>
                      );
                    })
                    : null}
                </div>
              );
            })
            : null}
        </div>
        <PaginatorComponent
          totalRecords={this.state.totalRecordsLength}
          first={this.state.first}
          rows={this.state.rows}
          onPageChange={this.onPageChange}
          isWeb={false}
        />
      </div>
    );
  }

  flattenArray = (arrayVal) => {
    let val = "";
    if (arrayVal) {
      val = JSON.stringify(arrayVal);
      val = val
        .replace(/"/g, "")
        .replace(/\[/g, "")
        .replace(/]/g, "")
        .replace(/{/g, "")
        .replace(/}/g, "")
        .replace(/,/g, " , ")
        .replace(/:/g, " : ");
    }
    return val;
  };

  getFormModal() {
    return (
      <FormModal
        onRef={(ref) => (this.formModalRef = ref)}
        allUsersData={this.state.allUsersData}
        editRequired={this.props.editRequired}
        openFormModal={this.state.isOpenFormModal}
        closeFormModal={this.closeFormModal}
        closeRejectModal={this.closeRejectModal}
        tablefieldsToShow={this.state.tablefieldsToShow}
        originalTableFields={this.state.originalTableFields}
        type={this.props.type}
        formType={this.state.formType}
        formFields={this.props.formFields}
        getDataFromServer={this.getDataFromServer}
        apiUrl={this.state.apiUrl}
        role={this.state.loginRole}
        tabType={this.state.tabType}
        getDoctorPostingFields={this.props.getDoctorPostingFields}
        categoryNames={this.props.categoryNames}
        companiesList={this.props.companiesList}
        getSettings={this.getSettings}
        filterCriteria={this.state.filterCriteria}
        criteria={this.props.criteria}
        groupEmailRequired={this.props.groupEmailRequired}
        rolesRequired={this.props.rolesRequired}
        menuList={this.props.menuList}
        routeTo={this.props.routeTo}
        displayViewOfForm={this.state.displayViewOfForm}
        displayBreadCrumbValue={this.state.displayBreadCrumbValue}
        displayBreadCrumbField={this.state.displayBreadCrumbField}
        userStatus={this.state.userStatus}
        actionsTypes={this.state.actionsTypes}
        entityType={this.props.entityType}
        setFilterCriteriaForActivities={this.setFilterCriteriaForActivities}
        totalRecords={this.state.totalRecordsLength}
        first={this.state.first}
        rows={this.state.rows}
        selectedInfo={this.state.selectedInfo}
        getValidations={this.props.getValidations ? this.props.getValidations : null} // form class screen teachers email validation formate s
      />
    );
  }
  // familyviewModalRef
  getViewModal() {
    return (
      <ViewModal
        type={this.props.type}
        openViewModal={this.state.openViewModal}
        rowData={this.state.rowData}
        formFields={this.props.formFields}
        onRef={(ref) => (this.viewModalRef = ref)}
        closeViewModal={this.closeViewModal}
        locationId={this.state.locationId}
        drawers={this.state.drawers}
      />
    );
  }
  getFamilyViewModal() {
    return (
      <FamilyViewModal
        type={this.props.type}
        openViewModal={this.state.openViewModal}
        rowData={this.state.rowData}
        formFields={this.props.formFields}
        onRef={(ref) => (this.familyviewModalRef = ref)}
        closeViewModal={this.closeViewModal}
        locationId={this.state.locationId}
        drawers={this.state.drawers}
      />
    );
  }

  getOpenShowHideColumnsModal() {
    return (
      <ShowHideColumnsModal
        isOpenShowHideColumnsModal={this.state.isOpenShowHideColumnsModal}
        closeShowHideColumnsModal={this.closeShowHideColumnsModal}
        tableFields={this.state.originalTableFields}
        type={this.props.type}
      />
    );
  }

  getFamilyModal() {
    return (
      <FamilyFormModal
        onRef={(ref) => (this.familyFormModalRef = ref)}
        openFormModal={this.state.isOpenFamilyFormModal}
        closeFormModal={this.closeFormModal}
        type={this.props.type}
        formType={this.state.formType}
        formFields={this.props.formFields}
        getDataFromServer={this.getDataFromServer}
        filterCriteria={this.state.filterCriteria}
        apiUrl={this.state.apiUrl}
        role={this.state.loginRole}
        tabType={this.state.tabType}
        filterCriteria={this.state.filterCriteria}
        familyRowData = {this.state.familyRowData}
      />
    );
  }

  getDeleteRowModal() {
    return (
      <DeleteRowModal
        type={this.props.type}
        openDeleteModal={this.state.openDeleteModal}
        closeDeleteModal={this.closeDeleteModal}
        selectActions={this.state.selectActions}
        deleteSelectedRow={this.deleteSelectedRow}
        confirmModalText={this.state.confirmModalText}
      />
    );
  }

  getBulkUploadModal() {
    return (
      <BulkUploadModal
        onRef={(ref) => (this.bulkUploadMoadalRef = ref)}
        bulkApi={this.props.bulkApi}
        type={this.props.type}
        sample={this.props.sample}
        sampleFilePath={this.props.sampleFilePath}
        openBulkUploadModal={this.state.openBulkUploadModal}
        closeBulkModal={this.closeBulkModal}
      />
    );
  }

  getConfirmatioinModal() {
    return (
      <ConfirmationModal
        formType={this.state.formType}
        onRef={(ref) => (this.confirmRef = ref)}
        openConfirmationModal={this.state.openConfirmationModal}
        closeConfirmationModal={this.closeConfirmationModal}
        confirm={this.clickConfirm}
        text={this.state.confirmText}
      />
    );
  }

  getSessionExpiryModal() {
    return <SessionExpiryModal SOpen={this.state.sessionExpiryModal} />;
  }

  handleDivorceUserOnClick = (item) => {
    this.setState({
      isOpenDivorceConfirmationModal: true,
      recordToConfirm: item,
      confirmModalText: "Are you sure to divorce user with the family",
      confirmType: "Divorce",
    });
  };

  handleBlockUserOnClick = (item) => {
    let txt = "";
    if (item.isAdminBlocked) {
      txt = "Are you sure to unblock user";
    } else {
      txt = "Are you sure to block user";
    }
    this.setState({
      isOpenDivorceConfirmationModal: true,
      recordToConfirm: item,
      confirmModalText: txt,
      confirmType: "UserBlock",
    });
  };

  handleResetUserPasswordOnClick = (item) => {
    this.setState({
      isOpenDivorceConfirmationModal: true,
      recordToConfirm: item,
      confirmModalText: "Are you sure to reset user password",
      confirmType: "UserPasswordReset",
    });
  };

  handleOfflinePaymentApproval = (item) => {
    this.setState({
      isOpenDivorceConfirmationModal: true,
      recordToConfirm: item,
      confirmModalText: "Are you sure to approve offline payment",
      confirmType: "Payment",
    });
  };

  handleResetFamilyOnClick = (item) => {
    this.setState({
      isOpenDivorceConfirmationModal: true,
      recordToConfirm: item,
      confirmModalText: "Are you sure to reset family",
      confirmType: "ResetFamily",
    });
  };

  closeDivorceConfirmationModal = async () => {
    await this.setState({
      isOpenDivorceConfirmationModal: false,
      recordToConfirm: null,
      confirmModalText: null,
      confirmType: null,
    });
    await this.getDataFromServer(this.state.filterCriteria);
  };

  cancelUserPwdResetModal = async () => {
    await this.setState({
      openUserPasswordResetModal: false,
      recordToConfirm: null,
      confirmModalText: null,
      confirmType: null,
      actions: "",
    });
    await this.getDataFromServer(this.state.filterCriteria);
  };

  handleConfirmAction = async () => {
    if (this.state.confirmType === "Payment") {
      await this.handlePaymentConfirmation();
    } else if (this.state.confirmType === "Divorce") {
      await this.handleDivorceConfirmation();
    } else if (this.state.confirmType === "ResetFamily") {
      await this.handleFamilyResetConfirmation();
    } else if (this.state.confirmType === "ResetFaculty") {
      await this.handleFacultyResetConfirmation();
    } else if (this.state.confirmType === "UserBlock") {
      await this.handleUserBlockConfirmation();
    } else if (this.state.confirmType === "UserPasswordReset") {
      await this.handleUserPasswordResetConfirmation();
    }
  };

  handleUserPasswordResetConfirmation = async () => {
    await this.setState({
      isOpenDivorceConfirmationModal: false,
      openUserPasswordResetModal: true,
    });
  };

  handleUserBlockConfirmation = async () => {
    let record = this.state.recordToConfirm;
    let userId = record._id;
    let method = "PUT";
    let query = "";
    if (record.isAdminBlocked) {
      query = "unblock=true";
    } else {
      query = "block=true";
    }
    let url = `users/block/${userId}?${query}`;
    await fetchMethodRequest(method, url).then(async (resp) => {
      if (resp) {
        if (resp.respCode && resp.respMessage) {
          showToasterMessage(resp.respMessage, "success");
        } else if (resp.errorMessage) {
          showToasterMessage(resp.errorMessage, "error");
        }
      }
    });
    await this.closeDivorceConfirmationModal();
  };

  handleDivorceConfirmation = async () => {
    let record = this.state.recordToConfirm;
    let userEmail = record.email;
    let method = "POST";
    let url = "users/divorce";
    let body = { email: userEmail };
    await fetchMethodRequest(method, url, body).then(async (resp) => {
      if (resp) {
        if (resp.respCode && resp.respMessage) {
          showToasterMessage(resp.respMessage, "success");
        } else if (resp.errorMessage) {
          showToasterMessage(resp.errorMessage, "error");
        }
      }
    });
    await this.closeDivorceConfirmationModal();
  };
  // handleFamilyDivorceConfirmation = async () => {
  //   let record = this.state.recordToConfirm;
  //   let userEmail = record.email;
  //   let method = "POST";
  //   let url = "users/divorce";
  //   let body = { email: userEmail };
  //   await fetchMethodRequest(method, url, body).then(async (resp) => {
  //     if (resp) {
  //       if (resp.respCode && resp.respMessage) {
  //         showToasterMessage(resp.respMessage, "success");
  //       } else if (resp.errorMessage) {
  //         showToasterMessage(resp.errorMessage, "error");
  //       }
  //     }
  //   });
  //   await this.closeDivorceConfirmationModal();
  // };

  handlePaymentConfirmation = async () => {
    let record = this.state.recordToConfirm;
    let paymentId = record._id;
    let method = "POST";
    let url = `payments/adminPaymentUpdate/${paymentId}`;
    let body = { payment_isCompleted: true, payment_details: { type: "cash" } };
    await fetchMethodRequest(method, url, body).then(async (resp) => {
      if (resp) {
        if (resp.respCode && resp.respMessage) {
          showToasterMessage(resp.respMessage, "success");
        } else if (resp.errorMessage) {
          showToasterMessage(resp.errorMessage, "error");
        }
      }
    });
    await this.closeDivorceConfirmationModal();
  };

  handleFamilyResetConfirmation = async () => {
    let record = this.state.recordToConfirm;
    let directoryId = record._id;
    let method = "POST";
    let body = {};
    let url = `directorys/resetUsers/${directoryId}`;
    await fetchMethodRequest(method, url, body).then(async (resp) => {
      if (resp) {
        if (resp.respCode && resp.respMessage) {
          showToasterMessage(resp.respMessage, "success");
        } else if (resp.errorMessage) {
          showToasterMessage(resp.errorMessage, "error");
        }
      }
    });
    await this.closeDivorceConfirmationModal();
  };
  handleFacultyResetConfirmation = async () => {
    let record = this.state.recordToConfirm;
    let directoryId = record._id;
    let method = "POST";
    let body = {};
    let url = `faculties/${directoryId}`;
    await fetchMethodRequest(method, url, body).then(async (resp) => {
      if (resp) {
        if (resp.respCode && resp.respMessage) {
          showToasterMessage(resp.respMessage, "success");
        } else if (resp.errorMessage) {
          showToasterMessage(resp.errorMessage, "error");
        }
      }
    });
    await this.closeDivorceConfirmationModal();
  };
  getDivorceConfirmationModal = () => {
    return (
      <DivorceConfModal
        open={this.state.isOpenDivorceConfirmationModal}
        close={this.closeDivorceConfirmationModal}
        confirm={this.handleConfirmAction}
        confirmationText={this.state.confirmModalText}
        user={this.state.recordToConfirm}
      />
    );
  };

  sendColumnDataToServer = async (value, type) => {
    let method = type;
    let url = "";
    let keyname = `${this.props.type}_column_order`;
    if (type == "PUT") {
      url = `employees/preferences/${this.state.loginData._id}`;

      let listPreferences = {};
      listPreferences[keyname] = value;
      let body = { listPreferences: listPreferences };
      await fetchMethodRequest(method, url, body).then(async (resp) => {
        if (resp) {
          if (resp.respCode) {
            return;
          } else if (resp.errorMessage) {
            showToasterMessage(resp.errorMessage, "error");
          }
        }
      });
    } else {
      url = `employees/preferences/${this.state.loginData._id}?key=${keyname}`;
      await fetchMethodRequest(method, url).then(async (resp) => {
        if (resp) {
          if (resp.respCode) {
            if (resp.details) {
              localStorage.setItem(
                `${this.props.type}_column_order`,
                JSON.stringify(resp.details)
              );
              // hari check where this should go
              if (this.props.getTableFields) {
                this.setState(
                  {
                    tablefields: this.props.getTableFields(),
                    originalTableFields: this.props.getTableFields(),
                    isLoading: false,
                    mobileListFields: this.props.getTableFields(),
                    displayViewOfForm: this.props.displayViewOfForm,
                  },
                  async () => {
                    // let colOrder = localStorage.getItem(
                    //   `${ this.props.type }_column_order`
                    // );
                    let colOrder = resp.details;
                    // let columns = await JSON.parse(colOrder);
                    if (colOrder && colOrder.length > 0) {
                      await this.getTableFieldsOrder();
                    } else {
                      await this.updateColumnsOrder(null);
                    }
                  }
                );
              }
            } else {
              if (this.props.getTableFields) {
                this.setState(
                  {
                    tablefields: this.props.getTableFields(),
                    originalTableFields: this.props.getTableFields(),
                    isLoading: false,
                    mobileListFields: this.props.getTableFields(),
                    displayViewOfForm: this.props.displayViewOfForm,
                  },
                  async () => {
                    await this.updateColumnsOrder(null);
                  }
                );
              }
            }
          } else if (resp.errorMessage) {
            showToasterMessage(resp.errorMessage, "error");
          }
        }
      });
    }
  };

  commonAlertModal = () => {
    return (
      <AlertModal
        open={this.state.isOpenAlertModal}
        close={() =>
          this.setState({
            isOpenAlertModal: false,
            actions: "",
          })
        }
        alertText={this.state.alertText}
      />
    );
  };

  getUserPasswordResetModal = () => {
    return (
      <UserPasswordResetModal
        openUserPasswordResetModal={this.state.openUserPasswordResetModal}
        user={this.state.recordToConfirm || this.state.selectedRows[0]}
        cancelReset={this.cancelUserPwdResetModal}
        entityType={this.props.entityType}
      />
    );
  };

  getPaymentApproveModal = () => {
    return (
      <PaymentApproveModal
        openUserPasswordResetModal={this.state.openPaymentApproveModal}
        selectedRows={this.state.selectedRows}
        selectedRowsId={this.state.selectedRowsId}
        cancelPayment={this.cancelPaymentApproveModal}
      />
    );
  };

  getPaymentsFormModal = () => {
    return (
      <PaymentsFormModal
        openPaymentsFormModal={this.state.isOpenFormModal}
        cancelReset={this.closeFormModal}
      // selectedRows={this.state.selectedRows}
      // selectedRowsId={this.state.selectedRowsId}
      // cancelPayment={this.cancelPaymentApproveModal}
      />
    );
  };

  cancelPaymentApproveModal = async () => {
    await this.setState({
      openPaymentApproveModal: false,
      recordToConfirm: null,
      confirmModalText: null,
      confirmType: null,
      actions: "",
    });
    await this.getDataFromServer(this.state.filterCriteria);
  };

  render() {
    return (
      <div>
        {this.state.isDisplay ? <Redirect to="/sendgroupemail" /> : null}
        {this.getTotalCard()}
        {this.getMobileCard()}
        {this.state.isOpenFormModal && this.props.type === "Payments" ? this.getPaymentsFormModal() : this.state.isOpenFormModal ? this.getFormModal() : null}
        {this.state.isOpenFamilyFormModal ? this.getFamilyModal() : null}
        {this.state.isOpenShowHideColumnsModal
          ? this.getOpenShowHideColumnsModal()
          : null}
        {this.state.openDeleteModal ? this.getDeleteRowModal() : null}
        {this.state.isOpenAlertModal ? this.commonAlertModal() : null}
        {this.state.openViewModal && this.props.type !== "Family" ? this.getViewModal() :
          this.state.openViewModal && this.props.type === "Family" ? this.getFamilyViewModal()
            : null}
        {this.props.sample && this.state.openBulkUploadModal
          ? this.getBulkUploadModal()
          : null}
        {this.state.openConfirmationModal ? this.getConfirmatioinModal() : null}
        {this.state.sessionExpiryModal ? this.getSessionExpiryModal() : null}
        {this.state.isOpenDivorceConfirmationModal
          ? this.getDivorceConfirmationModal()
          : null}
        {this.state.openUserPasswordResetModal
          ? this.getUserPasswordResetModal()
          : null}
        {this.state.openPaymentApproveModal
          ? this.getPaymentApproveModal()
          : null}
      </div>
    );
  }
}

const dropdownOptionsStyle = {
  padding: "4px 6px",
  fontSize: 12,
  fontWeight: "500",
  color: "white",
};

export default withTranslation("common")(DataTables);
