import React from "react";
import ProductsDT from "./ProductsDT";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import store from "../../../App/store";
import DataTables from "../../CommonDataTable/DataTable";

export default class Products extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      languageData: "",
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState();
    let languageData =
      storeData &&
        storeData.settingsData &&
        storeData.settingsData.settings &&
        storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  }

  getTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: false,
        displayInSettings: false,
      },
      // {
      //   textAlign: "center",
      //   width: 47,
      //   field: "Sno",
      //   header: "Sno",
      //   filter: false,
      //   sortable: false,
      //   show: true,
      //   placeholder: "Search",
      // },
      {
        textAlign: "left",
        show: true,
        width: 160,
        field: "store_name",
        fieldType: "Link",
        header: "Store Name",
        filter: false,
        sortable: true,
        style: {
          color: config.templateColor,
          cursor: "pointer",
          textTransform: "capitalize",
        },
        displayInSettings: true,
      },
      {
        textAlign: "left",
        width: 180,
        show: true,
        field: "school_name",
        mobile: true,
        header: "School Name",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        textAlign: "left",
        width: 180,
        show: true,
        mobile: true,
        field: "product_name",
        header: "Product Name",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        textAlign: "left",
        width: 140,
        field: "other_name",
        mobile: true,
        show: true,
        header: "Other Name",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },

      {
        textAlign: "center",
        width: 100,
        mobile: true,
        show: true,
        field: "msrp",
        header: "Msrp",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        textAlign: "center",
        width: 150,
        mobile: true,
        show: true,
        field: "sku",
        header: "Sku",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        textAlign: "center",
        width: 100,
        mobile: true,
        show: true,
        field: "cost",
        fieldType: "Currency",
        header: "Cost",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },

      {
        textAlign: "center",
        width: 100,
        mobile: true,
        show: true,
        field: "price",
        fieldType: "Currency",
        header: "Price",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        textAlign: "center",
        width: 150,
        mobile: true,
        show: true,
        field: "availableFrom",
        dateFormat: "MM-DD-YYYY",
        withoutTimeoffset: false,
        fieldType: "Date",
        header: "Start Date",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        textAlign: "center",
        width: 150,
        mobile: true,
        show: true,
        field: "availableTo",
        withoutTimeoffset: false,
        fieldType: "Date",
        dateFormat: "MM-DD-YYYY",
        header: "End Date",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        textAlign: "center",
        width: 100,
        show: true,
        mobile: true,
        field: "stock",
        header: "Stock",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        textAlign: "left",
        width: 160,
        field: "description",
        mobile: false,
        show: true,
        header: "Description",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      // {
      //   "textAlign": "left",
      //   "width": 160,
      //   "field": "image_url",
      //   "show": true,
      //   "header": "Image Url",
      //   "filter": true,
      //   "sortable": true
      // },
      {
        textAlign: "center",
        width: 110,
        mobile: false,
        field: "display_order",
        show: true,
        header: "Display Order",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        textAlign: "center",
        width: 120,
        mobile: false,
        field: "purchase_limit",
        show: true,
        header: "Purchase Limit",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        textAlign: "center",
        width: 90,
        field: "frequency",
        mobile: false,
        show: true,
        header: "Frequency",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 80,
        fieldType: "BoolText",
        field: "isCopiedProduct",
        header: "Is Duplicate Product",
        options: config.preloadOptions,
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 70,
        field: "Actions",
        fieldType: "Actions",
        header: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
      },
    ];
    return data;
  };

  getFormFields = () => {
    return [
      {
        required: true,
        value: "",
        searchField: "Name",
        searchApi: "schools",
        type: "autoComplete",
        options: [
          { school_name: "Name" },
          { district_id: "district_id" },
          { school_id: "school_id" },
        ],
        name: "school_name",
        label: "School Name",
        id: "Name",
        placeholder: "School Name",
        isEdit: true
      },
      {
        required: true,
        value: "",
        type: "dropDown",
        name: "store_name",
        label: "Select Stores",
        id: "store_name",
        isMultiSelect: false,
        isEdit: false,
        options: [],
        placeholder: "Select Stores",
        optionsName: "stores",
      },
      {
        value: "",
        type: "text",
        name: "product_name",
        label: "Product Name",
        id: "product_name",
        placeholder: "Product Name",
        required: true,
      },
      {
        value: "",
        type: "text",
        name: "other_name",
        label: "Other Name",
        id: "other_name",
        placeholder: "Other Name",
      },
      {
        show: false,
        value: "",
        type: "text",
        name: "sku",
        label: "Sku",
        id: "sku",
        placeholder: "Sku",
        required: true,
      },
      {
        value: "",
        type: "dropDown",
        name: "color",
        label: "Color",
        id: "color",
        options: config.colorTypes,
        placeholder: "Select Color",
        isMultiSelect: true,
        // "required": true
      },
      {
        value: "",
        type: "dropDown",
        name: "size",
        label: "Size",
        id: "size",
        options: config.sizeTypes,
        placeholder: "Select Size",
        isMultiSelect: true,
        // "required": true
      },
      {
        value: "",
        type: "text",
        name: "cost",
        label: "Cost",
        showHelpIcon: true,
        helpText: "CostHelpText",
        id: "cost",
        placeholder: "Cost",
        required: true,
      },
      {
        value: "",
        type: "text",
        name: "price",
        label: "Price",
        id: "Price",
        showHelpIcon: true,
        helpText: "PriceHelpText",
        placeholder: "Price",
        required: true,
      },
      {
        value: "",
        type: "text",
        name: "msrp",
        label: "Msrp",
        showHelpIcon: true,
        helpText: "MsrpHelpText",
        id: "msrp",
        placeholder: "Msrp",
        required: true,
      },
      {
        value: "",
        type: "text",
        name: "stock",
        label: "Stock",
        id: "stock",
        placeholder: "Stock",
        required: true,
      },
      {
        value: "",
        type: "text",
        name: "purchase_limit",
        label: "Purchase Limit",
        id: "purchase_limit",
        placeholder: "Purchase Limit",
        required: true,
      },
      {
        value: "",
        type: "text",
        name: "frequency",
        label: "Frequency",
        id: "frequency",
        placeholder: "Frequency",
        required: true,
      },
      {
        value: "",
        type: "textarea",
        name: "description",
        label: "Description",
        id: "description",
        placeholder: "Description",
        required: true,
      },
      {
        value: "",
        type: "text",
        name: "display_order",
        label: "Display Order",
        id: "display_order",
        placeholder: "Display Order",
        required: true,
      },
      {
        value: "",
        type: "date",
        name: "availableFrom",
        label: "Available From",
        id: "availableFrom",
        placeholder: "Available From",
        dateFormat:'MM/dd/yyyy h:mm aa',
        timeInputLabel:true,
        showTimeInput:true,
        required: true,
      },
      {
        value: "",
        type: "date",
        name: "availableTo",
        label: "Available To",
        id: "availableTo",
        placeholder: "Available To",
        dateFormat:'MM/dd/yyyy h:mm aa',
        timeInputLabel:true,
        showTimeInput:true,
        required: true,
      },
      {
        value: "",
        type: "multipleprofile",
        name: "multipleImages",
        label: "Choose Images",
        id: "Image",
        placeholder: "Image",
        imagePath: "product",
      },
    ];
  };

  render() {
    return (
      <DataTables
        getTableFields={this.getTableFields}
        formFields={this.getFormFields}
        addRequried={true}
        editRequired={true}
        deleteRequired={true}
        viewRequired={true}
        settingsRequired={true}
        globalSearch={"Product Name/School Name"}
        type="Products"
        exportToCsv={true}
        apiUrl={apiCalls.Products}
        actionsTypes={actionsTypes}
      />
    );
  }
}

const actionsTypes = [
  {
    name: "Delete",
    options: [{ label: "Delete", value: "Delete", show: true, multiple: true }],
  },
];
