import React, { PureComponent } from "react";
import { Field, reduxForm } from "redux-form";
import { Button, CardBody } from "reactstrap";
import { Redirect } from "react-router";

import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { load as loadAccount } from "../../../../redux/reducers/commonReducer";

// fecth method from service.js file
import fetch from "../../../../config/service";
import config from "../../../../config/config";
import configMessage from "../../../../config/configMessages";
// show message
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
import DefaultInput from "../../../../shared/components/form/DefaultInput";
import validate from "../../../Validations/validate";
import Loader from "../../../App/Loader";
import EyeIcon from "mdi-react/EyeIcon";
import apiCalls from "../../../../config/apiCalls";

//validations
let newpassval = (value) =>
  value
    ? config.passwordRegex.test(value)
      ? undefined
      : "The Password should contain minimum 12 characters with one Uppercase letter, one Lower case letter,one Number and a special Character"
    : configMessage.fillField;

let adminnewpassval = (value) =>
  value
    ? config.adminpasswordRegex.test(value)
      ? undefined
      : "The Password should contain minimum 16 characters with one Uppercase letter, one Lower case letter,one Number and a special Character"
    : configMessage.fillField;
class ChangeRecoverPassword extends PureComponent {
  constructor(props) {
    super(props);
    this.buttonActionType = null;
    this.state = {
      isLogin: false,
      isLoading: true,
    };
    // if (this.props.location.search.includes("active=true&entityType=user")) {
    this.checkValidUrl()
    // }
  }

  componentDidMount() {
    this.setState({ isLoading: false });
    if (this.props.location.search.includes("entityType=user")) {
      this.setState({
        userRole: true
      });
    }


  }
  checkValidUrl = async () => {
    await this.setState({
      isLoading: true
    });
    let method = '';
    let body = {};
    let url = '';
    method = 'GET';

    let arr1 = this.props.location.pathname.split("/");
    let token = arr1[2]
    if (this.props.location.search.includes("entityType=user")) {
      url = `${apiCalls.VerifyLink}?token=${token}&active=${this.props.location.search.includes("active=")}&entityType=user`;
    } else {
      url = `${apiCalls.VerifyLink}?token=${token}&active=${this.props.location.search.includes("active=")}`;
    }

    fetch(method, url).then(async response => {
      if (response && response.respCode && response.respMessage) {
        await this.setState({
          validUrl: true
        });
        showToasterMessage(response.respMessage, 'success');
      } else if (response && response.errorMessage) {
        showToasterMessage(response.errorMessage, 'error');
        await this.setState({
          validUrl: false
        });
      }
      await this.setState({
        isLoading: false
      });
    })
  }
  // on value change in input
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  showPassword = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };

  showConfirmPassword = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({
      showConfirmPassword: !prevState.showConfirmPassword,
    }));
  };

  // handle login user data
  handleUserPassword = (values) => {
    this.setState({
      isLoading: true,
    });
    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.enEmail
    ) {
      values.enEmail = this.props.match.params.enEmail;
    }
    values.entityType = config.entityType;
    let query = "",
      fromScreen = "";
    if (this.props.location && this.props.location.search) {
      if (this.props.location.search.includes("entityType=user")) {
        fromScreen = "?entityType=user";
        values.entityType = "user";
      } else if (this.props.location.search === "?active=true") {
        query = "?active=true";
        fromScreen = "?active=true";
      }
    }
    if (this)
      return fetch("POST", `auth/changeRecoverPassword${query}`, values)
        .then((response) => {
          if (response && response.respCode && response.respCode === 200) {
            showToasterMessage(response.respMessage, "success");
            if (fromScreen.includes("entityType=user")) {
              document.getElementById("applink").click();
            } else {
              this.setState({
                isLogin: true,
              });
            }
          } else if (response && response.errorMessage) {
            // showToasterMessage(response.errorMessage, "error");
          }
          this.setState({
            isLoading: false,
          });
          this.clearInputFields();
        })
        .catch((err) => {
          return err;
        });
  };

  // clear input data
  clearInputFields = () => {
    this.props.reset();
  };

  submit = (values) => {
    if (values && values.newPassword === values.confirmPassword) {
      this.handleUserPassword(values);
    } else if (values.password !== values.confirmPassword) {
      if (!this.state.userRole) {
        newpassval = (values) => configMessage.passwordMatchValidation;
      } else {
        adminnewpassval = (values) => configMessage.passwordMatchValidation;
      }
    }
  };

  render() {
    const { handleSubmit } = this.props;
    return (
      <CardBody>
        {this.state.validUrl ?
          <form className="form" onSubmit={handleSubmit(this.submit)}>
            <div className="form__form-group">
              <Loader loader={this.state.isLoading} />
              <div className="form__form-group">
                <label className="form__form-group-label">New Password</label>
                <div className=" form__form-group-field">
                  <Field
                    className="inputLogin"
                    type={this.state.showConfirmPassword ? "text" : "password"}
                    name="newPassword"
                    component={DefaultInput}
                    placeholder="New Password"
                    validate={this.state.userRole ? [newpassval] : [adminnewpassval]}
                  />
                  <button
                    type="button"
                    className={`form__form-group-button${this.state.showConfirmPassword ? " active" : ""
                      }`}
                    onClick={(e) => this.showConfirmPassword(e)}
                  >
                    <EyeIcon />
                  </button>
                </div>
              </div>
              <div className="form__form-group">
                <label className="form__form-group-label">Confirm Password</label>
                <div className="form__form-group-field">
                  <Field
                    className="inputLogin"
                    type={this.state.showPassword ? "text" : "password"}
                    name="confirmPassword"
                    component={DefaultInput}
                    validate={this.state.userRole ? [newpassval] : [adminnewpassval]}
                    placeholder="Confirm Password"
                  />{" "}
                  <button
                    type="button"
                    className={`form__form-group-button${this.state.showPassword ? " active" : ""
                      }`}
                    onClick={(e) => this.showPassword(e)}
                  >
                    <EyeIcon />
                  </button>
                </div>
              </div>
            </div>
            <div className="pt-4" style={{ margin: "auto" }}>
              <Button color="primary" type="submit">
                Submit
              </Button>
              <Button
                style={{ marginLeft: "10px" }}
                color="primary"
                outline
                onClick={() => this.clearInputFields()}
              >
                Clear
              </Button>
              {this.state.isLogin ? <Redirect to="/Log_in" /> : null}
              <a id="applink" target="blank" href="https://apps.apple.com" />
            </div>
          </form> :
          <div style={{ width: 800, textAlign: "center" }}><b>The Url is invalid Please try again</b></div>}
      </CardBody>
    );
  }
}

ChangeRecoverPassword = reduxForm({
  form: "RecoverPassword form", // a unique identifier for this form
  validate,
  enableReinitialize: true,
})(ChangeRecoverPassword);

ChangeRecoverPassword = connect(
  (state) => ({
    initialValues: state.commonData.data, // pull initial values from account reducer
  }),
  { load: loadAccount } // bind account loading action creator
)(ChangeRecoverPassword);

export default ChangeRecoverPassword;
