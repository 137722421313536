/* eslint-disable react/prop-types */
import React from 'react';
import { Container, Row } from 'reactstrap';

import Profile from './components/Profile';

class ProfilePage extends React.Component {

  render() {
    return (
      <Container>
        <Row>
          <Profile
            onRef={(ref) => { this.profileRef = ref }}
            locationProps={this.props}
          />
        </Row>
      </Container>
    );
  }
}

export default ProfilePage;