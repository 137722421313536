import React from "react";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import fetchMethodRequest from "../../../../config/service";
import store from "../../../App/store";
import DataTables from "../../CommonDataTable/DataTable";
import filePath from "../../../../config/configSampleFiles";

export default class Teachers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      languageData: "",
    };
  }

  componentDidMount = async () => {
    await this.getSettingsFromServer();
    let loginData = localStorage.getItem("loginCredentials");
    if (loginData) {
      loginData = JSON.parse(loginData);
      if (loginData && loginData.role && loginData.role == "districtAdmin" || loginData.role == "schoolAdmin") {
        this.setState({
          loginRole: false,
        });
      } else {
        if (loginData && loginData.role) {
          this.setState({
            loginRole: true,
          });
        }
      }
    }
  };

  getSettingsFromServer = async () => {
    let filterCriteria = {},
      url;
    filterCriteria.sortfield = "sequenceNo";
    filterCriteria.direction = "asc";
    url = `settings`;
    fetchMethodRequest("GET", url)
      .then(async (res) => {
        if (res && res.settings[0]) {
          let pta_dues = res.settings[0].pta_dues;
          let supporting_donations = res.settings[0].supporting_donations;
          let paymentTypes = res.settings[0].payment_types;
          await this.setState({
            pta_dues: pta_dues,
            supporting_donations: supporting_donations,
            paymentTypes: paymentTypes,
          });
        }
      })
      .catch((err) => {
        return err;
      });
  };

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState();
    let languageData =
      storeData &&
        storeData.settingsData &&
        storeData.settingsData.settings &&
        storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  }

  getTableFields = () => {
    let data = [
      // {
      //   textAlign: "center",
      //   width: 47,
      //   field: "",
      //   fieldType: "multiple",
      //   header: "",
      //   selectionMode: "multiple",
      //   show: true,
      //   mobile: true,
      //   displayInSettings: false,
      // },
      // {
      //   textAlign: "center",
      //   width: 47,
      //   field: "Sno",
      //   header: "Sno",
      //   filter: false,
      //   sortable: false,
      //   placeholder: "Search",
      //   show: true,
      // },
      {
        show: true,
        textAlign: "left",
        width: 180,
        field: "name",
        mobile: true,
        fieldType: "Link",
        header: "District Name",
        filter: false,
        placeholder: "Search",
        sortable: true,
        style: {
          color: config.templateColor,
          cursor: "pointer",
          textTransform: "capitalize",
        },
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 120,
        field: "district_id",
        mobile: true,
        header: "District Code",
        filter: false,
        placeholder: "Search",
        sortable: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 80,
        fieldType: "BoolText",
        field: "preload",
        header: "Preload",
        options: config.preloadOptions,
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      // {
      //   show: true,
      //   textAlign: "center",
      //   width: 70,
      //   fieldType: "Bool",
      //   field: "pta_dues",
      //   header: "Payment",
      //   options: config.isPaymentEnabledOptions,
      //   filter: false,
      //   sortable: false,
      //   displayInSettings: true,
      // },

      {
        show: true,
        textAlign: "center",
        width: 100,
        fieldType: "Array",
        field: "payment_types",
        header: "Payment",
        filter: false,
        sortable: false,
        displayInSettings: true,
      },

      {
        show: true,
        textAlign: "left",
        width: 180,
        field: "state_name",
        mobile: true,
        header: "State Name",
        filter: false,
        placeholder: "Search",
        sortable: true,
        textCapitalize: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 100,
        field: "pincode",
        mobile: true,
        header: "Zip Code",
        filter: false,
        placeholder: "Search",
        sortable: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 180,
        field: "created",
        mobile: true,
        fieldType: "Date",
        header: "Created",
        filter: false,
        sortable: true,
        placeholder: config.dateDBFormat,
        dateFormat: config.dateDBFormat,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 60,
        field: "Actions",
        header: "Actions",
        fieldType: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
      },
    ];
    return data;
  };

  getFormFields = () => {
    let statesNames = [
      { label: "Alabama - AL", value: "Alabama - AL" },
      { label: "Alaska - AK", value: "Alaska - AK" },
      { label: "Arizona - AZ", value: "Arizona - AZ" },
      { label: "Arkansas - AR", value: "Arkansas - AR" },
      { label: "California - CA", value: "California - CA" },
      { label: "Colorado - CO", value: "Colorado - CO" },
      { label: "Connecticut - CT", value: "Connecticut - CT" },
      { label: "Delaware - DE", value: "Delaware - DE" },
      { label: "Florida - FL", value: "Florida - FL" },
      { label: "Georgia - GA", value: "Georgia - GA" },
      { label: "Hawaii - HI", value: "Hawaii - HI" },
      { label: "Idaho - ID", value: "Idaho - ID" },
      { label: "Illinois - IL", value: "Illinois - IL" },
      { label: "Indiana - IN", value: "Indiana - IN" },
      { label: "Iowa - IA", value: "Iowa - IA" },
      { label: "Kansas - KS", value: "Kansas - KS" },
      { label: "Kentucky - KY", value: "Kentucky - KY" },
      { label: "Louisiana - LA", value: "Louisiana - LA" },
      { label: "Maine - ME", value: "Maine - ME" },
      { label: "Maryland - MD", value: "Maryland - MD" },
      { label: "Massachusetts - MA", value: "Massachusetts - MA" },
      { label: "Michigan - MI", value: "Michigan - MI" },
      { label: "Minnesota - MN", value: "Minnesota - MN" },
      { label: "Mississippi - MS", value: "Mississippi - MS" },
      { label: "Missouri - MO", value: "Missouri - MO" },
      { label: "Montana - MT", value: "Montana - MT" },
      { label: "Nebraska - NE", value: "Nebraska - NE" },
      { label: "Nevada - NV", value: "Nevada - NV" },
      { label: "New Hampshire - NH", value: "New Hampshire - NH" },
      { label: "New Jersey - NJ", value: "New Jersey - NJ" },
      { label: "New Mexico - NM", value: "New Mexico - NM" },
      { label: "New York - NY", value: "New York - NY" },
      { label: "North Carolina - NC", value: "North Carolina - NC" },
      { label: "North Dakota - ND", value: "North Dakota - ND" },
      { label: "Ohio - OH", value: "Ohio - OH" },
      { label: "Oklahoma - OK", value: "Oklahoma - OK" },
      { label: "Oregon - OR", value: "Oregon - OR" },
      { label: "Pennsylvania - PA", value: "Pennsylvania - PA" },
      { label: "Rhode Island - RI", value: "Rhode Island - RI" },
      { label: "South Carolina - SC", value: "South Carolina - SC" },
      { label: "South Dakota - SD", value: "South Dakota - SD" },
      { label: "Tennessee - TN", value: "Tennessee - TN" },
      { label: "Texas - TX", value: "Texas - TX" },
      { label: "Utah - UT", value: "Utah - UT" },
      { label: "Vermont - VT", value: "Vermont - VT" },
      { label: "Virginia - VA", value: "Virginia - VA" },
      { label: "Washington - WA", value: "Washington - WA" },
      { label: "West Virginia - WV", value: "West Virginia - WV" },
      { label: "Wisconsin - WI", value: "Wisconsin - WI" },
      { label: "Wyoming - WY", value: "Wyoming - WY" },
    ];
    return [
      {
        value: "",
        type: "dropDown",
        name: "state_name",
        label: "State Name",
        id: "state_name",
        placeholder: "State Name",
        options: statesNames,
        required: true,
      },
      {
        value: "",
        type: "text",
        name: "name",
        label: "District Name",
        id: "name",
        placeholder: "District Name",
        required: true,
      },
      {
        value: "",
        type: "number",
        name: "pincode",
        label: "Zip Code",
        id: "pinCode",
        placeholder: "Pin Code",
        required: true,
      },

      // {
      //   value: "",
      //   type: "dropDown",
      //   name: "pta_dues",
      //   label: "Payment Dues",
      //   id: "Payment Dues",
      //   options: this.state.pta_dues,
      //   placeholder: "Payment Dues",
      //   isMultiSelect: true,
      //   required: true,
      // },
      // {
      //   value: "",
      //   type: "dropDown",
      //   name: "supporting_donations",
      //   label: "Supporting Donations",
      //   id: "Supporting Donations",
      //   options: this.state.supporting_donations,
      //   placeholder: "Supporting Donations",
      //   isMultiSelect: true,
      //   required: true,
      // },
      // {
      //   value: "",
      //   type: "dropDown",
      //   name: "payment_types",
      //   label: "Payment Types",
      //   id: "Payment Types",
      //   options: this.state.paymentTypes,
      //   placeholder: "Payment Types",
      //   isMultiSelect: true,
      //   required: true,
      // },
      {
        value: "",
        type: "radio",
        name: "preload",
        label: "Preload",
        id: "preload",
        options: [
          { label: "True", value: "True", showError: true },
          { label: "False", value: "False" },
        ],
        defaultChecked: "True",
        required: true,
      },
      // {
      //   value: "",
      //   type: "text",
      //   name: "paypal_client_id",
      //   label: "Paypal Client Id",
      //   id: "clientId",
      //   placeholder: "Client Id",
      //   //required: true,
      // },
      // {
      //   value: "",
      //   type: "text",
      //   name: "paypal_client_secret",
      //   label: "Paypal Secret Key",
      //   id: "secretKey",
      //   placeholder: "Secret Key",
      //   //required: true,
      // },
      // {
      //   value: "",
      //   type: "dropDown",
      //   name: "payment_types",
      //   label: "Payment Types",
      //   id: "Payment Types",
      //   options: this.state.paymentTypes,
      //   placeholder: "Payment Types",
      //   isMultiSelect: true, 
      //   required: true,
      // },

      {
        value: "",
        type: "dropDown",
        name: "payment_types",
        label: "Payment Types",
        id: "Payment Types",
        options: this.state.paymentTypes,
        placeholder: "Payment Types",
        isMultiSelect: true,
        dependentTextField: ["clientId" , "secretKey"],
        name1:"Paypal",
        required: true,
      },
      {
        show: false,
        value: "",
        type: "text",
        name: "paypal_client_id",
        label: "Paypal Client Id",
        id: "clientId",
        placeholder: "Client Id",
        dependentEditTextField: true,
        name1:"Paypal",
        required: true,
      },
      {
        show:false,
        value: "",
        type: "text",
        name: "paypal_client_secret",
        label: "Paypal Secret Key",
        id: "secretKey",
        placeholder: "Secret Key",
        dependentEditTextField: true,
        name1:"Paypal",
        required: true,
      },
    ];
  };

  render() {
    return (
      <DataTables
        getTableFields={this.getTableFields}
        formFields={this.getFormFields}
        globalSearch={"State Name/District Name/Zip Code"}
        addRequried={this.state.loginRole}
        exportToCsv={true}
        editRequired={true}
        deleteRequired={true}
        viewRequired={true}
        settingsRequired={true}
        sample={true}
        sampleFilePath={filePath.district}
        bulkApi={"district"}
        type="District"
        apiUrl={apiCalls.District}
        viewQuery="requireSchool=true"
      // actionsTypes={actionsTypes}
      />
    );
  }
}

const actionsTypes = [
  {
    name: "Delete",
    options: [{ label: "Delete", value: "Delete", show: true, multiple: true }],
  },
];
