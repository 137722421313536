import React from "react";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import DataTables from "../../CommonDataTable/DataTable";
import fetchMethodRequest from "../../../../config/service";

export default class Employees extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      languageData: "",
      roleOptions: [],
    };
  }

  componentDidMount = async () => {
    this.getRolesFromServer();
  };

  getRolesFromServer = async () => {
    let filterCriteria = {},
      url;
    filterCriteria.sortfield = "sequenceNo";
    filterCriteria.direction = "asc";
    filterCriteria.criteria = [{ key: "type", value: "Admin", type: "eq" }];
    url = `roles?filter=${JSON.stringify(filterCriteria)}`;
    fetchMethodRequest("GET", url)
      .then(async (res) => {
        if (res && res.roles) {
          let roleOptions = [];
          for (let i = 0; i < res["roles"].length > 0; i++) {
            if (res["roles"][i]["role"] != null) {
              let roles = res["roles"][i]["role"];
              let tempRoles = roles[0].toUpperCase() + roles.slice(1);
              roleOptions.push({ label: tempRoles, value: roles });
            }
          }
          await this.setState({
            roleOptions: roleOptions,
          });
        }
      })
      .catch((err) => {
        return err;
      });
  };

  getTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: false,
      },
      // {
      //   show: true,
      //   textAlign: "center",
      //   width: 47,
      //   field: "Sno",
      //   header: "Sno",
      //   filter: false,
      //   sortable: false,
      //   placeholder: "Search",
      // },
      {
        show: true,
        textAlign: "left",
        width: 200,
        field: "username",
        mobile: true,
        header: "Employee Name",
        filter: false,
        fieldType: "Link",
        sortable: true,
        displayInSettings: true,
        style: {
          color: config.templateColor,
          cursor: "pointer",
          textTransform: "capitalize",
        },
      },
      {
        show: true,
        textAlign: "left",
        width: 180,
        field: "email",
        mobile: true,
        header: "Email",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 100,
        mobile: true,
        field: "phone",
        header: "Phone",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 140,
        field: "role",
        mobile: true,
        header: "Role",
        filter: false,
        sortable: true,
        fieldType: "Role",
        options: config.employeeTableRolesOptions,
        style: {
          padding: "4px 6px",
          fontSize: 12,
          color: "white",
          textTransform: "capitalize",
          fontWeight: "bold",
        },
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 150,
        field: "school_name",
        mobile: true,
        header: "School Name",
        filter: false,
        sortable: true,
        textCapitalize: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 150,
        mobile: true,
        field: "district_name",
        header: "District Name",
        filter: false,
        sortable: true,
        textCapitalize: true,
        displayInSettings: true,
      },

      {
        show: true,
        textAlign: "center",
        width: 100,
        field: "status",
        mobile: true,
        fieldType: "Badge",
        header: "Status",
        filter: false,
        options: config.tabfieldsStatusTypes,
        sortable: false,
        style: {
          padding: "4px 6px",
          fontSize: 12,
          color: "white",
          textTransform: "capitalize",
          fontWeight: "bold",
        },
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 70,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
      },
    ];
    return data;
  };

  getFormFields = () => {
    return [
      {
        value: "",
        type: "text",
        name: "fname",
        label: "First Name",
        id: "firstName",
        placeholder: "First Name",
        required: true,
      },
      {
        value: "",
        type: "text",
        name: "lname",
        label: "Last Name",
        id: "lName",
        placeholder: "Last Name",
        required: true,
      },
      {
        value: "",
        type: "text",
        name: "email",
        label: "Email",
        id: "email",
        placeholder: "Email",
        required: true,
      },
      {
        value: "",
        type: "text",
        name: "phone",
        label: "Phone",
        id: "phone",
        placeholder: "Phone",
        required: true,
      },
      {
        value: "",
        type: "dropDown",
        name: "role",
        label: "Role",
        id: "role",
        placeholder: "Role",
        dependent: [
          { districtAdmin: "district_name" },
          { schoolAdmin: "school_name" },
          { GroupAdmin: "isSelectRole" },
        ],
        options: this.state.roleOptions ? this.state.roleOptions : [],
        required: true,
      },
      {
        show: false,
        value: "",
        type: "radio",
        name: "isSelectRole",
        label: "Select Type",
        id: "isSelectRole",
        options: [
          { label: "School", value: "schoolAdmin", showError: true },
          { label: "District", value: "districtAdmin" },
        ],
        dependent: [
          { districtAdmin: "district_name" },
          { schoolAdmin: "school_name" },

        ],
        defaultChecked: "True",
        required: true,
      },
      {
        show: false,
        required: true,
        value: "",
        searchField: "name",
        searchApi: "districts",
        type: "autoComplete",
        options: [{ district_id: "district_id" }, { district_name: "name" }],
        name: "district_name",
        label: "District Name",
        id: "district_id",
        placeholder: "District Name",
      },
      {
        show: false,
        required: true,
        value: "",
        searchField: "Name",
        searchApi: "schools",
        type: "autoComplete",
        options: [
          { school_name: "Name" },
          { district_id: "district_id" },
          { school_id: "school_id" },
          { district_name: "district_name" },
        ],
        name: "school_name",
        label: "School Name",
        id: "Name",
        placeholder: "School Name",
      },
    ];
  };
  render() {
    return (
      <DataTables
        getTableFields={this.getTableFields}
        formFields={this.getFormFields}
        addRequried={true}
        exportToCsv={true}
        editRequired={true}
        deleteRequired={true}
        viewRequired={true}
        settingsRequired={true}
        globalSearch={"Employee Name/Email/School Name"}
        type="Administrators"
        entityType="employee"
        apiUrl={apiCalls.Employee}
        actionsTypes={actionsTypes}
      />
    );
  }
}

const actionsTypes = [
  {
    name: "Delete",
    options: [{ label: "Delete", value: "Delete", show: true, multiple: true }],
  },
  {
    name: "Active",
    options: [{ label: "Active", value: "Active", show: true, multiple: true }],
  },
  {
    name: "InActive",
    options: [
      { label: "InActive", value: "Inactive", show: true, multiple: true },
    ],
  },
  {
    name: "Invite",
    options: [
      {
        label: "Invite",
        value: "Invite",
        show: true,
        multiple: true,
      },
    ],
  },
  {
    name: "ResetPassword",
    options: [
      {
        label: "Change Password",
        value: "ResetPassword",
        show: true,
        multiple: false,
      },
    ],
  },
];
