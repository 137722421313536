import React from "react";
import { Container } from "reactstrap";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import GroupData from "./components/GroupData";

const groupdata = ({ t }) => (
  <Container>
    <GroupData />
  </Container>
);

groupdata.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation("common")(groupdata);
