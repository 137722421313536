import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import EmailTemplate from './components/EmailTemplate';

const EmailTemplatesPage = () => (
  <Container>
    <EmailTemplate />
  </Container>
);

export default withTranslation('common')(EmailTemplatesPage);