import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Ecommerce from './components/Ecommerce';

const ecommerce = ({ t }) => (
  <Container>
    <Ecommerce />
  </Container>
);

ecommerce.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(ecommerce);