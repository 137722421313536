import React from 'react';
import config from '../../config/config';
import configImages from '../../config/configImages'
class LogoUI extends React.Component {
    render() {
        return (
            <div className='logo'>
                <h2 className='text-center my-2'>
                    <img src={configImages.loginLogo} className='hide-mobile img_htwt' ></img>
                </h2>
            </div>
        )
    }

}
export default LogoUI;
