import {
  SETTINGS_DATA,
  // AUTHENTICATE_ERROR_AUTH,
} from '../actions/settingsAction';

const initialState = {
  data: {},
  pincodeLength: 0,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SETTINGS_DATA:
      return { settings: action.data };
    case 'SETTINGS_PINCODE':
      return {
        ...state,
        pincodeLength: action.pincodeLength
      };
    case 'SETTINGS_PINCODE_RETURN':
      return state.pincodeLength;
    default:
      return state;
  }
}
