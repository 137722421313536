import React from 'react';
import { withTranslation } from 'react-i18next';

class DefaultClearInput extends React.Component {
    constructor(props) {
        super(props);

    }

    onChange = (event, type) => {
        if (event && event.target) {
            const { onChange, name } = this.props.input;
            // if (this.props.type === 'number') {
            //     if (event.target.value) {
            //         let num = event.target.value;
            //         if (name === 'noofDrawers' && num > 10) {
            //             num = 10;
            //         } else if (num <= 0) {
            //             num = 0;
            //         }
            //         onChange(num);
            //     } else {
            //         onChange('');
            //     }
            // } else {
            //     if (this.props.handleComments) {
            //         this.props.handleComments(event.target.value)
            //     }
            //     onChange(event.target.value);
            // }
            if (type === 'nocaptalize') {
                onChange(event.target.value);

            } else if (type === 'captalize') {
                let value = event.target.value.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
                onChange(value);
            }
        }
    }

    render() {
        const { input, placeholder, type, value, isDisable, onChange, t, makeValue, clearAll, meta: { touched, error }, className } = this.props;
        return (
            <div className="form__form-group-input-wrap">
                <div className="form__form-group-field ">
                    <input style={isDisable ? { background: 'lightgray', border: "none" } : input && (input.name === 'title') ?
                        { background: 'white', } : { background: 'white', textTransform: 'capitalize' }} {...input}
                        className='mb-0'
                        disabled={isDisable}
                        placeholder={placeholder}
                        value={makeValue}
                        type={type}
                        // onChange={(e) => {
                        //     this.onChange(e, 'captalize')
                        // }}
                        maxLength={this.props.maxLength ? this.props.maxLength : null}
                        className={className}
                    />
                </div>
                {/* {touched && error && <span className="form__form-group-error">{error}</span>} */}
                <div style={{ height: '13px' }}>
                    {touched && error && <span className="form__form-group-error">{t(error)}</span>}
                </div>
            </div>
        );
    }
}
export default withTranslation('common')(DefaultClearInput);
