
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Button, CardBody, Modal, Row } from 'reactstrap';

// fecth method from service.js file
import config from '../../../config/config';
import configMessage from '../../../config/configMessages';
// show message 
import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';
import DefaultInput from '../../../shared/components/form/DefaultInput';
import Select from '../../../shared/components/form/Select'
import validate from '../../Form/validate';
import TransactionModal from './PaymentSchoolModal'
import Loader from '../../App/Loader';
import fetchMethodRequest from '../../../config/service';
import AutoComplete from '../../Form/components/AutoComplete';
import apiCalls from '../../../config/apiCalls';
const required = value => (value || typeof value === 'string' ? undefined : configMessage.fillField);

class PaymentsFormModal extends React.Component {
    constructor(props) {
        super(props);
        this.buttonActionType = null;
        this.state = {
            isLoginSuccess: false,
            isLoading: true,
            districtId: "",
            schoolId: "",
            childsPaymentDetails: []
        };
    }

    componentDidMount() {
        this.setState({ isLoading: false });

    }

    // // on value change in input
    handleAutoCompleteData = async (event) => {

    }

    showPassword = (e) => {
        e.preventDefault();
        this.setState(prevState => ({
            showPassword: !prevState.showPassword
        }));
    }

    showConfirmPassword = (e) => {
        e.preventDefault();
        this.setState(prevState => ({
            showConfirmPassword: !prevState.showConfirmPassword
        }));
    }

    // handle login user data
    handleUserPassword = async (values) => {
        await this.setState({
            isLoading: true
        });
        let url;
        url = apiCalls.TransactionDetails;
        values.school_id = values.school.school_id
        values.district_id = values.school.district_id
        delete values.school

        fetchMethodRequest('POST', url, values).then(async response => {
            if (response && response.checkPaymentResponse && response.checkPaymentResponse.childrens && response.checkPaymentResponse.childrens.length > 0) {
                let childsPaymentDetails = response.checkPaymentResponse.childrens
                for (var obj of childsPaymentDetails) {
                    let ptaTypes = []

                    for (var item of obj.school.pta_types) {
                        ptaTypes.push({
                            label: item.type + " " + item.amount,
                            value: item.type + " " + item.amount,
                        })
                    }
                    obj.school.ptaTypes = ptaTypes
                    let supportingDonations = []
                    for (var item of obj.school.supporting_donations) {
                        supportingDonations.push({
                            label: item,
                            value: item,
                        })
                    }
                    obj.school.supportingDonations = supportingDonations
                }
                let payments = []

                await this.setState({
                    childsPaymentDetails: childsPaymentDetails,
                    transactionDetails: response,
                    isOpenTransactionTypeModal: true,
                    userInputVal: values
                })
                this.clearInputFields()
                this.getTransactionTypeModal()
                // showToasterMessage(response.respMessage, 'success');
            } else if (response && response.checkPaymentResponse && response.checkPaymentResponse.childrens && response.checkPaymentResponse.childrens.length === 0) {
                showToasterMessage("selected record doesn't contains any children ", 'error');
            } else if (response && response.errorMessage) {
                showToasterMessage(response.errorMessage, 'error');
            }
            await this.setState({
                isLoading: false
            });
            // await this.cancelReset();
        })
    }

    // clear input data
    clearInputFields = () => {
        this.props.reset();
    }

    submit = (values) => {
        this.handleUserPassword(values)
    }

    cancelReset = async () => {
        await this.setState({
            isOpenTransactionTypeModal: false
        })
        await this.props.reset();
        await this.props.cancelReset();
    }

    getTransactionTypeModal() {
        return (
            <TransactionModal
                isOpenTransactionTypeModal={this.state.isOpenTransactionTypeModal}
                cancelReset={this.cancelReset}
                transactionDetails={this.state.transactionDetails}
                childsPaymentDetails={this.state.childsPaymentDetails}
                userInputVal={this.state.userInputVal} />)
    }
    render() {
        const { handleSubmit, openPaymentsFormModal } = this.props;
        console.log("this.state.childsPaymentDetails", this.state.childsPaymentDetails)
        return (
            <div>
                <Modal isOpen={openPaymentsFormModal} centered className={'userPwdResetModal'}>
                    <CardBody className='p-2'>
                        <h4 className='pb-3'>Get Transaction Details</h4>
                        <form className={'form'} onSubmit={handleSubmit(this.submit)}>
                            <div className='form__form-group' >
                                <Loader loader={this.state.isLoading} />
                                <div className="form__form-group pb-2">
                                    <label className="form__form-group-label">Email</label>
                                    <div className=' form__form-group-field'>
                                        <Field className='inputLogin'
                                            type={'text'}
                                            name="email"
                                            component={DefaultInput}
                                            // placeholder='New Password'
                                            validate={[required]}
                                        />

                                    </div>
                                </div>
                                <div className="form__form-group pb-1">
                                    <label className="form__form-group-label">Transaction Id</label>
                                    <div className='form__form-group-field'>
                                        <div className=' form__form-group-field'>
                                            <Field className='inputLogin'
                                                type={'text'}
                                                name="transactionId"
                                                component={DefaultInput}
                                                placeholder='Transaction Id'
                                                validate={[required]}
                                            />

                                        </div>
                                    </div>
                                </div>
                                <div className="form__form-group pb-1">
                                    <label className="form__form-group-label">School</label>
                                    <div className='form__form-group-field'>
                                        <div className=' form__form-group-field'>
                                            <Field className='inputLogin'
                                                type={'text'}
                                                name="school"
                                                component={AutoComplete}
                                                searchApi={apiCalls.Schools}
                                                searchField={"Name"}
                                                placeholder='School'
                                                handleAutoCompleteData={(event) =>
                                                    this.handleAutoCompleteData(event, "school")
                                                }
                                                validate={[required]}
                                            />

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ margin: 'auto' }}>
                                <Button color="primary" type='submit'>Submit</Button>
                                <Button color="primary" type='buttom' onClick={this.cancelReset}>Cancel</Button>
                            </div>
                        </form>
                    </CardBody>
                </Modal>
                {this.state.isOpenTransactionTypeModal ? this.getTransactionTypeModal() : null}
            </div>
        );
    }
}

PaymentsFormModal = reduxForm({
    form: 'User Password Reset Form', // a unique identifier for this form
    validate,
})(PaymentsFormModal);

export default PaymentsFormModal;