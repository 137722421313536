import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Class from './components/Class';

const classes = ({ t }) => (
  <Container>
    <Class />
  </Container>
);

classes.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(classes);