import React from "react";
import SchoolDT from "./SchoolDT";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import fetchMethodRequest from "../../../../config/service";
import filePath from "../../../../config/configSampleFiles";
import DataTables from "../../CommonDataTable/DataTable";

export default class School extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      languageData: "",
      supporting_donations: [],
      paymentTypes: [],
      studentFormFields: [],
    };
  }

  componentDidMount = async () => {
    this.getSettingsFromServer();
    let loginData = localStorage.getItem("loginCredentials");
    if (loginData) {
      loginData = JSON.parse(loginData);
      if (loginData && loginData.role && loginData.role == "schoolAdmin") {
        this.setState({
          loginRole: false,
        });
      } else {
        if (loginData && loginData.role) {
          this.setState({
            loginRole: true,
          });
        }
      }
    }
  };

  getSettingsFromServer = async () => {
    let filterCriteria = {},
      url;
    filterCriteria.sortfield = "sequenceNo";
    filterCriteria.direction = "asc";
    url = `settings`;
    fetchMethodRequest("GET", url)
      .then(async (res) => {
        if (res && res.settings[0]) {
          let pta_dues = res.settings[0].pta_dues;
          let supporting_donations = res.settings[0].supporting_donations;
          let paymentTypes = res.settings[0].payment_types;
          let studentFormFields = res.settings[0].studentFormFields;
          let schoolGrades = res.settings[0].schoolGrades;
          await this.setState({
            supporting_donations: supporting_donations,
            pta_dues: pta_dues,
            paymentTypes: paymentTypes,
            studentFormFields: studentFormFields,
            schoolGrades: schoolGrades,
          });
        }
      })
      .catch((err) => {
        return err;
      });
  };

  getTableFields = () => {
    let data = [
      // {
      //   textAlign: "center",
      //   width: 47,
      //   field: "",
      //   fieldType: "multiple",
      //   header: "",
      //   selectionMode: "multiple",
      //   show: true,
      //   mobile: true,
      //   displayInSettings: false,
      // },
      // {
      //   show: true,
      //   textAlign: "center",
      //   width: 47,
      //   field: "Sno",
      //   header: "Sno",
      //   filter: false,
      //   sortable: false,
      //   placeholder: "Search",
      // },
      {
        show: true,
        textAlign: "left",
        mobile: true,
        width: 250,
        fieldType: "Link",
        field: "Name",
        header: "School Name",
        filter: false,
        sortable: true,
        style: {
          color: config.templateColor,
          cursor: "pointer",
          textTransform: "capitalize",
        },
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "center",
        mobile: true,
        width: 120,
        field: "school_id",
        header: "School Code",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 120,
        mobile: true,
        field: "district_name",
        header: "District",
        filter: false,
        sortable: true,
        textCapitalize: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 180,
        mobile: true,
        filter: false,
        sortable: true,
        field: "Address",
        header: "Address Line1",
        textCapitalize: true,
        displayInSettings: true,
      },
      // {
      //   show: true,
      //   textAlign: "left",
      //   width: 180,
      //   filter: false,
      //   mobile: true,
      //   sortable: true,
      //   field: "Address2",
      //   header: "Address Line2",
      //   textCapitalize: true,
      //   displayInSettings: true,
      // },
      {
        show: true,
        textAlign: "left",
        width: 180,
        mobile: false,
        filter: false,
        sortable: true,
        field: "city",
        header: "City",
        textCapitalize: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 180,
        filter: false,
        mobile: false,
        sortable: true,
        field: "state",
        header: "State",
        textCapitalize: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 100,
        mobile: false,
        filter: false,
        sortable: true,
        field: "pincode",
        header: "Zip Code",
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 100,
        mobile: false,
        filter: false,
        sortable: true,
        field: "total_amount",
        header: "Amount Received",
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "center",
        mobile: false,
        width: 130,
        filter: false,
        sortable: true,
        field: "Main_Number",
        header: "Phone Number",
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 140,
        filter: false,
        mobile: false,
        sortable: true,
        field: "Attendance_Line",
        header: "Attendance Line",
        displayInSettings: true,
      },
      {
        show: true,
        mobile: false,
        textAlign: "left",
        width: 140,
        filter: false,
        sortable: true,
        field: "Nurse",
        header: "Nurse",
        textCapitalize: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 250,
        filter: false,
        mobile: false,
        sortable: true,
        field: "website",
        header: "Website",
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 180,
        filter: false,
        mobile: false,
        sortable: true,
        field: "Transportation",
        header: "Transportation Line",
        textCapitalize: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 180,
        filter: false,
        sortable: true,
        field: "School_Hours",
        header: "School Hours",
        mobile: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 140,
        filter: false,
        mobile: false,
        sortable: true,
        field: "Closing_Delays",
        header: "Delayed openings",
        // fieldType: "Time",
        dateFormat: config.datePickerOnlyTimeFormat,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 180,
        filter: false,
        sortable: true,
        field: "Early_Dismissal_time",
        mobile: true,
        header: "Early Dismissal Time",
        // fieldType: "Time",
        dateFormat: config.datePickerOnlyTimeFormat,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 180,
        mobile: true,
        filter: false,
        sortable: true,
        field: "principal_name",
        header: "Principal Name",
        textCapitalize: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 180,
        filter: false,
        mobile: true,
        sortable: true,
        field: "principal_email",
        header: "Principal Email",
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 180,
        filter: false,
        mobile: true,
        sortable: true,
        field: "pta_president_name",
        header: "President Name",
        textCapitalize: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        mobile: true,
        width: 180,
        filter: false,
        sortable: true,
        field: "pta_president_email",
        header: "President Email",
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 70,
        fieldType: "BoolText",
        field: "preload",
        header: "Preload",
        options: config.preloadOptions,
        filter: false,
        sortable: false,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 70,
        fieldType: "Boolean",
        field: "hasFoundation",
        header: "Has Foundation",
        filter: false,
        sortable: false,
        displayInSettings: true,
      },

      {
        show: true,
        textAlign: "center",
        width: 70,
        fieldType: "Bool",
        field: "pta_dues",
        header: "Payment",
        options: config.isPaymentEnabledOptions,
        filter: false,
        sortable: false,
        displayInSettings: true,
      },
      // {
      //   show: true,
      //   textAlign: "left",
      //   width: 70,
      //   fieldType: "Actions",
      //   field: "Actions",
      //   header: "Actions",
      //   filter: false,
      //   sortable: false,
      //   displayInSettings: true,
      // },
    ];
    return data;
  };

  getFormFields = () => {
    let { studentFormFields, schoolGrades } = this.state;
    let statesNames = [
      { label: "Alabama - AL", value: "Alabama - AL" },
      { label: "Alaska - AK", value: "Alaska - AK" },
      { label: "Arizona - AZ", value: "Arizona - AZ" },
      { label: "Arkansas - AR", value: "Arkansas - AR" },
      { label: "California - CA", value: "California - CA" },
      { label: "Colorado - CO", value: "Colorado - CO" },
      { label: "Connecticut - CT", value: "Connecticut - CT" },
      { label: "Delaware - DE", value: "Delaware - DE" },
      { label: "Florida - FL", value: "Florida - FL" },
      { label: "Georgia - GA", value: "Georgia - GA" },
      { label: "Hawaii - HI", value: "Hawaii - HI" },
      { label: "Idaho - ID", value: "Idaho - ID" },
      { label: "Illinois - IL", value: "Illinois - IL" },
      { label: "Indiana - IN", value: "Indiana - IN" },
      { label: "Iowa - IA", value: "Iowa - IA" },
      { label: "Kansas - KS", value: "Kansas - KS" },
      { label: "Kentucky - KY", value: "Kentucky - KY" },
      { label: "Louisiana - LA", value: "Louisiana - LA" },
      { label: "Maine - ME", value: "Maine - ME" },
      { label: "Maryland - MD", value: "Maryland - MD" },
      { label: "Massachusetts - MA", value: "Massachusetts - MA" },
      { label: "Michigan - MI", value: "Michigan - MI" },
      { label: "Minnesota - MN", value: "Minnesota - MN" },
      { label: "Mississippi - MS", value: "Mississippi - MS" },
      { label: "Missouri - MO", value: "Missouri - MO" },
      { label: "Montana - MT", value: "Montana - MT" },
      { label: "Nebraska - NE", value: "Nebraska - NE" },
      { label: "Nevada - NV", value: "Nevada - NV" },
      { label: "New Hampshire - NH", value: "New Hampshire - NH" },
      { label: "New Jersey - NJ", value: "New Jersey - NJ" },
      { label: "New Mexico - NM", value: "New Mexico - NM" },
      { label: "New York - NY", value: "New York - NY" },
      { label: "North Carolina - NC", value: "North Carolina - NC" },
      { label: "North Dakota - ND", value: "North Dakota - ND" },
      { label: "Ohio - OH", value: "Ohio - OH" },
      { label: "Oklahoma - OK", value: "Oklahoma - OK" },
      { label: "Oregon - OR", value: "Oregon - OR" },
      { label: "Pennsylvania - PA", value: "Pennsylvania - PA" },
      { label: "Rhode Island - RI", value: "Rhode Island - RI" },
      { label: "South Carolina - SC", value: "South Carolina - SC" },
      { label: "South Dakota - SD", value: "South Dakota - SD" },
      { label: "Tennessee - TN", value: "Tennessee - TN" },
      { label: "Texas - TX", value: "Texas - TX" },
      { label: "Utah - UT", value: "Utah - UT" },
      { label: "Vermont - VT", value: "Vermont - VT" },
      { label: "Virginia - VA", value: "Virginia - VA" },
      { label: "Washington - WA", value: "Washington - WA" },
      { label: "West Virginia - WV", value: "West Virginia - WV" },
      { label: "Wisconsin - WI", value: "Wisconsin - WI" },
      { label: "Wyoming - WY", value: "Wyoming - WY" },
    ];
    return [
      {
        required: true,
        value: "",
        searchField: "name",
        searchApi: "districts",
        type: "autoComplete",
        options: [{ district_id: "district_id" }, { district_name: "name" }],
        name: "district_name",
        label: "District Name",
        id: "district_id",
        placeholder: "District Name",
      },
      {
        value: "",
        type: "text",
        name: "district_id",
        label: "District Code",
        id: "district_id",
        placeholder: "District Code",
        disable: true,
      },
      // {
      //   value: "",
      //   type: "text",
      //   name: "school_id",
      //   label: "School Code",
      //   id: "school_id",
      //   placeholder: "School Code",
      //   required: true,
      // },
      {
        value: "",
        type: "text",
        name: "Name",
        label: "School Name",
        id: "name",
        placeholder: "School Name",
        required: true,
      }, {
        value: "",
        type: "text",
        name: "Address",
        label: "Street Address ",
        id: "Address",
        placeholder: "Address Line1",
        required: true,
      }, {
        value: "",
        type: "textarea",
        name: "city",
        label: "City",
        id: "city",
        placeholder: "City",
        required: true,
      }, {
        value: "",
        type: "textarea",
        name: "state",
        label: "State",
        id: "state",
        placeholder: "State",
        required: true,
      }, {
        value: "",
        type: "number",
        name: "pincode",
        label: "Zip Code",
        id: "pincode",
        placeholder: "Zip Code",
        required: true,
      },
      {
        value: "",
        type: "text",
        name: "website",
        label: "Web Address",
        id: "website",
        placeholder: "Website",
        required: true,
      }, {
        value: "",
        type: "text",
        name: "School_Hours",
        label: "School Hours",
        id: "School_Hours",
        placeholder: "School Hours",
        required: true,
      }, {
        value: "",
        type: "time",
        name: "Closing_Delays",
        label: "Delayed Opening",
        id: "Closing_Delays",
        placeholder: "Closing Delays",
        required: true,
      }, {
        value: "",
        type: "time",
        name: "Early_Dismissal_time",
        label: "Early Dismissal Time",
        id: "Early_Dismissal_time",
        placeholder: "Early Dismissal Time",
        required: true,
      }, {
        value: "",
        type: "text",
        name: "Transportation",
        label: "Transportation Line",
        id: "Transportation",
        placeholder: "Transportation Line",
        required: true,
      }, {
        value: "",
        type: "text",
        name: "Attendance_Line",
        label: "Attendance Line",
        id: "Attendance_Line",
        placeholder: "Attendance Line",
        // required: true,
      },
      {
        value: "",
        type: "text",
        name: "Main_Number",
        label: "Main Office",
        id: "Main_Number",
        placeholder: "Phone Number",
        required: true,
      },
      {
        value: "",
        type: "text",
        name: "Nurse",
        label: "Nurse",
        id: "Nurse",
        placeholder: "Nurse",
        required: true,
      },


      {
        value: "",
        type: "text",
        name: "principal_name",
        label: "School Principal",
        id: "principal_name",
        placeholder: "School Principal",
        required: true,
      },
      {
        value: "",
        type: "email",
        name: "principal_email",
        label: "Principal Email",
        id: "principal_email",
        placeholder: "email@gmail.com",
        required: true,
      },
      {
        value: "",
        type: "text",
        name: "pta_president_name",
        label: "PTA/PTO/HSA President",
        id: "pta_president_name",
        placeholder: "PTA/PTO/HSA President",
        required: true,
      },
      {
        value: "",
        type: "text",
        name: "pta_president_email",
        label: "President Email",
        id: "pta_president_email",
        placeholder: "President Email",
        required: true,
      },
      {
        value: "",
        type: "dropDown",
        name: "schoolGrades",
        label: "School Grades",
        id: "schoolGrades",
        options: schoolGrades,
        placeholder: "Select School Grades",
        isMultiSelect: true,
        required: true,
      }, {
        value: "",
        type: "dropDown",
        name: "studentFormFields",
        label: "Student Fields",
        id: "studentFormFields",
        options: studentFormFields,
        placeholder: "Select Student Fields",
        isMultiSelect: true,
        required: true,
      }, {
        value: "",
        type: "dropDown",
        name: "pta_types",
        label: "PTA/PTO/HSA Dues",
        id: "Payment Dues",
        options: this.state.pta_dues,
        placeholder: "PTA/PTO/HSA Dues",
        isMultiSelect: true,
        required: false,
        dependentTextField: ["Individual", "Family", "Teacher"],
      }, {
        value: "",
        type: "text",
        name: "septaFee",
        label: "Special Education,e.g.SEPTA,Dues",
        id: "septaFee",
        placeholder: "Special Education,e.g.SEPTA,Dues",
      },
      {
        value: "",
        type: "dropDown",
        name: "supporting_donations",
        label: "Supporting Donations",
        id: "Supporting Donations",
        options: this.state.supporting_donations,
        placeholder: "Supporting Donations",
        isMultiSelect: true,
        required: true,
      },
      {
        show: false,
        value: "",
        type: "text",
        name: "Individual_dues",
        label: "Individual Amount",
        id: "Individual",
        placeholder: "Individual Amount",
        dependentEditTextField: true,
        required: true,
      }, {
        value: "",
        type: "radio",
        name: "hasFoundation",
        label: "Has Foundation",
        id: "hasFoundation",
        options: [
          { label: "True", value: "true", showError: true },
          { label: "False", value: "false" },
        ],
        defaultChecked: "True",
        dependent: [
          { foundation_Name: "foundation_Name" },
          { foundation_Email: "foundation_Email" },
          { paypal_client_id: "paypal_client_id" },
          { paypal_client_secret: "paypal_client_secret" },

        ],
        required: false,
      }, {
        show: false,
        value: "",
        type: "text",
        name: "foundation_Name",
        label: "Foundation Name",
        id: "Foundation Name",
        placeholder: "Foundation Name",
        // dependentEditTextField: true,
        required: true,
      }, {
        show: false,
        value: "",
        type: "email",
        name: "foundation_Email",
        label: "Foundation Email",
        id: "Foundation Email",
        placeholder: "Foundation Email",
        // dependentEditTextField: true,
        required: true,
      }, {
        show: false,
        value: "",
        type: "text",
        name: "paypal_client_id",
        label: "Paypal Client id",
        id: "Paypal Client id",
        placeholder: "Paypal Client id",
        // dependentEditTextField: true,
        required: true,
      }, {
        show: false,
        value: "",
        type: "text",
        name: "paypal_client_secret",
        label: "Paypal Client Secret",
        id: "Paypal Client Secret",
        placeholder: "Paypal Client Secret",
        // dependentEditTextField: true,
        required: true,
      },
      {
        show: false,
        value: "",
        type: "text",
        name: "Family_dues",
        label: "Family Amount",
        id: "Family",
        placeholder: "Family Amount",
        dependentEditTextField: true,
        required: true,
      },
      {
        show: false,
        value: "",
        type: "text",
        name: "Teacher_dues",
        label: "Teacher Amount",
        id: "Teacher",
        placeholder: "Teacher Amount",
        dependentEditTextField: true,
        required: true,
      },
      // {
      //   value: "",
      //   type: "textarea",
      //   name: "Address2",
      //   label: "Address Line2",
      //   id: "Address2",
      //   placeholder: "Address Line2",
      //   required: true,
      // },

      // {
      //   value: "",
      //   type: "dropDown",
      //   name: "state",
      //   label: "State",
      //   id: "state",
      //   placeholder: "State",
      //   options: statesNames,
      //   required: true,
      // },





      // {
      //   value: "",
      //   type: "dropDown",
      //   name: "payment_types",
      //   label: "Payment Types",
      //   id: "Payment Types",
      //   options: this.state.paymentTypes,
      //   placeholder: "Payment Types",
      //   isMultiSelect: true,
      //   required: true,
      // },
      {
        value: "",
        type: "profile",
        name: "logo",
        label: "School Logo",
        id: "Logo",
        placeholder: "Image",
        imagePath: "school",
      },
    ];
  };

  render() {
    return (
      <DataTables
        getTableFields={this.getTableFields}
        formFields={this.getFormFields}
        addRequried={this.state.loginRole}
        editRequired={true}
        deleteRequired={true}
        viewRequired={true}
        settingsRequired={true}
        sample={true}
        exportToCsv={true}
        sampleFilePath={filePath.school}
        bulkApi={"school"}
        globalSearch={"District/School Code/School Name"}
        type="School"
        apiUrl={apiCalls.Schools}
        // actionsTypes={actionsTypes}
        routeTo="school"
        displayViewOfForm="screen"
      />
    );
  }
}

const actionsTypes = [
  {
    name: "Delete",
    options: [{ label: "Delete", value: "Delete", show: true, multiple: true }],
  },
];
