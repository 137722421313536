import React from "react";
import GroupDataDT from "./GroupDataDT";
import store from "../../../App/store";
import { withTranslation } from "react-i18next";
import apiCalls from "../../../../config/apiCalls";
import config from "../../../../config/config";
import DataTables from "../../CommonDataTable/DataTable";

// config file
class GroupMembers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      languageData: "",
    };
  }
  componentDidMount = () => { };

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState();
    let languageData =
      storeData &&
        storeData.settingsData &&
        storeData.settingsData.settings &&
        storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  }

  getTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: false,
      },
      // {
      //   show: true,
      //   textAlign: "center",
      //   width: 47,
      //   field: "Sno",
      //   header: "Sno",
      //   filter: false,
      //   sortable: false,
      //   placeholder: "Search",
      // },
      {
        show: true,
        textAlign: "left",
        width: 150,
        field: "school_name",
        header: "School",
        filter: false,
        sortable: false,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 150,
        field: "name",
        header: "Title",
        filter: false,
        sortable: false,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 150,
        field: "type",
        header: "Type",
        textCapitalize: true,
        filter: false,
        sortable: false,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 150,
        field: "description",
        header: "Description",
        filter: false,
        sortable: false,
        textCapitalize: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 70,
        field: "Actions",
        fieldType: "Actions",
        header: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
      },
    ];
    return data;
  };
  getFormFields = () => {
    var loginRole;
    let loginData = localStorage.getItem("loginCredentials");
    if (loginData) {
      loginData = JSON.parse(loginData);
      if (loginData && loginData.role) {
        loginRole = loginData.role;
      }
    }
    return [
      {
        show: loginRole == "schoolAdmin" ? false : true,
        required: true,
        value: "",
        searchField: "Name",
        searchApi: "schools",
        type: "autoComplete",
        options: [
          { school_name: "Name" },
          { district_id: "district_id" },
          { school_id: "school_id" },
        ],
        name: "school_name",
        label: "School Name",
        id: "Name",
        placeholder: "School Name",
        sendCriteria: "school_id",
      },
      {
        value: "",
        type: "text",
        name: "title",
        label: "Name of Group",
        id: "nameOfGroup",
        placeholder: "Name Of Group",
        required: true,
      },
      {
        value: "",
        type: "text",
        name: "alias",
        label: "School Alias",
        id: "alias",
        placeholder: "School Alias",
        required: false,
      },
      {
        value: "",
        type: "textarea",
        name: "description",
        label: "Description",
        id: "des",
        placeholder: "Description",
        required: true,
      },
      {
        value: "",
        type: "dropDown",
        name: "type",
        label: "Type of Group",
        id: "typeOfGroup",
        placeholder: "Type of Group",
        options: config.GroupTypes,
        required: true,
        multipleDependentSameValue: true,
        dependent: [{ class: "class_name" }, { grade: "class_name" }],
      },
      {
        show: false,
        value: "",
        searchField: "class_display_name",
        searchApi: "classes",
        type: "autoComplete",
        name: "class_name",
        label: "Class/Grade",
        options: [
          { classOrGrade: "class_display_name" },
          { class_id: "_id" },
          { grade_int: "grade" },
          { class_name: "class_name" },
        ],
        id: "classOrGrade",
        placeholder: "Class/Grade",
        required: true,
      },
    ];
  };

  render() {
    return (
      <DataTables
        getTableFields={this.getTableFields}
        formFields={this.getFormFields}
        addRequried={true}
        editRequired={true}
        deleteRequired={true}
        viewRequried={false}
        settingsRequired={true}
        globalSearch={"Title / Type"}
        exportToCsv={true}
        type="Groups"
        apiUrl={apiCalls.Groups}
        actionsTypes={actionsTypes}
        showSchoolDropdown={true}
        showAllDropdownValue={true}
        showGroupDropdown={true}
        criteria={criteria}
      />
    );
  }
}
const actionsTypes = [
  {
    name: "Delete",
    options: [{ label: "Delete", value: "Delete", show: true, multiple: true }],
  },
];
const criteria = [{ key: "school_name", value: "", type: "eq" }];

export default withTranslation("common")(GroupMembers);
