import React from "react";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import store from "../../../App/store";
import DataTables from "../../CommonDataTable/DataTable";

export default class RefundDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      languageData: "",
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState();
    let languageData =
      storeData &&
        storeData.settingsData &&
        storeData.settingsData.settings &&
        storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  }

  getTableFields = () => {
    let data = [
      // {
      //   textAlign: "center",
      //   width: 47,
      //   field: "",
      //   fieldType: "multiple",
      //   header: "",
      //   selectionMode: "single",
      //   show: true,
      //   mobile: true,
      //   displayInSettings: false,
      // },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        header: "Sno",
        filter: false,
        sortable: false,
        placeholder: "Search",
        show: true,
      },
     
      {
        textAlign: "center",
        width: 150,
        field: "order_code",
        header: "Order Code",
        filter: false,
        show: true,
        sortable: true,
        placeholder: "Search",
      },
      {
        textAlign: "center",
        width: 100,
        field: "order_id",
        header: "Order ID",
        filter: false,
        show: true,
        sortable: true,
        placeholder: "Search",
      },
      {
        textAlign: "left",
        mobile: true,
        width: 200,
        show: true,
        field: "school_name",
        header: "School Name",
        // fieldType: "Link",
        filter: false,
        sortable: true,
        style: {
          color: config.templateColor,
          cursor: "pointer",
          textTransform: "capitalize",
        },
        displayInSettings: true,
      },
      {
        textAlign: "left",
        width: 150,
        mobile: true,
        show: true,
        field: "child_name",
        header: "Student Name",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        textAlign: "left",
        width: 150,
        mobile: true,
        show: true,
        field: "user_name",
        header: "Customer",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        "textAlign": "left",
        "width": 200,
        "mobile": true,
        "show": true,
        "field": "userEmail",
        "fieldType": "Array",
        "header": "Email",
        "filter": false,
        "sortable": true
      },
      {
        textAlign: "center",
        show: true,
        mobile: true,
        width: 140,
        field: "created",
        fieldType: "Date",
        dateFormat: config.dateTimeFormat,
        header: "Ordered Date",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      // {
      //   textAlign: "center",
      //   mobile: true,
      //   width: 80,
      //   show: true,
      //   field: "paymentStatus",
      //   header: "Payment",
      //   filter: false,
      //   sortable: true,
      //   displayInSettings: true,
      // },
      {
        textAlign: "center",
        mobile: true,
        width: 80,
        show: true,
        field: "ordered_price",
        fieldType: "Currency",
        header: "Total",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        textAlign: "center",
        mobile: true,
        width: 80,
        show: true,
        field: "initialOrderedCost",
        fieldType: "Currency",
        header: "initialOrder Cost",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        textAlign: "center",
        mobile: true,
        width: 80,
        show: true,
        field: "refundedAmount",
        fieldType: "Badge",
        header: "refund Amount",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 150,
        field: "status",
        mobile: true,
        header: "Status",
        options: config.orderStatusTableFieldTypes,
        // fieldType: "Badge",
        filter: false,
        sortable: false,
        // style: {
        //   padding: "4px 6px",
        //   fontSize: 12,
        //   color: "white",
        //   fontWeight: "bold",
        // },
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 100,
        fieldType: "Actions",
        field: "Actions",
        style: {
          color: config.templateColor,
          cursor: "pointer",
          textTransform: "capitalize",
        },
        header: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
      },
    ];
    return data;
  };
  getFormFields = () => {
    return [
      {
        required: true,
        value: "",
        searchField: "Name",
        searchApi: "schools",
        type: "autoComplete",
        options: [
          { school_name: "Name" },
          { district_id: "district_id" },
          { school_id: "school_id" },
        ],
        name: "school_name",
        label: "School Name",
        id: "Name",
        placeholder: "School Name",
      },
      {
        required: true,
        value: "",
        searchField: "firstname",
        searchApi: "childs",
        type: "autoComplete",
        options: [{ child_id: "child_id" }, { child_name: "firstname" }],
        name: "child_name",
        label: "Student Name",
        id: "Student Name",
        placeholder: "Student Name",
      },
      {
        value: "",
        type: "number",
        name: "qty",
        label: "Qty",
        id: "qty",
        placeholder: "Qty",
      },
      {
        value: "",
        type: "number",
        name: "ordered_price",
        label: "Ordered Price",
        id: "ordered_price",
        placeholder: "Ordered Price",
        required: true,
      },
      {
        value: "",
        type: "number",
        name: "ordered_cost",
        label: "Ordered Cost",
        id: "ordered_cost",
        placeholder: "Ordered Cost",
        required: true,
      },
      {
        required: true,
        value: "",
        searchField: "store_name",
        searchApi: "stores",
        type: "autoComplete",
        options: [{ store_name: "store_name" }],
        name: "store_name",
        label: "Store",
        id: "store_name",
        placeholder: "Store",
      },
      {
        required: true,
        value: "",
        type: "dropDown",
        name: "product_name",
        label: "Product Name",
        id: "Product Name",
        options: [],
        placeholder: "Product Name",
        isMultiSelect: true,
        optionsName: "products",
      },
      {
        value: "",
        type: "textarea",
        name: "comments",
        label: "Comments",
        id: "comments",
        placeholder: "Comments",
        required: true,
      },
      {
        required: true,
        value: "",
        type: "dropDown",
        name: "status",
        label: "Status",
        id: "status",
        options: config.orderStatusFormFieldTypes,
        placeholder: "Status",
      },
    ];
  };

  render() {
    return (
     
      <DataTables
        getTableFields={this.getTableFields}
        formFields={this.getFormFields}
        addRequried={false}
        editRequired={false}
        deleteRequired={false}
        viewRequired={true}
        settingsRequired={true}
        exportToCsv={true}
        globalSearch={"School Name/Student Name"}
        type="Refund Details"
        apiUrl={"orders/cancelled_products"}
        routeTo="RefundDetails"
        displayViewOfForm="screen"
      />
    );
  }
}

