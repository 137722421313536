import React from 'react';
import { Link } from 'react-router-dom';
// import Image404 from '../../../shared/img/404/404.png';
import { Redirect } from 'react-router-dom';
class NotFound404 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirecToLogin: false,
    }
  }

  componentDidMount = async () => {
    let loginCredentials = localStorage.loginCredentials ? JSON.parse(localStorage.loginCredentials) : false;
    if (!loginCredentials) {
      await this.setState({ redirecToLogin: true })
    } else {
      await this.setState({ redirecToLogin: false })
    }
  }
  render() {
    return (
      <div className="not-found" style={{ marginLeft: 26 }}>
        {
          this.state.redirecToLogin ?
            <Redirect to={`/log_in`} />
            : null
        }
        <div className="not-found__content">
          {/* <img className="not-found__image" src={Image404} alt="404" /> */}
          <h3 className="not-found__info">Ooops! The page you are looking for could not be found :(</h3>
          <Link className="btn btn-primary" to="/">Back To Login</Link>
        </div>
      </div>
    )
  }
}



export default NotFound404;
