
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Button, CardBody, Modal, Row } from 'reactstrap';

// show message 
import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';
import DefaultInput from '../../../shared/components/form/DefaultInput';
import Select from '../../../shared/components/form/Select'
import validate from '../../Validations/validate';
import fetchMethodRequest from '../../../config/service';
import Checkbox from '../../../shared/components/form/CheckBox'

class PaymentsFormModal extends React.Component {
    constructor(props) {
        super(props);
        this.buttonActionType = null;
        this.state = {
            isLoginSuccess: false,
            isLoading: true,
            districtId: "",
            schoolId: "",
            childsPaymentDetails: [],
            totalUserAmount: 0
        };
    }

    componentDidMount() {
        this.setState({ isLoading: false, childsPaymentDetails: this.props.childsPaymentDetails });

    }

    // // on value change in input
    handleAutoCompleteData = async (event) => {

    }


    // handle login user data
    handleUserPassword = async (values) => {
        console.log("values", values)
        await this.setState({
            isLoading: true
        });
        let url;
        url = `directorys/makepayment?type=mobile&version=1.01`
        var body = {};
        let childsPaymentDetails = this.props.childsPaymentDetails
        let payments = []
        // for (var obj of childsPaymentDetails){
        var totalUserAmount = 0;

        for (let i = 0; i < childsPaymentDetails.length; i++) {
            let obj5 = {
                child_id: childsPaymentDetails[i].childId,
                school_id: childsPaymentDetails[i].school.school_id,
                district_id: childsPaymentDetails[i].school.district_id,
                district_name: childsPaymentDetails[i].school.district_name,
                payment_mode: "online",
                payment_type: values[`ptaTypes${i}`] ? values[`ptaTypes${i}`].slice(0, values[`ptaTypes${i}`].length - 3) : 0,
                pta_dues: values[`ptaTypes${i}`] ? parseInt(values[`ptaTypes${i}`].slice(-2)) : 0,
                "supporting_donations": values[`supportingDonationsOthers${i}`] ? parseInt(values[`supportingDonationsOthers${i}`]) : values[`supportingDonations${i}`] ? parseInt(values[`supportingDonations${i}`]) : 0,
                "septaFee": this.state.childsPaymentDetails[i].school[`septaFeechecked${i}`] ? parseInt(childsPaymentDetails[i].school.septaFee) : 0
            }
            totalUserAmount = parseInt(totalUserAmount) + parseInt(obj5.pta_dues) + parseInt(obj5.supporting_donations) + parseInt(obj5.septaFee)
            payments.push(obj5)
            await this.setState({
                payments: payments,
                // totalUserAmount: totalUserAmount
            })
        }

        var paymentDetails = {
            PayerId: this.props.transactionDetails.payerId,
            PayId: this.props.transactionDetails.payId,
            amount: this.props.transactionDetails.response.details.amount.total,
        }
        body.payment_mode = "online"
        body.payment_details = paymentDetails
        body.payment_method = "paypal"
        body.payments = this.state.payments // 
        body.email = this.props.userInputVal.email
        if (totalUserAmount === parseInt(paymentDetails.amount)) {
            fetchMethodRequest('POST', url, body).then(async response => {
                if (response) {
                    this.props.cancelReset()
                    // showToasterMessage(response.respMessage, 'success');
                } else if (response && response.errorMessage) {
                    showToasterMessage(response.errorMessage, 'error');
                }
                await this.setState({
                    isLoading: false
                });
                // await this.cancelReset();
            })
        } else {
            showToasterMessage("Selected Values are not matched with the total Amount", 'error');
        }
    }

    onChange = async (e, index) => {
        let childsPaymentDetails = this.state.childsPaymentDetails
        if (e === "others") {
            let otherskey = `isShownothersField${index}`
            childsPaymentDetails[index].school[`isShownothersField${index}`] = true
        } else {
            if (childsPaymentDetails[index].school[`isShownothersField${index}`]) {
                delete childsPaymentDetails[index].school[`isShownothersField${index}`]
            }
        }
        await this.setState({
            childsPaymentDetails: childsPaymentDetails
        })
    }

    // clear input data
    clearInputFields = () => {
        this.props.reset();
    }

    submit = (values) => {
        console.log(values)
        this.handleUserPassword(values)
    }

    onChangeSeptaFee = async (e, index) => {
        console.log("ee", e)
        let childsPaymentDetails = this.state.childsPaymentDetails
        if (childsPaymentDetails[index].school[`septaFeechecked${index}`]) {
            delete childsPaymentDetails[index].school[`septaFeechecked${index}`]
        } else {
            childsPaymentDetails[index].school[`septaFeechecked${index}`] = true
        }
        await this.setState({
            childsPaymentDetails: childsPaymentDetails
        })
    }

    cancelReset = async () => {
        await this.props.reset();
        await this.props.cancelReset();
    }


    render() {
        const { handleSubmit, isOpenTransactionTypeModal, userInputVal, transactionDetails } = this.props;
        const { childsPaymentDetails } = this.state;
        return (
            <Modal isOpen={isOpenTransactionTypeModal} centered>
                <CardBody className='p-2'>
                    <h4 className='pb-3'>Add Payment</h4>

                    {childsPaymentDetails && childsPaymentDetails.length > 0 ?
                        <form className="row form" onSubmit={handleSubmit(this.submit)}>
                            {userInputVal ?
                                <>
                                    <Row className="col-sm-12 mb-2" style={{ fontSize: "14px" }}><div className='col-sm-6'><b><span className='col-sm-2'>Email</span><span>{userInputVal.email}</span></b></div>
                                        <div className='col-sm-6' style={{ fontSize: "14px" }}><b><span className='col-sm-3'>Transaction Id</span><span>{userInputVal.transactionId}</span></b></div></Row>
                                    <Row className="col-sm-12">
                                        <div className='col-sm-7 pl-0 pr-0'><b><span className='col-sm-3'>Total Amt</span><span>{transactionDetails.response.details.amount.total}</span></b></div></Row></>
                                : null}
                            {childsPaymentDetails && childsPaymentDetails.length > 0 ?
                                childsPaymentDetails.map((item, index) => (
                                    <Row className="col-sm-12 ml-0 mr-0 cradBodres addressDetailsBg mb-3">
                                        <Row className="col-sm-12 my-2">
                                            <span className='col-sm-4'><b>School Name</b></span><span className='col-sm-8 text-left'><b>{item.school.Name}</b></span>
                                        </Row>
                                        <div className="form__form-group col-sm-6 pb-2">
                                            <label className="form__form-group-label">PTA Types</label>
                                            <div className=' form__form-group-field'>
                                                <Field className='inputLogin'
                                                    type={'dropDown'}
                                                    name={`ptaTypes${index}`}
                                                    component={Select}
                                                    options={item.school.ptaTypes}
                                                    placeholder='PTA Types'
                                                //validate={[required]}
                                                />

                                            </div>
                                        </div>
                                        <div className="form__form-group col-sm-6  pb-1">
                                            <label className="form__form-group-label">Supporting Donations</label>
                                            <div className='form__form-group-field'>
                                                <div className=' form__form-group-field'>
                                                    <Field className='inputLogin'
                                                        type={'dropDown'}
                                                        name={`supportingDonations${index}`}
                                                        component={Select}
                                                        options={item.school.supportingDonations}
                                                        placeholder='Supporting Donations'
                                                        //validate={[required]}
                                                        onChange={(e) => this.onChange(e, index)}
                                                    />

                                                </div>
                                            </div>
                                        </div>
                                        {item.school[`isShownothersField${index}`] ?
                                            <div className="form__form-group col-sm-6  pb-1">
                                                <label className="form__form-group-label">Septa Fee</label>
                                                <div className='form__form-group-field'>
                                                    <div className=' form__form-group-field'>
                                                        <Field className='inputLogin'
                                                            type={'text'}
                                                            name={`supportingDonationsOthers${index}`}
                                                            component={DefaultInput}
                                                            placeholder='Septa Fee'
                                                        //validate={[required]}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            : null}
                                        <div className="form__form-group col-sm-12  pb-1">
                                            <label className="form__form-group-label">{`Additionally I would join SEPTA for $${item.school.septaFee}`}</label>
                                            <div className='form__form-group-field'>
                                                <div className=' form__form-group-field'>
                                                    <input className='inputLogin'
                                                        style={{ width: "15px", height: "15px" }}
                                                        type={'checkbox'}
                                                        name={`septaFeechecked${index}`}
                                                        component={Checkbox}
                                                        // placeholder='New Password'
                                                        onChange={(e) => this.onChangeSeptaFee(e, index)}
                                                    // checked={item.school[`septaFeechecked${index}`] ? true : false}
                                                    />

                                                </div>
                                            </div>
                                        </div>
                                    </Row>
                                )) : null}
                            <div style={{ margin: 'auto' }}>
                                <Button color="primary" type='submit'>Submit</Button>
                                <Button color="primary" type='buttom' onClick={this.cancelReset}>Cancel</Button>
                            </div>
                        </form> : null}
                </CardBody>
            </Modal>
        );
    }
}

PaymentsFormModal = reduxForm({
    form: 'User Password Reset Form', // a unique identifier for this form
    validate,
})(PaymentsFormModal);

export default PaymentsFormModal;