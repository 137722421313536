import React from "react";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import store from "../../../App/store";
import DataTables from "../../CommonDataTable/DataTable";

// config file
export default class Ecommerce extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      languageData: "",
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState();
    let languageData =
      storeData &&
        storeData.settingsData &&
        storeData.settingsData.settings &&
        storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  }

  getTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: false,
      },
      // {
      //   show: true,
      //   textAlign: "center",
      //   width: 47,
      //   field: "Sno",
      //   header: "Sno",
      //   filter: false,
      //   sortable: false,
      //   placeholder: "Search",
      // },
      {
        textAlign: "center",
        width: 60,
        mobile: false,
        field: "display_order",
        show: true,
        header: "Display Order",
        filter: false,
        sortable: true,
        fieldType: "ViewDetails",
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 160,
        mobile: true,
        field: "school_name",
        header: "School Name",
        filter: false,
        sortable: true,
        style: {
          color: config.templateColor,
          cursor: "pointer",
          textTransform: "capitalize",
        },
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        mobile: true,
        width: 180,
        field: "store_name",
        header: "Store Name",
        fieldType: "Link",
        filter: false,
        sortable: true,
        displayInSettings: true,
        style: {
          color: config.templateColor,
          cursor: "pointer",
          textTransform: "capitalize",
        },
      },
      {
        show: true,
        textAlign: "left",
        mobile: true,
        width: 160,
        field: "description",
        header: "Description",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      // {
      // "show": true,
      // "textAlign": "center",
      // "width": 80, "field": "image", "header": "Image", "filter": true, "sortable": true
      // },
      {
        show: true,
        textAlign: "center",
        type: "date",
        mobile: true,
        width: 80,
        field: "startDate",
        dateFormat: config.dateFormat,
        fieldType: "Date",
        withoutTimeoffset: false,
        header: "Start Date",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "center",
        type: "date",
        mobile: true,
        width: 80,
        field: "endDate",
        dateFormat: config.dateFormat,
        fieldType: "Date",
        withoutTimeoffset: false,
        header: "End Date",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 80,
        fieldType: "BoolText",
        field: "isCopiedStore",
        header: "Is Duplicate Store",
        options: config.preloadOptions,
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 70,
        field: "Actions",
        fieldType: "Actions",
        header: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
      },
    ];
    return data;
  };

  getFormFields = () => {
    return [
      {
        required: true,
        value: "",
        searchField: "Name",
        searchApi: "schools",
        type: "autoComplete",
        options: [
          { school_name: "Name" },
          { district_id: "district_id" },
          { school_id: "school_id" },
        ],
        name: "school_name",
        dependent: [
          { isFoundationStore: "isFoundationStore" }],
        label: "School Name",
        id: "Name",
        placeholder: "School Name",
      },
      {
        value: "",
        type: "text",
        name: "store_name",
        label: "Store Name",
        id: "store_name",
        placeholder: "Store Name",
        required: true,
      },
      {
        value: "",
        type: "date",
        name: "startDate",
        label: "Start Date",
        dateFormat:"MM/dd/yyyy h:mm aa",
        timeInputLabel:true,
        showTimeInput:true,
        id: "startDate",
        placeholder: "Start Date",
        required: true,
      },
      {
        value: "",
        type: "date",
        name: "endDate",
        label: "End Date",
        dateFormat:"MM/dd/yyyy h:mm aa",
        timeInputLabel:true,
        showTimeInput:true,
        id: "endDate",
        placeholder: "End Date",
        required: true,
      },

      {
        value: "",
        type: "text",
        name: "display_order",
        label: "Display Order",
        id: "display_order",
        placeholder: "Display Order",
        required: true,
      }, {
        show: false,
        value: "",
        type: "radio",
        name: "isFoundationStore",
        label: " Is Foundation Store",
        id: "isFoundationStore",

        options: [
          { label: "Foundation", value: "true", showError: true },
          { label: "Regular", value: "false" },
        ],
        defaultChecked: "True",
        required: true,
      },
      {
        value: "",
        type: "textarea",
        name: "description",
        label: "Description",
        id: "description",
        placeholder: "Description",
        required: true,
      },
      {
        value: "",
        type: "profile",
        name: "image",
        label: "Select Image",
        id: "Image",
        placeholder: "Image",
        imagePath: "store",
      },
    ];
  };

  render() {
    return (
      <DataTables
        getTableFields={this.getTableFields}
        formFields={this.getFormFields}
        addRequried={true}
        editRequired={true}
        deleteRequired={true}
        viewRequired={true}
        settingsRequired={true}
        otherActionsRequired={true}
        copyRequired={true}
        exportToCsv={true}
        globalSearch={'Store Name/SchoolName '}
        type="Store"
        apiUrl={apiCalls.Store}
        actionsTypes={actionsTypes}
        displayViewOfForm="screen"
        routeTo="Store"
      />
    );
  }
}

const actionsTypes = [
  {
    name: "Delete",
    options: [{ label: "Delete", value: "Delete", show: true, multiple: true }],

  },
  {
    name: "Archieve",
    options: [{ label: "Archive", value: "Archive", show: true, multiple: true }],

  },
];
