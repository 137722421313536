import React from "react";
import DataTables from "../../CommonDataTable/DataTable";
import fetchMethodRequest from "../../../../config/service"
import store from "../../../App/store";
import config from "../../../../config/config";

export default class News extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      languageData: "",
      newletterCategoryOptions: "",
      newPublishOptions: "",
    };
  }

  componentDidMount = () => {
    fetchMethodRequest('GET', 'stores').then(async (response) => {
      if (response) {
        let states = response['stores'];
        let roleOptions = [];
        if (states && states.length > 0) {
          for (let i = 0; i < states.length; i++) {
            roleOptions.push({ label: states[i]['store_name'], value: states[i]['_id'] })
          }
          await this.setState({
            schoolOptions: roleOptions,
          })
        }
      }
    }).catch((err) => {
      return err
    })
  }

  getTableFields = () => {
    let data = [
      // {
      //   textAlign: "center",
      //   width: 47,
      //   field: "",
      //   fieldType: "multiple",
      //   header: "",
      //   selectionMode: "multiple",
      //   show: true,
      //   mobile: true,
      //   displayInSettings: false,
      // },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        header: "Sno",
        filter: false,
        sortable: false,
        placeholder: "Search",
        show: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 200,
        field: "product_name",
        mobile: true,
        header: "product",
        filter: false,
        sortable: true,

        displayInSettings: true,
      }, {
        show: true,
        textAlign: "left",
        width: 200,
        field: "class_name",
        mobile: true,
        header: "Class Name",
        filter: false,
        sortable: true,

        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 200,
        field: "child_name",
        fieldType: "Array",
        mobile: true,
        header: "Child Name",
        filter: false,
        sortable: true,

        displayInSettings: true,
      }, {
        show: true,
        textAlign: "center",
        width: 200,
        field: "c_purchase_qty",
        fieldType: "Array",
        mobile: true,
        header: "Purchase Qty",
        filter: false,
        sortable: true,

        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        mobile: true,
        width: 100,
        field: "comments",
        header: "Comments",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        mobile: true,
        width: 100,
        field: "store_name",
        header: "Store Name",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
    ];
    return data;
  };

  getFormFields = () => {
    var loginRole;
    let loginData = localStorage.getItem("loginCredentials");
    if (loginData) {
      loginData = JSON.parse(loginData);
      if (loginData && loginData.role) {
        loginRole = loginData.role;
      }
    }
    return [
      {
        show: loginRole == "schoolAdmin" ? false : true,
        required: true,
        value: "",
        searchField: "Name",
        searchApi: "schools",
        type: "autoComplete",
        options: [
          { school_name: "Name" },
          { district_id: "district_id" },
          { school_id: "school_id" },
        ],
        name: "school_name",
        label: "School Name",
        id: "Name",
        placeholder: "School Name",
      },
      {
        value: "",
        type: "text",
        name: "title",
        label: "Title",
        id: "title",
        placeholder: "Title",
        required: true,
      },
      // {
      //   value: "",
      //   type: "textarea",
      //   id: "description",
      //   required: true,
      //   placeholder: "Description",
      //   name: "description",
      //   label: "Description",
      // },
      {
        value: "",
        type: "dropDown",
        id: "newsCategory",
        required: true,
        placeholder: "Newsletter Category",
        name: "news_category",
        label: "Newsletter Category",
        options:
          this.state.newletterCategoryOptions &&
            this.state.newletterCategoryOptions.length > 0
            ? this.state.newletterCategoryOptions
            : [],
      },
      {
        value: "",
        type: "dropDown",
        id: "newsPublish",
        required: true,
        placeholder: "Publish/Unpublish",
        name: "news_publish",
        label: "Publish/Unpublish",
        options:
          this.state.newPublishOptions &&
            this.state.newPublishOptions.length > 0
            ? this.state.newPublishOptions
            : [],
      },
      {
        required: true,
        value: "",
        type: "date",
        name: "publicationDate",
        label: "Publication Date",
        id: "publicationDate",
        placeholder: "Publication Date",
      },
      {
        required: true,
        value: "",
        type: "ckeditor",
        name: "newsletterBody",
        label: "Newsletter Body",
        id: "newsletterBody",
        placeholder: "name",
      },
    ];
  };

  render() {
    return (
      <DataTables
        getTableFields={this.getTableFields}
        formFields={this.getFormFields}
        addRequried={false}
        editRequired={false}
        deleteRequired={false}
        viewRequired={true}
        settingsRequired={false}
        globalSearch={"Store/Product"}
        exportToCsv={true}
        type="Orders Summary By Class"
        storeOptions={this.state.schoolOptions}
        apiUrl={"orders/orders_by_class"}
      // actionsTypes={actionsTypes}
      />
    );
  }
}

const actionsTypes = [
  {
    name: "Delete",
    options: [{ label: "Delete", value: "Delete", show: true, multiple: true }],
  },
];
