import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Events from './components/Events';

const events = ({ t }) => (
  <Container>
    <Events />
  </Container>
);

events.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(events);