const config = {
  // employee
  //apiUrl: "http://api.school1.dosystemsinc.com/api/",
  // apiUrl: 'http://localhost:3000/api/',
  // imgUrl: 'http://localhost:3000/images/',

  apiUrl: 'https://api.mobilearq.com/api/',
  imgUrl: 'https://api.mobilearq.com/api/images/',
  //imgUrl: "http://api.school1.dosystemsinc.com/images/",
  socketUrl: "https://api.mobilearq.com",
  janusUrl: "wss://janus.dosystemsinc.com:8989/janus",
  // janusUrl: 'wss://vyjanus.dosystemsinc.com:8989/janus',
  // janusUrl: 'ws://janus.dosystemsinc.com:8188/janus',
  serverErrMessage: "Could Not reach server",

  //regex
  borderValiFation: "false",
  messages: true,

  entityType: "employee",

  appName: "LOGO",
  loginName: "Employee",
  emailRegex: /^(?=.{1,50}$)[_a-zA-z0-9-]+(\.[_a-zA-z0-9-]+)*@[a-zA-z0-9-]+(\.[a-zA-z0-9-]+)*(\.[a-z]{2,4})$/,

  passwordRegex: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*?[!@#\?$₹&<>{}`+*~_();:/%-.]).{12,32}$/,
  adminpasswordRegex: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*?[!@#\?$₹&<>{}`+*~_();:/%-.]).{16,32}$/,
  userPasswordRegex: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#\?$₹&<>{}`+*~_();:/%-.]).{12,}$/,
  aadharcardNumberRegex: /^([0-9]){12}$/,
  pancardNumberRegex: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
  //phoneNumberRegex: /^[+]?(\d{1,2})?[\s.-]?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
  phoneNumberRegex: /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/,
  userNameRegex: /^[a-zA-Z\s]{1,30}$/,
  lastNameRegex: /^[a-zA-Z\s]{1,30}$/,
  subjectRegex: /^[a-zA-Z\s]{1,50}$/,
  companyRegex: /^([A-Za-z0-9\s@.,]){1,30}$/,
  roomIdRegex: /^([0-9]){4,10}$/,
  pincodeRegex: /^([0-9]){5}$/,
  chequeRegex: /^[a-zA-Z0-9\s]{0}$/,
  numberRegex: /\d+\.?\d{0,}$/,

  websiteRegex: /^(https ?: \/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/,
  // server response codes
  updateResCode: 205,
  deleteResCode: 206,

  datePlaceholder: "--/--/----",
  dateFormat: "MM/DD/YYYY",
  dateTabularFormat: "MMM DD YYYY",
  dateDisplayModalFormat: "DD MMM YYYY",
  dateDBFormat: "MM-DD-YYYY",
  dateDayMonthFormat: "DD-MM-YYYY",
  dateYearMonthFormat: "YYYY-MM-DD",
  dayYearDateFormat: "YYYY-MM-DD",
  basicDateFromat: "MM/DD/YYYY HH:mm A",
  descDateFromat: "MMM DD YYYY HH:mm A",
  datePickerTimeFormat: "MMMM DD YYYY h:mm a",
  createdDatePickerTimeFormat: "MM-DD-YYYY, h:mm a",
  datePickerOnlyTimeFormat: "h:mm a",
  timeFormat: "HH:mm",
  timeFormatAmPm: "h:mm a",
  syncTimeFormat: "hh:mm A, MM-DD-YYYY",
  lastModifiedDateFormat: "MM/DD/YYYY HH:mm",
  dateTimeFormat: "MM/DD/YYYY hh:mm",
  fullDateFormat: "YYYY-MM-DD HH:mm:ss",
  fullDateTimeFormat: "YYYY-MM-DD[T]HH:mm:ss.SSZ",
  dbDateFormat: "YYYY-MM-DD[T]HH:mm:ss.SSZ",
  dbOnlyDateFormat: "YYYY-MM-DD[T]00:00:00Z",
  atdbOnlyDateFormat: "YYYY-MM-DD[T]00:00:00",
  datedbOnlyDateFormat: "YYYY-MM-DD[T]23:59:59",

  // dbOnlyDateFormat:""
  ESTTimezone: "America/New_York",

  noView: "noView",
  edit: "edit",
  view: "view",
  templateColor: "#0e4768",
  whiteColor: "#ffffff",
  darkTemplateColor: "#00000045",
  avatarBgColor: "green",
  blackColor: "black",
  formFieldStatusTypes: [
    {
      label: "Active",
      value: "Active",
    },
    {
      label: "Inactive",
      value: "Inactive",
    },
    {
      label: "Pending",
      value: "Pending",
    },
    {
      label: "Blocked",
      value: "Blocked",
    },
  ],
  tabfieldsStatusTypes: [
    {
      label: "Active",
      value: "Active",
      color: "success",
    },
    {
      label: "Inactive",
      value: "Inactive",
      color: "warning",
    },
    {
      label: "Pending",
      value: "Pending",
      color: "danger",
    },
    {
      label: "Blocked",
      value: "Blocked",
      color: "danger",
    },
  ],

  orderStatusType : [
    {
      label:"Delivered",
      value:'Delivered',
      color:'success',
    },
    {
      label:"Cancelled",
      value:'Cancelled',
      color:'danger',
    },
    {
      label:"Order Placed",
      value:'Order Placed',
      color:'info',
    }
  ],

  emailStatusTypes: [
    {
      label: "Sent",
      value: "Sent",
      color: "success",
    },
    {
      label: "Pending",
      value: "Pending",
      color: "danger",
    },
    {
      label: "Failed",
      value: "Failed",
      color: "danger",
    },
  ],
  uploadStatusTypes: [
    {
      label: "Completed",
      value: "Completed",
      color: "success",
    },
    {
      label: "Pending",
      value: "Pending",
      color: "danger",
    },
    {
      label: "Failed",
      value: "Failed",
      color: "danger",
    },
  ],
  employeeFormRolessTypes: [
    { label: "Super Admin", value: "superAdmin" },
    { label: "School Admin", value: "schoolAdmin" },
    { label: "District Admin", value: "districtAdmin" },
  ],
  GroupTypes: [
    { label: "Class", value: "class" },
    { label: "Grade", value: "grade" },
    { label: "Custom", value: "custom" },
  ],
  employeeTableRolesOptions: [
    // { label: "Super Admin", value: "superAdmin", color: "success" },
    // { label: "School Admin", value: "schoolAdmin", color: "info" },
    // { label: "District Admin", value: "districtAdmin", color: "info" },
    
    { label: "Super Admin", value: "superadmin", color: "primary" },
    { label: "School Admin", value: "schooladmin", color: "secondary" },
    { label: "District Admin", value: "districtadmin", color: "info" },
    { label: "Manager", value: "manager", color: "warning" }
  ],
  orderStatusFormFieldTypes: [
    {
      label: "Order Placed",
      value: "Order Placed",
    },
    {
      label: "Order Delivered",
      value: "Order Delivered",
    },
    {
      label: "Dispatched",
      value: "Dispatched",
    },
    {
      label: "Canceled",
      value: "Canceled",
    },
    {
      label: "Active",
      value: "Active",
    },
    {
      label: "Pending",
      value: "Pending",
    },
  ],
  orderStatusTableFieldTypes: [
    {
      label: "Order Placed",
      value: "Order Placed",
      color: "success",
    },
    {
      label: "Order Delivered",
      value: "Order Delivered",
      color: "info",
    },
    {
      label: "Dispatched",
      value: "Dispatched",
      color: "info",
    },
    {
      label: "Canceled",
      value: "Canceled",
      color: "danger",
    },
    {
      label: "Active",
      value: "Active",
      color: "success",
    },
    {
      label: "Pending",
      value: "Pending",
      color: "danger",
    },
  ],
  paymentTypes: [
    { label: "Offline", value: "offline" },
    { label: "Paypal", value: "paypal" },
    { label: "Stripe", value: "stripe" },
    { label: "Merchant Sevices", value: "merchant sevices" },
  ],
  pta_dues: [
    {
      label: "Individual 20$",
      value: { type: "Individual", amount: 20 },
    },
    {
      label: "Family 30$",
      value: { type: "Family", amount: 30 },
    },
    {
      label: "Teacher 20$",
      value: { type: "Teacher", amount: 20 },
    },
  ],
  supporting_donations: [
    { label: "25$", value: "25" },
    { label: "50$", value: "50" },
    { label: "100$", value: "100" },
    { label: "Others", value: "others" },
  ],

  // Products
  colorTypes: [
    { label: "Black", value: "#000000" },
    { label: "White", value: "#ffffff" },
    { label: "Red", value: "#ff0000" },
    { label: "Green", value: "#00ff00" },
    { label: "Blue", value: "#0000ff" },
  ],
  sizeTypes: [
    { label: "Small", value: "S" },
    { label: "Medium", value: "M" },
    { label: "Large", value: "L" },
    { label: "Xtra Large", value: "XL" },
  ],
  stateOptions: [
    { label: "Alabama - AL", value: "Alabama - AL" },
    { label: "Alaska - AK", value: "Alaska - AK" },
    { label: "Arizona - AZ", value: "Arizona - AZ" },
    { label: "Arkansas - AR", value: "Arkansas - AR" },
    { label: "California - CA", value: "California - CA" },
    { label: "Colorado - CO", value: "Colorado - CO" },
    { label: "Connecticut - CT", value: "Connecticut - CT" },
    { label: "Delaware - DE", value: "Delaware - DE" },
    { label: "Florida - FL", value: "Florida - FL" },
    { label: "Georgia - GA", value: "Georgia - GA" },
    { label: "Hawaii - HI", value: "Hawaii - HI" },
    { label: "Idaho - ID", value: "Idaho - ID" },
    { label: "Illinois - IL", value: "Illinois - IL" },
    { label: "Indiana - IN", value: "Indiana - IN" },
    { label: "Iowa - IA", value: "Iowa - IA" },
    { label: "Kansas - KS", value: "Kansas - KS" },
    { label: "Kentucky - KY", value: "Kentucky - KY" },
    { label: "Louisiana - LA", value: "Louisiana - LA" },
    { label: "Maine - ME", value: "Maine - ME" },
    { label: "Maryland - MD", value: "Maryland - MD" },
    { label: "Massachusetts - MA", value: "Massachusetts - MA" },
    { label: "Michigan - MI", value: "Michigan - MI" },
    { label: "Minnesota - MN", value: "Minnesota - MN" },
    { label: "Mississippi - MS", value: "Mississippi - MS" },
    { label: "Missouri - MO", value: "Missouri - MO" },
    { label: "Montana - MT", value: "Montana - MT" },
    { label: "Nebraska - NE", value: "Nebraska - NE" },
    { label: "Nevada - NV", value: "Nevada - NV" },
    { label: "New Hampshire - NH", value: "New Hampshire - NH" },
    { label: "New Jersey - NJ", value: "New Jersey - NJ" },
    { label: "New Mexico - NM", value: "New Mexico - NM" },
    { label: "New York - NY", value: "New York - NY" },
    { label: "North Carolina - NC", value: "North Carolina - NC" },
    { label: "North Dakota - ND", value: "North Dakota - ND" },
    { label: "Ohio - OH", value: "Ohio - OH" },
    { label: "Oklahoma - OK", value: "Oklahoma - OK" },
    { label: "Oregon - OR", value: "Oregon - OR" },
    { label: "Pennsylvania - PA", value: "Pennsylvania - PA" },
    { label: "Rhode Island - RI", value: "Rhode Island - RI" },
    { label: "South Carolina - SC", value: "South Carolina - SC" },
    { label: "South Dakota - SD", value: "South Dakota - SD" },
    { label: "Tennessee - TN", value: "Tennessee - TN" },
    { label: "Texas - TX", value: "Texas - TX" },
    { label: "Utah - UT", value: "Utah - UT" },
    { label: "Vermont - VT", value: "Vermont - VT" },
    { label: "Virginia - VA", value: "Virginia - VA" },
    { label: "Washington - WA", value: "Washington - WA" },
    { label: "West Virginia - WV", value: "West Virginia - WV" },
    { label: "Wisconsin - WI", value: "Wisconsin - WI" },
    { label: "Wyoming - WY", value: "Wyoming - WY" },
  ],
  parentTypeOptions: [
    { label: "Mother", value: "Mother" },
    { label: "Father", value: "Father" },
    { label: "Guardian", value: "Guardian" },
  ],
  vaccinationStatusOptions: [
    { label: "1st Dose Completed", value: "1st Dose Completed" },
    { label: "2nd Dose Completed", value: "2nd Dose Completed" },
    { label: "Vaccination Completed", value: "Vaccination Completed" },
    { label: "Not Yet", value: "Not Yet" },
  ],
  userOtpVerifiedOptions: [
    {
      value: true,
      color: "success",
      tooltip: "Verified",
      displayText: "Verified",
    },
    {
      value: false,
      color: "danger",
      tooltip: "Not Verified",
      displayText: "Not Verified",
    },
  ],
  isPaymentEnabledOptions: [
    {
      value: "True",
      color: "#28a745",
    },
    {
      value: "False",
      color: "#dc3545",
    },
  ],
  preloadOptions: [
    {
      value: "True",
      color: "#28a745",
    },
    {
      value: "False",
      color: "#dc3545",
    },
  ],

  foundationOptions: [
    {
      value: "True",
      color: "success",
      // displayText: "True",
    },
    {
      value: false,
      color: "danger",
      // displayText: "False",
    },
  ],
};
export default config;
