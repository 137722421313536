import React from "react";
import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


export default class Download extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      languageData: "",
    };
  }

  render() {
    const dataSet1 = this.props.products ? this.props.products : []
    const dataSet2 = this.props.order ? this.props.order : []
    return (

      <ExcelFile filename={this.props.archievedStore ? this.props.archievedStore[0].store_name : "Store"} element={<button className="excel_download" style={{ display: "none" }}>Download Data</button>}>
        {dataSet1 && dataSet1.length > 0 ?
          < ExcelSheet data={dataSet1} name="Products">
            {this.props.headers1.map((item, i) => {
              return <ExcelColumn label={item} value={item} />

            })}
          </ExcelSheet> : < ExcelSheet data={[]} name="Products">
            <ExcelColumn label={""} value={""} />
          </ExcelSheet>
        }
        {
          dataSet2 && dataSet2.length > 0 ?
            <ExcelSheet data={dataSet2} name="Orders">
              {this.props.headers2.map((item, i) => {
                return <ExcelColumn label={item} value={item} />

              })}

            </ExcelSheet> : < ExcelSheet data={[]} name="Orders">
              <ExcelColumn label={""} value={""} /></ExcelSheet>
        }
        {
          this.props.archievedStore && Object.keys(this.props.archievedStore).length > 0 ?
            <ExcelSheet data={this.props.archievedStore} name="Store">
              {this.props.headers3.map((item, i) => {
                return <ExcelColumn label={item} value={item} />

              })}

            </ExcelSheet> : < ExcelSheet data={[]} name="Store">
              <ExcelColumn label={""} value={""} /></ExcelSheet>
        }
      </ ExcelFile >
    );
  }
}