import React from "react";
import { AutoComplete } from "primereact/autocomplete";
import fetch from "../../../config/service";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

class AutoCompleteComponent extends React.Component {
  static propTypes = {
    onChange: PropTypes.func,
    meta: PropTypes.shape({
      touched: PropTypes.bool,
      error: PropTypes.string,
    }),
  };
  constructor(props) {
    super(props);
    this.state = {
      isValidate: false,
      filteredSuggestions: [],
      errorMessage: "",
    };
    // this.getAllSuggestions('', 'cont');
  }

  componentDidMount = async () => {
    await this.getAllSuggestions("", "cont");
  };

  // to get all suggestions in autocomplete
  getAllSuggestions = async (event, type) => {
    this.setState({
      isValidate: false,
      noData: false,
      errorMessage: "",
    });
    let filterCriteria = {
      limit: 30,
      page: 1,
      sortfield: "created",
      direction: "desc",
      criteria: [],
    };
    if (event && event.query) {
      filterCriteria["criteria"].push({
        key: this.props.searchField,
        value: event.query,
        type: "regexOr",
      });
    }

    if (
      this.props.input.name === "department_name" &&
      this.props.screen === "Faculty/Staff" &&
      this.props.schoolName
    ) {
      filterCriteria["criteria"] = [{
        key: "school_name",
        value: this.props.schoolName,
        type: "eq",
      }];
    }
    if (
      this.props.input.name === "class_name" &&
      this.props.screen === "Groups" &&
      this.props.schoolName
    ) {
      filterCriteria["criteria"].push({
        key: "school_id",
        value: this.props.schoolName,
        type: "eq",
      });
    }
    if (
      this.props.input.name === "store_name" &&
      this.props.screen === "Orders" &&
      this.props.schoolName
    ) {
      filterCriteria["criteria"].push({
        key: "school_name",
        value: this.props.schoolName,
        type: "eq",
      });
    }
    if (
      (this.props.screen === "Departments" || this.props.screen === "Class" || this.props.screen === "Faculty/Staff") &&
      this.props.districtName && this.props.input.name !== "district_name" && this.props.input.name !== "department_name"
    ) {
      filterCriteria["criteria"].push({
        key: "district_name",
        value: this.props.districtName,
        type: "eq",
      });
    }
    if (
      this.props.input.name === "child_name" &&
      this.props.screen === "Orders" &&
      this.props.schoolName
    ) {
      filterCriteria["criteria"].push({
        key: "school_name",
        value: this.props.schoolName,
        type: "eq",
      });
    }
    let apiUrl = this.props.searchApi;
    let url = `${apiUrl}?filter=${JSON.stringify(filterCriteria)}`;
    return fetch("GET", url)
      .then((response) => {
        if (response) {
          let dropdownData = [];
          if (
            response[apiUrl] &&
            response[apiUrl].length &&
            response[apiUrl].length > 0
          ) {
            dropdownData = response[apiUrl];
          }
          if (dropdownData && dropdownData.length === 0) {
            this.setState({
              filteredSuggestions: [],
              noData: true,
            });
          } else {
            this.setSuggestions(dropdownData);
          }
        }
      })
      .catch((err) => {
        return err;
      });
  };

  // set filtered sugeestions, removing duplicates
  setSuggestions = async (dropdownData) => {
    if (this.props.input && this.props.input.value) {
      if (this.props.input.value.length > 0) {
        let values = this.props.input.value;
        if (Array.isArray(values)) {
          values.forEach((element) => {
            let field = "_id";
            dropdownData = dropdownData.filter(
              (item) => item[field] !== element[field]
            );
          });
        }
      }
    }
    if (
      this.props.input.name === "department_name" &&
      this.props.screen === "Faculty/Staff" &&
      !this.props.schoolName
    ) {
      dropdownData = [];
    }
    if (
      this.props.input.name === "child_name" &&
      this.props.screen === "Orders" &&
      !this.props.schoolName
    ) {
      dropdownData = [];
    }
    if (
      this.props.input.name === "store_name" &&
      this.props.screen === "Orders" &&
      !this.props.schoolName
    ) {
      dropdownData = [];
    }
    if (this.props.screen === "Store" && this.props.input.name === "school_name") {
      this.props.getFilteredSchool(dropdownData)
    }
    await this.setState({
      filteredSuggestions: dropdownData,
    });
  };

  // on selected value from suggestions
  onSelectRecord(e) {
    this.setState({
      noData: false,
      isValidate: false,
      errorMessage: "",
    });
    if (this.props.input) {
      const { name, onChange } = this.props.input;
      onChange(e.value);
      if (this.props && this.props.handleAutoCompleteData) {
        this.props.handleAutoCompleteData(e.value, name);
      }
      if (e && e.value) {
        this.removeDuplicates(e.value, name);
      }
    }
  }

  // to remove duplicates
  removeDuplicates() {
    if (this.state.filteredSuggestions) {
      this.setSuggestions(this.state.filteredSuggestions);
    }
  }

  render() {
    const { placeholder, input, multiple, t, data, keyindex } = this.props;
    const { touched, error } = this.props.meta;

    return (
      <div className="date-picker mb-2">
        <span className="p-fluid">
          <AutoComplete
            style={{ background: "white", textTransform: "capitalize" }}
            multiple={multiple}
            value={input && input.value ? input.value : null}
            suggestions={this.state.filteredSuggestions}
            completeMethod={(e) => this.getAllSuggestions(e)}
            size={20}
            // data={ }
            minLength={3}
            placeholder={placeholder}
            dropdown={true}
            onChange={(e) => this.onSelectRecord(e)}
            field={this.props.searchField}
            disabled={this.props.disabled}
          />
        </span>
        {
          <span className="form__form-group-error">
            {touched && error && t(error)}
          </span>
        }
        {
          <span className="form__form-group-error">
            {this.state.noData && "no records found"}
          </span>
        }
        {this.state.isValidate ? (
          <span className="form__form-group-error">
            {this.state.errorMessage}
          </span>
        ) : null}
      </div>
    );
  }
}

export default withTranslation("common")(AutoCompleteComponent);
