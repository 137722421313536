import React from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';

export default class DivorceConfirmationModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <Modal isOpen={this.props.open} centered className='confirmationModal'>
                <ModalBody>
                    <h5 className='m-2'>{this.props.confirmationText}?</h5>
                    <Button color="primary" outline className='m-2' onClick={this.props.close}>No</Button>
                    <Button color="primary" outline className='m-2' onClick={this.props.confirm}>Yes</Button>
                </ModalBody>
            </Modal>
        );
    }
}