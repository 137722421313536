import React from "react";
import DataTables from "../../CommonDataTable/DataTable";
import store from "../../../App/store";
import config from "../../../../config/config";

export default class PendingCartReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      languageData: "",
      newletterCategoryOptions: "",
      newPublishOptions: [],
    };
  }





   getTableFields = () => {
    let data = [
      
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        header: "Sno",
        filter: false,
        sortable: false,
        placeholder: "Search",
        show: true,
      },

      {
        show:true,
        mobile:true,
        textAlign:'left',
        width:100,
        field:'schoolName',
        header:'School Name',
        filter:false,
        sortable:true,
        displayInSettings:true
      },

      {
        show: true,
        textAlign: "center",
        mobile: true,
        width: 100,
        field: "totalQty",
        header: "Total Qty",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        mobile: true,
        width: 120,
        field: "userEmail",
        header: "Email",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      
      {
        show: true,
        textAlign: "left",
        mobile: true,
        width: 100,
        field: "products",
        fieldType:"Array",
        header: "Product Name",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        mobile: true,
        width: 100,
        field: "userName",
        header: "User Name",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 150,
        mobile: true,
        field: "created",
        dateFormat: config.dateFormat,
        fieldType: "Date",
        header: "Created",
        filter: false,
        sortable: true,
        textCapitalize: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 100,
        fieldType: "Actions",
        field: "Actions",
        style: {
          color: config.templateColor,
          cursor: "pointer",
          textTransform: "capitalize",
          marginLeft:"10px",
        },
        header: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
      },
    ];
    return data;
  };

  getFormFields = () => {
    var loginRole;
    let loginData = localStorage.getItem("loginCredentials");
    if (loginData) {
      loginData = JSON.parse(loginData);
      if (loginData && loginData.role) {
        loginRole = loginData.role;
      }
    }
    return [
      {
        show: loginRole == "schoolAdmin" ? false : true,
        required: true,
        value: "",
        searchField: "Name",
        searchApi: "schools",
        type: "autoComplete",
        options: [
          { school_name: "Name" },
          { district_id: "district_id" },
          { school_id: "school_id" },
        ],
        name: "school_name",
        label: "School Name",
        id: "Name",
        placeholder: "School Name",
      },
      {
        value: "",
        type: "text",
        name: "title",
        label: "Title",
        id: "title",
        placeholder: "Title",
        required: true,
      },
      // {
      //   value: "",
      //   type: "textarea",
      //   id: "description",
      //   required: true,
      //   placeholder: "Description",
      //   name: "description",
      //   label: "Description",
      // },
      {
        value: "",
        type: "dropDown",
        id: "newsCategory",
        required: true,
        placeholder: "Newsletter Category",
        name: "news_category",
        label: "Newsletter Category",
        options:
          this.state.newletterCategoryOptions &&
            this.state.newletterCategoryOptions.length > 0
            ? this.state.newletterCategoryOptions
            : [],
      },
      {
        value: "",
        type: "dropDown",
        id: "newsPublish",
        required: true,
        placeholder: "Publish/Unpublish",
        name: "news_publish",
        label: "Publish/Unpublish",
        options:
          this.state.newPublishOptions &&
            this.state.newPublishOptions.length > 0
            ? this.state.newPublishOptions
            : [],
      },
      {
        required: true,
        value: "",
        type: "date",
        name: "publicationDate",
        label: "Publication Date",
        id: "publicationDate",
        placeholder: "Publication Date",
      },
      {
        required: true,
        value: "",
        type: "ckeditor",
        name: "newsletterBody",
        label: "Newsletter Body",
        id: "newsletterBody",
        placeholder: "name",
      },
    ];
  };

  render() {
    return (
      <DataTables
        getTableFields={this.getTableFields}
        formFields={this.getFormFields}
        addRequried={false}
        editRequired={false}
        deleteRequired={true}
        viewRequired={true}
        settingsRequired={false}
        globalSearch={"Product Name"}
        exportToCsv={true}
        type="Pending Carts"
        apiUrl={"carts/get_pending_cart"}
        routeTo="pending_carts"
        displayViewOfForm="screen"
      />
    );
  }
}

