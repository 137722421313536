import React from 'react';
import LogInForm from './components/LogInForm';
import config from '../../../config/config';
import configImages from '../../../config/configImages';
import LogoUI from '../../LogoUI/logoUI'
const LogIn = () => (
  <div className="account justify-content-center ">
    <div>

      {/* <h2 className='text-center my-2'>
        <img src={configImages.defaultImg} className='hide-mobile img_htwt' ></img>
      </h2> */}
      <div className="account__wrapper  cardLoginPadding">
        <LogoUI />

        <div className="account__card cardWidthLogin pt-1 pb-2">

          <div className='row justifyContentLogin my-3'>
            {/* <div className='col-sm-5 textcenter mt-4 pl-0 pr-0 mb-4'>
            <LogoUI />
            <img src={configImages.defaultImg} className='hide-mobile img_htwt'></img>
          </div> */}

            <div className='col-sm-12 vertical_middle width_90 my-2 pl-0 pr-0 '>
              <div className='width_90'>

                <LogInForm onSubmit />
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
);

export default LogIn;
