import React from "react";
import apiCalls from "../../../../config/apiCalls";
import store from "../../../App/store";
import filePath from "../../../../config/configSampleFiles";
import DataTables from "../../CommonDataTable/DataTable";

// config file
export default class Directory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      languageData: "",
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState();
    let languageData =
      storeData &&
        storeData.settingsData &&
        storeData.settingsData.settings &&
        storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  }

  getTableFields = () => {
    let data = [
      {
        textAlign: "left",
        width: 47,
        field: "",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: false,
      },
      {
        show: true,
        textAlign: "left",
        width: 100,
        field: "Actions",
        fieldType: "Actions",
        header: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
      },
      // {
      //   show: true,
      //   textAlign: "left",
      //   width: 47,
      //   field: "Sno",
      //   header: "Sno",
      //   filter: false,
      //   sortable: false,
      //   placeholder: "Search",
      // },
      {
        show: true,
        textAlign: "left",
        mobile: true,
        width: 120,
        field: "family",
        fieldType: "Link",
        header: "Family",
        filter: false,
        sortable: true,
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        mobile: true,
        width: 150,
        field: "district",
        header: "District",
        filter: false,
        sortable: true,
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        displayInSettings: true,
      },
      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 120, "field": "prepaid_dues", "header": "Paid Dues", "filter": true, "sortable": true
      // },
      {
        show: true,
        textAlign: "left",
        mobile: true,
        width: 220,
        field: "email1",
        header: "Email",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        filter: false,
        mobile: true,
        sortable: true,
        width: 100,
        field: "parent1",
        header: "Parent1",
        textCapitalize: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        filter: false,
        mobile: true,
        sortable: true,
        width: 150,
        field: "p1_display_name",
        header: "Parent1 Name",
        textCapitalize: true,
        displayInSettings: true,
      },
      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "filter": true,
      //   "mobile": true,
      //   "sortable": true,
      //   "width": 160,
      //   "field": "p1_middlename",
      //   "header": "Parent1 Middle Name",
      // },
      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "filter": true,
      //   "mobile": true,
      //   "sortable": true,
      //   "width": 160,
      //   "field": "p1_lastname",
      //   "header": "Parent1 Last Name",
      // },
      {
        show: true,
        textAlign: "left",
        filter: false,
        mobile: true,
        sortable: true,
        width: 160,
        field: "p1_suffix",
        header: "Parent1 Suffix",
        textCapitalize: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        filter: false,
        mobile: true,
        sortable: true,
        width: 150,
        field: "p1_firstname",
        header: "Parent1 First Name",
        textCapitalize: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        filter: false,
        mobile: true,
        sortable: true,
        width: 160,
        field: "p1_lastname",
        header: "Parent1 Last Name",
        textCapitalize: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        filter: false,
        mobile: true,
        sortable: true,
        width: 160,
        field: "p1_middlename",
        header: "Parent1 Middle Name",
        textCapitalize: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        filter: false,
        mobile: true,
        sortable: true,
        width: 80,
        field: "parent2",
        header: "Parent2",
        textCapitalize: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        filter: false,
        mobile: true,
        sortable: true,
        width: 150,
        field: "p2_display_name",
        header: "Parent2 Name",
        textCapitalize: true,
        displayInSettings: true,
      },
      // {
      //   "show": true,
      //   "mobile": true,
      //   "textAlign": "left",
      //   "filter": true,
      //   "sortable": true,
      //   "width": 160,
      //   "field": "p2_middlename",
      //   "header": "Parent2 Middle Name",
      // },
      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "filter": true,
      //   "mobile": true,
      //   "sortable": true,
      //   "width": 160,
      //   "field": "p2_lastname",
      //   "header": "Parent2 Last Name",
      // },
      {
        show: true,
        textAlign: "left",
        filter: false,
        mobile: true,
        sortable: true,
        width: 160,
        field: "p2_suffix",
        header: "Parent2 Suffix",
        textCapitalize: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        filter: false,
        mobile: true,
        sortable: true,
        width: 160,
        field: "p2_firstname",
        header: "Parent2 First Name",
        textCapitalize: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        filter: false,
        mobile: true,
        sortable: true,
        width: 160,
        field: "p2_lastname",
        header: "Parent2 Last Name",
        textCapitalize: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        filter: false,
        mobile: true,
        sortable: true,
        width: 160,
        field: "p2_middlename",
        header: "Parent2 Middle Name",
        textCapitalize: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        filter: false,
        mobile: true,
        sortable: true,
        width: 160,
        field: `divorced_parent_email`,
        header: "Divorced Parent EMail",
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        filter: false,
        mobile: true,
        sortable: true,
        width: 240,
        field: `divorced_parent_displayName`,
        header: "Divorced Parent Display Name",
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        filter: false,
        mobile: true,
        sortable: true,
        width: 160,
        field: `related_family_family`,
        header: "Related Family",
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        filter: false,
        mobile: true,
        sortable: true,
        width: 160,
        field: `related_parent_email`,
        header: "Related Parent Email",
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        filter: false,
        mobile: true,
        sortable: true,
        width: 240,
        field: `related_parent_displayName`,
        header: "Related Parent Display Name",
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        filter: false,
        mobile: true,
        sortable: true,
        width: 190,
        field: "address1",
        header: " Address Line 1",
        textCapitalize: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        mobile: true,
        filter: false,
        sortable: true,
        width: 190,
        field: "address2",
        header: " Address Line 2",
        textCapitalize: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        filter: false,
        mobile: true,
        sortable: true,
        width: 150,
        field: "city",
        header: "City",
        textCapitalize: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        filter: false,
        mobile: true,
        sortable: true,
        width: 100,
        field: "state",
        header: "State",
        textCapitalize: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "center",
        filter: false,
        sortable: true,
        mobile: true,
        width: 100,
        field: "zipCode",
        header: "Zip Code",
        displayInSettings: true,
      },
      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "filter": true,
      //   "sortable": true,
      //   "width": 100,
      //   "field": "otherInfo",
      //   "header": "OtherInfo",
      // },
      // {
      //   "show": true,
      //   "textAlign": "right",
      //   "filter": true,
      //   "sortable": true,
      //   "width": 100,
      //   "field": "phone1",
      //   "header": "Phone1",
      // },
      // {
      //   "show": true,
      //   "textAlign": "right",
      //   "filter": true,
      //   "sortable": true,
      //   "width": 100,
      //   "field": "phone2",
      //   "header": "Phone2",
      // },
      // {
      //   "show": true,
      //   "textAlign": "right",
      //   "filter": true,
      //   "sortable": true,
      //   "width": 100,
      //   "field": "phone3",
      //   "header": "Phone3",
      // },
      {
        show: true,
        textAlign: "center",
        filter: false,
        mobile: true,
        sortable: true,
        width: 200,
        field: "phone1_work",
        header: "Parent1 PhoneNo Work",
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "center",
        filter: false,
        mobile: true,
        sortable: true,
        width: 200,
        field: "phone1_home",
        header: "Parent1 PhoneNo Home",
        displayInSettings: true,
      }, {
        show: true,
        textAlign: "center",
        filter: false,
        mobile: true,
        sortable: true,
        width: 200,
        field: "phone2_home",
        header: "Parent2 PhoneNo Home",
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "center",
        filter: false,
        mobile: true,
        sortable: true,
        width: 200,
        field: "phone1_mobile",
        header: "Parent1 PhoneNo Mobile",
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "center",
        filter: false,
        mobile: true,
        sortable: true,
        width: 200,
        field: "phone2_work",
        header: "Parent2 PhoneNo Work",
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "center",
        filter: false,
        mobile: true,
        sortable: true,
        width: 200,
        field: "phone2_mobile",
        header: "Parent2 PhoneNo Mobile",
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        filter: false,
        mobile: true,
        sortable: true,
        width: 220,
        field: "email2",
        header: "Email2",
        displayInSettings: true,
      },

    ];
    return data;
  };

  getFormFields = () => {
    return [
      {
        value: "",
        type: "text",
        name: "family",
        label: "Family",
        id: "Family",
        placeholder: "Family",
        required: true,
      },
      {
        value: "",
        type: "text",
        name: "parent1",
        label: "Parent1",
        id: "Parent1",
        placeholder: "Parent1",
        required: true,
      },
      {
        value: "",
        type: "text",
        name: "p1_middlename",
        label: "Parent1 Middle Name",
        id: "P1_middlename",
        placeholder: "Parent1 Middle Name",
        required: true,
      },
      {
        value: "",
        type: "text",
        name: "p1_lastname",
        label: "Parent1 Last Name",
        id: "P1_lastname",
        placeholder: "Parent1 Last Name",
        required: true,
      },
      {
        value: "",
        type: "text",
        name: "p1_suffix",
        label: "Parent1 Suffix",
        id: "p1_suffix",
        placeholder: "Parent1 Suffix",
        required: true,
      },
      {
        value: "",
        type: "text",
        name: "parent2",
        label: "Parent2",
        id: "Parent2",
        placeholder: "Parent2",
        required: true,
      },
      {
        value: "",
        type: "text",
        name: "p2_middlename",
        label: "Parent2 Middle Name",
        id: "p2_middlename",
        placeholder: "Parent2 Middle Name",
        required: true,
      },
      {
        value: "",
        type: "text",
        name: "p2_lastname",
        label: "Parent2 Last Name",
        id: "P2_lastname",
        placeholder: "Parent2 Last Name",
        required: true,
      },
      {
        value: "",
        type: "text",
        name: "p2_suffix",
        label: "Parent2 Suffix",
        id: "p2_suffix",
        placeholder: "Parent2 Suffix",
        required: true,
      },
      {
        value: "",
        type: "textarea",
        name: "address1",
        label: "Address Line1",
        id: "Address1",
        placeholder: "Address Line1",
        required: true,
      },

      {
        value: "",
        type: "textarea",
        name: "address2",
        label: "Address Line2",
        id: "Address2",
        placeholder: "Address Line2",
        required: true,
      },
      {
        value: "",
        type: "text",
        name: "city",
        label: "City",
        id: "City",
        placeholder: "City",
        required: true,
      },
      {
        value: "",
        type: "text",
        name: "state",
        label: "State",
        id: "state",
        placeholder: "State",
        required: true,
      },
      {
        value: "",
        type: "text",
        name: "zipCode",
        label: "ZipCode",
        id: "ZipCode",
        placeholder: "ZipCode",
        required: true,
      },
      {
        value: "",
        type: "text",
        name: "phone4_p1",
        label: "Phone4 Parent1",
        id: "phone4_p1",
        placeholder: "Phone4 Parent1",
      },
      {
        value: "",
        type: "text",
        name: "phone4_type",
        label: "Phone4 Type",
        id: "phone4_type",
        placeholder: "Phone4 Type",
      },
      {
        value: "",
        type: "text",
        name: "phone5_p2",
        label: "Phone5 Parent2",
        id: "phone5_p2",
        placeholder: "Phone5 Parent2",
      },
      {
        value: "",
        type: "text",
        name: "phone5_type",
        label: "Phone5 Type",
        id: "phone5_type",
        placeholder: "Phone5 Type",
      },
      {
        value: "",
        type: "text",
        name: "email1",
        label: "Email1",
        id: "Email1",
        placeholder: "Email1",
        required: true,
      },
      {
        value: "",
        type: "text",
        name: "email2",
        label: "Email2",
        id: "Email2",
        placeholder: "Email2",
        required: true,
      },
      {
        value: "",
        type: "text",
        name: "prepaid_dues",
        label: "Prepaid Dues",
        id: "prepaid_dues",
        placeholder: "Prepaid Dues",
        required: true,
      },
    ];
  };

  render() {
    return (
      <DataTables
        getTableFields={this.getTableFields}
        // formFields={this.getFormFields}
        addRequried={true}
        editRequired={true}
        deleteRequired={true}
        viewRequired={true}
        settingsRequired={true}
        banRequired={true}
        sample={true}
        exportToCsv={true}
        sampleFilePath={filePath.family}
        bulkApi={"children"}
        globalSearch={"Family Name/Email/Address"}
        type="Family"
        apiUrl={apiCalls.Family}
        otherActionsRequired={true}
        actionsTypes={actionsTypes}
      />
    );
  }
}

const actionsTypes = [
  {
    name: "Delete",
    options: [{ label: "Delete", value: "Delete", show: true, multiple: true }],
  },
  {
    name: "ResetFamily",
    options: [
      {
        label: "Reset",
        value: "ResetFamily",
        show: true,
        multiple: true,
      },
    ],
  },
  // {
  //   name: "Divorse",
  //   options: [
  //     {
  //       label: "Divorce",
  //       value: "Divorce",
  //       show: true,
  //       multiple: true,
  //     },
  //   ],
  // },
  {
    name: "Separated",
    options: [
      {
        label: "Separate",
        value: "Separated",
        show: true,
        multiple: true,
      },
    ],
  },
];
