import React, { Component } from "react";
import PropTypes from "prop-types";
import SidebarLink from "./SidebarLink";
import SidebarCategory from "./SidebarCategory";
import { withTranslation } from "react-i18next";

class SidebarContent extends Component {
  static propTypes = {
    changeToDark: PropTypes.func.isRequired,
    changeToLight: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  componentDidMount = () => {};
  hideSidebar = () => {
    const { onClick } = this.props;
    onClick();
  };

  render() {
    const { menuList, t } = this.props;

    return (
      <div className="sidebar__content">
        <ul className="sidebar__block pb-4">
          {menuList && menuList.length > 0
            ? menuList.map((item, index) => {
                if (item.submenus && item.submenus.length > 0) {
                  return (
                    <SidebarCategory
                      key={index}
                      title={t(item.displayTitle)}
                      icon={item.icon}
                      sidebar={this.props.sidebar}
                    >
                      {item.submenus.map((sitem, sindex) => {
                        return (
                          <SidebarLink
                            key={sindex}
                            title={sitem.displayTitle}
                            icon={sitem.icon}
                            onClick={this.hideSidebar}
                            route={sitem.route}
                            sidebar={this.props.sidebar}
                            submenus={item.submenus}
                          />
                        );
                      })}
                    </SidebarCategory>
                  );
                } else {
                  return (
                    <SidebarLink
                      key={index}
                      icon={item.icon}
                      title={item.displayTitle}
                      route={item.route}
                      onClick={this.hideSidebar}
                      sidebar={this.props.sidebar}
                    />
                  );
                }
              })
            : null}
        </ul>
      </div>
    );
  }
}

export default withTranslation("common")(SidebarContent);
