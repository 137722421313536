import React from "react";
import {
    Modal, ModalBody, ModalHeader, Badge, Button
} from "reactstrap";
import classNames from 'classnames';
import Scrollbar from 'react-smooth-scrollbar';
import config from '../../../config/config';
import fetchMethodRequest from '../../../config/service';
import Loader from '../../App/Loader';

export default class TemplatePreviewModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rowData: {},
            label: '',
            status: '',
            type: null
        };
    }

    componentDidMount = () => {
        let data = this.props.data;
        if (document.getElementById('templateData')) {
            document.getElementById('templateData').innerHTML = data['templateText']
        }
    };





    render() {
        const modalClass = classNames({
            'modal-dialog--colored': this.state.colored,
            'modal-dialog--header': this.state.header,
        });
        return (
            <div style={{ display: 'flex' }}>
                <Loader loader={this.state.isLoading} />
                <Modal isOpen={this.props.openPreviewModal}
                    className={` modal-dialog--primary modal-dialog--header ${modalClass}`}
                >
                    <ModalHeader className="modal__header viewModalHeader" >
                        Email Preview
            <button className="lnr lnr-cross modal__close-btn" type="button"
                            onClick={this.props.closePreviewModal} />
                    </ModalHeader>
                    <ModalBody id='templateData'>

                    </ModalBody>
                    <div className='col-sm-12'>
                        <Button color='primary'
                            type="button"
                            disabled={this.props.actions && this.props.filterValue ? false : true}
                            onClick={() => this.props.sendEmailToServer()}>
                            Send Email
                </Button>
                        <Button color='primary'
                            type="button"
                            outline
                            onClick={() => this.props.closePreviewModal()}>
                            Cancel
                </Button>
                    </div>
                </Modal>
            </div>
        );
    }
}