import React from "react";
import apiCalls from "../../../../config/apiCalls";
import store from "../../../App/store";
import filePath from "../../../../config/configSampleFiles";
import DataTables from "../../CommonDataTable/DataTable";
import config from "../../../../config/config";

// config file
export default class Class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      languageData: "",
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState();
    let languageData =
      storeData &&
        storeData.settingsData &&
        storeData.settingsData.settings &&
        storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  }

  getTableFields = () => {
    let data = [

      // {
      //   textAlign: "center",
      //   width: 47,
      //   field: "Sno",
      //   header: "Sno",
      //   filter: false,
      //   sortable: false,
      //   placeholder: "Search",
      //   show: true,
      // },
      {
        show: true,
        textAlign: "left",
        width: 220,
        mobile: true,
        field: "csvFile",
        header: "Uploaded File",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },

      {
        show: true,
        textAlign: "left",
        width: 250,
        mobile: true,
        field: "duplicateFileDownloadUrl",
        header: "Failed File",
        fieldType: 'Download',
        style: {
          color: config.templateColor,
          cursor: "pointer",
          textTransform: "capitalize",
        },
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 160,
        field: "createdByName",
        header: "Created By Name",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 100,
        field: "total",
        fieldType: 'Number',
        header: "Total Records",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 100,
        field: "failed",
        fieldType: 'Number',
        header: "Failed Records",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 110,
        field: "success",
        fieldType: 'Number',
        header: "Success Records",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 100,
        field: "type",
        header: "Type",
        fieldType: 'Capitalize',
        filter: false,
        sortable: true,
        displayInSettings: true,
        textTransform: true,
      },
      // {
      //   show: true,
      //   textAlign: "left",
      //   width: 160,
      //   mobile: true,
      //   field: "csvFilePath",
      //   header: "Csv File Path",
      //   filter: false,
      //   sortable: true,
      //   textCapitalize: true,
      //   displayInSettings: true,
      // },
      // {
      //   show: true,
      //   textAlign: "center",
      //   width: 140,
      //   field: "duplicateFile",
      //   mobile: true,
      //   header: "Duplicate File",
      //   filter: false,
      //   sortable: true,
      //   displayInSettings: true,
      // },
      // {
      //   show: true,
      //   textAlign: "left",
      //   width: 180,
      //   field: "duplicateFilePath",
      //   mobile: true,
      //   header: "Duplicate File Path",
      //   filter: false,
      //   sortable: true,
      //   displayInSettings: true,
      // },
      {
        show: true,
        textAlign: "center",
        width: 120,
        field: "status",
        mobile: true,
        header: "Status",
        fieldType: "Badge",
        style: {
          padding: "4px 6px",
          fontSize: 12,
          color: "white",
          textTransform: "capitalize",
          fontWeight: "bold",
        },
        options: config.uploadStatusTypes,
        filter: false,
        sortable: false,
      },

      {
        show: true,
        textAlign: "center",
        width: 180,
        field: "created",
        header: "Created",
        fieldType: 'Date',
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
    ];
    return data;
  };


  render() {
    return (
      <DataTables
        getTableFields={this.getTableFields}
        viewRequired={true}
        settingsRequired={true}
        globalSearch={"Created By Name"}
        type="Uploads"
        apiUrl={apiCalls.Uploads}
      />
    );
  }
}


