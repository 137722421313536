
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Button, CardBody, Modal } from 'reactstrap';

// fecth method from service.js file
import fetch from '../../../config/service';
import config from '../../../config/config';
import configMessage from '../../../config/configMessages';
// show message 
import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';
import DefaultInput from '../../../shared/components/form/DefaultInput';
import validate from '../../Validations/validate';
import Loader from '../../App/Loader';
import EyeIcon from 'mdi-react/EyeIcon';

//validations
let newpassval = value => (value ? config.userPasswordRegex.test(value) ? undefined : 'Password must have at least 12 characters and contain at least one Uppercase letter, one Lower case letter,one Number and a special Character.' : configMessage.fillField)
let adminnewpassval = (value) =>
    value
        ? config.adminpasswordRegex.test(value)
            ? undefined
            : "The Password should contain minimum 16 characters with one Uppercase letter, one Lower case letter,one Number and a special Character"
        : configMessage.fillField;
class UserPasswordResetModal extends React.Component {
    constructor(props) {
        super(props);
        this.buttonActionType = null;
        this.state = {
            isLoginSuccess: false,
            isLoading: true
        };
    }

    componentDidMount() {
        this.setState({ isLoading: false });
        if (this.props.entityType === "employee") {
            this.setState({ admin: true });
        }
    }

    // on value change in input
    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        })
    }

    showPassword = (e) => {
        e.preventDefault();
        this.setState(prevState => ({
            showPassword: !prevState.showPassword
        }));
    }

    showConfirmPassword = (e) => {
        e.preventDefault();
        this.setState(prevState => ({
            showConfirmPassword: !prevState.showConfirmPassword
        }));
    }

    // handle login user data
    handleUserPassword = async (values) => {
        await this.setState({
            isLoading: true
        });
        let user = this.props.user;
        let url = `auth/createPassword?adminReset=true&_id=${user._id}&fromAdmin=true`;
        values.entityType = this.props.entityType

        fetch('POST', url, values).then(async response => {
            if (response && response.respCode && response.respMessage) {
                showToasterMessage(response.respMessage, 'success');
            } else if (response && response.errorMessage) {
                showToasterMessage(response.errorMessage, 'error');
            }
            await this.setState({
                isLoading: false
            });
            await this.cancelReset();
        })
    }

    // clear input data
    clearInputFields = () => {
        this.props.reset();
    }

    submit = (values) => {
        if (values && values.newPassword === values.confirmPassword) {
            this.handleUserPassword(values)
        } else if (values.password !== values.confirmPassword) {
            newpassval = values => (configMessage.passwordMatchValidation)
        }
    }

    cancelReset = async () => {
        await this.props.reset();
        await this.props.cancelReset();
    }

    render() {
        const { handleSubmit, openUserPasswordResetModal } = this.props;
        console.log("dhbfjhds", this.props)
        return (
            <Modal isOpen={openUserPasswordResetModal} centered className='userPwdResetModal'>
                <CardBody className='p-2'>
                    {this.props.entityType === "user" ?
                        <h4 className='pb-3'>Reset User Password</h4> :
                        <h4 className='pb-3'>Reset Administrator Password</h4>}
                    <form className="form" onSubmit={handleSubmit(this.submit)}>
                        <div className='form__form-group'>
                            <Loader loader={this.state.isLoading} />
                            <div className="form__form-group pb-2">
                                <label className="form__form-group-label">New Password</label>
                                <div className=' form__form-group-field'>
                                    <Field className='inputLogin'
                                        type={this.state.showConfirmPassword ? 'text' : "password"}
                                        name="newPassword"
                                        component={DefaultInput}
                                        placeholder='New Password'
                                        validate={this.state.admin ? [adminnewpassval] : [newpassval]}
                                    />
                                    <button
                                        type="button"
                                        className={`form__form-group-button${this.state.showConfirmPassword ? ' active' : ''}`}
                                        onClick={e => this.showConfirmPassword(e)}
                                    ><EyeIcon />
                                    </button>
                                </div>
                            </div>
                            <div className="form__form-group pb-1">
                                <label className="form__form-group-label">Confirm Password</label>
                                <div className='form__form-group-field'>
                                    <Field className='inputLogin'
                                        type={this.state.showPassword ? 'text' : "password"}
                                        name="confirmPassword"
                                        component={DefaultInput}
                                        validate={this.state.admin ? [adminnewpassval] : [newpassval]}
                                        placeholder='Confirm Password'
                                    /> <button
                                        type="button"
                                        className={`form__form-group-button${this.state.showPassword ? ' active' : ''}`}
                                        onClick={e => this.showPassword(e)}
                                    ><EyeIcon />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div style={{ margin: 'auto' }}>
                            <Button color="primary" type='submit'>Submit</Button>
                            <Button color="primary" type='buttom' onClick={this.cancelReset}>Cancel</Button>
                        </div>
                    </form>
                </CardBody>
            </Modal>
        );
    }
}

UserPasswordResetModal = reduxForm({
    form: 'User Password Reset Form', // a unique identifier for this form
    validate,
})(UserPasswordResetModal);

export default UserPasswordResetModal;