import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import SendGroupEmail from './components/SendGroupEmail';

const SendGroupEmailTemplatesPage = () => (
  <Container>
    <SendGroupEmail />
  </Container>
);

export default withTranslation('common')(SendGroupEmailTemplatesPage);