import React, { PureComponent } from "react";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";

import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { load as loadAccount } from "../../../../redux/reducers/commonReducer";
// import {settingsAction} from '../../../../redux/actions/settingsAction'
import {
  settings,
  settingsPincode,
} from "../../../../redux/actions/settingsAction";
import PropTypes from "prop-types";

import EyeIcon from 'mdi-react/EyeIcon';
// import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
// import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';

import renderCheckBoxField from "../../../../shared/components/form/CheckBox";

// Validate input
import validate from "../../../Validations/validate";

// Input field
import DefaultInput from "../../../../shared/components/form/DefaultInput";

// Config
import configMessage from "../../../../config/configMessages";
import fetchRequest from "../../../../config/service";
import apiCalls from "../../../../config/apiCalls";
import config from "../../../../config/config";

// Toaster message
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";

// Loader
import Loader from "../../../App/Loader";

const required = (value) => {
  // return value || (typeof value === "string") ? undefined : configMessage.fillField;
  return value && value.length !==0 ? undefined : configMessage.fillField;
}
 

class LogInForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      isLoginDisabled: false,
      isLoading: true,
      loginRememberInfo: null,
      captchaValue: null,
      isRobot: false,
      isLoginSuccess: false,
      isLoading: false,
      loginRole: "",
    };

    this.getLoginRememberInfo();
  }

  getLoginRememberInfo = async () => {
    let local = localStorage;
    let keys = Object.keys(local);
    for (let key of keys) {
      if (key !== "langKeys" && key != "loginRememberInfo") {
        localStorage.removeItem(key);
      }
    }

    let loginRememberInfo = localStorage.getItem("loginRememberInfo");
    loginRememberInfo = loginRememberInfo
      ? JSON.parse(loginRememberInfo)
      : null;
    await this.props.load(loginRememberInfo);
    this.setState({
      loginRememberInfo: loginRememberInfo,
      remember_me:
        loginRememberInfo && loginRememberInfo.remember_me
          ? loginRememberInfo.remember_me
          : false,
    });
  };

  // Passowrd Visible handler
  showPassword = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
  };

  submit = (values) => {
    if (values.email && values.password) {
      this.setState({
        remember_me: values.remember_me,
        isLoginDisabled: true,
        isLoading: true,
      });
      this.handleLoginUser(values);
    }
  };

  handleLoginUser = async (values) => {
    let userBody = {
      email: values.email,
      password: values.password,
      entityType: config.entityType,
    };
    localStorage.setItem("loginBody", JSON.stringify(userBody));
    return fetchRequest("POST", apiCalls.loginUser, userBody)
      .then(async (response) => {
        if (response && response.respCode && response.respCode === 200) {
          this.setState({
            isLoading: true,
          });
          let userDetails = response.details;
          if (userDetails && userDetails._id) {
            if (response.accessToken) {
              let tokenInfo = {
                accessToken: response.accessToken,
                refreshToken: response.refreshToken,
                tokenExpires: response.tokenExpires,
              };
              userDetails = { ...userDetails, ...tokenInfo };
              this.getSettingsData(userDetails);
              // save user credentials in storage
              localStorage.setItem(
                "loginCredentials",
                JSON.stringify(userDetails)
              );
              if (userDetails && userDetails.role) {
                this.setState({
                  loginRole: userDetails.role,
                });
              }
            }
            if (userDetails && userDetails.rolePermissions) {
              let permissions = userDetails.rolePermissions;
              // save user permissions in storage
              localStorage.setItem(
                "rolePermissions",
                JSON.stringify(permissions)
              );
            }
          }
          showToasterMessage(response.respMessage, "success");
          await this.setState(
            {
              isLoginSuccess: true,
            },
            () => {
              if (this.state.remember_me) {
                var data = {
                  email: values.email,
                  password: values.password,
                  remember_me: this.state.remember_me,
                };
                if (
                  this.state.loginRememberInfo &&
                  this.state.loginRememberInfo.email &&
                  this.state.loginRememberInfo.email !== values.email
                ) {
                  localStorage.setItem(
                    "loginRememberInfo",
                    JSON.stringify(data)
                  );
                } else if (!this.state.loginRememberInfo) {
                  localStorage.setItem(
                    "loginRememberInfo",
                    JSON.stringify(data)
                  );
                }
              } else {
                localStorage.removeItem("loginRememberInfo");
              }
            }
          );
        } else if (response && response.errorMessage) {
          setTimeout(() => {
            this.setState({
              isLoginDisabled: false,
              isLoading: false,
            });
          }, 250);
          showToasterMessage(response.errorMessage, "error");
        }
        // settingsAction
      })
      .catch((err) => {
        this.setState({
          isLoginDisabled: false,
          isLoading: false,
        });
      });
  };

  handleRememberMe = (bool) => {
    this.setState({
      remember_me: !bool,
    });
  };

  getSettingsData = (userDetails) => {
    let settingsData;
    let filterCriteria = {};
    // let url=?filter=${JSON.stringify(filterCriteria)}
    filterCriteria["criteria"] = [
      { key: "userObjId", value: userDetails["_id"], type: "eq" },
    ];
    fetchRequest("GET", `settings`).then(async (response) => {
      if (response && response.respCode) {
        settingsData = response.settings[0];

        await this.props.load(settingsData);
        await this.props.settingsLoad(settingsData);
        await this.props.settingsPincodeLength(settingsData["pincodeLength"]);
      }
    });
    return settingsData;
  };
  render() {
    const { handleSubmit } = this.props;
    const { showPassword } = this.state;
    return (
      <form className="form" onSubmit={handleSubmit(this.submit)}>
        <Loader loader={this.state.isLoading} />
        <div className="form__form-group">
          <span className="form__form-group-label">Username</span>
          <div className="form__form-group-field">
            {/* <div className="form__form-group-icon">
              <AccountOutlineIcon />
            </div> */}
            <Field
              name="email"
              component={DefaultInput}
              type="email"
              placeholder="Email"
              validate={required}
            />
          </div>
        </div>
        <div className="form__form-group mt-2">
          <div className="form__form-group-label">Password</div>
          <div className="form__form-group-field">
            <Field
              name="password"
              component={DefaultInput}
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              validate={required}
            />

            <button
              type="button"
              className={`form__form-group-button${showPassword ? ' active' : ''}`}
              onClick={e => this.showPassword(e)}
            ><EyeIcon />
            </button>

          </div>
          <div>
            <span className="float-left ">
              <div className="form__form-group ">
                <div className="form__form-group-field">
                  <Field
                    name="remember_me"
                    component={renderCheckBoxField}
                    label="Remember me"
                    id="remember_me"
                    checked={this.state.remember_me}
                    onChange={() =>
                      this.handleRememberMe(this.state.remember_me)
                    }
                  />
                </div>
              </div>
            </span>
            <span className="float-right pt-2 mt-1">
              <Link to="/forgot_password">Forgot password?</Link>
            </span>
          </div>
        </div>
        <div style={{ marginTop: 10 }} className="w-100 pt-2">
          <span className="float-left w-100">
            <Button
              className="ml-auto "
              style={{ width: "100%" }}
              color="primary"
              disabled={this.state.isLoginDisabled}
            >
              LOGIN
            </Button>
          </span>
        </div>
        {/* <div style={{ marginTop: 10 }} className='w-100 pt-2'>
          <span className='float-left w-100'>
            <Link to="/sign_up">
              <Button className="ml-auto" style={{ width: '100%' }}
                color="primary"
                disabled={this.state.isLoginDisabled}>SIGNUP</Button>
            </Link>
          </span>
        </div> */}
        {/* change */}
        {/* Navigate to Home */}
        {this.state.isLoginSuccess && this.state.loginRole === "superAdmin" ? (
          <Redirect to="/employee" />
        ) : null}
        {this.state.isLoginSuccess && this.state.loginRole !== "superAdmin" ? (
          <Redirect to="/faculties" />
        ) : null}
      </form>
    );
  }
}

LogInForm = reduxForm({
  form: "log_in_form", // a unique identifier for this form
  validate,
  enableReinitialize: true,
})(LogInForm);

// You have to connect() to any reducers that you wish to connect to yourself
LogInForm = connect(
  (state) => ({
    initialValues: state.commonData.data, // pull initial values from account reducer
  }),
  {
    load: loadAccount, // bind account loading action creator
    settingsLoad: settings, //binding settingsDAta
    settingsPincodeLength: settingsPincode,
  }
)(LogInForm);

export default LogInForm;
