import React from "react";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import store from "../../../App/store";
import DataTables from "../../CommonDataTable/DataTable";

// config file
export default class AuditLog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      languageData: "",
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState();
    let languageData =
      storeData &&
        storeData.settingsData &&
        storeData.settingsData.settings &&
        storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  }

  getTableFields = () => {
    let data = [
      // {
      //   textAlign: "center",
      //   width: 47,
      //   field: "Sno",
      //   header: "Sno",
      //   filter: false,
      //   sortable: false,
      //   mobile: true,
      //   placeholder: "Search",
      //   show: true,
      // },
      {
        textAlign: "center",
        show: true,
        mobile: true,
        width: 80,
        field: "created",
        fieldType: "Date",
        dateFormat: config.dateTimeFormat,
        header: "Created Date",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        textAlign: "left",
        width: 60,
        field: "context",
        mobile: true,
        header: "Context",
        filter: false,
        sortable: true,
        show: true,
        textCapitalize: true,
        displayInSettings: true,
      },
      {
        "textAlign": "left",
        "width": 60,
        "field": "contextType",
        "header": "Context Type",
        "filter": false,
        "sortable": true,
        "show": true,
      },
      {
        textAlign: "left",
        width: 60,
        field: "email",
        mobile: true,
        header: "Email",
        filter: false,
        sortable: true,
        show: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 80,
        mobile: true,
        fieldType: "Array",
        field: "description",
        header: "Description",
        filter: false,
        sortable: true,
        textCapitalize: true,
        displayInSettings: true,
      },

    ];
    return data;
  };

  getFormFields = () => {
    return [
      {
        value: "",
        type: "text",
        name: "user_name",
        label: "User Name",
        id: "user_name",
        placeholder: "User Name",
        required: true,
      },
      {
        value: "",
        type: "text",
        name: "email",
        label: "Email",
        id: "email",
        placeholder: "Email",
        required: true,
      },
    ];
  };

  render() {
    return (
      <DataTables
        getTableFields={this.getTableFields}
        formFields={this.getFormFields}
        globalSearch={"Context/Email/Description"}
        type="Audit Logs"
        apiUrl={apiCalls.Activities}
      />
    );
  }
}
