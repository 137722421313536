export const SETTINGS_DATA = 'SETTINGS_DATA';
// export const AUTHENTICATE_ERROR_AUTH = 'AUTHENTICATE_ERROR_AUTH';

export function settings(data) {
  return {
    type: SETTINGS_DATA,
    data,
  };
}
export function settingsPincode(pincodeLength) {
  return {
    type: 'SETTINGS_PINCODE',
    pincodeLength,
  };
}
export function settingsPincodeLength() {
  console.log('settingsPincodeLength')
  return {
    type: 'SETTINGS_PINCODE_RETURN',
  };
}