import React from "react";
import classNames from "classnames";
import { load as loadAccount } from "./../../redux/reducers/commonReducer";
import { settings } from "../../redux/actions/settingsAction";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Select from "../../shared/components/form/Select";
import RadioButton from "../../shared/components/form/RadioButton";
import DefaultInput from "../../shared/components/form/DefaultInput";
import DefaultInputTextArea from "../../shared/components/form/DefaultTextArea";

// import { Dropdown } from 'primereact/dropdown';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BulkUploadModal from "../Cruds/CommonModals/BulkUploadModal";
import FileUpload from "../Form/components/FileUpload";
// import MultiSelectDropDown from '../Form/components/MultiSelect';

import fetchMethodRequest from "../../config/service";
import config from "../../config/config";

// import DataTables from '../Cruds/CommonDataTable/DataTable';
import {
  Button,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
// Toaster message
import showToasterMessage from "../UI/ToasterMessage/toasterMessage";
import validate from "../Validations/validate";
// Loader
import Loader from "../App/Loader";
// Calendar
//session expiry modal
// import SessionExpiryModal from '../Cruds/CommonModals/SessionexpiryModal'
// import { th } from 'date-fns/locale';
// import store from '../App/store';

// const required = value => (value || typeof value === 'string' ? undefined : configMessages.fillField);
const normalizePhone = (value) => {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/[^\d]/g, "");
  if (onlyNums.length <= 3) {
    return onlyNums;
  }
  if (onlyNums.length <= 7) {
    return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`;
  }
  return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 6)}-${onlyNums.slice(
    6,
    10
  )}`;
};
class SettingsForm extends React.Component {
  static propTypes = {
    t: PropTypes.func,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      settingsData: "",
      options: [
        { label: "DD/MM/YYYY", value: "DD/MM/YYYY" },
        { label: "MM/DD/YYYY", value: "MM/DD/YYYY" },
        { label: "MMM DD YYYY", value: "MMM DD YYYY" },
        { label: "DD MMM YYYY", value: "DD MMM YYYY" },
        { label: "MM-DD-YYYY", value: "MM-DD-YYYY" },
        { label: "DD-MM-YYYY", value: "DD-MM-YYYY" },
        { label: "MM/DD/YYYY HH:mm A", value: "MM/DD/YYYY HH:mm A" },
        { label: "MMM DD YYYY HH:mm A", value: "MMM DD YYYY HH:mm A" },
        { label: "hh:mm A, MM-DD-YYYY", value: "hh:mm A, MM-DD-YYYY" },
        { label: "MM/DD/YYYY HH:mm", value: "MM/DD/YYYY HH:mm" },
        { label: "YYYY-MM-DD HH:mm:ss", value: "YYYY-MM-DD HH:mm:ss" },
        {
          label: "YYYY-MM-DD[T]HH:mm:ss.SSS",
          value: "YYYY-MM-DD[T]HH:mm:ss.SSS",
        },
        { label: "YYYY-MM-DD[T]00:00:00Z", value: "YYYY-MM-DD[T]00:00:00Z" },
        { label: "MMM YYYY", value: "MMM YYYY" },
        { label: "MMM  DD, YYYY", value: "MMM  DD, YYYY" },
      ],
      isLoading: false,
      activeTab: "2",
      selectschool: "",
      committeNames: [],
      openBulkUploadModal: false,
      displayTabThree: false,
      showUpload: false,
    };
  }
  setShowUpload = async (type) => {
    if (type === false) {
      await this.setState({
        isLoading: false,
      });
    }
    await this.setState({ showUpload: type });
  };
  getTableFieldsMenu = () => {
    let { languageData } = this.state;
    let roleTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Admin",
        value: "Admin",
      },
      {
        label: "Super Admin",
        value: "Super Admin",
      },
      {
        label: "User",
        value: "User",
      },
    ];
    let StatusTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    let data = [
      {
        show: true,
        textAlign: "center",
        width: 47,
        field: "Sno",
        header: "Sno",
        filter: false,
        sortable: false,
        placeholder: "Search",
      },

      {
        show: true,
        textAlign: "left",
        width: 80,
        field: "icon",
        header: "Icon",
        filter: true,
        sortable: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 80,
        field: "displayTitle",
        header: "Display Title",
        filter: true,
        sortable: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 80,
        field: "title",
        header: "Title",
        filter: true,
        sortable: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 80,
        field: "route",
        header: "Route",
        filter: true,
        sortable: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 80,
        field: "status",
        header: "Status",
        filter: true,
        sortable: false,
      },
      {
        show: true,
        textAlign: "center",
        width: 70,
        field: "Actions",
        header: "Actions",
        filter: false,
        sortable: false,
      },
    ];
    return data;
  };

  getFormFieldsMenu = () => {
    let { languageData } = this.state;
    let statusTypes = [
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    return [
      {
        value: "",
        type: "text",
        name: "icon",
        label: "Icon",
        id: "Icon",
        placeholder: "Icon",
        required: true,
      },
      {
        value: "",
        type: "text",
        name: "displayTitle",
        label: "Display Title",
        id: "displayTitle",
        placeholder: "Display Title",
        required: true,
      },
      {
        value: "",
        type: "text",
        name: "title",
        label: "Title",
        id: "title",
        placeholder: "Title",
        required: true,
      },
      {
        value: "",
        type: "text",
        name: "route",
        label: "Route",
        id: "route",
        placeholder: "Route",
        required: true,
      },
      {
        required: false,
        value: "",
        type: "dropDown",
        name: "status",
        label: "Status",
        id: "status",
        options: statusTypes,
        placeholder: "Status",
      },
    ];
  };
  getTableFields = () => {
    let { languageData } = this.state;
    let roleTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Admin",
        value: "Admin",
      },
      {
        label: "Super Admin",
        value: "Super Admin",
      },
      {
        label: "User",
        value: "User",
      },
    ];
    let StatusTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    let data = [
      // {
      //   show: true,
      //   textAlign: "center",
      //   width: 47,
      //   field: "Sno",
      //   header: "Sno",
      //   filter: false,
      //   sortable: false,
      //   placeholder: "Search",
      // },

      {
        show: true,
        textAlign: "left",
        width: 80,
        field: "label",
        header: "State Label",
        filter: true,
        sortable: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 80,
        field: "value",
        header: "State Name",
        filter: true,
        sortable: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 80,
        field: "status",
        header: "Status",
        filter: true,
        sortable: false,
      },
      {
        show: true,
        textAlign: "center",
        width: 70,
        field: "Actions",
        header: "Actions",
        filter: false,
        sortable: false,
      },
    ];
    return data;
  };

  getFormFields = () => {
    let { languageData } = this.state;
    let statusTypes = [
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    return [
      {
        value: "",
        type: "text",
        name: "label",
        label: "State Label",
        id: "State Label",
        placeholder: "State Label",
        required: true,
      },
      {
        value: "",
        type: "text",
        name: "value",
        label: "State Value",
        id: "value",
        placeholder: "State Value",
        required: true,
      },
      {
        required: false,
        value: "",
        type: "dropDown",
        name: "status",
        label: "Status",
        id: "status",
        options: statusTypes,
        placeholder: "Status",
      },
    ];
  };
  componentDidMount = async () => {
    let userDetails = JSON.parse(localStorage.getItem("loginCredentials"));
    if (userDetails && userDetails.role) {
      if (userDetails.role === "superAdmin") {
        await this.setState({
          displayTabThree: true,
        });
      } else {
        await this.setState({
          displayTabThree: false,
        });
      }
    }
    await this.getSettings();
    await this.getSchools();
  };

  getSchools = async () => {
    fetchMethodRequest("GET", "schools")
      .then(async (response) => {
        if (response) {
          let states = response["schools"];
          let roleOptions = this.state.committeNames;
          if (states && states.length > 0) {
            for (let i = 0; i < states.length; i++) {
              roleOptions.push({
                label: states[i]["Name"],
                value: states[i]["Name"],
              });
            }
            await this.setState({
              committeNames: roleOptions,
            });
          }
        }
      })
      .catch((err) => {
        return err;
      });
  };

  getSettings = async () => {
    let userDetails = JSON.parse(localStorage.getItem("loginCredentials"));
    // let apiUrl=''
    this.setState({
      isLoading: true,
    });
    // let url = `settings?filter=${JSON.stringify(filterCriteria)}`
    let filterCriteria = {};
    filterCriteria["criteria"] = [
      { key: "userObjId", value: userDetails["_id"], type: "eq" },
    ];
    fetchMethodRequest("GET", `settings`).then(async (response) => {
      if (response && response.respCode) {
        let settingsData = response.settings[0];
        this.setState({
          isLoading: false,
          settingsData: settingsData,
        });
        if (settingsData && settingsData['i18n']) {
          settingsData['i18n'] = JSON.stringify(settingsData['i18n']);
          let t = settingsData['i18n'];

          t = t.replace(/,/g, ",\n")

          settingsData['i18n'] = t;
        }
        await this.props.load(settingsData);
        await this.props.load(settingsData);
      }
    });
  };
  //send data to server
  saveDataToServer = (formValues) => {
    if (formValues['i18n']) {
      let t = formValues['i18n'];
      t = t.replace(/,\n/g, ',');
      t = JSON.parse(t);
      formValues['i18n'] = t
    }

    let validationExists;
    this.setState({
      isLoading: true,
    });
    let settingsData = this.state.settingsData;
    if (formValues) {
      let method, apiUrl;
      apiUrl = `settings/${settingsData._id}`;
      return fetchMethodRequest("PUT", apiUrl, formValues)
        .then(async (response) => {
          // let sessionexpired = await localStorage.getItem('sessionexpired')
          // if (sessionexpired === "true") {
          //   await this.setState({ sessionExpiryModal: true })
          // }
          let settingsData = this.state.settingsData;
          settingsData.dateFormat = formValues.dateFormat;
          this.setState({ settingsData: settingsData });
          this.getSettings()
          // await this.props.settingsLoad(settingsData);
          if (response && response.respCode) {
            showToasterMessage(response.respMessage, "success");
          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, "error");
          }

          this.setState({
            isLoading: false,
          });
        })
        .catch((err) => {
          return err;
        });
    }
  };
  apple = () => {
    console.log("I am refreshed");
  };
  toggle = async (tab) => {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      await this.setState({
        activeTab: tab,
        selectschool: "",
        showUpload: false,
      });
    }
  };
  onFilterChangeWithSchool = async (event) => {
    await this.setState({
      [event.target.name]: event.target.value,
    });
  };
  //open Bulk Modal
  bulkUploadMoadal = () => {
    this.setState({ openBulkUploadModal: true });
  };
  //close Bulk Modal
  closeBulkModal = async () => {
    await this.setState(
      {
        openBulkUploadModal: false,
      },
      () => {
        this.bulkUploadMoadalRef.handleClickCount();
      }
    );
  };
  uploadToServer = () => {
    if (this.fileUploadRef) {
      this.setState({
        isLoading: true,
      });
      this.fileUploadRef.UploadFileToServer();
    }
  };
  render() {
    const { handleSubmit, t } = this.props;
    const { options, isLoading, activeTab } = this.state;
    let isSubmitting = false;
    const modalClass = classNames({
      "modal-dialog--colored": this.state.colored,
      "modal-dialog--header": this.state.header,
    });

    //
    return (
      <div className="container">
        <Loader loader={isLoading} />
        <div className="row">
          <div className="col-sm-12 px-4 pt-3">
            <div className="tabs tabs--justify">
              <div className="tabs__wrap">
                <Nav tabs>
                  {Tabs.map((tab) => {
                    return tab.name === "Bulk Upload Students" ? (
                      this.state.displayTabThree ? (
                        <NavItem key={tab.serialNo}>
                          <NavLink
                            className={classNames({
                              active: activeTab === tab.serialNo,
                            })}
                            onClick={() => {
                              this.toggle(tab.serialNo);
                            }}
                          >
                            {tab.name}
                          </NavLink>
                        </NavItem>
                      ) : null
                    ) : (
                      <NavItem key={tab.serialNo}>
                        <NavLink
                          className={classNames({
                            active: activeTab === tab.serialNo,
                          })}
                          onClick={() => {
                            this.toggle(tab.serialNo);
                          }}
                        >
                          {tab.name}
                        </NavLink>
                      </NavItem>
                    );
                  })}
                </Nav>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <form onSubmit={handleSubmit(this.saveDataToServer)}>
                      <div className="row ">
                        <div className="col-sm-7">
                          <Card>
                            <CardBody className="px-3">
                              <div className="row create-header py-3 ml-0">
                                <h4 className="float-left ">
                                  {" "}
                                  <b>{t("General Settings")}</b>
                                </h4>
                              </div>
                              <div className="form-group">
                                <label>{t("Select Date Format")}</label>
                                <div className="form__form-group-field">
                                  <Field
                                    name={"dateFormat"}
                                    component={Select}
                                    options={options}
                                    placeholder={t("Select Date Format")}
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <label>{t("Disable Multiple Login")}</label>
                                <div className="d-block d-sm-block d-md-flex radio-container">
                                  <div className="form-check form-check-inline custom-align mr-4">
                                    <Field
                                      component={RadioButton}
                                      name="disableMultipleLogin"
                                      id="disableMultipleLogin1"
                                      className=" mr-2"
                                      radioValue={true}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="disableMultipleLogin1"
                                    >
                                      {t("True")}
                                    </label>
                                  </div>
                                  <div className="form-check form-check-inline custom-align">
                                    <Field
                                      component={RadioButton}
                                      name="disableMultipleLogin"
                                      id="disableMultipleLogin2"
                                      className=" mr-2"
                                      radioValue={false}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="disableMultipleLogin2"
                                    >
                                      {t("False")}
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="form-group">
                                <label>{t("Enable Mails")}</label>
                                <div className="d-block d-sm-block d-md-flex radio-container">
                                  <div className="form-check form-check-inline custom-align mr-4">
                                    <Field
                                      component={RadioButton}
                                      name="enableMails"
                                      id="enableMails"
                                      className=" mr-2"
                                      radioValue={true}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="enableMails"
                                    >
                                      {t("True")}
                                    </label>
                                  </div>
                                  <div className="form-check form-check-inline custom-align">
                                    <Field
                                      component={RadioButton}
                                      name="enableMails"
                                      id="enableMails"
                                      className=" mr-2"
                                      radioValue={false}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="enableMails"
                                    >
                                      {t("False")}
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="form-group">
                                <label>{t("Enable Users Delete")}</label>
                                <div className="d-block d-sm-block d-md-flex radio-container">
                                  <div className="form-check form-check-inline custom-align mr-4">
                                    <Field
                                      component={RadioButton}
                                      name="enableUserDelete"
                                      id="enableUserDelete"
                                      className=" mr-2"
                                      radioValue Mails={true}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="enableUserDelete"
                                    >
                                      {t("True")}
                                    </label>
                                  </div>
                                  <div className="form-check form-check-inline custom-align">
                                    <Field
                                      component={RadioButton}
                                      name="enableUserDelete"
                                      id="enableUserDelete"
                                      className=" mr-2"
                                      radioValue={false}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="enableUserDelete"
                                    >
                                      {t("False")}
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="form-group">
                                <label>{t("Enable Terminal Logs")}</label>
                                <div className="d-block d-sm-block d-md-flex radio-container">
                                  <div className="form-check form-check-inline custom-align mr-4">
                                    <Field
                                      component={RadioButton}
                                      name="enableTerminalLogs"
                                      id="enableTerminalLogs"
                                      className=" mr-2"
                                      radioValue={true}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="enableTerminalLogs"
                                    >
                                      {t("True")}
                                    </label>
                                  </div>
                                  <div className="form-check form-check-inline custom-align">
                                    <Field
                                      component={RadioButton}
                                      name="enableTerminalLogs"
                                      id="enableTerminalLogs"
                                      className=" mr-2"
                                      radioValue={false}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="enableTerminalLogs"
                                    >
                                      {t("False")}
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="form-group">
                                <label>{t("PinCode Length")}</label>
                                <Field
                                  type="number"
                                  component={DefaultInput}
                                  name="pincodeLength"
                                  className="form-control"
                                />
                              </div>
                              <div className="form-group">
                                <label>{t("Block IP Address")}</label>
                                <Field
                                  component={DefaultInput}
                                  name="blockingIP_times"
                                  className="form-control"
                                />
                              </div>
                              <div className="form-group">
                                <label>{t('IOS version')}</label>
                                <Field
                                  type='text'
                                  component={DefaultInput}
                                  name="IOSversion" className="form-control" />
                              </div><div className="form-group">
                                <label>{t('Android Version')}</label>
                                <Field
                                  type='text'
                                  component={DefaultInput}
                                  name="AndroidVersion" className="form-control" />
                              </div>

                              <div className="form-group">
                                <label>{t("Header Title Style")}</label>
                                <Field
                                  name="headerTitleStyle"
                                  component={Select}
                                  options={this.state.settingsData.headerStyle}
                                  className="form-control"
                                />
                              </div>
                              <div className="form-group">
                                <label>{t("Details Tittle Style")}</label>
                                <Field
                                  name="detailsTitleStyle"
                                  component={Select}
                                  options={this.state.settingsData.detailsStyle}
                                  className="form-control"
                                />
                              </div>

                              <div className="pl-5 pt-4 float-right">
                                <button
                                  type="submit"
                                  className="btn btn-primary"
                                >
                                  {t("Update")}
                                </button>
                              </div>
                            </CardBody>
                          </Card>
                        </div>
                      </div>
                    </form>
                  </TabPane>
                  <TabPane tabId="2">
                    <form onSubmit={handleSubmit(this.saveDataToServer)}>
                      <div className="row">
                        <div className="col-sm-7">
                          <Card>
                            <CardBody className="px-3">
                              <div className="row create-header py-3 ml-0">
                                <h4 className="float-left ">
                                  {" "}
                                  <b>{t("Token Time Settings")}</b>
                                </h4>
                              </div>
                              <div className="form-group">
                                <label>
                                  {t("Admin Expire Token Time (min)")}
                                </label>
                                <Field
                                  component={DefaultInput}
                                  name="adminExpireTokenTimeInMin"
                                  className="form-control"
                                  type="number"
                                />
                              </div>
                              <div className="form-group">
                                <label>{t("Expire Token Time (min)")} </label>
                                <Field
                                  component={DefaultInput}
                                  name="expireTokenTimeInMin"
                                  className="form-control"
                                  type="number"
                                />
                              </div>
                              <div className="pl-5 pt-4 float-right">
                                <button
                                  type="submit"
                                  className="btn btn-primary"
                                >
                                  {t("Update")}
                                </button>
                              </div>
                            </CardBody>
                          </Card>
                        </div>
                      </div>
                    </form>
                  </TabPane>
                  <TabPane tabId="3">
                    <form onSubmit={handleSubmit(this.saveDataToServer)}>
                      <div className="row ">
                        <div className="col-sm-7">
                          <Card>
                            <CardBody className="px-3">
                              <div className="row create-header py-3 ml-0">
                                <h4 className="float-left ">
                                  {" "}
                                  <b>{t("Payment Settings")}</b>
                                </h4>
                              </div>
                              <div className="form__form-group">
                                <label>{t("Westport PTA Permission")}</label>
                                <Field
                                  type="textarea"
                                  component={DefaultInputTextArea}
                                  name="westport_PTA_permission_text"
                                />
                              </div>
                              <div className="form__form-group">
                                <label>{t("Terms And Services")}</label>
                                <Field
                                  type="textarea"
                                  component={DefaultInputTextArea}
                                  name="terms_and_services_text"
                                  className="form-control"
                                />
                              </div>
                              <div className="form__form-group">
                                <label>{t("Mobile Access")}</label>
                                <Field
                                  type="textarea"
                                  component={DefaultInputTextArea}
                                  name="mobile_access_text"
                                />
                              </div>
                              {/* <div className="form__form-group">
                                <label>{t('Payment Types')}</label>
                                <Field
                                  type='dropdDown'
                                  filterElement={config.paymentTypes}
                                  component={MultiSelectDropDown}
                                  name="payment_types" />
                              </div>
                              <div className="form__form-group">
                                <label>{t('Pta Dues')}</label>
                                <Field
                                  type='dropdDown'
                                  filterElement={config.pta_dues}
                                  component={MultiSelectDropDown}
                                  name="pta_dues" />
                              </div>
                              <div className="form__form-group">
                                <label>{t('Supporting Donations')}</label>
                                <Field
                                  type='dropdDown'
                                  filterElement={config.supporting_donations}
                                  component={MultiSelectDropDown}
                                  name="supporting_donations" />
                              </div> */}
                              <div className="pl-5 pt-4 float-right">
                                <button
                                  type="submit"
                                  className="btn btn-primary"
                                >
                                  {t("Update")}
                                </button>
                              </div>
                            </CardBody>
                          </Card>
                        </div>
                      </div>
                    </form>
                  </TabPane>
                  <TabPane tabId="4">
                    <div className="col-sm-12">
                      <Card>
                        <CardBody>
                          <div className="row">
                            {/* <div className='col-5'>
                            Please Attach File
                            </div> */}
                            <div className="col-12 my-3">
                              <FileUpload
                                type="bulkUpload"
                                onRef={(ref) => (this.fileUploadRef = ref)}
                                url={"employees/csvupload?type=children"}
                                setShowUpload={this.setShowUpload}
                              // close={this.props.closeBulkModal}
                              // selectschool={this.props.selectschool}
                              // accept=".csv"
                              />
                            </div>
                            {this.state.showUpload && (
                              <div className="col-sm-12 text-center">
                                <Button
                                  color="primary"
                                  outline
                                  onClick={this.uploadToServer}
                                >
                                  Upload - Students
                                </Button>
                              </div>
                            )}
                          </div>
                          {/* <div className='row'>
                            <div className='col-sm-6'>
                              <div className='col-sm-6 pl-0 text-left'>
                                <Dropdown style={{ minWidth: '30%', marginTop: 5, lineHeight: 1.1 }}
                                  appendTo={document.body}
                                  name='selectschool'
                                  value={this.state['selectschool']}
                                  placeholder='Select School'
                                  options={this.state.committeNames}
                                  onChange={(e) => this.onFilterChangeWithSchool(e)} />
                              </div>
                            </div>
                            <div className='col-sm-12 text-left'>
                              <Button color="primary"
                                size="sm"
                                className="p-1"
                                // disabled={!this.state.selectschool}
                                onClick={this.bulkUploadMoadal}>
                                <FontAwesomeIcon
                                  icon='upload'
                                  className='pl-1' size='lg'
                                  data-toggle="tool-tip" title="Bulk Upload"
                                  onClick={this.bulkUploadMoadal} />
                              </Button>
                            </div>
                          </div> */}
                        </CardBody>
                      </Card>
                    </div>
                  </TabPane>
                  <TabPane tabId="5">
                    <form onSubmit={handleSubmit(this.saveDataToServer)}>
                      <div className="row ">
                        <div className="col-sm-7 ">
                          <Card>
                            <CardBody className="px-3">
                              <div className="row create-header py-3 ml-0">
                                <h4 className="float-left ">
                                  {" "}
                                  <b>{t("Messages Settings")}</b>
                                </h4>
                              </div>
                              <div className="form__form-group">
                                <label>{t("Messages")}</label>
                                <Field
                                  type="textarea"
                                  component={DefaultInputTextArea}
                                  rows={20}
                                  name="i18n"
                                />
                              </div>
                              <div className="pl-5 pt-4 float-right">
                                <button
                                  type="submit"
                                  className="btn btn-primary"
                                >
                                  {t("Update")}
                                </button>
                              </div>
                            </CardBody>
                          </Card>
                        </div>
                      </div>
                    </form>
                  </TabPane>
                  {/* <TabPane tabId="4">
                    <div className='col-sm-12'>
                      <DataTables
                        MobileTableFields={this.getTableFieldsMenu}
                        getTableFields={this.getTableFieldsMenu}
                        formFields={this.getFormFieldsMenu}
                        addRequried={true}
                        // editRequried={true}
                        deleteRequried={true}
                        // viewRequried={true}
                        getSettings={this.getSettings}
                        // globalSearch={'value'}
                        type='Menulist'
                        apiUrl='menulists'
                      />
                    </div >
                  </TabPane> */}
                </TabContent>
              </div>
            </div>
          </div>
          {/* <div style={{ overflowY: 'hidden', height: '100%' }} onLoad={this.apple} className='col-sm-12'>
            <form onSubmit={handleSubmit(this.saveDataToServerState)}>
              <div className="form-group">
                <label>State Label</label>
                <Field
                  component={DefaultInput}
                  name="adminExpireTokenTimeInMin" className="form-control" />
              </div>
              <div className="form-group">
                <label>State Name</label>
                <Field
                  component={DefaultInput}
                  name="expireTokenTimeInMin" className="form-control" />
              </div>
            </form>
          </div > */}

          <BulkUploadModal
            onRef={(ref) => (this.bulkUploadMoadalRef = ref)}
            bulkApi={"children"}
            type={"Students"}
            sample={true}
            openBulkUploadModal={this.state.openBulkUploadModal}
            closeBulkModal={this.closeBulkModal}
            selectschool={this.state.selectschool}
            sample={`${config.apiUrl}employees/csvupload?type=children`}
          />
        </div>
      </div>
    );
  }
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
SettingsForm = reduxForm({
  form: "Settings Form", // a unique identifier for this form
  validate,
  enableReinitialize: true,
})(SettingsForm);

// You have to connect() to any reducers that you wish to connect to yourself
SettingsForm = connect(
  (state) => ({
    initialValues: state.commonData.data, // pull initial values from account reducer
  }),
  {
    load: loadAccount, // bind account loading action creator
    settingsLoad: settings,
    // settingsData:auth
  }
)(SettingsForm);

export default withTranslation("common")(SettingsForm);

const Tabs = [
  { name: "General Settings", serialNo: "1" },
  { name: "Token Settings", serialNo: "2" },
  { name: "Payment Settings", serialNo: "3" },
  { name: "Message Settings", serialNo: "5" },
  // { "name": "Bulk Upload Students", "serialNo": "4" },
];
