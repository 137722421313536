import React from "react";
import apiCalls from "../../../../config/apiCalls";
import config from "../../../../config/config";
import DataTables from "../../CommonDataTable/DataTable";
import fetch from "../../../../config/service";
import { Card, CardBody, Button, Row, Col } from "reactstrap";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import TemplatePreviewModal from "../../../Cruds/CommonModals/TemplatePreviewModal";
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
import { Paginator } from 'primereact/paginator';


export default class EmailTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      actions: "",
      actionsTypes: [],
      filterElement: [],
      filterValue: "",
      openPreviewModal: false,
      data: -1,
      page: 1,
      selectedLimit: 10,
      limitoptions: [
        { label: "10", value: 10 },
        { label: "15", value: 15 },
        { label: "30", value: 30 },
        { label: "50", value: 50 },
        { label: "100", value: 100 },
        { label: "250", value: 250 }],
      filter: { limit: 10, page: 1, criteria: [] },
      lastPageNum: 2
    };
  }

  componentDidMount() {
    this.getGroup();
  }

  sendEmailToServer = async () => {
    this.closePreviewModal();
    let body = {},
      url = "";
    body["templateId"] = this.state.data["_id"];
    body["selectedIds"] = this.state.filterValue;
    url = `${apiCalls.Groups}/groupmails/${this.state.actions}`;
    fetch("POST", url, body).then(async (res) => {
      if (res && res.respCode) {
        this.setState({
          actions: "",
          filterValue: "",
        });
        showToasterMessage(res.respMessage, "success");
      } else if (res && res.errorMessage) {
        showToasterMessage(res.errorMessage, "error");
      }
    });
  };

  getGroup = async () => {
    let data = localStorage.getItem("templateData");
    data = JSON.parse(data);

    if (data) {
      await this.setState({
        data: data,
      });
      let url = "",
        filter = {
          criteria: [],
        };
      filter["criteria"].push({
        key: "school_name",
        value: data["school_name"],
        type: "eq",
      });
      url = `${apiCalls.Groups}?filter=${JSON.stringify(filter)}`;
      fetch("GET", url)
        .then(async (res) => {
          if (res && res["groups"].length > 0) {
            let resposneData = res["groups"],
              groupTypes = [];
            for (let i = 0; i < resposneData.length; i++) {
              groupTypes.push({
                label: resposneData[i]["name"],
                value: resposneData[i]["_id"],
              });
            }
            await this.setState({
              actionsTypes: groupTypes,
            });
          } else {
            await this.setState({
              actionsTypes: [],
            });
          }
        })
        .catch((err) => {
          return err;
        });
    }
  };

  //onActionsChange
  onActionsChange = async (event, type) => {
    if (type === "dropdownFilter") {
      await this.setState({
        [event.target.name]: event.target.value,
        filterElement: [],
        filterValue: "",
      });
      let url = "",
        filter = this.state.filter
      filter["criteria"].push({
        key: "group_id",
        value: event.target.value,
        type: "eq",
      });
      await this.setState({
        filter: filter
      })
      this.handlegetValues()
    };

  }

  handlegetValues() {
    let filter = this.state.filter, url
    url = `members?filter=${JSON.stringify(filter)}`;
    fetch("GET", url)
      .then(async (res) => {
        if (res && res["members"].length > 0) {
          let resposneData = res["members"],
            groupTypes = [];
          for (let i = 0; i < resposneData.length; i++) {
            groupTypes.push({
              label: resposneData[i]["email"],
              value: resposneData[i]["email"],
            });
          }
          if (res.pagination && res.pagination.totalCount) {
            console.log(res.pagination.totalCount / this.state.selectedLimit)
            let lastPageNum = Math.ceil(res.pagination.totalCount / this.state.selectedLimit);
            console.log(lastPageNum)
            console.log(this.state.page)

            await this.setState({
              totalCount: res.pagination.totalCount,
              lastPageNum: lastPageNum
            })
          }
          await this.setState({
            filterElement: groupTypes,
          });
        } else {
          await this.setState({
            filterElement: [],
          });
        }
      })
      .catch((err) => {
        return err;
      });
  }

  handleFilter = async (e) => {
    if (e && e.value) {
      await this.setState({
        filterValue: e.value,
      });
    } else {
      await this.setState({
        filterValue: "",
      });
    }
  };

  onPageChange = (event) => {
    let filter = this.state.filter
    filter["limit"] = event.value
    this.setState({
      selectedLimit: event.value,
      filter: filter
      // rows2: event.rows
    });

    this.handlegetValues()

  }

  closePreviewModal = () => {
    this.setState({
      openPreviewModal: false,
    });
  };

  onChangePage = (event, type) => {
    let filter = this.state.filter
    filter["page"] = type === "increment" ? event + 1 : event - 1
    this.setState({
      // selectedLimit: event.value,
      page: type === "increment" ? event + 1 : event - 1,
      filter: filter
      // rows2: event.rows
    });
    this.handlegetValues()

  }

  render() {
    return (
      <div>
        {this.state.openPreviewModal && (
          <TemplatePreviewModal
            data={this.state.data}
            openPreviewModal={this.state.openPreviewModal}
            closePreviewModal={this.closePreviewModal}
            sendEmailToServer={this.sendEmailToServer}
            actions={this.state.actions}
            filterValue={this.state.filterValue}
          />
        )}
        <Card style={{ margin: "7px" }} className={"table_data_Desktop"}>
          <CardBody className="tableCardBody">
            <div className="row">
              <div className="col-sm-12">
                <h4>
                  <b>Send Email</b>
                </h4>
                <h5 className="my-4">
                  Choose desired group and email addresses to send the email
                </h5>
              </div>
              <div className="col-sm-4">
                <Dropdown
                  style={{ minWidth: "100%", lineHeight: 1.3 }}
                  className="ml-auto mb-3 mr-3"
                  name="actions"
                  value={this.state.actions}
                  disabled={
                    this.state.actionsTypes &&
                      this.state.actionsTypes.length > 0
                      ? false
                      : true
                  }
                  options={this.state.actionsTypes}
                  placeholder={"Select Group"}
                  onChange={(e) => this.onActionsChange(e, "dropdownFilter")}
                />
              </div>
              {this.state.actions && (<>
                {this.state.page > 1 ?
                  <i className="pi pi-step-backward-alt" style={{ 'fontSize': '3em' }} onClick={() => this.onChangePage(this.state.page, "decrement")}></i> : null}
                <div className="col-sm-4 pl-0 d-flex pr-0">
                  <div className="date-picker">
                    <span className="p-fluid mb-2">
                      <MultiSelect
                        style={{ minWidth: "15%", lineHeight: 1 }}
                        value={this.state.filterValue}
                        options={this.state.filterElement}
                        onChange={(e) => this.handleFilter(e)}
                        placeholder={"Select Email"}
                        filter={true}
                      />
                    </span>
                  </div>
                  {this.state.lastPageNum !== this.state.page ?
                    <i className="pi pi-step-forward-alt" style={{ 'fontSize': '3em' }} onClick={() => this.onChangePage(this.state.page, "increment")}></i> : null}
                  <div className="" style={{ width: 10 }}>
                    <Dropdown
                      style={{ minWidth: "15%", lineHeight: 1.3 }}
                      className="ml-auto mb-3 mr-3"
                      name="actions"
                      value={this.state.selectedLimit}
                      options={this.state.limitoptions}
                      placeholder={"Select limit"}
                      onChange={(e) => this.onPageChange(e)}
                    /></div>
                </div>
              </>
              )}
              <div className="col-sm-12 mt-4">
                <Button
                  color="primary"
                  outline
                  type="button"
                  onClick={() =>
                    this.setState({
                      openPreviewModal: true,
                    })
                  }
                >
                  Preview Email
                </Button>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    );
  }
}

const actionsTypes = [
  {
    name: "Delete",
    options: [{ label: "Delete", value: "Delete", show: true, multiple: true }],
  },
];

const paginatorStyle = {
  padding: 10,
  backgroundColor: 'rgba(0,0,0,.03)',
  border: 'none'
}

const countColStyle = {
  backgroundColor: 'White',
  border: 'none',
  textAlign: 'center'
}

const countTextStyle = {
  fontSize: 13,
  fontFamily: "Open Sans,Helvetica Neue,sans-serif",
}