import React, { PureComponent } from 'react';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';

import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { load as loadAccount } from '../../../../redux/reducers/commonReducer';
// import {settingsAction} from '../../../../redux/actions/settingsAction'
import { settings } from '../../../../redux/actions/settingsAction'
import PropTypes from 'prop-types';

// import EyeIcon from 'mdi-react/EyeIcon';
// import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
// import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';

// import renderCheckBoxField from '../../../../shared/components/form/CheckBox';

// Validate input
import validate from '../../../Validations/validate';

// Input field
import DefaultInput from '../../../../shared/components/form/DefaultInput';

// Config
import configMessage from '../../../../config/configMessages';
import fetchRequest from '../../../../config/service';
import apiCalls from '../../../../config/apiCalls';
// import config from '../../../../config/config';

// Toaster message
import showToasterMessage from '../../../UI/ToasterMessage/toasterMessage';

// Loader
import Loader from '../../../App/Loader';

const required = value => (value || typeof value === 'string' ? undefined : configMessage.fillField)

class LogInForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      isLoginDisabled: false,
      loginRememberInfo: null,
      captchaValue: null,
      isRobot: false,
      isLoginSuccess: false,
      isLoading: false,
      loginRole: ''
    };

    this.getLoginRememberInfo();
  }

  getLoginRememberInfo = async () => {
    let keysToRemove = ["loginCredentials", "rolePermissions", 'loginBody', 'sessionexpired'];
    for (let key of keysToRemove) {
      localStorage.removeItem(key);
    }

    let loginRememberInfo = localStorage.getItem('loginRememberInfo');
    loginRememberInfo = loginRememberInfo ? JSON.parse(loginRememberInfo) : null;
    await this.props.load(loginRememberInfo);
    this.setState({
      loginRememberInfo: loginRememberInfo,
      remember_me: loginRememberInfo && loginRememberInfo.remember_me ? loginRememberInfo.remember_me : false
    })
  }

  showPassword = (e) => {
    e.preventDefault();
    this.setState(prevState => ({ showPassword: !prevState.showPassword }));
  }

  submit = (values) => {
    if (values.email && values.password) {

      this.setState({
        remember_me: values.remember_me,
        isLoginDisabled: true,
        isLoading: true
      })
      this.handleLoginUser(values)
    }
  }

  handleLoginUser = async (values) => {
    let userBody = {
      email: values.email,
      password: values.password,
      firstName: values.firstName,
      lastName: values.lastName,
      role: "admin",
    };
    localStorage.setItem('loginBody', JSON.stringify(userBody));
    return fetchRequest('POST', apiCalls.Employees, userBody)
      .then(async (response) => {
        if (response && response.respCode && response.respCode === 200) {
          this.setState({
            isLoading: true
          });
          let userDetails = response.details;
          if (userDetails && userDetails._id) {
            if (response.accessToken) {
              let tokenInfo = {
                accessToken: response.accessToken,
                refreshToken: response.refreshToken,
                tokenExpires: response.tokenExpires,
              };
              userDetails = { ...userDetails, ...tokenInfo };
              this.getSettingsData(userDetails)
              // save user credentials in storage
              localStorage.setItem('loginCredentials', JSON.stringify(userDetails));
              if (userDetails && userDetails.role && userDetails.role === 'Teacher') {
                this.setState({
                  loginRole: userDetails.role
                })
              }
            }
            if (userDetails && userDetails.rolePermissions) {
              let permissions = userDetails.rolePermissions;
              // save user permissions in storage
              localStorage.setItem('rolePermissions', JSON.stringify(permissions));
            }
          }
          showToasterMessage(response.respMessage, 'success');
          await this.setState({
            isLoginSuccess: true,
          }, () => {
            if (this.state.remember_me) {
              var data = {
                email: values.email,
                password: values.password,
                remember_me: this.state.remember_me
              }
              if (this.state.loginRememberInfo && this.state.loginRememberInfo.email &&
                this.state.loginRememberInfo.email !== values.email) {
                localStorage.setItem('loginRememberInfo', JSON.stringify(data));
              } else if (!this.state.loginRememberInfo) {
                localStorage.setItem('loginRememberInfo', JSON.stringify(data));
              }
            } else {
              localStorage.removeItem('loginRememberInfo');
            };
          });
        } else if (response && response.errorMessage) {
          setTimeout(() => {
            this.setState({
              isLoginDisabled: false,
              isLoading: false
            })
          }, 250);
          showToasterMessage(response.errorMessage, 'error');
        }
        // settingsAction
      }).catch((err) => {
        this.setState({
          isLoginDisabled: false,
          isLoading: false
        });
      });
  }

  onChange = (value) => {
    if (value === 'remember' && this.state.remember_me) {
      this.setState({
        remember_me: false
      })
    }
  }
  getSettingsData = (userDetails) => {
    let settingsData;
    let filterCriteria = {};
    filterCriteria['criteria'] = [{ key: 'userObjId', value: userDetails['_id'], type: 'eq' }];
    fetchRequest('GET', `settings?filter=${JSON.stringify(filterCriteria)}`).then(async (response) => {
      if (response && response.respCode) {
        settingsData = response.settings[0]
        await this.props.load(settingsData)
        await this.props.settingsLoad(settingsData)
      }

    })
    return settingsData;
  }
  render() {
    const { handleSubmit } = this.props;
    const { showPassword } = this.state;

    return (
      <form className="form" onSubmit={handleSubmit(this.submit)}>
        <Loader loader={this.state.isLoading} />
        <div className="form__form-group">
          <span className="form__form-group-label">Email</span>
          <div className="form__form-group-field">
            <Field
              name="email"
              component={DefaultInput}
              type="email"
              placeholder="Email"
              validate={required}
            />
          </div>
        </div>
        <div className="form__form-group mt-2">
          <div className="form__form-group-label">Password</div>
          <div className="form__form-group-field">
            <Field
              name="password"
              component={DefaultInput}
              type={showPassword ? 'text' : 'password'}
              placeholder="Password"
              validate={required}
            />
          </div>
        </div>
        <div className="form__form-group mt-2">
          <span className="form__form-group-label">First Name</span>
          <div className="form__form-group-field">
            <Field
              name="firstName"
              component={DefaultInput}
              type="text"
              placeholder="First Name"
              validate={required}
            />
          </div>
        </div>
        <div className="form__form-group mt-2">
          <span className="form__form-group-label">Last Name</span>
          <div className="form__form-group-field">
            <Field
              name="lastName"
              component={DefaultInput}
              type="text"
              placeholder="Last Name"
              validate={required}
            />
          </div>
        </div>


        <div style={{ marginTop: 10 }} className='w-100 pt-2'>
          <span className='float-left w-100'>
            <Button className="ml-auto " style={{ width: '100%' }}
              color="primary"
              disabled={this.state.isLoginDisabled}>SIGNUP</Button>
          </span>
        </div>
        <div style={{ marginTop: 10 }} className='w-100'>

          <span className='float-right pt-2'>
            <Link to="/log_in"> Back to Login</Link>
          </span>
        </div>
        {/* change */}
        {/* Navigate to Home */}
        {this.state.isLoginSuccess ? <Redirect to="/employee" /> : null}
      </form >
    );
  }
}

LogInForm = reduxForm({
  form: 'sign_in_form', // a unique identifier for this form
  validate,
  enableReinitialize: true,
})(LogInForm);

// You have to connect() to any reducers that you wish to connect to yourself
LogInForm = connect(
  state => ({
    initialValues: state.commonData.data // pull initial values from account reducer
  }),
  {
    load: loadAccount,// bind account loading action creator
    settingsLoad: settings //binding settingsDAta
  }
)(LogInForm);

export default LogInForm;
