import React, { PureComponent } from "react";
import DownIcon from "mdi-react/ChevronDownIcon";
import { Collapse } from "reactstrap";
import TopbarMenuLink from "./TopbarMenuLink";
import config from "../../../config/config";
import configImages from "../../../config/configImages";
import { withTranslation } from "react-i18next";

const Ava = `${process.env.PUBLIC_URL}/img/ava.png`;
const noProfileImage = configImages.defaultImg;

class TopbarProfile extends PureComponent {
  constructor() {
    super();
    this.state = {
      collapse: false,
      userData: {},
    };
  }

  toggle = () => {
    this.setState((prevState) => ({ collapse: !prevState.collapse }));
  };

  componentDidMount = async () => {
    this.updateTopbarProfileData();
  };

  updateTopbarProfileData = async () => {
    let userData = localStorage.getItem("loginCredentials");
    userData = await JSON.parse(userData);
    await this.setState({
      userData: userData,
    });
  };

  render() {
    const { collapse } = this.state;
    const profileImage =
      this.state.userData && this.state.userData.photo
        ? this.state.userData.photo
        : null;
    const { t } = this.props;

    return (
      <div className="topbar__profile">
        <button className="topbar__avatar" type="button" onClick={this.toggle}>
          {/* change */}
          <img
            className="topbar__avatar-img"
            src={
              profileImage
                ? `${config.imgUrl}employee/${profileImage}`
                : noProfileImage
            }
          />
          <p
            className="topbar__avatar-name"
            style={{ textTransform: "capitalize" }}
          >
            {this.state.userData &&
            (this.state.userData.lname || this.state.userData.fname)
              ? `${this.state.userData.fname} ${this.state.userData.lname}`
              : "No Name"}
          </p>
          <DownIcon className="topbar__icon" />
        </button>
        {collapse && (
          <button
            className="topbar__back"
            type="button"
            onClick={this.toggle}
          />
        )}
        <Collapse isOpen={collapse} className="topbar__menu-wrap">
          <div className="topbar__menu topbarMenu">
            <div className="topBarImageAlignment">
              <img
                src={
                  profileImage
                    ? `${config.imgUrl}/employee/${profileImage}`
                    : noProfileImage
                }
                className="topBarImage"
              />
              <div style={{ textTransform: "capitalize" }}>
                {(this.state.userData && this.state.userData.lname) ||
                this.state.userData.fname
                  ? `${this.state.userData.fname} ${this.state.userData.lname}`
                  : "No Name"}
                {/* <div className='themeColorText'>{this.state.userData &&
                  this.state.userData.role ?
                  this.state.userData.role : ''}</div> */}
              </div>
            </div>

            <div className="topbar__menu-divider" />
            <div className="topbar__menu">
              <TopbarMenuLink
                title={t("My Profile")}
                icon="user"
                path="/profile"
                toggleClose={this.toggle}
                updateTopbarProfileData={this.updateTopbarProfileData}
              />
              {/* <TopbarMenuLink title="Account Settings" icon="cog" path="/settings" toggleClose={this.toggle} /> */}
              <TopbarMenuLink
                title={t("Change Password")}
                icon="bubble"
                path="/changePassword"
                toggleClose={this.toggle}
                updateTopbarProfileData={this.updateTopbarProfileData}
              />
              {/* <TopbarMenuLink title="Settings" icon="cog" path="/adminSettings" toggleClose={this.toggle}
                updateTopbarProfileData={this.updateTopbarProfileData}
              /> */}
              <div className="topbar__menu-divider" />
              <TopbarMenuLink
                title={t("Log Out")}
                icon="exit"
                path="/log_in"
                toggleClose={this.toggle}
                updateTopbarProfileData={this.updateTopbarProfileData}
              />
            </div>
          </div>
        </Collapse>
      </div>
    );
  }
}
export default withTranslation("common")(TopbarProfile);
