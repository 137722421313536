import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import News from './components/Store&Product';

const newses = ({ t }) => (
  <Container>
    <News />
  </Container>
);

newses.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(newses);