import React from "react";
import PaymentsDT from "./PaymentsDT";
import apiCalls from "../../../../config/apiCalls";
import { withTranslation } from "react-i18next";
import DataTables from "../../CommonDataTable/DataTable";
import config from "../../../../config/config";

class Payments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      languageData: "",
      activeTab: "1",
    };
  }

  getTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: false,
      },
      // {
      //   show: true,
      //   textAlign: "center",
      //   field: "Sno",
      //   header: "Sno",
      //   width: 47,
      //   filter: false,
      //   sortable: false,
      // },
      {
        show: true,
        textAlign: "center",
        width: 150,
        mobile: true,
        field: "child_name",
        fieldType:"Array",
        header: "Student Name",
        filter: false,
        sortable: true,
        textCapitalize: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 200,
        mobile: true,
        field: "school_name",
        header: "School Name",
        filter: false,
        sortable: true,
        textCapitalize: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 150,
        mobile: true,
        field: "district_id",
        header: "District Id",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 100,
        mobile: true,
        field: "septaFee",
        header: "Septa Fee",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 200,
        mobile: true,
        field: "district_name",
        header: "District Name",
        textCapitalize: true,
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 200,
        mobile: true,
        field: "user_name",
        header: "Parent Name",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 200,
        mobile: true,
        field: "user_email",
        header: "Parent Email",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 200,
        mobile: true,
        field: "payment_type",
        header: "Payment Type",
        filter: false,
        sortable: true,
        textCapitalize: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 150,
        mobile: true,
        field: "payment_mode",
        header: "Payment Mode",
        fieldType:'BoldText',
        filter: false,
        sortable: true,
        textCapitalize: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 150,
        mobile: true,
        field: "payment_method",
        header: "Payment Method",
        fieldType:'BoldText',
        filter: false,
        sortable: true,
        textCapitalize: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 150,
        mobile: true,
        field: "payment_amount",
        header: "Payment Amount($)",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 150,
        mobile: true,
        field: "fee",
        header: "Transaction Fee($)",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 90,
        mobile: true,
        field: "pta_dues",
        header: "PTA Dues($)",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },


      {
        mobile: true,
        textAlign: "center",
        width: 160,
        field: "transactionId",
        show: true,
        header: "Transaction Id",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 110,
        mobile: true,
        field: "supporting_donations",
        header: "Supporting Donations($)",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },

      {
        mobile: true,
        textAlign: "center",
        width: 160,
        field: "payment_date",
        dateFormat: "MM-DD-YYYY h:mm a",
        fieldType: "Date",
        show: true,
        header: "Payment Date",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 150,
        mobile: true,
        field: "payment_isCompleted",
        fieldType: "BooleanBadge",
        header: "Payment Status",
        filter: false,
        sortable: true,
        options: config.paymentStatusOptions,
        style: {
          padding: "4px 6px",
          fontSize: 12,
          color: "white",
          textTransform: "capitalize",
          fontWeight: "bold",
        },
        displayInSettings: true,
      },
    ];
    return data;
  };

  getFormFields = () => {
    return [
      {
        value: "",
        type: "text",
        name: "child_name",
        id: "child_name",
        label: "Student Name",
        placeholder: "Student Name",
        required: true,
      },
      {
        value: "",
        type: "text",
        name: "school_name",
        id: "school_name",
        label: "School Name",
        placeholder: "School Name",
        required: true,
      },
      {
        value: "",
        type: "text",
        name: "user_email",
        id: "user_email",
        label: "Parent Email",
        placeholder: "Email",
        required: true,
      },
      {
        value: "",
        type: "text",
        name: "payment_mode",
        id: "payment_mode",
        label: "Payment Mode",
        placeholder: "Payment Mode",
        required: true,
      },
      {
        value: "",
        type: "text",
        name: "payment_type",
        id: "payment_type",
        label: "Payment Type",
        placeholder: "Payment Type",
        required: true,
      },
    ];
  };

  render() {
    return (
      <div>
        <DataTables
          getTableFields={this.getTableFields}
          formFields={this.getFormFields}
          addRequried={true}
          editRequired={false}
          deleteRequired={false}
          viewRequired={true}
          settingsRequired={true}
          globalSearch={"Email/School"}
          type="Payments"
          apiUrl={apiCalls.Payments}
          otherActionsRequired={true}
          exportToCsv={true}
          actionsTypes={actionsTypes}
          paymentMode={paymentMode}
        />
      </div>
    );
  }
}

export default withTranslation("common")(Payments);

const paymentMode = [
  {
    name: "payment",
    options: [
      { label: "All", value: "all" },
      { label: "Online", value: "online" },
      { label: "Offline", value: "offline" },
    ],
  },
];

const actionsTypes = [
  {
    name: "Delete",
    options: [{ label: "Delete", value: "Delete", show: true, multiple: true }],
  },

  {
    name: "ApprovePayment",
    options: [
      {
        label: "ApprovePayment",
        value: "ApprovePayment",
        show: true,
        multiple: true,
      },
    ],
  },
];
