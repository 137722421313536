/* eslint indent: "off" */
import React from "react";
import { Route, Switch } from "react-router-dom";
import MainWrapper from "./MainWrapper";
import Layout from "../Layout/index";

// Login
import Landing from "../Landing/LogIn";
// import LoginCheck from '../Landing/loginCheck';
import SignUp from "../SignUp/Signup";

// Dashboard
import Home from "../Dashboard/index";

// Profile
import ChangePassword from "../Account/ChangePassword/index";
import ChangeRecoverPassword from "../Account/ChangeRecoverPassword/index";
import ForgotPassword from "../Account/ForgotPassword/index";
import LoginChangePassword from "../Account/LoginChangePassword/index";
import Profile from "../Account/Profile";

// Tables
import Parent from "../Cruds/Parent/index";
import Faculty from "../Cruds/Faculty/index";
import School from "../Cruds/School/index";
import EmailTemplate from "../Cruds/EmailTemplates/index";
import Settings from "../Cruds/Settings/index";
import AdminSettings from "../Settings/index";
import Ecommerce from "../Cruds/Ecommerce/index";
import Class from "../Cruds/Class/index";
import News from "../Cruds/News/index";
import District from "../Cruds/District/index";

import OrderSummaryReport from "../Cruds/SummaryReport/index";
import StoreReport from "../Cruds/StoreReport/index";
import ProductReport from "../Cruds/ProductsReport/index";
import OrdersClass from "../Cruds/Orderbyclass/index"
import OrdersGrade from "../Cruds/Orderbygrade/index"

import Students from "../Cruds/Students/index";
import Roles from "../Cruds/Roles/index";
import AuditsLog from "../Cruds/AuditsLog/index";
import Events from "../Cruds/Events/index";
import Directory from "../Cruds/Directory/index";
import OrderSummary from "../Cruds/OrderSummary/index";
import OrderbyStoreProd from "../Cruds/Store&ProductReport/index";
import Departments from "../Cruds/DepartMents/index";
import FamilyData from "../Cruds/FamilyData/index";
import Employees from "../Cruds/Employees/index";
import LastLogin from "../Cruds/LastLogin/index";
import NoTRegistered from "../Cruds/NoTRegistered/index";
import Products from "../Cruds/Products/index";
import RefundDetails from "../Cruds/RefundDetails/index"
import Payments from "../Cruds/Payments/index";
import Groups from "../Cruds/Groups/index";
import GroupMembers from "../Cruds/GroupMembers/index";
import GroupEmail from "../Cruds/GroupEmail/index";
import SendGroupEmail from "../Cruds/SendGroupEmail/index";
import GroupMembersview from "../Cruds/ViewDeleteMember/index";
import Uploads from '../Cruds/Uploads/index';
import EmailStatus from '../Cruds/EmailStatus/index';
import PendingCart from "../Cruds/PendingCart/index";
import EcommercePayments from "../Cruds/EcommercePayments/index"
// 404 page not found
import ErrorNotFound from "../Account/404/index";
import ProductsReport from "../Cruds/ProductsReport/index";

const Tables = () => {
  let roles = localStorage.rolePermissions
    ? JSON.parse(localStorage.rolePermissions)
    : false;
  if (roles) {
    return (
      <Switch>
        <Route
          path="/users"
          component={
            roles["Users"] &&
              (roles["Users"] === "Edit" || roles["Users"] === "View")
              ? Parent
              : ErrorNotFound
          }
        /><Route
          path="/orderSummaryReport"
          component={
            roles["Orders Summary Report"] &&
              (roles["Orders Summary Report"] === "Edit" || roles["Orders Summary Report"] === "View")
              ? OrderSummaryReport
              : ErrorNotFound
          }
        /><Route
          path="/ecommerce_payments"
          component={
            roles["Ecommerce Payments"] &&
              (roles["Ecommerce Payments"] === "Edit" || roles["Ecommerce Payments"] === "View")
              ? EcommercePayments
              : ErrorNotFound
          }
        /><Route
          path="/order_by_store_products"
          component={
            roles["Orders Summary Report"] &&
              (roles["Orders Summary Report"] === "Edit" || roles["Orders Summary Report"] === "View")
              ? OrderbyStoreProd
              : ErrorNotFound
          }
        /><Route
          path="/pending_carts"
          component={
            roles["Pending Carts"] &&
              (roles["Pending Carts"] === "Edit" || roles["Pending Carts"] === "View")
              ? PendingCart
              : ErrorNotFound
          }
        />
        <Route
          path="/ordersummarybystore"
          component={
            roles["Orders By Store"] &&
              (roles["Orders By Store"] === "Edit" || roles["Orders By Store"] === "View")
              ?
              StoreReport
              : ErrorNotFound
          }
        />
        <Route
          path="/ordersummarybyproducts"
          component={
            roles["Orders By Products"] &&
              (roles["Orders By Products"] === "Edit" || roles["Orders By Products"] === "View")
              ? ProductsReport
              : ErrorNotFound
          }
        />
        <Route
          path="/uploads"
          component={
            roles["Uploads"] &&
              (roles["Uploads"] === "Edit" || roles["Uploads"] === "View")
              ? Uploads
              : ErrorNotFound
          }
        />
        <Route
          path="/emailstatus"
          component={
            roles["Email Status"] &&
              (roles["Email Status"] === "Edit" || roles["Email Status"] === "View")
              ? EmailStatus
              : ErrorNotFound
          }
        />
        <Route
          path="/faculties"
          component={
            roles["Faculty/Staff"] &&
              (roles["Faculty/Staff"] === "Edit" ||
                roles["Faculty/Staff"] === "View")
              ? Faculty
              : ErrorNotFound
          }
        />
        <Route
          path="/familydata"
          component={
            roles["Family Data"] &&
              (roles["Family Data"] === "Edit" || roles["Family Data"] === "View")
              ? FamilyData
              : ErrorNotFound
          }
        />
        <Route
          path="/lastlogin"
          component={
            roles["Last Login"] &&
              (roles["Last Login"] === "Edit" || roles["Last Login"] === "View")
              ? LastLogin
              : ErrorNotFound
          }
        />
        <Route
          path="/groupemail"
          component={
            roles["Group Email"] &&
              (roles["Group Email"] === "Edit" || roles["Group Email"] === "View")
              ? GroupEmail
              : ErrorNotFound
          }
        />
        <Route path="/sendgroupemail" component={SendGroupEmail} />
        <Route
          path="/nonregistered"
          component={
            roles["Registered"] &&
              (roles["Registered"] === "Edit" || roles["Registered"] === "View")
              ? NoTRegistered
              : ErrorNotFound
          }
        />
        <Route
          path="/employee"
          component={
            roles["Administrators"] &&
              (roles["Administrators"] === "Edit" ||
                roles["Administrators"] === "View")
              ? Employees
              : ErrorNotFound
          }
        />
        <Route
          path="/order_summary_grade"
          component={
            roles["Orders Summary By Grade"] &&
              (roles["Orders Summary By Grade"] === "Edit" ||
                roles["Orders Summary By Grade"] === "View")
              ? OrdersGrade
              : ErrorNotFound
          }
        />
        <Route
          path="/order_summary_class"
          component={
            roles["Orders Summary By Class"] &&
              (roles["Orders Summary By Class"] === "Edit" ||
                roles["Orders Summary By Class"] === "View")
              ? OrdersClass
              : ErrorNotFound
          }
        />
        <Route
          path="/products"
          component={
            roles["Products"] &&
              (roles["Products"] === "Edit" || roles["Products"] === "View")
              ? Products
              : ErrorNotFound
          }
        />

        <Route
          path="/RefundDetails"
          component={
            roles["Refund Details"] &&
              (roles["Refund Details"] === "Edit" || roles["Refund Details"] === "View")
              ? RefundDetails
              : ErrorNotFound
          }
        />

        <Route
          path="/school"
          component={
            roles["School"] &&
              (roles["School"] === "Edit" || roles["School"] === "View")
              ? School
              : ErrorNotFound
          }
        />
        <Route
          path="/template"
          component={
            roles["Email Templates"] &&
              (roles["Email Templates"] === "Edit" ||
                roles["Email Templates"] === "View")
              ? EmailTemplate
              : ErrorNotFound
          }
        />
        <Route
          path="/store"
          component={
            roles["Store"] &&
              (roles["Store"] === "Edit" || roles["Store"] === "View")
              ? Ecommerce
              : ErrorNotFound
          }
        />
        <Route
          path="/news"
          component={
            roles["News"] &&
              (roles["News"] === "Edit" || roles["News"] === "View")
              ? News
              : ErrorNotFound
          }
        />
        <Route
          path="/class"
          component={
            roles["Class"] &&
              (roles["Class"] === "Edit" || roles["Class"] === "View")
              ? Class
              : ErrorNotFound
          }
        />
        <Route
          path="/students"
          component={
            roles["Students"] &&
              (roles["Students"] === "Edit" || roles["Students"] === "View")
              ? Students
              : ErrorNotFound
          }
        />
        <Route
          path="/role"
          component={
            roles["Roles"] &&
              (roles["Roles"] === "Edit" || roles["Roles"] === "View")
              ? Roles
              : ErrorNotFound
          }
        />
        <Route
          path="/ordersummary"
          component={
            roles["Orders"] &&
              (roles["Orders"] === "Edit" || roles["Orders"] === "View")
              ? OrderSummary
              : ErrorNotFound
          }
        />
        <Route
          path="/audits"
          component={
            roles["Audit Logs"] &&
              (roles["Audit Logs"] === "Edit" || roles["Audit Logs"] === "View")
              ? AuditsLog
              : ErrorNotFound
          }
        />
        <Route
          path="/family"
          component={
            roles["Family"] &&
              (roles["Family"] === "Edit" || roles["Family"] === "View")
              ? Directory
              : ErrorNotFound
          }
        />
        <Route
          path="/departments"
          component={
            roles["Departments"] &&
              (roles["Departments"] === "Edit" || roles["Departments"] === "View")
              ? Departments
              : ErrorNotFound
          }
        />
        <Route
          path="/events"
          component={
            roles["Events"] &&
              (roles["Events"] === "Edit" || roles["Events"] === "View")
              ? Events
              : ErrorNotFound
          }
        />
        <Route
          path="/district"
          component={
            roles["District"] &&
              (roles["District"] === "Edit" || roles["District"] === "View")
              ? District
              : ErrorNotFound
          }
        />
        {/* <Route path="/settings" component={Settings}
        component={roles['Settings'] && (roles['Settings'] === "Edit" || roles['Settings'] === 'View') ? Settings : ErrorNotFound}
      /> */}
        <Route
          path="/adminSettings"
          component={AdminSettings}
          component={
            roles["Settings"] &&
              (roles["Settings"] === "Edit" || roles["Settings"] === "View")
              ? AdminSettings
              : ErrorNotFound
          }
        />
        <Route
          path="/payments"
          component={
            roles["Payments"] &&
              (roles["Payments"] === "Edit" || roles["Payments"] === "View")
              ? Payments
              : ErrorNotFound
          }
        />
        <Route
          path="/groups"
          component={
            roles["Groups"] &&
              (roles["Groups"] === "Edit" || roles["Groups"] === "View")
              ? Groups
              : ErrorNotFound
          }
        />
        <Route
          path="/addmember"
          component={
            roles["Add Member"] &&
              (roles["Add Member"] === "Edit" || roles["Add Member"] === "View")
              ? GroupMembers
              : ErrorNotFound
          }
        />
        <Route
          path="/viewanddeletemember"
          component={
            roles["View and Delete Member"] &&
              (roles["View and Delete Member"] === "Edit" ||
                roles["View and Delete Member"] === "View")
              ? GroupMembersview
              : ErrorNotFound
          }
        />
      </Switch>
    );
  }
};

const Account = () => (
  <Switch>
    <Route path="/changepassword" component={ChangePassword} />
    <Route path="/profile" component={Profile} />
  </Switch>
);

const wrappedRoutes = () => {
  let loginCredentials = localStorage.loginCredentials
    ? JSON.parse(localStorage.loginCredentials)
    : false;
  if (loginCredentials) {
    return (
      <div>
        <Layout />
        <div className="container__wrap">
          <Route path="/" component={Account} />
          <Route path="/" component={Tables} />
          <Route path="/dashBoard" component={Home} />
        </div>
      </div>
    );
  } else {
    return <ErrorNotFound />;
  }
};

const Router = () => (
  <MainWrapper>
    <main>
      <Switch>
        <Route
          path="/changeRecoverPassword/:enEmail"
          render={(props) => <ChangeRecoverPassword {...props} />}
        />
        <Route path="/loginChangePassword" component={LoginChangePassword} />
        <Route exact path="/sign_up" component={SignUp} />
        <Route exact path="/" component={Landing} />
        <Route path="/log_in" component={Landing} />
        <Route path="/logout" component={Landing} />
        <Route path="/forgot_password" component={ForgotPassword} />

        <Route path="/" component={wrappedRoutes} />
        <Route component={ErrorNotFound} />
      </Switch>
    </main>
  </MainWrapper>
);

export default Router;
