import React from "react";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import store from "../../../App/store";
import { withTranslation } from "react-i18next";
import DataTables from "../../CommonDataTable/DataTable";

class Events extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      languageData: "",
      activeTab: "1",
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState();
    let languageData =
      storeData &&
        storeData.settingsData &&
        storeData.settingsData.settings &&
        storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  }

  getTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: false,
      },
      // {
      //   textAlign: "center",
      //   width: 47,
      //   show: true,
      //   field: "Sno",
      //   header: "Sno",
      //   filter: false,
      //   sortable: false,
      //   placeholder: "Search",
      // },
      {
        mobile: true,
        textAlign: "left",
        width: 160,
        fieldType: "Link",
        field: "school_name",
        show: true,
        header: "School Name",
        filter: false,
        sortable: true,
        style: {
          color: config.templateColor,
          cursor: "pointer",
          textTransform: "capitalize",
        },
        displayInSettings: true,
      },
      {
        mobile: true,
        textAlign: "left",
        width: 80,
        field: "title",
        show: true,
        header: "Title",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        mobile: true,
        textAlign: "left",
        width: 80,
        field: "desc",
        show: true,
        header: "Desc",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        mobile: true,
        textAlign: "center",
        width: 80,
        field: "start",
        dateFormat: config.dateDBFormat,
        fieldType: "Date",
        show: true,
        header: "Start Date",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        mobile: true,
        textAlign: "center",
        width: 80,
        field: "end",
        dateFormat: config.dateDBFormat,
        fieldType: "Date",
        show: true,
        header: "End Date",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        mobile: true,
        textAlign: "center",
        width: 80,
        field: "startTime",
        fieldType: "Time",
        dateFormat: config.datePickerOnlyTimeFormat,
        show: true,
        header: "Start Time",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        mobile: true,
        textAlign: "center",
        width: 80,
        field: "endTime",
        fieldType: "Time",
        dateFormat: config.datePickerOnlyTimeFormat,
        show: true,
        header: "End Time",
        filter: false,
        sortable: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 70,
        field: "Actions",
        fieldType: "Actions",
        header: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
      },
    ];
    return data;
  };

  getFormFields = () => {
    return [
      {
        required: true,
        value: "",
        searchField: "Name",
        searchApi: "schools",
        options: [
          { school_name: "Name" },
          { district_id: "district_id" },
          { school_id: "school_id" },
        ],
        type: "autoComplete",
        name: "school_name",
        label: "School Name",
        id: "Name",
        placeholder: "School Name",
      },
      {
        value: "",
        type: "text",
        name: "title",
        label: "Title",
        id: "title",
        placeholder: "Title",
        required: true,
      },
      {
        value: "",
        name: "start",
        type: "date",
        label: "Start Date",
        id: "Start Date",
        placeholder: "Start Date",
        required: true,
      },
      {
        value: "",
        name: "startTime",
        type: "time",
        label: "Start Time",
        id: "Start Time",
        placeholder: "Start Time",
        required: true,
      },
      {
        value: "",
        name: "end",
        type: "date",
        label: "End Date",
        id: "End Date",
        placeholder: "End Date",
        required: true,
      },
      {
        value: "",
        name: "endTime",
        type: "time",
        label: "End Time",
        id: "End Time",
        placeholder: "End Time",
        required: true,
      },
      {
        value: "",
        type: "textarea",
        name: "desc",
        label: "Description",
        id: "Description",
        placeholder: "Description",
        required: true,
      },
    ];
  };

  render() {
    return (
      <div>
        <DataTables
          getTableFields={this.getTableFields}
          formFields={this.getFormFields}
          addRequried={true}
          editRequired={true}
          deleteRequired={true}
          viewRequired={true}
          settingsRequired={true}
          globalSearch={"Title"}
          exportToCsv={true}
          type="Events"
          apiUrl={apiCalls.Events}
          actionsTypes={actionsTypes}
        />
      </div>
    );
  }
}

export default withTranslation("common")(Events);

const actionsTypes = [
  {
    name: "Delete",
    options: [{ label: "Delete", value: "Delete", show: true, multiple: true }],
  },
];
