import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import LastLogin from './components/LastLogin'

const lastlogin = ({ t }) => (
  <Container>
    <LastLogin />
  </Container>
);

lastlogin.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(lastlogin);