const apiCalls = {
  Orders: "orders",
  Products: "products",
  Store: "stores",
  Templates: "templates",
  Activities: "activities",
  Faculty: "faculties",
  Departments: "departments",
  Classes: "classes",
  Schools: "schools",
  District: "districts",
  Roles: "roles",
  Family: "directorys",
  loginUser: "auth/login",
  loginChangePassword: "auth/loginChangePassword",
  MenuList: "menus",
  Settings: "settings",
  LocationImagePath: "uploads?uploadWhileCreate=true&uploadPath=location",
  ProfileImagePath: "uploads?uploadWhileCreate=true&uploadPath=employee",
  dashboard: "dashboard/counts",
  User: "users",
  Brand: "brands",
  Employee: "employees",
  Employees: "employees",
  Events: "events",
  Payments: "payments",
  Groups: "groups",
  GroupMembers: "groups/nonmembers",
  GroupMembersView: "members",
  Uploads: 'bulkuploadStatus',
  EmailStatus: 'emailstatus',
  VerifyLink: 'auth/checkpasswordlink',
  TransactionDetails: "directorys/getTransationDetails"
};
export default apiCalls;
