const commonSampleFilePath =
  "https://api.mobilearq.com/images/sample";

const configSampleFiles = {
  school: `${commonSampleFilePath}/sample_school_bulk_upload_file.xlsx`,
  class: `${commonSampleFilePath}/sample_class_bulk_upload_file.xlsx`,
  family: `${commonSampleFilePath}/sample_family_bulk_upload_file.xlsx`,
  district: `${commonSampleFilePath}/sample_district_bulk_upload_file.xlsx`,
  faculty: `${commonSampleFilePath}/sample_faculty_bulk_upload_file.xlsx`,
  department: `${commonSampleFilePath}/sample_department_bulk_upload_file.xlsx`,
};
export default configSampleFiles;
