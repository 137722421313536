import React from "react";
import { Container } from "reactstrap";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import GroupMembersData from "./components/GroupMembers";

const groupMemberdata = ({ t }) => (
  <Container>
    <GroupMembersData />
  </Container>
);

groupMemberdata.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation("common")(groupMemberdata);
