import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import GroupEmail from './components/GroupEmail';

const GroupEmailTemplatesPage = () => (
  <Container>
    <GroupEmail />
  </Container>
);

export default withTranslation('common')(GroupEmailTemplatesPage);